import "./itrstZone.css";

import * as React from "react";
import { useEffect, useState, useRef, useCallback } from "react";

import { Input, Checkbox, Icon, Pagination } from "semantic-ui-react";

import Modal from "../../shared/components/uiElements/Modal";
import Popup from "../../shared/components/uiElements/Popup";
import questionMarkGuide from "../../config/questionMarkGuide";

// React-Map-GL
import Map, { Source, Layer, useControl, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// import draw, { Editor, DrawPolygonMode, EditingMode } from "react-map-gl-draw";

import DrawControl from "./draw-control.ts";

// TURF
import turf from "turf";

// AXIOS
import axios from "axios";

import moment from "moment";

// Proj4
import proj4 from "proj4";

// config
import config from "../../config/config";

proj4.defs(
  "EPSG:5179",
  "+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs("EPSG:4326", "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs");

const ItrstZone = (props) => {
  const minDate = new Date().getFullYear() - 15;
  const maxDate = new Date().getFullYear();
  const [yearstate, setYear] = useState(maxDate);

  // Mapbox TOKEN
  const MAPBOX_TOKEN =
    "pk.eyJ1Ijoic2RqMzIzIiwiYSI6ImNsZGIxazJ5cDBvaXkzb25uM2NhZ2xvc3EifQ.wMTHtB5DTOMkIn-RI-Xs-w";
  const APIToken = localStorage.getItem("login-token");

  const mapRef = React.useRef();

  // Raster Layer (V-World MAP)
  const MAP_LAYER_ID = "vworld_map";
  const MAP_SRC = {
    type: "raster",
    minzoom: 6,
    maxzoom: 19,
    tiles: [config.vworldSat],
  };
  const MAP_LAYER_STYLE = {
    id: MAP_LAYER_ID,
    type: "raster",
    paint: {
      "raster-opacity": 1.0,
    },
  };

  // Vector Layer (FarmMap Shape)
  const FM_LAYER_ID = "tn_fmap_info";
  const FM_SRC = {
    type: "vector",
    minzoom: 6,
    maxzoom: 19,
    tiles: [
      config.geoServer +
        "/gwc/service/wmts?layer=famid-dev:tn_fmap_info&style=&tilematrixset=EPSG:900913&Service=WMTS&Request=GetTile&Version=1.0.0&Format=application/vnd.mapbox-vector-tile&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}",
    ],
  };
  let FM_LAYER_STYLE;

  function INIT_FM() {
    FM_LAYER_STYLE = {
      id: FM_LAYER_ID,
      type: "fill",
      "source-layer": FM_LAYER_ID,
      layout: { visibility: "visible" },
      paint: {
        "fill-color": "rgba(255, 255, 0, 0.3)",
        "fill-outline-color": "rgba(0, 0, 0, 1.0)",
      },
    };

    return (
      <Source key={FM_LAYER_ID} {...FM_SRC}>
        <Layer {...FM_LAYER_STYLE} />
      </Source>
    );
  }

  // 관심구역 정보입력 ON & OFF
  const [visible, setVisible] = useState(false);
  // 검색방식
  const [addressType, setAddressType] = useState("addr");
  // 검색입력주소
  const [addressInput, setAddressInput] = useState("");
  // 검색결과
  const [addressResult, setAddressResult] = useState();
  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // 등록성공
  const [isOK, setIsOK] = useState(false);
  // Drawing
  const [drawing, setDrawing] = useState(false);
  // Draw polygon points coord
  const [polygonCoord, setPolygonCoord] = useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const [onMouseID, setOnMouseID] = useState([]);
  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");
  // Required
  const [namestate, setName] = useState();
  // const [yearstate, setYear] = useState(maxDate);
  const [cropstate, setCrop] = useState();
  const [etcCnstate, setEtcCn] = useState();
  const [geoJsonstate, setGeoJson] = useState();
  // Optional
  const [shpPath, setShpPath] = useState();
  const [orgFileNm, setOrgFileNm] = useState();

  const [markerCoord, setMarkerCoord] = useState();

  const handleName = (event) => {
    event.preventDefault();
    setName(event.target.value);
  };
  const handleYear = (event) => {
    event.preventDefault();
    const year = parseInt(event.target.value, 10);
    setYear(year);
  };
  const handleEtcCn = (event) => {
    event.preventDefault();
    setEtcCn(event.target.value);
  };
  const handleDrawing = () => {
    if (fileShpRef.current.value === "") {
      if (drawing) {
        INIT_FM();
        setSelectedID([]);
        setDrawing(false);
      } else {
        INIT_FM();
        setSelectedID([]);
        setDrawing(true);
      }
    } else {
      setPopupMsg("파일 업로드 시 영역 선택은 불가능합니다.");
      setShowPopup(true);
    }
  };
  const handleDelete = () => {
    INIT_FM();
    setSelectedID([]);
    setDrawing(false);
    setShpPath();
    setOrgFileNm();
    setSrc();
    setLayer();
    if (fileShpRef.current) {
      fileShpRef.current.value = "";
    }
  };
  const handleAddressInput = (event) => {
    event.preventDefault();
    setAddressInput(event.target.value);
  };

  const pageSize = 10;

  useEffect(() => {
    if (addressResult) {
      setTotalPages(Math.ceil(addressResult.length / pageSize));
    }
  }, [addressResult]);

  const handlePageChange = (event, data) => {
    setActivePage(data.activePage);
  };

  // 주소검색
  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      if (addressInput) {
        e.preventDefault();
        adrressSearch();
      } else {
        setPopupMsg("주소를 입력하세요.");
        setShowPopup(true);
      }
    }
  };

  function adrressSearch() {
    if (addressType == "addr") {
      axios({
        method: "get",
        url: `/api/parcel/search?type=addr&keyword=${addressInput}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.success) {
            setAddressResult(result.data.data);
          } else {
            setPopupMsg("주소 검색 실패");
            setShowPopup(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (addressType == "pnu") {
      axios({
        method: "get",
        url: `/api/parcel/search?type=pnu&keyword=${Number(addressInput)}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.success) {
            setAddressResult(result.data.data);
          } else {
            setPopupMsg("주소 검색 실패");
            setShowPopup(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const ITRST_LAYER_ID = "selected_ItrstZone";
  const [ITRST_SRC_ACTIVE, setSrc] = useState(null);
  const [ITRST_LAYER_STYLE_ACTIVE, setLayer] = useState(null);

  // const [fileExt, setFileExt] = useState(null);

  // SHP Upload
  const uploadShp = (e) => {
    setPolygonCoord([]);
    const formData = new FormData();
    formData.append("shpFile", e.target.files[0]);

    axios({
      method: "post",
      url: `/api/zone/upload`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      data: formData,
    })
      .then((result) => {
        if (result.data.success) {
          setVisible(true);
          //           const crs = proj4.defs(result);
          // console.log(crs);

          const geojsondata = result.data.data.geoJson;
          const center = turf.center(geojsondata);

          const map = mapRef.current.getMap();
          map.jumpTo({
            center: [
              center.geometry.coordinates[0],
              center.geometry.coordinates[1],
            ],
            essential: true,
          });

          setShpPath(result.data.data.shpPath);
          setOrgFileNm(result.data.data.orgFileNm);

          const srcDummy = {
            type: "geojson",
            data: geojsondata,
          };

          const layerDummy = {
            id: ITRST_LAYER_ID,
            type: "fill",
            source: srcDummy,
            layout: {},
            paint: {
              "fill-color": "rgba(0, 0, 255, 0.45)",
              "fill-outline-color": "rgba(0, 0, 255, 1.0)",
            },
          };

          setSrc(srcDummy);
          setLayer(layerDummy);
        } else {
          setPopupMsg(result.data.msg);
          setShowPopup(true);

          // setPopupMsg("경계파일 업로드 실패");
          // setShowPopup(true);
        }

        // const beforGeojson = result.data.data.geoJson;
        // const beforecoord = beforGeojson.features[0].geometry.coordinates
        // const coordArray = beforecoord.reduce((result, element, index) => {
        //     if (index % 2 === 0)
        //     {
        //         result.push([element, beforecoord[index + 1]]);
        //     }
        //     return result;
        //     }, []);

        // const afterGeojson =
        // {
        //     "type": "MultiPolygon",
        //     "coordinates": coordArray[0][0]
        // }

        // setGeoJson(JSON.stringify(beforGeojson));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fill Farmmap
  function highlightFarmMapFill(sID, oID) {
    if (sID == "") {
      sID = "00000000";
    }

    if (oID == "") {
      oID = "00000000";
    }

    // let newLayer = {
    //     id: FM_LAYER_ID,
    //     type: 'fill',
    //     source: FM_SRC,
    //     "source-layer": FM_LAYER_ID,
    //     paint: {
    //         'fill-color':
    //         [
    //             "match",
    //             ["get", "FMAP_INNB"],
    //             sID,
    //             "rgba(0, 0, 255, 0.7)",
    //             'rgba(255, 255, 0, 0.3)',
    //         ],
    //         'fill-outline-color':
    //         [
    //             "match",
    //             ["get", "FMAP_INNB"],
    //             oID,
    //             "rgba(255, 255, 255, 1.0)",
    //             'rgba(0, 0, 0, 0.3)',
    //         ],
    //     },
    // };

    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.setPaintProperty(FM_LAYER_ID, "fill-color", [
        "match",
        ["get", "FMAP_INNB"],
        sID,
        "rgba(0, 0, 255, 0.7)",
        "rgba(255, 255, 0, 0.3)",
      ]);
      map.setPaintProperty(FM_LAYER_ID, "fill-outline-color", [
        "match",
        ["get", "FMAP_INNB"],
        oID,
        "rgba(255, 255, 255, 1.0)",
        "rgba(0, 0, 0, 0.3)",
      ]);
    }

    // setFarmMapLayer(newLayer);
  }

  useEffect(() => {
    highlightFarmMapFill(selectedID, onMouseID);
  }, [selectedID, onMouseID]);

  // const onMovemap = (e) => {
  //     if(showMap && showShp){
  //         let selectedFeature = mapRef.current.queryRenderedFeatures(e.point, {layers: [FM_LAYER_ID]});
  //         let selectedFeatureID = 0;
  //         if (selectedFeature[0]){
  //             selectedFeatureID = selectedFeature[0].properties.FMAP_INNB;
  //         }
  //         if (selectedFeatureID){
  //             setOnMouseID(selectedFeatureID);
  //         }
  //     }
  // }

  //팜맵 단일선택
  // const onclickmap = (e) => {
  //     if(!drawing) {
  //         // const selectedFeature = e.features[0];
  //         const selectedFeature = mapRef.current.queryRenderedFeatures(e.point, {layers: [FM_LAYER_ID]});

  //         if (selectedFeature[0]) {
  //         const selectedFeatureID = selectedFeature[0].properties.FMAP_INNB;
  //         const polygonCoords4326 = selectedFeature[0].geometry.coordinates;
  //         // const polygonCoords5179 = [];

  //         // polygonCoords4326[0].map((e)=>{
  //             // polygonCoords5179.push(proj4("EPSG:4326", "EPSG:5179", e));
  //         // })

  //         const geometry = {
  //             "type": "MultiPolygon",
  //             "coordinates": [polygonCoords4326]
  //         }

  //         const feature = {
  //             "type": "Feature",
  //             "properties":
  //             {
  //                 "FMAP_INNB": selectedFeature[0].properties.FMAP_INNB,
  //                 "VDPT_YR": selectedFeature[0].properties.VDPT_YR,
  //                 "PNU_LNM_CD": selectedFeature[0].properties.PNU_LNM_CD,
  //                 "LGL_EMD_CD": selectedFeature[0].properties.LGL_EMD_CD,
  //                 "LGL_EMD_NM": selectedFeature[0].properties.LGL_EMD_NM,
  //                 "CHG_CFCD": selectedFeature[0].properties.CHG_CFCD,
  //                 "CHG_CFNM": selectedFeature[0].properties.CHG_CFNM,
  //                 "LNM": selectedFeature[0].properties.LNM,
  //                 "INTPR_CD": selectedFeature[0].properties.INTPR_CD,
  //                 "INTPR_NM": selectedFeature[0].properties.INTPR_NM,
  //                 "INVD_CFCD": selectedFeature[0].properties.INVD_CFCD,
  //                 "INVD_CFNM": selectedFeature[0].properties.INVD_CFNM,
  //                 "ITPINP_DE": selectedFeature[0].properties.ITPINP_DE,
  //                 "RNHST_CD": selectedFeature[0].properties.RNHST_CD,
  //                 "RNHST_NM": selectedFeature[0].properties.RNHST_NM,
  //                 "AREA": selectedFeature[0].properties.AREA
  //             },
  //             "geometry": geometry
  //         }

  //         if (selectedFeatureID){
  //             let index = -1;
  //             selectedID.map((e, i)=>{
  //             if(e === selectedFeatureID){
  //                 index = i;
  //                 }
  //             })
  //             let copySelectedID = [...selectedID]
  //             let copyFeatures = [];
  //             if(geoJsonstate){
  //                 const geojsonParse = JSON.parse(geoJsonstate);
  //                 copyFeatures = [...geojsonParse.features];
  //             }
  //             if(index >= 0){
  //                 copySelectedID.splice(index, 1);
  //                 copyFeatures.splice(index, 1);
  //             }
  //             else if(index == -1){
  //                 copySelectedID.push(selectedFeatureID);
  //                 copyFeatures.push(feature);
  //             }

  //             const afterGeojson = {
  //                 "type": "FeatureCollection",
  //                 "name": "reg_cut_test",
  //                 "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::4326" } },
  //                 "features": copyFeatures
  //             }

  //             setSelectedID(copySelectedID);
  //             setGeoJson(JSON.stringify(afterGeojson));
  //         }
  //         }
  //     }
  // }

  const onclickmap = (e) => {
    if (!drawing) {
      const selectedFeature = mapRef.current.queryRenderedFeatures(e.point, {
        layers: [FM_LAYER_ID],
      });

      if (selectedFeature.length != 0) {
        const pointCoord = {
          type: "Point",
          coordinates: [e.lngLat.lng, e.lngLat.lat],
        };

        axios({
          method: "get",
          url: `/api/fmap/search/area?geoJson=${JSON.stringify(pointCoord)}`,
          headers: {
            Authorization: `Bearer {${APIToken}}`,
          },
        }).then((response) => {
          const selectedFeature = response.data.data;

          if (selectedFeature[0]) {
            const selectedFeatureID = selectedFeature[0].FMAP_INNB;
            const polygonCoords4326 = JSON.parse(selectedFeature[0].geojson);

            const geometry = {
              type: "MultiPolygon",
              coordinates: [polygonCoords4326.coordinates[0]],
            };

            const feature = {
              type: "Feature",
              properties: {
                FMAP_INNB: selectedFeature[0].FMAP_INNB,
                VDPT_YR: selectedFeature[0].VDPT_YR,
                PNU_LNM_CD: selectedFeature[0].PNU_LNM_CD,
                LGL_EMD_CD: selectedFeature[0].LGL_EMD_CD,
                LGL_EMD_NM: selectedFeature[0].LGL_EMD_NM,
                CHG_CFCD: selectedFeature[0].CHG_CFCD,
                CHG_CFNM: selectedFeature[0].CHG_CFNM,
                LNM: selectedFeature[0].LNM,
                INTPR_CD: selectedFeature[0].INTPR_CD,
                INTPR_NM: selectedFeature[0].INTPR_NM,
                INVD_CFCD: selectedFeature[0].INVD_CFCD,
                INVD_CFNM: selectedFeature[0].INVD_CFNM,
                ITPINP_DE: selectedFeature[0].ITPINP_DE,
                RNHST_CD: selectedFeature[0].RNHST_CD,
                RNHST_NM: selectedFeature[0].RNHST_NM,
                AREA: selectedFeature[0].AREA,
              },
              geometry: geometry,
            };

            if (selectedFeatureID) {
              let index = -1;
              selectedID.map((e, i) => {
                if (e === selectedFeatureID) {
                  index = i;
                }
              });
              let copySelectedID = [...selectedID];
              let copyFeatures = [];
              if (geoJsonstate) {
                const geojsonParse = JSON.parse(geoJsonstate);
                copyFeatures = [...geojsonParse.features];
              }
              if (index >= 0) {
                copySelectedID.splice(index, 1);
                copyFeatures.splice(index, 1);
              } else if (index == -1) {
                copySelectedID.push(selectedFeatureID);
                copyFeatures.push(feature);
              }

              const afterGeojson = {
                type: "FeatureCollection",
                name: "reg_cut_test",
                crs: {
                  type: "name",
                  properties: { name: "urn:ogc:def:crs:EPSG::4326" },
                },
                features: copyFeatures,
              };

              setSelectedID(copySelectedID);
              setGeoJson(JSON.stringify(afterGeojson));
            }
          }
        });
      }
    }
  };

  // 팜맵 영역 선택
  useEffect(() => {
    if (polygonCoord[0]) {
      const polygonGeojson = {
        type: "MultiPolygon",
        coordinates: [[polygonCoord[0].geometry.coordinates[0]]],
      };

      axios({
        method: "get",
        url: `/api/fmap/search/area?geoJson=${JSON.stringify(polygonGeojson)}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((response) => {
          const selectedFeature = response.data.data;
          const selectedFeatureID = [];
          selectedFeature.map((e) => {
            selectedFeatureID.push(e.FMAP_INNB);
          });

          const features = [];

          selectedFeature.map((e) => {
            const feature = {
              type: "Feature",
              properties: {
                FMAP_INNB: e.FMAP_INNB,
                VDPT_YR: e.VDPT_YR,
                PNU_LNM_CD: e.PNU_LNM_CD,
                LGL_EMD_CD: e.LGL_EMD_CD,
                LGL_EMD_NM: e.LGL_EMD_NM,
                CHG_CFCD: e.CHG_CFCD,
                CHG_CFNM: e.CHG_CFNM,
                LNM: e.LNM,
                INTPR_CD: e.INTPR_CD,
                INTPR_NM: e.INTPR_NM,
                INVD_CFCD: e.INVD_CFCD,
                INVD_CFNM: e.INVD_CFNM,
                ITPINP_DE: e.ITPINP_DE,
                RNHST_CD: e.RNHST_CD,
                RNHST_NM: e.RNHST_NM,
                AREA: e.AREA,
              },

              geometry: JSON.parse(e.geojson),
            };

            features.push(feature);
          });

          const afterGeojson = {
            type: "FeatureCollection",
            name: "reg_cut_test",
            crs: {
              type: "name",
              properties: { name: "urn:ogc:def:crs:EPSG::4326" },
            },
            features: features,
          };

          setSelectedID(selectedFeatureID);
          setGeoJson(JSON.stringify(afterGeojson));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [polygonCoord]);

  const handleKeyPress = (e) => {
    if (drawing) {
      if (e.key === "Escape") {
        handleDrawing(false);
      }
    }
  };

  function validation() {
    if (namestate == undefined || namestate == "") {
      setPopupMsg("관심구역명을 입력하세요.");
      setShowPopup(true);
      return false;
    }
    if (yearstate == undefined || yearstate == "") {
      setPopupMsg("연도정보를 입력하세요.");
      setShowPopup(true);
      return false;
    }
    // if (isNaN(yearstate) || yearstate.length !== 4) {
    //   setPopupMsg("연도정보를 4자리 숫자로 입력하세요.");
    //   setShowPopup(true);
    //   return false;
    // }
    if (cropstate == undefined) {
      setPopupMsg("이모작 여부를 선택하세요.");
      setShowPopup(true);
      return false;
    }
    if (geoJsonstate == undefined && shpPath == undefined) {
      setPopupMsg("관심구역 영역을 선택하세요.");
      setShowPopup(true);
      return false;
    }
    if (tagListState[tagListState.length - 1] == "") {
      setPopupMsg("태그명을 입력하세요.");
      setShowPopup(true);
      return false;
    }

    return true;
  }

  // 관심구역 생성(등록)
  const onClickAdd = () => {
    let isOk = validation();

    if (isOk) {
      axios({
        method: "post",
        url: `/api/zone/add`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: {
          name: namestate,
          year: yearstate,
          crop: cropstate,
          etcCn: etcCnstate,
          shpPath: shpPath,
          orgFileNm: orgFileNm,
          geoJson: geoJsonstate,
          tagList: JSON.stringify(tagListState),
        },
      })
        .then((result) => {
          if (result.data.success) {
            setPopupMsg(
              "<p>" +
                "관심구역 생성이 완료되었습니다.<br>관심구역 관리 탭에서 조회 가능합니다." +
                "</p>"
            );
            setShowPopup(true);
            setIsOK(true);
          } else {
            setPopupMsg("관심구역 생성 실패");
            setShowPopup(true);
          }
        })
        .catch((error) => {
          setPopupMsg("관심구역 생성 실패");
          setShowPopup(true);
          console.log(error);
        });
    }
  };

  const [tagListState, setTagListState] = useState([]);

  const tagNameHandler = (e, i) => {
    const dummyArray = [...tagListState];
    dummyArray[i] = e.target.value;
    setTagListState(dummyArray);
  };

  const addTag = () => {
    if (tagListState.length == 0) {
      const dummyArray = [...tagListState];
      dummyArray.push("");
      setTagListState(dummyArray);
    } else if (tagListState.length >= 1 && tagListState.length < 10) {
      if (tagListState[tagListState.length - 1] == "") {
        setPopupMsg("기존 태그의 태그명을 입력하세요.");
        setShowPopup(true);
      } else {
        const dummyArray = [...tagListState];
        dummyArray.push("");
        setTagListState(dummyArray);
      }
    } else if (tagListState.length >= 10) {
      setPopupMsg("최대 태그 개수는 10개입니다.");
      setShowPopup(true);
    }
  };

  const delTag = (i) => {
    const dummyArray = [...tagListState];
    dummyArray.splice(i, 1);
    setTagListState(dummyArray);
  };

  const validateTag = () => {
    const dummyArray = [...tagListState];
    const result = [
      ...new Set(
        dummyArray.filter((item, index) => dummyArray.indexOf(item) !== index)
      ),
    ];
    if (result.length >= 1) {
      setPopupMsg("중복 태그입니다.");
      setShowPopup(true);
    }
  };

  const renderTagList = () => {
    return (
      tagListState &&
      tagListState.map((tag, i) => {
        return (
          <div className="newTag">
            <input
              className="tagName"
              value={tagListState[i]}
              onChange={(e) => {
                tagNameHandler(e, i);
              }}
              onBlur={validateTag}
              maxLength="15"
              style={{
                width: `${tagListState[i].length * 8 + 40}px`, // 글자 수에 따라 동적으로 너비 조정
              }}
            ></input>
            <i
              className="fa-regular fa-circle-minus tagDelete"
              onClick={() => {
                delTag(i);
              }}
            ></i>
          </div>
        );
      })
    );
  };

  // 파일업로드
  const fileShpRef = useRef(null);
  const [shpUplClicked, setShpUplClicked] = useState(false);
  const handleFileClick = () => {
    setShpUplClicked(true);
    fileShpRef.current.click();
  };

  // 물음표 가이드
  const [isShpFileHovered, setIsShpFileHovered] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleShpFileMouseEnter = () => {
    setIsShpFileHovered(true);
  };

  const handleShpFileMouseLeave = () => {
    setIsShpFileHovered(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // const [features, setFeatures] = useState({});

  // const onUpdate = useCallback(e => {
  //   setFeatures(currFeatures => {
  //     const newFeatures = {...currFeatures};
  //     for (const f of e.features) {
  //       newFeatures[f.id] = f;
  //     }
  //     return newFeatures;
  //   });
  // }, []);

  // const onDelete = useCallback(e => {
  //   setFeatures(currFeatures => {
  //     const newFeatures = {...currFeatures};
  //     for (const f of e.features) {
  //       delete newFeatures[f.id];
  //     }
  //     return newFeatures;
  //   });
  // }, []);

  const brCount = (popupMsg.match(/<br>/g) || []).length;
  const paddingValue =
    brCount === 0 ? "35px 0" : brCount === 1 ? "25px 0" : "15px 0";
  const fontSizeValue = brCount === 0 ? "18px" : "16px";
  const clickTimeout = useRef(null);

  function displayMarker() {
    if (markerCoord) {
      return (
        <Marker
          longitude={markerCoord[0]}
          latitude={markerCoord[1]}
          anchor="bottom"
        >
          <div className="mapbox-Marker-image" />
        </Marker>
      );
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <div onKeyDown={handleKeyPress}>
        <Modal
          isFixed={false}
          show={props.showGen}
          // onCancel={()=>{props.setShowGen(false)}}
          header="관심구역 생성"
          icon={
            <i
              className="code x icon"
              onClick={() => {
                props.setShowGen(false);
              }}
            />
          }
          isMobile={props.isMobile}
          className={`itrstZone`}
        >
        <div className="contents-col itrstzone-container">
            <div className="contents-col sub-container">
              <div className="contents-row search-itrstZone-form">
                <div className="contents-col search-container">
                  <div className="contents-row search-label-box">
                    <div className="search-text">필지 검색</div>
                    <div className="search-select">
                      <Checkbox
                        name="checkboxRadioGroup"
                        value="addr"
                        checked={addressType === "addr"}
                        onChange={(e, data) => {
                          setAddressType(data.value);
                        }}
                      />
                      <div className="textB">지번 주소</div>
                      <Checkbox
                        name="checkboxRadioGroup"
                        value="pnu"
                        checked={addressType === "pnu"}
                        onChange={(e, data) => {
                          setAddressType(data.value);
                        }}
                      />
                      <div className="textB">PNU</div>
                    </div>
                  </div>
                  {/* <div className="contents-row flex-center"> */}
                  <Input
                    className="search-input"
                    icon={
                      <Icon
                        className="address-search-icon"
                        name="search"
                        link
                        onClick={adrressSearch}
                      />
                    }
                    element="input"
                    type="text"
                    placeholder="예) 전라남도 영광군 영광읍 신월리"
                    onKeyPress={handleOnKeyPress}
                    onChange={handleAddressInput}
                    value={addressInput}
                  ></Input>
                  {/* </div> */}
                </div>
                <input
                  id="shpUpload"
                  type="file"
                  accept=".zip"
                  style={{ visibility: "hidden" }}
                  onChange={uploadShp}
                  ref={fileShpRef}
                ></input>
                <div className="divider-col"></div>
                <div className="contents-row itrstZone-btn-container">
                  <div className="contents-row itrstZone-form">
                    {/* <label htmlFor="shpUpload" className="gc-btn button shpUpload"> */}
                    <label
                      onClick={handleFileClick}
                      className={`gc-btn button shpUpload ${
                        shpUplClicked && orgFileNm ? "active" : ""
                      }`}
                    >
                      <div className="itrstzoneBtn shpFile-upl"></div>
                      <label className="shpUploadLabel">파일업로드</label>
                      <i
                        className={`fa-solid fa-circle-question ${
                          isShpFileHovered ? "hover" : ""
                        }`}
                        onMouseEnter={handleShpFileMouseEnter}
                        onMouseLeave={handleShpFileMouseLeave}
                      >
                        <span
                          className={`tooltip ${
                            isShpFileHovered ? "hover" : ""
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: questionMarkGuide.file,
                          }}
                        />
                      </i>

                      {/* <input
                                        id="shpUpload" 
                                        type="file" 
                                        accept=".zip"
                                        style={{ visibility:"hidden" }}
                                        onChange={uploadShp}
                                    >
                                    </input> */}
                      {/* <div className="shpUpload-question">좌표계 : <span>UTM-K (EPSG:5179)</span><br></br>파일확장자 : <span>.zip</span></div> */}
                    </label>

                    <div
                      className={`gc-btn button draw ${
                        drawing ? "active" : ""
                      }`}
                      onClick={handleDrawing}
                    >
                      <div className="itrstzoneBtn draw-selection"></div>
                      영역선택
                      <i
                        className={`fa-solid fa-circle-question ${
                          isHovered ? "hover" : ""
                        }`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <span
                          className={`tooltip ${isHovered ? "hover" : ""}`}
                          dangerouslySetInnerHTML={{
                            __html: questionMarkGuide.draw,
                          }}
                        />
                      </i>
                    </div>
                    <div className="gc-btn button reset" onClick={handleDelete}>
                      <div className="itrstzoneBtn reset-selection"></div>선택
                      초기화
                    </div>
                  </div>

                  <div className="contents-row btn-group">
                    <div
                      className="gc-btn button remove"
                      onClick={() => {
                        props.setShowGen(false);
                      }}
                    >
                      취소
                    </div>
                    <div className="gc-btn button add" onClick={onClickAdd}>
                      등록
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`search-result-container ${
                  addressResult ? "" : "hidden"
                }`}
              >
                {/* <div className="subtitle-text">검색결과</div> */}
                {addressResult &&
                  addressResult.map((e, i) => {
                    if (addressType == "addr") {
                      return (
                        i >= (activePage - 1) * pageSize &&
                        i < activePage * pageSize && (
                          <div
                            key={i}
                            className="search-result-item"
                            onClick={() => {
                              setVisible(true);
                              const map = mapRef.current.getMap();
                              map.jumpTo({
                                center: [e.point.x, e.point.y],
                                essential: true,
                              });
                              setMarkerCoord([e.point.x, e.point.y]);
                              setAddressInput(e.address.parcel);
                              setAddressResult();
                            }}
                          >
                            <div className="result-text">
                              {e.address.parcel}
                            </div>
                          </div>
                        )
                      );
                    } else if (addressType == "pnu") {
                      return (
                        i >= (activePage - 1) * pageSize &&
                        i < activePage * pageSize && (
                          <div
                            key={i}
                            className="search-result-item"
                            onClick={() => {
                              setVisible(true);
                              const map = mapRef.current.getMap();
                              map.jumpTo({
                                center: [e.x, e.y],
                                essential: true,
                              });
                              setAddressInput(e.PNU_LNM_CD);
                              setAddressResult();
                            }}
                          >
                            <div className="result-text">
                              {e.bjd_nm + " " + e.LGL_EMD_NM + " " + e.LNM}
                            </div>
                          </div>
                        )
                      );
                    }
                  })}
                <div className="pagination-container">
                  <Pagination
                    className="pagination"
                    activePage={activePage}
                    totalPages={totalPages}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    onPageChange={handlePageChange}
                  ></Pagination>
                </div>
              </div>
              {/* <div className="itrstZone-form">
                        <div className="button shpUpload">
                            <label htmlFor="shpUpload" className="shpUploadLabel"> 경계파일(shp) 업로드</label>
                            <input
                                id="shpUpload" 
                                type="file" 
                                accept=".zip"
                                style={{ visibility:"hidden" }}
                                onChange={uploadShp}
                            >
                            </input>
                        </div>
                        <button className="button reset" onClick={handleDelete}><i className="redo alternate icon" />선택 초기화</button>
                    </div> */}
            </div>
          </div>   
          <div className="map-container" style={{width: '100%', height: '500px'}}>
            <Map
              className="Mapbox-Map"
              mapboxAccessToken={MAPBOX_TOKEN}
              ref={mapRef}
              style={{width: '100%', height: '500px'}}
              initialViewState={{
                latitude: 35.829281,
                longitude: 127.040901,
                zoom: 15,
                minZoom: 6,
                maxZoom: 24,
                maxBounds: [
                  [122.0, 33.0], // Southwest coordinates
                  [132.0, 40.0], // Northeast coordinates
                ],
              }}
              // visible={showMap}
              onClick={(e) => {
                if (fileShpRef.current.value === "") {
                  if (clickTimeout.current !== null) {
                    clearTimeout(clickTimeout.current);
                    clickTimeout.current = null;
                    return;
                  }

                  clickTimeout.current = setTimeout(() => {
                    onclickmap(e);
                    clickTimeout.current = null;
                  }, 300);
                } else {
                  setPopupMsg("파일 업로드 시 필지 선택은 불가능합니다.");
                  setShowPopup(true);
                }
              }}
              doubleClickZoom={false}
            >
                        <div className="itrsZone-info-container">
              <button
                className={visible ? "itrsZone-info active" : "itrsZone-info"}
                onClick={() => {
                  setVisible(!visible);
                }}
              >
                관심구역 정보 입력
                {visible ? (
                  <div className="gc-shrink_icon" />
                ) : (
                  <div className="gc-expansion_icon" />
                )}
              </button>

              <div className={`input-form ${visible ? "visible" : "hidden"}`}>
                <div className="field">
                  <div className="text">
                    <span className="required-marker">*</span>관심구역명
                  </div>
                  <input
                    className="input"
                    type="input"
                    id="name"
                    name="name"
                    onChange={handleName}
                    onKeyDown={handleKeyDown}
                    spellCheck="false"
                    placeholder="관심구역명을 입력하세요."
                  />
                </div>
                <div className="field">
                  <div className="text">
                    <span className="required-marker">*</span>등록일
                  </div>
                  <input
                    className="input"
                    type="input"
                    id="date"
                    name="date"
                    disabled="disabled"
                    // placeholder='2023-03-21'
                    value={moment().format("YYYY-MM-DD")}
                  />
                </div>
                <div className="field">
                  <div className="text">
                    <span className="required-marker">*</span>연도정보
                  </div>

                  <select
                    className="input"
                    // size={10}
                    value={yearstate}
                    onChange={handleYear}
                  >
                    {Array.from(
                      { length: maxDate - minDate + 1 },
                      (_, i) => maxDate - i
                    ).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>

                  {/* <input
                    className="input"
                    type="input"
                    id="year"
                    name="year"
                    onChange={handleYear}
                    spellCheck="false"
                    placeholder="예시) 2024"
                  /> */}
                </div>
                <div className="field radio">
                  <div className="text">
                    <span className="required-marker">*</span>이모작 여부
                  </div>
                  <div className="input radio">
                    <div className="radioflex">
                      <Checkbox
                        type="radio"
                        value="1"
                        checked={cropstate === "1"}
                        onChange={(e, data) => setCrop(data.value)}
                      />
                      <div className="subtext">단작</div>
                    </div>
                    <div className="radioflex">
                      <Checkbox
                        type="radio"
                        value="2"
                        checked={cropstate === "2"}
                        onChange={(e, data) => setCrop(data.value)}
                      />
                      <div className="subtext">하작</div>
                    </div>
                    <div className="radioflex">
                      <Checkbox
                        type="radio"
                        value="3"
                        checked={cropstate === "3"}
                        onChange={(e, data) => setCrop(data.value)}
                      />
                      <div className="subtext">동작</div>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <div className="text">
                    <span className="required-marker-hidden">*</span>기타 내용
                  </div>
                  <input
                    className="input long"
                    type="textarea"
                    id="etcCn"
                    name="etcCn"
                    onChange={handleEtcCn}
                    onKeyDown={handleKeyDown}
                    spellCheck="false"
                  />
                </div>
                <div className="field tag">
                  <div className="text">
                    <span className="required-marker-hidden">*</span>태그
                  </div>
                  <div className="tag-list">
                    {renderTagList()}
                    <button className="addTagButton" onClick={addTag}>
                      + 태그 추가
                    </button>
                  </div>
                </div>
              </div>
            </div>   
              {drawing ? (
                <DrawControl
                  position="bottom-right"
                  displayControlsDefault={false}
                  controls={
                    {
                      // polygon: true,
                      // trash: true
                    }
                  }
                  defaultMode="draw_polygon"
                  onCreate={(data) => {
                    setPolygonCoord(data.features);
                    setDrawing(false);
                  }}

                  // onUpdate={onUpdate}
                  // onDelete={onDelete}
                />
              ) : null}

              <Source {...MAP_SRC}>
                <Layer {...MAP_LAYER_STYLE} />
              </Source>

              {/* <Source {...FM_SRC}>
                  <Layer {...FM_LAYER_STYLE}/>
              </Source> */}

              {INIT_FM()}

              {displayMarker()}

              {ITRST_SRC_ACTIVE != null && ITRST_LAYER_STYLE_ACTIVE != null ? (
                <Source key={ITRST_LAYER_ID} {...ITRST_SRC_ACTIVE}>
                  <Layer {...ITRST_LAYER_STYLE_ACTIVE} />
                </Source>
              ) : null}

              {/* {
                  drawing?
                  <Editor
                      clickRadius={12}
                      mode={drawingmode}
                      // onSelect={(selected) => {}}
                      onUpdate={({ data }) => {
                          if(data.length > 0)
                          {
                              setPolygonCoord(data);
                              setDrawing(false);
                          }
                      }}
                  >
                  </Editor>
                  : null
              } */}
            </Map>
          </div>
          <div className="itrstzone-container-footer">
            <div className="read-info">
              <h4>
                1. 화면에서 필지를 직접 클릭하거나, 오른쪽 하단 '영역선택'
                버튼으로 영역을 설정하세요.
              </h4>
              <span className="read_info_blue">
                ※ '영역선택' 버튼을 클릭한 뒤 좌클릭으로 영역을 지정하고,
                더블클릭으로 설정을 마무리할 수 있습니다.
              </span>
              <span className="read_info_blue">
                ※ 관리하는 구역의 경계를 파일로 가지고 있는 경우, '파일 업로드'
                버튼으로 영역을 설정할 수 있습니다.
              </span>
              <span className="read_info_blink">
                좌표계 : UTM-K (EPSG:5179) 파일확장자 : .zip
              </span>

              <h4>2. 관심 구역에 대한 정보를 입력 후 등록을 클릭하세요.</h4>
            </div>
          </div>
        </Modal>
      </div>
      <Popup
        show={showPopup}
        // onCancel={() => {setShowPopup(false);if(isOK){props.setShowGen(false);window.location.reload()}}}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowPopup(false);
              if (isOK) {
                props.setShowGen(false);
                setIsOK(false);
                window.location.reload();
              }
            }}
          />
        }
      >
        <div className="modal-info">
          {/* <p>{popupMsg}</p> */}
          <p
            style={{
              fontSize: fontSizeValue,
              padding: paddingValue,
            }}
            dangerouslySetInnerHTML={{ __html: popupMsg }}
          ></p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);
              if (isOK) {
                props.setShowGen(false);
                setIsOK(false);
                window.location.reload();
              }
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default ItrstZone;

// Viewport
// const [viewport, setViewport] = useState({
//     width: 1100,
//     height: 760,
//     longitude: movetoXY.X,
//     latitude: movetoXY.Y,
//     zoom: 15
// });

// 화면이동
// const viewportHandler = (event) => {
//     setViewport({
//         width: 1100,
//         height: 760,
//         longitude: Number(event.X),
//         latitude: Number(event.Y),
//         zoom: 15
//     })
// }

// useEffect(() => {
//     if (showInput == true)
//     {
//         viewportHandler(movetoXY);
//     }
// },
// [movetoXY]
// );

import "./signup.css";
import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";

import Popup from "../../../shared/components/uiElements/Popup";
import InstSearch from "../components/instSearch";
import TermsOfService from "../../../routes/user/pages/TermsOfService";

// AXIOS
import axios from "axios";

function Signup(props) {
  const navigate = useNavigate();

  const [id, setID] = useState("");
  const [pw, setPW] = useState("");
  const [pwre, setPWRE] = useState("");
  const [tellNo, setTellNo] = useState("");
  const [email, setEmail] = useState("");
  const [hintCd, setHintCd] = useState("");
  const [hintAns, setHintAns] = useState("");
  const [userTy, setUserTy] = useState("");
  const [userNm, setUserNm] = useState("");
  const [instNm, setInstNm] = useState("");
  const [instId, setInstId] = useState("");
  const [deptNm, setDeptNm] = useState("");
  const [idMatching, setIDMatching] = useState(false);
  const [pwMatching, setPWMatcing] = useState(false);

  const [idMsg, setIDMsg] = useState(
    "아이디 입력 후 중복 확인 버튼을 눌러주세요."
  );
  const [pwMsg, setPWMsg] = useState(
    "비밀번호는 8자 이상 문자/숫자/특수문자 조합으로 설정하세요."
  );

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showInstPopup, setShowInstPopup] = useState(false);

  const options = [
    { key: 1, text: "내가 가장 좋아하는 장소는?", value: 1 },
    { key: 2, text: "내가 가장 좋아하는 음식은?", value: 2 },
    { key: 3, text: "인생의 좌우명은?", value: 3 },
    { key: 4, text: "가장 감명 깊게 본 영화는?", value: 4 },
  ];

  const handleID = (event) => {
    event.preventDefault();
    setID(event.target.value);
  };
  const handlePW = (event) => {
    event.preventDefault();
    setPW(event.target.value);
  };
  const handlePWRE = (event) => {
    event.preventDefault();
    setPWRE(event.target.value);
  };
  const handleTellNo = (event) => {
    event.preventDefault();
    setTellNo(event.target.value);
  };
  const handleEmail = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };
  const handleHintCd = (event, data) => {
    event.preventDefault();
    setHintCd(data.value);
  };
  const handleHintAns = (event) => {
    event.preventDefault();
    setHintAns(event.target.value);
  };
  const handleUserNm = (event) => {
    event.preventDefault();
    setUserNm(event.target.value);
  };
  const handleInstNm = (event) => {
    setInstNm(event);
  };
  const handleInstId = (event) => {
    setInstId(event);
  };
  const handleDeptNm = (event) => {
    if (instNm !== "") {
      event.preventDefault();
      setDeptNm(event.target.value);
    } else {
      setPopupMsg("소속을 선택한 후 입력하세요.");
      setShowAddPopup(true);
      setDeptNm("");
    }
  };

  useEffect(() => {
    setInstNm("");
    setDeptNm("");
  }, [userTy, setUserTy]);

  const postsignup = () => {
    let isOK = validation();

    if (isOK) {
      axios({
        method: "post",
        url: `/api/signup`,
        data: {
          id: id,
          pw: pw,
          tellNo: tellNo,
          email: email,
          hintCd: hintCd,
          hintAns: hintAns,
          userTy: userTy,
          userNm: userNm,
          instId: instId,
          deptNm: deptNm,
        },
      })
        .then((result) => {
          if (result.data.success) {
            setPopupMsg(result.data.msg);
            setShowAddPopup(true);
          } else {
            setPopupMsg("회원가입 실패");
            setShowAddPopup(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setShowAddPopup(true);
    }
  };

  const regexPw = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@$%^&+]).*$/;
  //   const expEmail = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  const expEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
  const phoneRegex = /^\d{3}-\d{4}-\d{4}$/;

  function validation() {
    if (
      id.length == 0 ||
      pw.length == 0 ||
      pwre.length == 0 ||
      tellNo.length == 0 ||
      email.length == 0 ||
      hintCd.length == 0 ||
      hintAns.length == 0 ||
      userTy.length == 0 ||
      userNm.length == 0 ||
      instId.length == 0
    ) {
      setPopupMsg("필수 정보를 입력하세요.");
      return false;
    }

    if (!idMatching) {
      setPopupMsg("ID 중복을 확인해주세요.");
      return false;
    }

    if (!pwMatching) {
      setPopupMsg("비밀번호가 일치하지 않습니다.");
      return false;
    }

    if (id.length < 4 || id.length > 16) {
      setPopupMsg("4자 이상 16자 이하<br>ID를 입력하세요.");
      return false;
    }

    if (pw.length < 8 || pw.length > 16) {
      setPopupMsg("8자 이상 16자 이하<br>비밀번호를 입력하세요.");
      return false;
    }

    if (!regexPw.test(pw)) {
      setPopupMsg(
        "비밀번호는 문자/숫자/특수문자를<br>모두 포함하여야 합니다.<br>※사용 가능 특수문자 : ! @ $ % ^ & +"
      );
      return false;
    }
    if (tellNo.length < 10 || tellNo.length > 12) {
      setPopupMsg("10자 이상 12자 이하<br>전화번호를 입력하세요.");
      setShowPopup(true);
      return false;
    }
    // if (!phoneRegex.test(tellNo)) {
    //   setPopupMsg("전화번호를<br>000-0000-0000 형식으로 입력하세요.");
    //   setShowPopup(true);
    //   return false;
    // }
    if (isNaN(Number(tellNo))) {
      setPopupMsg("전화번호를 숫자로 입력해주세요.");
      setShowPopup(true);
      return false;
    }
    if (!email.length > 0 || !expEmail.test(email)) {
      setPopupMsg("이메일을<br>user@example.com 형식으로 입력하세요.");
      return false;
    }

    if (!(isChecked1 && isChecked2)) {
      setPopupMsg("플랫폼 이용 약관 및 개인정보 수집<br>모두 동의해주세요");
      return false;
    }

    return true;
  }

  const idconfirm = () => {
    if (id === "") {
      setShowAddPopup(true);
      setPopupMsg("사용자 ID를 먼저 입력하세요.");
    } else {
      axios({
        method: "get",
        url: `/api/account/check/id?id=${id}`,
      })
        .then((result) => {
          const confirm = result.data.success;
          if (confirm) {
            setIDMatching(true);
            setIDMsg("사용 가능한 ID입니다.");
          } else {
            setIDMatching(false);
            setIDMsg("이미 가입된 ID입니다.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (pw) {
      if (pw === pwre) {
        setPWMsg("비밀번호가 일치합니다.");
        setPWMatcing(true);
      } else {
        setPWMsg("비밀번호가 일치하지 않습니다.");
        setPWMatcing(false);
      }
    }
  }, [pw, pwre]);

  const handleUserTyClick = () => {
    if (userTy !== 5) {
      setShowPopup(true);
    } else {
      //   doSomethingElse();
    }
  };

  function handleMenuClick() {
    if (userTy !== 5) {
      setShowPopup(true);
    } else {
      //   doSomethingElse();
    }
  }

  const handleButtonClick = () => {
    if (userTy !== null) {
      if (userTy !== 5) {
        setShowInstPopup(true);
      } else {
        setShowInstPopup((prevShowInstPopup) => !prevShowInstPopup);
      }
    } else {
      setShowInstPopup((prevShowInstPopup) => !prevShowInstPopup);
    }
  };

  // 이용약관
  // const handleLinkClick = (linkNm) => {
  //     props.handleIsTerms(linkNm);
  //     navigate('/termsofservice');
  // };

  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const handleCheckboxChange = (checkboxNumber) => {
    if (checkboxNumber === 1) {
      setIsChecked1(!isChecked1);
    } else if (checkboxNumber === 2) {
      setIsChecked2(!isChecked2);
    }
  };

  const [showTermsOfServicePopup, setShowTermsOfServicePopup] = useState(false);
  const handleLinkClick = (linkNm) => {
    // setShowTermsOfServicePopup(!showTermsOfServicePopup);
    setShowTermsOfServicePopup(true);
    props.handleIsTerms(linkNm);
  };

  // 비밀번호는 문자/숫자/특수문자를 모두 포함하여야 합니다. ※사용 가능 특수문자 : ! @ $ % ^ & +
  const brCount = (popupMsg.match(/<br>/g) || []).length;
  const paddingValue =
    brCount === 0 ? "35px 0" : brCount === 1 ? "25px 0" : "15px 0";
  const fontSizeValue = brCount === 0 ? "18px" : "16px";

  return (
    <div className="signup-container">
      <div className="signup-guide">
        <div className="title">회원가입</div>
        <div className="content">
          회원가입 진행을 위해 플랫폼 이용 약관, 개인정보 수집 및 이용 동의가
          필요합니다.
        </div>
      </div>

      <div className="division-A"></div>

      <div className="signup-type">
        <div className="main-text">
          가입구분<span className="required-marker">*</span>
        </div>
        <div className="type">
          <Checkbox
            name="checkboxRadioGroup"
            type="radio"
            className="check"
            value="1"
            checked={userTy === "1"}
            onChange={(e, data) => setUserTy(data.value)}
            // onClick={setInstNm("")}
          />
          <p className="text">개인(영농조합)</p>
        </div>
        <div className="type">
          <Checkbox
            name="checkboxRadioGroup"
            type="radio"
            className="check"
            value="2"
            checked={userTy === "2"}
            onChange={(e, data) => setUserTy(data.value)}
          />
          <p className="text">영농조합 관리자</p>
        </div>
        <div className="type">
          <Checkbox
            name="checkboxRadioGroup"
            type="radio"
            className="check"
            value="3"
            checked={userTy === "3"}
            onChange={(w, data) => setUserTy(data.value)}
          />
          <p className="text">기관</p>
        </div>
        <div className="type">
          <Checkbox
            name="checkboxRadioGroup"
            type="radio"
            className="check"
            value="4"
            checked={userTy === "4"}
            onChange={(w, data) => setUserTy(data.value)}
          />
          <p className="text">농작업 수행업체</p>
        </div>
        {/* <div className="type">
                    <Checkbox 
                        name="checkboxRadioGroup"
                        type="radio"
                        className="check"
                        value="5"
                        checked={userTy === "5"}
                        onChange={(w, data) => setUserTy(data.value)}
                    />
                    <p className="text">기타</p>
                </div> */}
      </div>

      <div className="division-B"></div>

      <div className="signup-inputIDPW">
        <div className="ID">
          <div className="main-text">
            사용자 ID<span className="required-marker">*</span>
          </div>
          <input
            type="text"
            className="input signup-input-id"
            onChange={handleID}
          />
          <button className="duplication" onClick={idconfirm}>
            중복확인
          </button>
          <div className={`${idMatching ? "idmatching" : "idunmatching"}`}>
            {idMsg}
          </div>
        </div>

        <div className="PW">
          <div className="mainpart">
            <div className="subpart">
              <div className="main-text password">
                비밀번호<span className="required-marker">*</span>
              </div>
              <input
                type="password"
                className="input signup-input-pw"
                onChange={handlePW}
              />
            </div>
            <div className="subpart">
              <div className="main-text">
                비밀번호 확인<span className="required-marker">*</span>
              </div>
              <input
                type="password"
                className="input signup-input-pwre"
                onChange={handlePWRE}
              />
            </div>
            <div
              className={`${
                pwMatching ? "passwordmatching" : "passwordunmatching"
              }`}
            >
              {pwMsg}
            </div>
          </div>
          <div className="mainpart">
            <div className="subpart">
              <div className="main-text passwordhint">
                비밀번호 힌트<span className="required-marker">*</span>
              </div>
              <Dropdown
                className="input signup-input-pwhint"
                selection
                options={options}
                onChange={handleHintCd}
              ></Dropdown>
            </div>
            <div className="subpart">
              <div className="text"></div>
              <input
                type="text"
                className="input signup-input-pwhintans"
                placeholder="힌트에 대한 답변을 입력하세요."
                onChange={handleHintAns}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="division-B"></div>

      <div className="signup-inputuser">
        <div className="mainpart">
          <div className="subpart">
            <div className="text">
              이름<span className="required-marker">*</span>
            </div>
            <input
              type="text"
              className="input signup-input-name"
              onChange={handleUserNm}
            />
          </div>
          <div className="subpart">
            <div className="text">
              소속<span className="required-marker">*</span>
            </div>
            <input
              type="text"
              className="input signup-input-inst"
              onClick={handleButtonClick}
              // onClick={() => handleUserTyClick()}
              // onClick={handleMenuClick}
              // onClick={()=>{setShowInstPopup(true)}}
              placeholder="검색"
              readOnly
              value={instNm}
            />
          </div>
          <div className="subpart">
            <div className="text">부서명</div>
            <input
              type="text"
              className="input signup-input-inst"
              placeholder="부서명"
              onChange={handleDeptNm}
              value={deptNm}
              disabled={userTy === "1" || userTy === "2"}
            />
          </div>
        </div>
        <div className="mainpart">
          <div className="subpart">
            <div className="text">
              전화번호<span className="required-marker">*</span>
            </div>
            <input
              type="text"
              className="input signup-input-pnum"
              onChange={handleTellNo}
              placeholder="예시) 01012345678"
            />
          </div>
          <div className="subpart">
            <div className="text">
              이메일<span className="required-marker">*</span>
            </div>
            <input
              type="text"
              className="input signup-input-email"
              onChange={handleEmail}
              placeholder="예시) user@example.com"
            />
          </div>
        </div>
      </div>

      <div className="division-B"></div>

      <div className="contents-row terms-of-service">
        <div className="contents-row terms-box">
          <div className="label">
            플랫폼 이용 약관<span className="required-marker">*</span>
          </div>
          <div className="contents-row terms-box-check">
            <Checkbox
              name="termsCheckbox"
              type="checkbox"
              className="check"
              checked={isChecked1}
              onChange={() => handleCheckboxChange(1)}
            />
            {/* <div className="text" onClick={() => handleLinkClick (1)}><Link to="/termsofservice">전체보기</Link></div> */}
            <div
              className="text"
              onClick={() => handleLinkClick("terms-of-service")}
            >
              전체보기
            </div>
          </div>
        </div>
        <div className="contents-row terms-box">
          <div className="label">
            개인정보 수집 및 이용 동의<span className="required-marker">*</span>
          </div>
          <div className="contents-row terms-box-check">
            <Checkbox
              name="termsCheckbox"
              type="checkbox"
              className="check"
              checked={isChecked2}
              onChange={() => handleCheckboxChange(2)}
            />
            {/* <div className="text" onClick={() => handleLinkClick (2)}><Link to="/termsofservice">전체보기</Link></div> */}
            <div
              className="text"
              onClick={() => handleLinkClick("privacy-prolicy")}
            >
              전체보기
            </div>
          </div>
        </div>
      </div>

      {/* <button className={`signup-button ${isOK? 'isok' : 'isnone'}`} onClick={postsignup}>확인</button> */}
      <button className={"signup-button isok"} onClick={postsignup}>
        확인
      </button>
      <div className="signup-back">
        <Link to="/home"> 이전으로</Link>
      </div>

      {showTermsOfServicePopup && (
        <TermsOfService
          showTermsOfServicePopup={showTermsOfServicePopup}
          setShowTermsOfServicePopup={setShowTermsOfServicePopup}
          handleIsTerms={props.handleIsTerms}
          isTerms_state={props.isTerms_state}
        />
      )}

      <Popup
        show={showAddPopup}
        onCancel={() => setShowAddPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowAddPopup(false);
              if (popupMsg.includes("생성")) {
                // if (userTy !== 5) {
                navigate("/home");
                // } else {
                //     navigate("/profile")
                // }
              }
            }}
          />
        }
      >
        <div className="modal-info">
          {/* <p dangerouslySetInnerHTML={{ __html: popupMsg }}></p> */}
          {/* <p
                        style={{
                            fontSize: popupMsg.includes('특수문자 : ! @ $ % ^ & +') ? '16px' : '18px',
                            padding: popupMsg.includes('특수문자 : ! @ $ % ^ & +') ? '15px' : '', 
                        }}
                        dangerouslySetInnerHTML={{ __html: popupMsg }}
                    ></p> */}
          <p
            style={{
              fontSize: fontSizeValue,
              padding: paddingValue,
            }}
            dangerouslySetInnerHTML={{ __html: popupMsg }}
          ></p>
          <button
            className="close_btn"
            onClick={() => {
              setShowAddPopup(false);
              if (popupMsg.includes("생성")) {
                // if (userTy !== 5) {
                navigate("/home");
                // } else {
                //     navigate("/profile")
                // }
              }
            }}
          >
            닫기
          </button>
        </div>
      </Popup>

      {userTy ? (
        // userTy === 1 || userTy === 2 || userTy === 3 || userTy === 4 ?
        // userTy != 5? (
        <InstSearch
          showInstPopup={showInstPopup}
          setShowInstPopup={setShowInstPopup}
          userTy={userTy}
          handleInstNm={handleInstNm}
          handleInstId={handleInstId}
        ></InstSearch>
      ) : (
        // ) : userTy == 5? (

        //     <Popup
        //         show={showInstPopup}
        //         onCancel={() => setShowInstPopup(false)}
        //         header="알림"
        //         icon={<i className="code x icon"  onClick={() => setShowInstPopup(false)} /> }
        //     >
        //     <div className='modal-info'>
        //         <p>신청폼</p>
        //         <button
        //             className="close_btn"
        //             onClick={() => setShowInstPopup(false)}
        //         >닫기</button>
        //     </div>
        //     </Popup>
        // )
        <Popup
          show={showInstPopup}
          onCancel={() => setShowInstPopup(false)}
          header="알림"
          icon={
            <i
              className="code x icon"
              onClick={() => setShowInstPopup(false)}
            />
          }
        >
          <div className="modal-info">
            <p>가입구분을 선택해주세요.</p>
            <button
              className="close_btn"
              onClick={() => setShowInstPopup(false)}
            >
              닫기
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default Signup;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import axios from 'axios';
import "./FileUpload.style.css";

const FileUpload = ({
  isImgPrcs,
  active,
  setShowAddPopup,
  setPopupMsg,
  handleFilesUpdate,
  files,
  onTiffChangeHandler,
  curActiveLayer
}) => {
  const [imgDataInfo, setImgDataInfo] = useState({});
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const fileInputRef = useRef(null);

  const maxCount = isImgPrcs ? 2 : 1;
  const validExtensions = isImgPrcs ? ['.zip', '.csv'] : ['.tif'];
  const APIToken = localStorage.getItem('login-token');
  const userId = localStorage.getItem('user-id');
  
  // 필요한 파일 목록 업데이트
  useEffect(() => {
    handleFilesUpdate([]);
  }, [isImgPrcs]);

  const fileUpdate = (orgnl_file_nm, file_object) => {
    handleFilesUpdate(prevFiles => [...prevFiles, { 
      name: `${orgnl_file_nm} [${orgnl_file_nm.split('.').pop()}]`, 
      fileObject: file_object,
      extension: orgnl_file_nm.split('.').pop(),
    }]);
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Component unmounted');
      }
    };
  }, [cancelTokenSource]);

  useEffect(() => {
    if (active === 3 && curActiveLayer?.drn_orign_img_yn === 'N') {
      fileUpdate(curActiveLayer.orgnl_file_nm, null);
    } else {
      handleFilesUpdate([]);
    }
  }, [active]);

  useEffect(() => {
    const updatedData = {};
    if (curActiveLayer) {
        updatedData[curActiveLayer.orgnl_file_nm] = curActiveLayer.drn_img_file_path ? 'tif' : false;
        setImgDataInfo(updatedData);
    }
    return () => {
      setImgDataInfo({});
    };
  }, [curActiveLayer]);

  const handleFileDownload = async (fileName, fileType) => {
    const drnImgId = curActiveLayer.drn_img_id;

    if (!fileType) {
      setShowAddPopup(true);
      setPopupMsg(`선택하신 파일이 없습니다.`);
      return;
    }

    setIsDownloading(true);
    setDownloadProgress(0);
    try {
      const source = axios.CancelToken.source();
      setCancelTokenSource(source);

      const response = await axios({
        method: 'get',
        url: `/api/download/${drnImgId}/${fileType}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        responseType: 'blob',
        cancelToken: source.token,
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setDownloadProgress(percentCompleted);
        },
        validateStatus: () => true,
      });

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);

      setPopupMsg('다운로드가 완료되었습니다.');
      setShowAddPopup(true);
    } catch (error) {
      if (axios.isCancel(error)) {
        setPopupMsg('다운로드가 취소되었습니다.');
      } else {
        setPopupMsg('다운로드에 실패하였습니다.');
      }
      setShowAddPopup(true);
    } finally {
      setIsDownloading(false);
      setDownloadProgress(0);
      setCancelTokenSource(null);
    }
  };

  const cancelDownload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Download canceled by user');
    }
  };

  const handleFileDelete = (index) => {
    fileInputRef.current.value = '';
    const newFiles = [...files];
    newFiles.splice(index, 1);
    handleFilesUpdate(newFiles);
  };

  const handleAllFileDelete = () => {
    handleFilesUpdate([]);
    fileInputRef.current.value = '';
  };

  const [dragOver, setDragOver] = useState(false);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange(e.dataTransfer.files[0]);
    }
  }, [validExtensions]);

  const fileValidation = (file) => {
    if (!file) {
      setShowAddPopup(true);
      setPopupMsg('선택하신 파일이 없습니다.');
      return false;
    }

    const fileExtension = `.${file.name.split('.').pop().toLowerCase()}`;

    if (!validExtensions.includes(fileExtension)) {
      const cleanExtensions = validExtensions.map(ext => ext.replace('.', ''));
      setShowAddPopup(true);
      setPopupMsg(`[${fileExtension.slice(1)}] 지원하지 않는 파일 확장자입니다.</br>[${cleanExtensions.join(', ')}] 파일로 업로드해주세요.`);
      return false;
    }

    if (files.length >= maxCount) {
      setShowAddPopup(true);
      setPopupMsg(`파일은 최대 ${maxCount}개까지 업로드할 수 있습니다.`);
      return false;
    }

    if (file.name.endsWith('.tif')) {
      return onTiffChangeHandler(file);
    }

    const usedExtensions = files.map(e => `.${e.extension.toLowerCase()}`);
    if (usedExtensions.includes(fileExtension)) {
      setShowAddPopup(true);
      setPopupMsg('이미 업로드 한 파일입니다.');
      return false;
    }

    return true;
  };

  const handleFileChange = (file) => {
    if (fileValidation(file)) {
      const maxFileNameLength = 50;
      let fileName = file.name;

      if (fileName.length > maxFileNameLength) {
        fileName = fileName.slice(0, maxFileNameLength) + '...';
      }

      handleFilesUpdate(prevFiles => [...prevFiles, { 
        name: `${fileName} [${file.name.split('.').pop()}, ${(file.size / 1024).toFixed(2)} KB]`, 
        fileObject: file,
        extension: file.name.split('.').pop(),
      }]);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const classNames = [
    'file-upload',
    dragOver ? 'drag-over' : ''
  ].filter(Boolean).join(' ');

  return (
    <div className='input-file-field-group'>
      {active !== 2 && (
      <React.Fragment>
      <div className={classNames}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <p className="txt">첨부할 파일을 여기에 끌어다 놓거나, 파일 선택 버튼을 눌러 파일을 직접 선택해주세요.</p>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept={validExtensions.join(',')}
          onChange={(e) => {
            handleFileChange(e.target.files[0]);
          }}
        />
        <button type="button" className="file-btn" onClick={handleButtonClick}>
          <i className="upload icon"></i>파일선택
        </button>
      </div>
      <div className="file-upload-result">
        <div className="upload-top">
          <div className="file-total">
            <span className="current">{files.length}개</span>/{maxCount}개
          </div>
          {files.length > 0 &&  
            <button
              type="button"
              className="btn-txt"
              onClick={handleAllFileDelete}
            >
              <i
                className="x icon"
                />
              전체 파일 삭제
            </button>
          }
        </div>
      </div>
      </React.Fragment>
      )}
      {active !== 2 &&
        <ul className="upload-list">
        {files.map((file, index) => (
          <li key={index} className={file.error ? 'is-error' : ''}>
            <div className="in">
              <div className="file-name"><i className="file outline icon"></i>{file.name}</div>
              <div className="button-area">
                {file.error && (
                  <span className="ico-invalid error">
                    <em className="sr-only">에러</em>
                  </span>
                )}
                <button
                  type="button"
                  className="btn btn-txt ico-before ico-del sm h-auto"
                  onClick={() => handleFileDelete(index)}
                >
                  <i className="x icon"/> 삭제
                </button>
              </div>
            </div>
            {file.error && (
              <p className="file-hint">{file.errorMessage}</p>
            )}
          </li>
          ))
        }
      </ul>
      }
      {(active === 2 && imgDataInfo) && (
        <ul className="download-list">
          {Object.entries(imgDataInfo).map(([key, value]) => (
            <li key={key}>
              <div className="file-name">
                {userId === curActiveLayer.user_id ?( value ? (
                  <>
                    <i className="file outline icon"></i>
                    {key}
                  </>
                ) : (
                  `${key} 파일이 없습니다.`
                )) : (
                  '파일 다운로드 권한이 없습니다.'
                )}
              </div>
              {userId === curActiveLayer.user_id && value && (
                <React.Fragment>
                  <div className="button-area">
                    <button
                      type="button"
                      className="btn btn-txt"
                      onClick={() => handleFileDownload(key, value)}
                      disabled={isDownloading}
                    >
                      {!isDownloading && (
                        <React.Fragment>
                          <i className="download icon" />
                          다운로드
                        </React.Fragment>
                      )}
                    </button>
                  </div>
                  {isDownloading && (
                    <div className="download-progress">
                      <progress value={downloadProgress} max="100" />
                      <span>{downloadProgress}%</span>
                      <button onClick={cancelDownload} disabled={!isDownloading} className="cancel-button">
                        다운로드 취소
                      </button>
                    </div>
                  )}
                </React.Fragment>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};


export default FileUpload

import './farmworkdetail.css'

import React, {useEffect, useState } from 'react';

import Modal from '../../../shared/components/uiElements/Modal'

function Farmworkdetail (props) {

    const [visible, setVisible] = useState(false);
    const curActiveLayer = props.curActiveLayer;
    const activeStepNumber = props.curActiveLayer.frmwkt_stts_cd;
    return (
        <React.Fragment>
            <Modal
                show={props.showDetail}
                onCancel={()=>{props.handleShowDetail(false)}}
                header="농작업 정보 상세보기"
                icon={<i className="code x icon" onClick={()=>{props.handleShowDetail(false)}} /> }
                className={`farmworkdetail ${props.isMobile ? 'mobile' : ''}`}
                // className={'farmworkdetail'}
                isMobile={props.isMobile}
            >
            <div className="farmworkdetail-container">
                <div className='contents-col farmworkdetail-box'>
                    <div className="maintext">작업 진행상태</div>
                    <div className="progress-image contents-row">
                        <div className="step contents-row">
                            {/* <div className="image" id="step-1"></div> */}
                            {/* <div className={`step-level ${props.curActiveLayer.frmwkt_stts_cd >= 1 ? 'stts' : ''}`}>1</div> */}
                            <div className={`text ${props.curActiveLayer.frmwkt_stts_cd === 1 ? 'stts' : ''}`}>요청완료</div>
                        </div>
                        <div className="step contents-row">
                            {/* <div className="image" id="step-2"></div> */}
                            {/* <div className={`step-level ${props.curActiveLayer.frmwkt_stts_cd >= 2 ? 'stts' : ''}`}>2</div> */}
                            <div className={`text ${props.curActiveLayer.frmwkt_stts_cd === 2 ? 'stts' : ''}`}>작업완료</div>
                        </div>
                        {/* <div className="step contents-row">
                            <div className={`text ${props.curActiveLayer.frmwkt_stts_cd >= 3 ? 'stts' : ''}`}>③ 작업진행</div>
                        </div>
                        <div className="step contents-row">
                            <div className={`text ${props.curActiveLayer.frmwkt_stts_cd >= 4 ? 'stts' : ''}`}>④ 작업완료</div>
                        </div> */}
                        {/* {props.curActiveLayer.frmwkt_stts_cd === 1 ? (
                            <div className="step contents-row">
                            <div className="image" id="step-1"></div>
                            <div className="text">작업요청</div>
                            </div>
                        ) : props.curActiveLayer.frmwkt_stts_cd === 2 ? (
                            <div className="step contents-row">
                            <div className="image" id="step-2"></div>
                            <div className="text">수행업체 선정</div>
                            </div>
                        ) : props.curActiveLayer.frmwkt_stts_cd === 3 ? (
                            <div className="step contents-row">
                            <div className="image" id="step-3"></div>
                            <div className="text">작업진행</div>
                            </div>
                        ) : props.curActiveLayer.frmwkt_stts_cd === 4 ? (
                            <div className="step contents-row">
                            <div className="image" id="step-4"></div>
                            <div className="text">작업완료</div>
                            </div>
                        ) : null} */}
                        {/* <div className="step contents-row">
                            <div className="image" id="step-1"></div>
                            <div className="text">작업요청</div>
                        </div>
                        <div className="step contents-row">
                            <div className="image" id="step-2"></div>
                            <div className="text">수행업체 선정</div>
                        </div>
                        <div className="step contents-row">
                            <div className="image" id="step-3"></div>
                            <div className="text">작업진행</div>
                        </div>
                        <div className="step contents-row">
                            <div className="image" id="step-4"></div>
                            <div className="text">작업완료</div>
                        </div> */}
                    </div>

                    <div className="maintext">등록정보</div>
                    <div className='information-box'>
                        <div className="information-container contents-col">
                            <div className='contents-row field-grid'>
                                <div className='contents-row field'>
                                <div className="title">수행업체명</div>
                                    <div className="content flex-two">{props.curActiveLayer.oprtp_nm !== null ? props.curActiveLayer.oprtp_nm : '정보 없음'}</div>
                                </div>
                                <div className='contents-row field'>
                                    <div className="title">작성자</div>
                                    <div className="content flex-two">{props.curActiveLayer.user_nm}</div>
                                </div>
                            </div>
                            <div className='contents-row field-grid'>
                                <div className='contents-row field'>
                                    <div className="title">등록일시</div>
                                    <div className="content flex-two">{props.curActiveLayer.frmwkt_req_dt.slice(0, 10)}</div>
                                </div>
                                <div className='contents-row field'>
                                    <div className="title">농작업 날짜</div>
                                    <div className="content flex-two">{props.curActiveLayer.frmwkt_ymd}</div>
                                </div>
                            </div>
                            <div className='contents-row field-grid'>
                                <div className='contents-row field'>
                                    <div className="title">필지 ID</div>
                                    <div className="content flex-two">{props.curActiveLayer.fmap_innb}</div>
                                </div>
                                <div className='contents-row field'>
                                    <div className="title">드론 프레임 타입</div>
                                    <div className="content flex-two">{props.curActiveLayer.drn_frm_ty === 4 ? '쿼드콥터' : props.curActiveLayer.drn_frm_ty === 6 ? '헥사콥터' : '옥토콥터'}</div>
                                </div>
                            </div>
                            <div className='contents-row field-grid'>
                                <div className='contents-row field'>
                                    <div className="title">대상 작물</div>
                                    <div className="content flex-two">{props.curActiveLayer.trgt_crps}</div>
                                    {/* <div className="content flex-two">{props.curActiveLayer.trgt_crps === 'lp' ? '액체 방제' : props.curActiveLayer.trgt_crps === 'sp' ? '고체 방제' : props.curActiveLayer.trgt_crps === 'lf' ? '액체비료' : props.curActiveLayer.trgt_crps === 'sf' ? '고체비료' : '파종'}</div> */}
                                </div>
                                <div className='contents-row field'>
                                    <div className="title">측간거리(m)</div>
                                    <div className="content flex-two">{props.curActiveLayer.grid_size}</div>
                                </div>
                            </div>
                            <div className='contents-row field-grid'>
                                <div className='contents-row field'>
                                    <div className="title">약제살포단위</div>
                                    <div className="content flex-two">{props.curActiveLayer.spray_unit}</div>
                                </div>
                                <div className='contents-row field'>
                                    <div className="title">농작업 분류</div>
                                    <div className="content">{props.curActiveLayer.frmwkt_ty_cd === 'lp' ? '액체 방제' : props.curActiveLayer.frmwkt_ty_cd === 'sp' ? '고체 방제' : props.curActiveLayer.frmwkt_ty_cd === 'lf' ? '액체비료' : props.curActiveLayer.frmwkt_ty_cd === 'sf' ? '고체비료' : '파종'}</div>
                                </div>
                            </div>
                            <div className='contents-row field'>
                                <div className="title">고유식별자</div>
                                <div className="content flex-two">{props.curActiveLayer.uid !== '' ? props.curActiveLayer.uid : '정보없음'}</div>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="information-container contents-col">
                        <div className="contents-row">
                            <div className="title">내역이름</div>
                            <div className="content"></div>
                        </div>
                        <div className="contents-row">
                            <div className="title">작성자</div>
                            <div className="content flex-two"></div>
                            <div className="title">최종 수정시간</div>
                            <div className="content flex-two"></div>
                        </div>
                        <div className="contents-row">
                            <div className="title">농작업 종류</div>
                            <div className="content flex-two"></div>
                            <div className="title">대상 작물</div>
                            <div className="content flex-two"></div>
                        </div>
                        <div className="contents-row">
                            <div className="title">필지</div>
                            <div className="content flex-two"></div>
                            <div className="title">재배관리맵 기준날짜</div>
                            <div className="content flex-two"></div>
                        </div>
                    </div> */}

                    {/* <div className="maintext">농작업 메타데이터</div>
                        
                    <div className="metadata-container contents-col">
                        <div className="uniqueID contents-row">
                            <div className="title">고유식별자</div>
                            <div className="content"></div>
                        </div>
                        <div 
                            className="viewinfo"
                            onClick={() => {
                                setVisible(!visible)
                            }} 
                        >정보보기</div>
                        <div className={`metadata-form ${visible? "visible" : "hidden"}`}>
                            <div className="metadata-maintext">기체 제원 데이터</div>
                            <div className="metadata-dronespec contents-col">
                                <div className="contents-row full-width">
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">기체 이름</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">기체 크기(mm)</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">기체 무게(g)</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="metadata-maintext">임무 데이터</div>
                            <div className="metadata-mission contents-col">
                            <div className="contents-row full-width">
                                <div className="contents-row full-width">
                                        <div className="metadata-title">농작업 종류</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">작물명</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">농자재명</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                </div>
                                <div className="contents-row">
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">농작업 영역</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">작업자 정보</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">농작업 결과</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                </div>
                                <div className="contents-row">
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">농작업통 정보</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">희석배수</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">자동여부</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                </div>
                                <div className="contents-row">
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">필지정보</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">노즐정보</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">병해충명</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                </div>
                                <div className="contents-row">
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">농작업 일자</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">기체 타입</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">농작업 시간(초)</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                </div>
                                <div className="contents-row">
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">농작업 길이(m)</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">임무 경로</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">비행 경로</div>
                                        <div className="metadata-content"></div>
                                    </div>
                                </div>
                                <div className="contents-row">
                                    <div className="contents-row full-width">
                                        <div className="metadata-title">배터리 정보</div>
                                        <div className="metadata-content"></div>
                                        <div className="metadata-content"></div>
                                        <div className="metadata-content"></div>
                                        <div className="metadata-content"></div>
                                        <div className="metadata-content"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                
                
                {
                    visible?
                    null
                    :
                
                    <div className="btn-container">
                        {/* <button className="btn delete">삭제</button>
                        <button className="btn modify">수정</button> */}
                        <button className="btn close" onClick={()=>{props.handleShowDetail(false)}}>확인</button>
                    </div>
                    
                }
            </div>
            
            </Modal>
        </React.Fragment>
    )
}

export default Farmworkdetail;
import "./Header.css";
import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Router,
  Routes,
  Route,
  Link,
  NavLink,
  useNavigate,
} from "react-router-dom";

import Modal from "../../../shared/components/uiElements/Modal";

import { Icon, Menu, Dropdown, Select, SearchResult } from "semantic-ui-react";

// AXIOS
import axios from "axios";

// TURF
import turf from "turf";

// Proj4
import proj4 from "proj4";

import SubMenu from "../subMenu/subMenu.js";
import useResponsive from "../../hooks/screenSize-hook.js";

proj4.defs(
  "EPSG:5179",
  "+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs("EPSG:4326", "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs");

function Header(props) {
  const APIToken = localStorage.getItem("login-token");
  const userName = localStorage.getItem("user-name");
  const userID = localStorage.getItem("user-id");
  const userLevel = localStorage.getItem("user-level");

  const [count, setCount] = useState(1);
  const handleClick = () => {
    setCount((prevCount) => prevCount * -1);
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    props.logout();
    navigate("/");
    window.location.reload();
    props.setShowSubmenu(false);
  };

  const [showZoneListPopup, setShowZoneListPopup] = useState(false); //관심구역 mobile modal
  const [selZoneNm, setSelZoneNm] = useState("관심구역");
  const [activeHeader, setActiveHeader] = useState(null);
  const [bboxCtr, setBboxCtr] = useState(false); //검색 시 bbox 안 날아가게

  const [searchZoneName, setSearchZoneName] = useState(""); //관심구역명 검색
  const [years, setYears] = useState([]); //연도정보 Select option
  const [selectedYears, setSelectedYears] = useState([]); //연도정보 checkbox
  const [expanded, setExpanded] = useState(false); //연도정보 dropdown control
  const [tags, setTags] = useState([]); //태그 Select option

  //submenu hover
  const handleMouseEnter = (div) => {
    setActiveHeader(div);
  };

  const handleMouseLeave = () => {
    setActiveHeader(false);
  };

  //연도정보 dropdown toggle
  const toggleCheckboxes = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  //연도정보 checkbox
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedYears((prev) =>
      checked ? [...prev, value] : prev.filter((year) => year !== value)
    );
  };

  //관심구역영역 바뀔때마다 태그랑 연도정보 update
  const [tagListNm, setTagListNm] = useState([]);
  useEffect(() => {
    const yearArray = [];

    if (tags) {
      const sortedTags = [...tags].sort((a, b) => b.max - a.max);
      const tagNm = sortedTags.map((item) => item.tag_nm);
      setTagListNm(tagNm);
    }

    if (props.searchMyZoneList && props.searchMyZoneList !== undefined) {
      props.searchMyZoneList.forEach(function (itrst_Info) {
        if (itrst_Info !== "") {
          yearArray.push(itrst_Info.yr_info);
        }
      });
    }
    const sortYears = [...new Set(yearArray)];
    setYears(sortYears.sort((a, b) => b - a));
  }, [props.searchMyZoneList, count]);

  useEffect(() => {
    if (props.selectItrst) {
      // console.log(props.selectItrst);
      if (props.selectItrst.user_id === userID) {
        props.setIsMine(true);
      } else {
        props.setIsMine(false);
      }
    }
  }, [props.selectItrst]);

  //선택한 관심구역의 지도 및 이름
  useEffect(() => {
    if (props.selectItrst) {
      if (userLevel >= 10) {
        props.setIsMine(true);
      } else {
        if (props.selectItrst.user_id === userID) {
          props.setIsMine(true);
        } else {
          props.setIsMine(false);
        }
      }
      const geojsondata = JSON.parse(props.selectItrst.geojson);
      const center = turf.center(geojsondata);
      const bbox = turf.bbox(geojsondata);
      const centerCoord5179 = center.geometry.coordinates;
      const centerCoord4326 = proj4("EPSG:5179", "EPSG:4326", centerCoord5179);
      if (
        centerCoord5179[0] > 124.0 &&
        centerCoord5179[0] < 132.0 &&
        centerCoord5179[1] > 32.0 &&
        centerCoord5179[1] < 39.0
      ) {
        props.handleCoord({
          centerX: centerCoord5179[0],
          centerY: centerCoord5179[1],
          bbox: bbox,
        });
      }

      if (
        centerCoord4326[0] > 124.0 &&
        centerCoord4326[0] < 132.0 &&
        centerCoord4326[1] > 32.0 &&
        centerCoord4326[1] < 39.0
      ) {
        props.handleCoord({
          centerX: centerCoord4326[0],
          centerY: centerCoord4326[1],
          bbox: bbox,
        });
      }
      setSelZoneNm(props.selectItrst.itrst_zone_nm);
    }
  }, [props.searchMyZoneList]);

  // 태그 리스트
  useEffect(() => {
    axios({
      method: "get",
      url: `/api/tag/list`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        setTags(result.data.data);
        // console.log("tags => "+tags);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props]);

  // 관심구역 검색 input
  const handleSearchZoneList = (e) => {
    setSearchZoneName(e.target.value);
  };

  // 클릭한 tag-nm
  const [selectedTag, setSelectedTag] = useState("");
  const handleTagClick = (nm) => {
    setSelectedTag((prev) => {
      if (prev.includes(nm)) {
        return prev.filter((item) => item !== nm);
      } else {
        return [...prev, nm];
      }
    });
  };

  const searchZoneList = () => {
    if (selectedYears) {
      props.handleCheckYear(selectedYears);
    }
    if (selectedTag) {
      props.handleCheckTag(selectedTag);
    }

    if (searchZoneName.length > 0) {
      props.handleMyZoneSearchName(searchZoneName);
      props.handleMyZoneSearchType("itrst_zone_nm");
    } else {
      props.handleMyZoneSearchName("");
    }
  };

  //관심구역 검색 초기화 onClearSearch
  const onClearSearch = () => {
    props.handleCheckYear("");
    props.handleCheckTag("");
    props.handleMyZoneSearchName("");
    props.handleMyZoneSearchType("");
    setSelectedYears([]);
    setSelectedTag("");
    setSearchZoneName("");
    // setItrstZoneClear(true);
  };

  //태그 접기 더보기
  const [showAll, setShowAll] = useState(false);
  const displayCount = showAll ? tagListNm.length : 4;

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleKeyPress = (e) => {
    if (e.key === " ") {
      e.stopPropagation();
    }
    if (e.key === "Enter") {
      e.stopPropagation();
      searchZoneList();
    }
  };

  // 관심구역 모바일 접기 더보기
  const [showZoneListAll, setShowZoneListAll] = useState(false);
  const zoneListCount = showZoneListAll ? props.myZoneList && props.myZoneList.length : 6;

  const toggleShowZoneListAll = () => {
    setShowZoneListAll(!showZoneListAll);
  };

  // 태블릿 세로일 때
  const {isTabletHeight} = useResponsive();
  const [maxHeight, setMaxHeight] = useState(400);

  useEffect(() => {
    const calculateHeight = () =>{
      const newHeight = Math.min(window.innerHeight - 400, 400);
      setMaxHeight(newHeight);
    }

    if(isTabletHeight){
      // 부모 요소를 관찰합니다
      calculateHeight();

      // 리사이즈 이벤트 리스너 추가
      window.addEventListener('resize', calculateHeight);
    };
    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => window.removeEventListener('resize', calculateHeight);
  }, [isTabletHeight]);

  return (
    <div className="app-container header-container">
      <header>
        <div className="title-container">
          <div className="main">
            
            <a href="/home">
              <div className="logo">
                <div className="title-image"></div>
              </div>
            </a>

            {props.isLoggedIn && !props.guideLine && !props.showThemeMapAdd &&
              <ul className="menu">
                <li
                  className="main-menu"
                  onMouseEnter={() => handleMouseEnter("header1")}
                  onMouseLeave={handleMouseLeave}
                >
                  <a href="#" className="main-link-text">
                    영상 및 주제도
                  </a>
                  {
                    <div
                      className={`side-box ${
                        activeHeader === "header1" ? "visible" : "hidden"
                      }`}
                    >
                      {/* <div className={`side-box`}> */}
                      <ul className="sub-menu">
                        <li
                          className="image"
                          onClick={() => {
                            props.handleShowImage(true);
                            props.handleShowTheme(false);
                            props.handleShowFarmwork(false);
                            props.handleShowDrnLog(false);
                            props.handleShowFarmworkSelect(false);
                            props.handleShowInstFarmwork(false);

                            props.handleSideBar(true);
                            props.handleShowThemeMapAdd(false);
                            props.handleGuideLine(false);
                          }}
                        >
                          <Link
                            to={"/home"}
                            onClick={() => {
                              props.setActiveHeaderMenu("/image");
                              props.handleHeader_state(0);
                              handleMouseLeave();
                            }}
                            className="sub-link-text"
                          >
                            관측영상 등록
                          </Link>
                        </li>

                        <li
                          className="theme"
                          onClick={() => {
                            props.handleShowImage(false);
                            props.handleShowTheme(true);
                            props.handleShowFarmwork(false);
                            props.handleShowDrnLog(false);
                            props.handleShowFarmworkSelect(false);
                            props.handleShowInstFarmwork(false);

                            props.handleSideBar(true);
                            props.handleShowThemeMapAdd(false);
                            props.handleGuideLine(false);
                          }}
                        >
                          <Link
                            to={"/home"}
                            onClick={() => {
                              props.setActiveHeaderMenu("/theme");
                              handleMouseLeave();
                            }}
                            className="sub-link-text"
                          >
                            주제도 등록
                          </Link>
                        </li>
                      </ul>
                    </div>
                  }
                </li>

                <li
                  className="main-menu"
                  onClick={() => {
                    props.handleShowImage(false);
                    props.handleShowTheme(false);
                    props.handleShowFarmwork(true);
                    props.handleShowDrnLog(false);
                    props.handleShowFarmworkSelect(false);
                    props.handleShowInstFarmwork(false);

                    props.handleSideBar(true);
                    props.handleShowThemeMapAdd(false);
                    props.handleGuideLine(false);

                    props.handleHeader_state(0);
                  }}
                >
                  <a href="#" className="main-link-text">
                    <Link
                      to={"/home"}
                      onClick={() => {
                        props.setActiveHeaderMenu("/farmwork");
                      }}
                      className="sub-link-text"
                    >
                      농작업
                    </Link>
                  </a>
                </li>

                {/* <li
                  className="main-menu"
                  onMouseEnter={() => handleMouseEnter("header2")}
                  onMouseLeave={handleMouseLeave}
                >
                  <a href="#" className="main-link-text">
                    농작업
                  </a>
                  {
                    <div
                      className={`side-box ${
                        activeHeader === "header2" ? "visible" : "hidden"
                      }`}
                    >
                      <ul className="sub-menu">
                        <li
                          className="image"
                          onClick={() => {
                            props.handleShowImage(false);
                            props.handleShowTheme(false);
                            props.handleShowFarmwork(true);
                            props.handleShowDrnLog(false);
                            props.handleShowFarmworkSelect(false);
                            props.handleShowInstFarmwork(false);

                            props.handleSideBar(true);
                            props.handleShowThemeMapAdd(false);
                            props.handleGuideLine(false);

                            props.handleHeader_state(false);
                          }}
                        >
                          <Link
                            to={"/home"}
                            onClick={() => {
                              props.setActiveHeaderMenu("/farmwork");
                            }}
                            className="sub-link-text"
                          >
                            농작업 조회
                          </Link>
                        </li>

                        // 새로 개발될 부분 (농작업 요청)
                        <li
                          className="theme"
                          onClick={() => {
                            props.handleShowImage(false);
                            props.handleShowTheme(false);
                            props.handleShowFarmwork(false);
                            props.handleShowDrnLog(false);
                            props.handleShowFarmworkSelect(true);
                            props.handleShowInstFarmwork(false);

                            props.handleSideBar(true);
                            props.handleShowThemeMapAdd(false);
                            props.handleGuideLine(false);

                            props.handleHeader_state(false);
                          }}
                        >
                          <Link
                            to={"/home"}
                            onClick={() => {
                              props.setActiveHeaderMenu("/farmworkSelect");
                            }}
                            className="sub-link-text"
                          >
                            농작업 요청
                          </Link>
                        </li>

                        // 새로 개발될 부분 (농작업 지원)
                        <li
                          className="theme"
                          onClick={() => {
                            props.handleShowImage(false);
                            props.handleShowTheme(false);
                            props.handleShowFarmwork(false);
                            props.handleShowDrnLog(false);
                            props.handleShowFarmworkSelect(false);
                            props.handleShowInstFarmwork(true);

                            props.handleSideBar(true);
                            props.handleShowThemeMapAdd(false);
                            props.handleGuideLine(false);

                            props.handleHeader_state(false);
                          }}
                        >
                          <Link
                            to={"/home"}
                            onClick={() => {
                              props.setActiveHeaderMenu("/instFarmwork");
                            }}
                            className="sub-link-text"
                          >
                            농작업 지원
                          </Link>
                        </li>
                      </ul>
                    </div>
                  }
                </li> */}

                {/* 새로 개발될 부분 드론 로그 업로드*/}
                <li
                  className="main-menu"
                  onClick={() => {
                    props.handleShowImage(false);
                    props.handleShowTheme(false);
                    props.handleShowFarmwork(false);
                    // props.handleShowDrnLog(true);
                    props.handleShowFarmworkSelect(false);
                    props.handleShowInstFarmwork(false);

                    props.handleSideBar(true);
                    props.handleShowThemeMapAdd(false);
                    props.handleGuideLine(false);

                    props.handleHeader_state(0);
                  }}
                >
                  <a href="#" className="main-link-text">
                    <Link
                      to={"/home"}
                      onClick={() => {
                        props.setActiveHeaderMenu("/drnLog");
                      }}
                      className="sub-link-text"
                    >
                      드론 로그 업로드
                    </Link>
                  </a>
                </li>

                <Link
                  to={"/bbslist"}
                  // className={`bbs`}
                  className={`bbs ${props.isMine ? null : "hidden"}`}
                  onClick={() => {
                    // props.handleIsNotice(false);
                    props.handleIsBbs_state("bulletinBoard");
                    props.handlePostTy(4);
                    props.setShowBulletinBoard(false);

                    props.handleHeader_state(1);
                  }}
                >
                  <li className="main-menu">
                    <a href="#" className="main-link-text">
                      게시판
                    </a>
                  </li>
                </Link>

                <Link
                  to={"/bbslist"}
                  // className={`bbs`}
                  className={`bbs ${props.isMine ? null : "hidden"}`}
                  onClick={() => {
                    // props.handleIsNotice(true);
                    props.handleIsBbs_state("notice");
                    props.handlePostTy(0);
                    props.setShowBulletinBoard(false);

                    props.handleHeader_state(1);
                  }}
                >
                  {" "}
                  <li className="main-menu">
                    <a href="#" className="main-link-text">
                      공지사항
                    </a>
                  </li>
                </Link>

                {/* <li
                  className="main-menu"
                  onClick={() => {
                    props.handleShowImage(false);
                    props.handleShowTheme(false);
                    props.handleShowFarmwork(false);
                    props.handleShowDrnLog(false);
                    props.handleShowFarmworkSelect(true);
                    props.handleShowInstFarmwork(false);

                    props.handleSideBar(true);
                    props.handleShowThemeMapAdd(false);
                    props.handleGuideLine(false);
                  }}
                >
                  <a href="#" className="main-link-text">
                    <Link
                      to={"/home"}
                      onClick={() => {
                        props.setActiveHeaderMenu("/farmworkSelect");
                      }}
                      className="sub-link-text"
                    >
                      농작업 요청
                    </Link>
                  </a>
                </li>

                <li
                  className="main-menu"
                  onClick={() => {
                    props.handleShowImage(false);
                    props.handleShowTheme(false);
                    props.handleShowFarmwork(false);
                    props.handleShowDrnLog(false);
                    props.handleShowFarmworkSelect(false);
                    props.handleShowInstFarmwork(true);

                    props.handleSideBar(true);
                    props.handleShowThemeMapAdd(false);
                    props.handleGuideLine(false);
                  }}
                >
                  <a href="#" className="main-link-text">
                    <Link
                      to={"/home"}
                      onClick={() => {
                        props.setActiveHeaderMenu("/instFarmwork");
                      }}
                      className="sub-link-text"
                    >
                      농작업 지원
                    </Link>
                  </a>
                </li> */}
              </ul>
            }
          </div>

          <div className={`submenu-box ${activeHeader ? "visible" : "hidden"}`} />

          {props.isLoggedIn && !props.showThemeMapAdd ? (
            <div className="sub">
              {!props.isMobile ? (
                <div className="comboBox">
                  <Dropdown
                    text={selZoneNm}
                    className="dropdown"
                    onClick={() => {
                      onClearSearch();
                      if (props.showSubmenu) {
                        props.setShowSubmenu(!props.showSubmenu);
                      }
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <Dropdown.Menu style={{left : '-140px'}}>
                      <div className="contents-col">
                        <div
                          className="gc-btn itrstMenu two"
                          icon="user"
                          onClick={() => {
                            if (
                              window.location.pathname === "/bbs" ||
                              window.location.pathname === "/bbslist" ||
                              window.location.pathname === "/profile" ||
                              window.location.pathname === "/adminmode"
                            ) {
                              navigate("/home");
                            }
                            props.handleShowMng(true);
                          }}
                        >
                          <i className="user icon"></i>관심구역 관리
                        </div>
                        <div
                          className="gc-btn itrstMenu two"
                          icon="settings"
                          onClick={() => {
                            if (
                              window.location.pathname === "/bbs" ||
                              window.location.pathname === "/bbslist" ||
                              window.location.pathname === "/profile" ||
                              window.location.pathname === "/adminmode"
                            ) {
                              navigate("/home");
                            }
                            props.handleShowGen(true);
                          }}
                        >
                          <i className="settings icon"></i>관심구역 생성
                        </div>
                      </div>
                      <div
                        className="division-A"
                        onClick={(e) => e.stopPropagation()}
                      ></div>
                      <div
                        className="contents-row tag-search"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <input
                          className="search-input"
                          type="text"
                          placeholder={"검색할 관심구역명을 입력하세요"}
                          onClick={(e) => e.stopPropagation()}
                          // onFocus={searchFoucus}
                          value={searchZoneName}
                          onChange={handleSearchZoneList}
                          onKeyDown={handleKeyPress}
                        ></input>
                        <div
                          className="search-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            searchZoneList();
                          }}
                        ></div>
                      </div>
                      <div
                        className="division-A"
                        onClick={(e) => e.stopPropagation()}
                      ></div>
                      <div className="contents-row tag-search">
                        <div className="toggle-tag">
                          <div className="multiselect">
                            <div
                              className="selectBox"
                              onClick={toggleCheckboxes}
                            >
                              <select id="year-select">
                                <option>년도를 선택하세요</option>
                              </select>
                              <div className="overSelect"></div>
                            </div>
                            {expanded && (
                              <div id="itrstYrCheck">
                                {years.map((year) => (
                                  <label key={year} htmlFor={year}>
                                    <input
                                      type="checkbox"
                                      id={year}
                                      value={year}
                                      onChange={handleCheckboxChange}
                                      checked={selectedYears.includes(
                                        year.toString()
                                      )}
                                    />
                                    <span> {year}</span>
                                  </label>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* 해시태그 hit 모음 */}
                      <div
                        className="contents-row tag-list"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="tag-btn">
                          {tagListNm &&
                            tagListNm.slice(0, displayCount).map((tag) => (
                              <div key={tag}>
                                <div
                                  className={`gc-btn all-tag ${
                                    selectedTag.includes(tag) ? "active" : ""
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleTagClick(tag);
                                  }}
                                >
                                  {tag}
                                </div>
                              </div>
                            ))}
                          {tagListNm && tagListNm.length > 1 ? (
                            <div>
                              <div
                                className="toggle-tag"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleShowAll();
                                }}
                              >
                                {showAll ? "접기" : "... 더보기"}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div
                        // className="contents-row tag-search"
                        className="contents-row tag-search"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div
                          className="gc-btn btn ok"
                          onClick={(e) => {
                            e.stopPropagation();
                            searchZoneList();
                            handleClick();
                          }}
                        >
                          검색
                        </div>
                        <div
                          className="gc-btn btn init"
                          onClick={(e) => {
                            e.stopPropagation();
                            onClearSearch();
                            handleClick();
                          }}
                        >
                          초기화
                        </div>
                      </div>

                      <div
                        className="list-count"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {props.myZoneList.length}개의 관심구역이 검색
                        되었습니다.
                      </div>

                      <div
                        className="division-A"
                        onClick={(e) => e.stopPropagation()}
                      ></div>

                      <div style={{ maxHeight: maxHeight , overflowY: "auto"}}>
                        {props.myZoneList
                          ? props.myZoneList.map(function (a, i) {
                              let tagList = a.tag_list;
                              if (typeof tagList === "string") {
                                tagList = tagList
                                  .replace(/[\[\]"'\s]/g, "")
                                  .split(",");
                              }
                              return (
                                <Dropdown.Item
                                  key={i}
                                  className="itrstItem"
                                  onClick={() => {
                                    if (
                                      window.location.pathname === "/bbs" ||
                                      window.location.pathname === "/profile" ||
                                      window.location.pathname === "/adminmode"
                                    ) {
                                      navigate("/home");
                                    }
                                    props.handleSideBar(true);
                                    const geojsondata = JSON.parse(a.geojson);
                                    const center = turf.center(geojsondata);
                                    const bbox = turf.bbox(geojsondata);
                                    const centerCoord5179 =
                                      center.geometry.coordinates;
                                    const centerCoord4326 = proj4(
                                      "EPSG:5179",
                                      "EPSG:4326",
                                      centerCoord5179
                                    );
                                    if (
                                      centerCoord5179[0] > 124.0 &&
                                      centerCoord5179[0] < 132.0 &&
                                      centerCoord5179[1] > 32.0 &&
                                      centerCoord5179[1] < 39.0
                                    ) {
                                      props.handleCoord({
                                        centerX: centerCoord5179[0],
                                        centerY: centerCoord5179[1],
                                        bbox: bbox,
                                      });
                                    }

                                    if (
                                      centerCoord4326[0] > 124.0 &&
                                      centerCoord4326[0] < 132.0 &&
                                      centerCoord4326[1] > 32.0 &&
                                      centerCoord4326[1] < 39.0
                                    ) {
                                      props.handleCoord({
                                        centerX: centerCoord4326[0],
                                        centerY: centerCoord4326[1],
                                        bbox: bbox,
                                      });
                                    }
                                    props.handleSelectItrst(a);
                                    setSelZoneNm(a.itrst_zone_nm);
                                  }}
                                >
                                  <div className="itrstNm">
                                    {a.itrst_zone_nm}
                                  </div>
                                  {tagList &&
                                    tagList.map((item, index) => (
                                      <div
                                        className={`${
                                          tagList &&
                                          tagList.length &&
                                          tagList[0] !== ""
                                            ? "gc-btn tagList-one"
                                            : ""
                                        }`}
                                        key={index}
                                      >
                                        <div className="">{item}</div>
                                      </div>
                                    ))}
                                </Dropdown.Item>
                              );
                            })
                          : null}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                //mobile이면
                <div
                  className="contents-row comboBox-mobile"
                  onClick={() => setShowZoneListPopup(true)}
                >
                  <div className="selZoneNm-mobile">{selZoneNm}</div>
                  <i className="fas fa-sort-down"></i>
                </div>
              )}

              
              <div className="desktop-view">
                <div
                className="contents-row userName"
                onClick={() => {
                  props.setShowSubmenu(!props.showSubmenu);
                }}
                >
                  <div className="userProfile"></div>
                  <span className="userNm">{userName} 님</span>
                </div>
                <div className={`submenu ${props.showSubmenu ? "active" : ""}`}>
                  <div
                    className="item"
                    onClick={() => {
                      props.setShowSubmenu(false);
                    }}
                  >
                  <div className="userinfo"></div>
                    <Link to={"/profile"} className="text">
                      회원정보
                    </Link>
                  </div>
                  {localStorage.getItem("user-level") >= 10 && !props.isMobile ? (
                    <div
                      className="item"
                      onClick={() => {
                        props.setShowSubmenu(false);
                      }}
                    >
                      <div className="adminmode"></div>
                      <Link to={"/adminmode"} className="text">
                        관리자 모드
                      </Link>
                    </div>
                  ) : null}
                  <div className="item" onClick={handleLogout}>
                    <div className="logout"></div>
                    <div className="text">로그아웃</div> 
                  </div>
                </div>
              </div>

              <div className="tablet-view">
                <div className={`icon`} 
                  style={{marginLeft:'10px',color:'white',padding:'10px'}}
                  onClick={()=>{
                    props.setShowSubmenu(!props.showSubmenu);
                  }}
                >
                  <Icon name="bars"/>
                </div>
                <SubMenu 
                  userName={userName}
                  handleLogout={handleLogout}
                  handleShowImage={props.handleShowImage}
                  handleShowTheme={props.handleShowTheme}
                  handleShowFarmwork={props.handleShowFarmwork}
                  handleShowDrnLog={props.handleShowDrnLog}
                  handleShowFarmworkSelect={props.handleShowFarmworkSelect}
                  handleShowInstFarmwork={props.handleShowInstFarmwork}
                  handleSideBar={props.handleSideBar}
                  handleShowThemeMapAdd={props.handleShowThemeMapAdd}
                  handleGuideLine={props.handleGuideLine}
                  setActiveHeaderMenu={props.setActiveHeaderMenu}
                  handleHeader_state={props.handleHeader_state}
                  handleMouseLeave={handleMouseLeave}
                  handleIsBbs_state={props.handleIsBbs_state}
                  handlePostTy={props.handlePostTy}
                  setShowBulletinBoard={props.setShowBulletinBoard}
                  showSubmenu={props.showSubmenu}
                />
              </div>
            </div>
          ) : null}
        </div>
      </header>

      {showZoneListPopup ? (
        <Modal
          show={showZoneListPopup}
          onCancel={() => setShowZoneListPopup(false)}
          header="알림"
          icon={
            <i
              className="code x icon"
              onClick={() => setShowZoneListPopup(false)}
            />
          }
          className={`zoneListPopup ${props.isMobile ? "mobile" : ""}`}
          isMobile={props.isMobile}
        >
          <div className="header-container mobile">
            <div className="contents-row tag-search">
              <input
                className="search-input"
                type="text"
                placeholder={"검색할 관심구역명을 입력하세요"}
                onClick={(e) => e.stopPropagation()}
                // onFocus={searchFoucus}
                value={searchZoneName}
                onChange={handleSearchZoneList}
                // onKeyDown={handleKeyPress}
              ></input>
              <div
                className="search-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  searchZoneList();
                }}
              ></div>
            </div>

            <div className="contents-row tag-list">
              <div className="multiselect">
                <div className="selectBox" onClick={toggleCheckboxes}>
                  <select id="year-select">
                    <option>년도를 선택하세요</option>
                  </select>
                  <div className="overSelect"></div>
                </div>
                {expanded && (
                  <div id="itrstYrCheck">
                    {years.map((year) => (
                      <label key={year} htmlFor={year}>
                        <input
                          type="checkbox"
                          id={year}
                          value={year}
                          onChange={handleCheckboxChange}
                          checked={selectedYears.includes(year.toString())}
                        />
                        <span> {year}</span>
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* 해시태그 */}
            <div className="contents-row tag-list">
              <div className="tag-btn">
                {tagListNm &&
                  tagListNm.slice(0, displayCount).map((tag) => (
                    <div key={tag}>
                      <div
                        className={`gc-btn all-tag ${
                          selectedTag.includes(tag) ? "active" : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTagClick(tag);
                        }}
                      >
                        {tag}
                      </div>
                    </div>
                  ))}

                {tagListNm && tagListNm.length > 1 ? (
                  <div>
                    <div
                      className="toggle-tag"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleShowAll();
                      }}
                    >
                      {showAll ? "접기" : "... 더보기"}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div
              className="contents-row tag-search"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                className="gc-btn btn ok"
                onClick={(e) => {
                  e.stopPropagation();
                  searchZoneList();
                }}
              >
                검색
              </div>
              <div
                className="gc-btn btn init"
                onClick={(e) => {
                  e.stopPropagation();
                  onClearSearch();
                }}
              >
                초기화
              </div>
            </div>

            <div className="search-results">
              <div className="contents-row search-result">
                {props.myZoneList.length}개의 관심구역이 검색 되었습니다.
              </div>
              <div className="zoneList">
                {props.myZoneList &&
                  props.myZoneList.slice(0, zoneListCount).map((a, i) => {
                    let tagList = a.tag_list;
                    if (typeof tagList === "string") {
                      tagList = tagList.replace(/[\[\]"'\s]/g, "").split(",");
                    }
                    return (
                      <div
                        className="itrstItem"
                        key={i}
                        onClick={() => {
                          if (
                            window.location.pathname === "/profile" ||
                            window.location.pathname === "/adminmode"
                          ) {
                            navigate("/home");
                          }
                          props.handleSideBar(true);
                          const geojsondata = JSON.parse(a.geojson);
                          const center = turf.center(geojsondata);
                          const bbox = turf.bbox(geojsondata);
                          const centerCoord5179 = center.geometry.coordinates;
                          const centerCoord4326 = proj4(
                            "EPSG:5179",
                            "EPSG:4326",
                            centerCoord5179
                          );
                          if (
                            centerCoord5179[0] > 124.0 &&
                            centerCoord5179[0] < 132.0 &&
                            centerCoord5179[1] > 32.0 &&
                            centerCoord5179[1] < 39.0
                          ) {
                            props.handleCoord({
                              centerX: centerCoord5179[0],
                              centerY: centerCoord5179[1],
                              bbox: bbox,
                            });
                          }

                          if (
                            centerCoord4326[0] > 124.0 &&
                            centerCoord4326[0] < 132.0 &&
                            centerCoord4326[1] > 32.0 &&
                            centerCoord4326[1] < 39.0
                          ) {
                            props.handleCoord({
                              centerX: centerCoord4326[0],
                              centerY: centerCoord4326[1],
                              bbox: bbox,
                            });
                          }

                          props.handleSelectItrst(a);
                          setSelZoneNm(a.itrst_zone_nm);
                          setShowZoneListPopup(false);
                        }}
                      >
                        <div className="contents-row itrst-zone">
                          <div className="itrstNm">{a.itrst_zone_nm}</div>
                          <div
                            className={`gc-btn zone-btn ${
                              tagList && tagList.length ? "ok-tag" : ""
                            } ${a.user_id === userID ? "manage" : "view"}`}
                          >
                            {a.user_id === userID ? "관리" : "조회"}
                          </div>
                        </div>

                        {tagList &&
                          tagList.map((item, index) => (
                            <div
                              className={`${
                                tagList && tagList.length && tagList[0] !== ""
                                  ? "gc-btn tagList-one"
                                  : ""
                              }`}
                              key={index}
                            >
                              <div className="">{item}</div>
                            </div>
                          ))}
                      </div>
                    );
                  })}
                {props.myZoneList && props.myZoneList.length > 7 && (
                  <div
                    className="contents-row toggle-zoneList"
                    onClick={(e) => {
                      toggleShowZoneListAll();
                    }}
                  >
                    {showZoneListAll ? "접기" : "... 더보기"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

export default Header;

import "./drnLogUpload.css";

import React, { useEffect, useState, useRef } from "react";

import Modal from "../../shared/components/uiElements/Modal";
import Popup from "../../shared/components/uiElements/Popup";
import axios from "axios";
import styled, { keyframes } from "styled-components";

// Proj4
import proj4 from "proj4";

// import config from "../../../config/config";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 80px 300px 80px 300px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: ${rotate} 1s infinite linear;
`;

proj4.defs(
  "EPSG:5179",
  "+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:5181",
  "+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +units=m +no_defs"
);
proj4.defs(
  "EPSG:5186",
  "+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs"
);
proj4.defs("EPSG:4326", "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs");

function DrnLogUpload(props) {
  const APIToken = localStorage.getItem("login-token");
  const userId = localStorage.getItem("user-id");

  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("필수 정보를 입력하세요.");
  const [showDrnLogDelPopup, setShowDrnLogDelPopup] = useState(false);

  // loading animation
  const [fileLoading, setFileLoading] = useState(false);
  // 파일 업로드
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [displayState, setDisplayState] = useState("none");
  const drnFileRef = useRef(null);
  const fileRef = useRef(null);

  //form
  const [drnLogNm, setDrnLogNm] = useState(""); //제목
  const [drnLogContent, setDrnLogContent] = useState(""); //내용
  const [drnLogJobYmd, setDrnLogJobYmd] = useState(""); //등록일자

  const [dummyDrnLogArray, setDummyDrnLogArray] = useState(); //수정 array

  const handleDrnLogNm = (e) => {
    setDrnLogNm(e.target.value);
  };

  const handleDrnLogContent = (e) => {
    setDrnLogContent(e.target.value);
  };

  const handleFileClick = () => {
    drnFileRef.current.click();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setSelectedFile(e.target.files[0]);
    setSelectedFileName(e.target.files[0].name);
    fileRef.current = file;
    setDisplayState("flex");
    e.target.value = "";
  };

  const cancel = () => {
    setSelectedFile();
    setSelectedFileName();
    setDisplayState("none");
  };

  const [showDrnLogCanPopup, setShowDrnLogCanPopup] = useState(false);
  const [drnLogModified, setDrnLogModified] = useState(false);
  const [showDrnLogModPopup, setShowDrnLogModPopup] = useState(false);

  function validation() {
    if (drnLogNm == undefined || drnLogNm == "") {
      setPopupMsg("제목을 입력하세요.");
      setShowPopup(true);
      // setFileLoading(false);
      return false;
    }
    if (selectedFileName == undefined || selectedFileName == "") {
      setPopupMsg("파일을 첨부하세요.");
      setShowPopup(true);
      setShowDrnLogModPopup(false);
      // setFileLoading(false);
      return false;
    }
    setFileLoading(true);
    return true;
  }

  const handleDrnLogUld = async (e) => {
    let isOK = validation();
    let zoneId = props.selectItrst.itrst_zone_id;

    // setFileLoading(true);
    const formData = new FormData();
    formData.append("zoneId", zoneId);
    formData.append("title", drnLogNm);
    formData.append("content", drnLogContent);
    // formData.append('folderNum', folderNum);
    formData.append("logMod", "drnLogAdd");
    formData.append("file", selectedFile);
    formData.append("userId", userId);

    // console.log("userId"+userId);

    const headers = {
      Authorization: `Bearer {${APIToken}}`,
      // "Content-Type": "multipart/form-data",
    };

    if (isOK) {
      axios
        .post(`/api/droneLog/add`, formData, headers)
        .then((result) => {
          setFileLoading(false);
          if (result.data.success) {
            setPopupMsg("등록이 완료되었습니다.");
            props.handleModalDelState(2);
            // props.setDrnLogFlag(false);
            setShowPopup(true);
            // console.log(result.data);
            // setSelectedFile();
            // setSelectedFileName();
            // setDrnLogNm();
            // setDrnLogContent();
            // props.showDrnLog(false);
          } else {
            setPopupMsg("등록에 실패하였습니다.");
            setShowPopup(true);
            // if(result.data.msg){
            //     const falseMsg = result.data.msg.split(":");
            //     setPopupMsg(falseMsg[1] && falseMsg[1].trim());
            // }
            // else{
            //     setPopupMsg('관측영상 등록 실패');
            // }
            // setShowPopup(true);
          }
        })
        .catch((error) => {
          // setLoading(false);
          console.log(error);
        });
    }
  };

  // 수정 SET
  useEffect(() => {
    if (props.activeDrnLog === 2 || props.activeDrnLog === 3) {
      // 관측영상 제목
      setDrnLogNm(props.curActiveLayer.ttl_nm);
      // 관측영상 내용
      setDrnLogContent(props.curActiveLayer.rmrk_cn || "");
      // 관측영상 첨부파일 이름
      setSelectedFileName(props.curActiveLayer.orgnl_file_nm);

      setSelectedFile(fileRef.current);
      // setDrnLogJobYmd(props.curActiveLayer.job_ymd || "");
    }
  }, [props.activeDrnLog]);

  // 수정을 취소하시겠습니까?
  function drnLogModNo() {
    props.handleActiveDrnLog(2);
    setDrnLogModified(false);
    setShowDrnLogCanPopup(false);
  }

  // 수정사항을 저장하시겠습니까?
  const drnLogModYes = async () => {
    let isOK = validation();
    let zoneId = props.selectItrst.itrst_zone_id;
    // let folderNum = props.selectItrst.itrst_zone_id;

    // console.log("selectedFile =>" + selectedFile);
    // console.log("selectedFileName => " + selectedFileName);

    const formData = new FormData();
    formData.append("zoneId", zoneId);
    formData.append("logSn", props.curActiveLayer.log_sn);
    formData.append("title", drnLogNm);
    formData.append("content", drnLogContent);
    // formData.append('folderNum', folderNum);
    formData.append("logMod", "drnLogMod");
    formData.append("userId", userId);
    formData.append("delFile", props.curActiveLayer.strg_path);

    if (selectedFile) {
      formData.append("file", selectedFile);
    } else {
      formData.append("file", fileRef.current);
      // console.log(fileRef.current);
    }
    // formData.append('file', selectedFile);

    const headers = {
      Authorization: `Bearer {${APIToken}}`,
      // "Content-Type": "multipart/form-data",
    };

    if (isOK) {
      setFileLoading(true);
      axios
        .post(`/api/droneLog/update`, formData, headers)
        .then((result) => {
          if (result.data.success) {
            setFileLoading(false);
            setPopupMsg("수정이 완료되었습니다.");
            props.handleModalDelState(3);
            // logfileState();
            // props.setDrnLogFlag(true);
            setShowPopup(true);
            // console.log(result.data);
            // setSelectedFile();
            // setSelectedFileName();
            // setDrnLogNm();
            // setDrnLogContent();
            // props.showDrnLog(false);
          } else {
            setFileLoading(false);
            setPopupMsg("등록에 실패하였습니다.");
            setShowPopup(true);
            // if(result.data.msg){
            //     const falseMsg = result.data.msg.split(":");
            //     setPopupMsg(falseMsg[1] && falseMsg[1].trim());
            // }
            // else{
            //     setPopupMsg('관측영상 등록 실패');
            // }
            // setShowPopup(true);
          }
        })
        .catch((error) => {
          // setLoading(false);
          console.log(error);
        });
    }
  };

  function onModDrnLogHandler() {
    props.handleActiveDrnLog(3);
    if (drnLogModified) {
      setShowDrnLogCanPopup(true);
    } else {
      setDrnLogModified(true);
      setDummyDrnLogArray({
        // zoneId: props.curActiveLayer.log_sn,
        title: drnLogNm,
        content: drnLogContent,
        // logCtgr: logCtgr,
        file: selectedFileName,
        // jobYmd: drnLogJobYmd,
      });
    }
  }

  function onSaveDrnLogHandler() {
    if (drnLogModified) {
      setShowDrnLogModPopup(true);
    } else {
      props.setShowDrnLog(false);
    }
  }

  const onDrnLogDelete = () => {
    // console.log(props.curActiveLayer);
    axios({
      method: "post",
      url: `/api/droneLog/delete`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      data: {
        logSn: props.curActiveLayer.log_sn,
        zoneId: props.curActiveLayer.itsrt_zone_id,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setPopupMsg("드론 로그가 삭제되었습니다.");
          setShowPopup(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <Modal
        show={props.showDrnLog}
        header={
          props.activeDrnLog === 2
            ? "드론 로그 업로드 조회"
            : props.activeDrnLog === 3
            ? "드론 로그 업로드 수정"
            : "드론 로그 업로드 등록"
        }
        icon={
          <i
            className="code x icon"
            onClick={() => {
              props.setShowDrnLog(false);
            }}
          />
        }
        className={`imageReg ${props.isMobile ? "mobile" : ""}`}
        isMobile={props.isMobile}
        // onSubmit={handleDrnLogUld}
      >
        <div className="contents-row main-container">
          <div className="drnLogUpload-container">
            {fileLoading ? (
              <>
                <Spinner />
                <div className="spinner">처리중입니다.</div>
              </>
            ) : (
              // <div className="contents-col drnLog-upload-form input-form flex-center">
              <div
                className={`contents-col drnLog-upload-form input-form flex-center ${
                  props.isMobile ? "mobile" : ""
                } `}
              >
                <div className="contents-row drnLog-reg">
                  <div className="contents-col inside">
                    <div className="contents-row field group position-btn">
                      <label className="label">
                        <span className="required-marker">*</span>제목
                      </label>
                      {props.activeDrnLog === 2 ? (
                        <div className={`input-img img-list`}>
                          <span> {props.curActiveLayer.ttl_nm}</span>
                        </div>
                      ) : (
                        <input
                          className={`input-img title ${
                            props.activeDrnLog == 2 ? "viewer" : ""
                          }`}
                          type="text"
                          id="name"
                          name="name"
                          onChange={handleDrnLogNm}
                          autoComplete="off"
                          value={
                            props.activeDrnLog === 2
                              ? props.curActiveLayer.ttl_nm
                              : drnLogNm
                          }
                          spellCheck="false"
                          disabled={props.activeDrnLog === 2}
                        />
                      )}
                    </div>
                    {props.activeDrnLog === 2 && (
                      <div className="contents-row field group inside">
                        <label className="label">작업일</label>
                        <input
                          className={`input-img img-list`}
                          type="text"
                          id="regYMD"
                          name="regYMD"
                          readOnly
                          value={props.curActiveLayer.job_ymd || ""}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="contents-row field group textarea">
                  <label className="label">내용</label>
                  <textarea
                    type="textarea"
                    className="input-img textarea"
                    onChange={handleDrnLogContent}
                    disabled={props.activeDrnLog === 2}
                    value={
                      props.activeDrnLog === 2
                        ? props.curActiveLayer.rmrk_cn !== ""
                          ? props.curActiveLayer.rmrk_cn || ""
                          : ""
                        : drnLogContent || ""
                    }
                    spellCheck="false"
                  ></textarea>
                </div>

                <div className="contents-row field group position-btn">
                  <label className="label">
                    <span className="required-marker">*</span> 로그파일
                  </label>
                  <div className="input-img file">
                    <div className="contents-col uplfile-group">
                      {selectedFileName && (
                        <div className="contents-row uplfile">
                          <div style={{ paddingTop: "5px" }}>
                            {selectedFileName}
                          </div>

                          {(props.activeDrnLog === 1 ||
                            props.activeDrnLog === 3) && (
                            <i className="x-icon" onClick={cancel}></i>
                          )}
                        </div>
                      )}
                    </div>

                    <input
                      id="drnLogUpload"
                      type="file"
                      multiple
                      ref={drnFileRef}
                      accept={".log, .bin, .csv"}
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                    />

                    {props.activeDrnLog !== 2 && (
                      <div className="contents-row btn-img-bottom">
                        <div
                          className="gc-btn btn-file file-check"
                          onClick={handleFileClick}
                        >
                          파일 선택
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* <input
                type="submit"
                ref={submitButton}
                style={{ display: "none" }}
              /> */}

                {/* <div className="contents-row btn-img-bottom">
                <button
                  className="gc-btn btn-img text-color"
                  onClick={() => {
                    props.setShowDrnLog(false);
                  }}
                >
                  취소
                </button>

                <button className="gc-btn btn-img"
                  onClick={handleDrnLogUld}
                >
                  등록
                </button>
              </div> */}

                {props.activeDrnLog == 1 ? (
                  <div className="contents-row btn-img-bottom add">
                    <button
                      className="gc-btn btn-img add"
                      onClick={handleDrnLogUld}
                    >
                      등록
                    </button>
                    <button
                      className="gc-btn btn-img cancel"
                      onClick={() => {
                        props.setShowDrnLog(false);
                      }}
                    >
                      취소
                    </button>
                  </div>
                ) : props.activeDrnLog == 2 || props.activeDrnLog == 3 ? (
                  <div className="contents-row btn-img-bottom add">
                    {
                      !props.isMobile ? (
                        <>
                          {drnLogModified ? null : userId ===
                              props.curActiveLayer.user_id ||
                            localStorage.getItem("user-level") >= 10 ? (
                            <button
                              className={"gc-btn btn-img red"}
                              onClick={() => setShowDrnLogDelPopup(true)}
                            >
                              삭제
                            </button>
                          ) : null}
                          {drnLogModified ? (
                            <>
                              <button
                                onClick={onSaveDrnLogHandler}
                                className="gc-btn btn-img"
                              >
                                저장
                              </button>
                              <button
                                onClick={onModDrnLogHandler}
                                className="gc-btn btn-img cancel"
                              >
                                취소
                              </button>
                            </>
                          ) : userId === props.curActiveLayer.user_id ||
                            localStorage.getItem("user-level") >= 10 ? (
                            <button
                              onClick={onModDrnLogHandler}
                              className="gc-btn btn-img text-color green"
                            >
                              수정
                            </button>
                          ) : null}
                          {drnLogModified ? null : (
                            <button
                              onClick={onSaveDrnLogHandler}
                              className="gc-btn btn-img cancel"
                            >
                              닫기
                            </button>
                          )}
                        </>
                      ) : null
                      // <div className='gc-btn mobile-ok' onClick={()=>{props.setShowImageReg(false)}} >확인</div>
                    }
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Popup
        show={showDrnLogDelPopup}
        onCancel={() => setShowDrnLogDelPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => setShowDrnLogDelPopup(false)}
          />
        }
      >
        <div className="modal-info">
          <p>드론 로그를 삭제하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowDrnLogDelPopup(false);
            }}
          >
            아니오
          </button>
          <button
            className="zoneinfo_ok_btn"
            // onClick={onDrnLogDelete}
            onClick={() => {
              onDrnLogDelete();
              props.handleModalDelState(1);
            }}
          >
            예
          </button>
        </div>
      </Popup>

      <Popup
        show={showDrnLogCanPopup}
        // onCancel={() => setShowCanPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => setShowDrnLogCanPopup(false)}
          />
        }
      >
        <div className="modal-info">
          <p>수정을 취소하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowDrnLogCanPopup(false);
            }}
          >
            아니오
          </button>
          <button className="zoneinfo_ok_btn" onClick={drnLogModNo}>
            예
          </button>
        </div>
      </Popup>

      <Popup
        show={showDrnLogModPopup}
        onCancel={() => setShowDrnLogModPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => setShowDrnLogModPopup(false)}
          />
        }
      >
        <div className="modal-info">
          <p>수정사항을 저장하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowDrnLogModPopup(false);
            }}
          >
            아니오
          </button>
          <button
            className="zoneinfo_ok_btn"
            onClick={() => {
              drnLogModYes();
              setShowDrnLogModPopup(false);
            }}
          >
            예
          </button>
        </div>
      </Popup>

      <Popup
        show={showPopup}
        onCancel={() => setShowPopup(false)}
        header="알림"
        icon={<i className="code x icon" onClick={() => setShowPopup(false)} />}
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);

              if (
                popupMsg.includes("등록") ||
                popupMsg.includes("삭제") ||
                popupMsg.includes("수정")
              ) {
                props.setShowDrnLog(false);
                props.handleActiveDrnLog(true);
                // props.activeFsMenuHandler(5);
                // props.setAddCheckDrn(true);
              }
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}

export default DrnLogUpload;

// import "./farmworkSelect.css";
import React, { useEffect, useState } from "react";

import config from "../../config/config";

import axios from "axios";
import { Select, Checkbox, TabPane, Tab } from "semantic-ui-react";
import DatePicker from "react-datepicker";

import Modal from "../../shared/components/uiElements/Modal";
import Popup from "../../shared/components/uiElements/Popup";

// TURF
import turf from "turf";

// Proj4
import proj4 from "proj4";

import moment from "moment";

function NewFarmworkRequest(props) {
  const APIToken = localStorage.getItem("login-token");

  const [totalCount, setTotalCount] = useState(0);

  // const [registFarmwork, setRegistFarmwork] = useState(false);
  // 필지정보
  const [farmReqID, setFarmReqID] = useState();
  // 일자
  const [startDate, setStartDate] = useState(null);
  // 대상 작물
  const [farmReqCrop, setFarmReqCrop] = useState();
  // 농작업 선택
  const [farmReqType, setFarmReqType] = useState();
  // 기타 의견
  const [opinion, setOpinion] = useState();

  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState();

  const handleFarmReqType = (e, data) => {
    setFarmReqType(data.value);
  };

  const handleFarmReqCrop = (e) => {
    setFarmReqCrop(e.target.value);
  };

  const handleOpinion = (e) => {
    setOpinion(e.target.value);
  };

  // useEffect(() => {
  //   console.log(props);
  // }, []);

  useEffect(() => {
    if (props.selectedFarm) {
      setFarmReqID(props.selectedFarm.FMAP_INNB);
    }
  }, [props.selectedFarm]);

  function highlightShpFillcrop(colormap) {
    if (props.mapRef.current) {
      const map = props.mapRef.current.getMap();

      map.setPaintProperty(
        props.selectItrst.itrst_zone_nm,
        "fill-color",
        colormap
      );
    }
  }

  // 농작업 요청 시 필지 선택 - 팜맵
  function highlightFarmMapFill(sID, color) {
    let FM_LAYER_ID = "tn_fmap_info";
    if (sID == "") {
      sID = "00000000";
    }

    if (props.mapRef.current) {
      const map = props.mapRef.current.getMap();
      map.setPaintProperty(FM_LAYER_ID, "fill-color", [
        "match",
        ["get", "FMAP_INNB"],
        sID,
        color,
        "rgba(255, 255, 255, 0.0)",
      ]);
      map.setPaintProperty(
        FM_LAYER_ID,
        "fill-outline-color",
        "rgba(255, 255, 0, 1.0)"
      );
    }
  }

  const farmwork_option = [
    { key: "lp", value: "lp", text: "액체 방제" },
    { key: "sp", value: "sp", text: "고체 방제" },
    { key: "lf", value: "lf", text: "액체 비료" },
    { key: "sf", value: "sf", text: "고체 비료" },
  ];

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
    }
  };

  function validation() {
    if (
      farmReqID === null ||
      farmReqCrop === null ||
      startDate === null ||
      farmReqType === null
    ) {
      setPopupMsg("모든 정보를 입력하세요.");
      setShowPopup(true);
    }
    return true;
  }

  function regFarmWork() {
    let isOK = validation();
    if (isOK) {
      props.setLoading(true);
      const Coord =
        `${props.farmworkCoord[0]}` + `,` + `${props.farmworkCoord[1]}`;

      axios({
        method: "get",
        // url:`http://api.vworld.kr/req/address?service=address&request=getAddress`
        url:
          `ex-api/api.vworld.kr/req/address?service=address&request=getAddress` +
          `&key=${config.vworldKey}` +
          `&point=${Coord}&type=parcel`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((res) => {
          console.log(res);
          if (isOK) {
            axios({
              method: "post",
              url: `/api/farmworkSelect/req`,
              headers: {
                Authorization: `Bearer {${APIToken}}`,
              },
              data: {
                fmapId: farmReqID, // 필지정보
                fworkYmd: moment(startDate).format("YYYY-MM-DD"), // 요청날짜(요청일)
                trgtCrps: farmReqCrop, // 대상작물
                fworkTyCd: farmReqType, // 농작업선택
                opinion: opinion, // 기타 의견
                addr: res.data.data.response.result[0].text,
                bjdCd: res.data.data.response.result[0].structure.level4LC,
                zoneId: props.selectItrst.itrst_zone_id,
              },
            }) //
              .then((result) => {
                console.log("result", result);
                if (result.data.success) {
                  props.setLoading(false);
                  setPopupMsg("농작업 요청이 완료되었습니다.");
                  setShowPopup(true);
                  props.setSelectedFarm(null);
                  setFarmReqID();
                  setStartDate(null);
                  setFarmReqCrop("");
                  setFarmReqType();
                  setOpinion();
                  props.setSearchFlag(true);
                } else {
                  props.setLoading(false);
                  setPopupMsg("농작업 요청 실패");
                  setShowPopup(true);
                  // props.setSelectedFarm(null);
                }
              })
              .catch((error) => {
                props.setLoading(false);
                console.log(error);
              });
          }
        }) //
        .catch((error) => {
          props.setLoading(false);
          console.log(error);
        });
    }
  }

  return (
    <React.Fragment>
      <Modal
        show={props.registFarmwork}
        header="농작업 요청"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              props.setRegistFarmwork(false);
              props.setSelectedFarm();
              if (
                props.selectedFarm !== null &&
                props.selectedFarm !== undefined
              ) {
                if (
                  props.selectedFarm.shp_id !== null &&
                  props.selectedFarm.shp_id !== undefined
                ) {
                  highlightShpFillcrop([
                    "match",
                    ["get", "shp_id"],
                    props.selectedFarm.shp_id,
                    "rgba(0, 0, 255, 0.0)",
                    "rgba(0, 0, 0, 0.0)",
                  ]);
                } else {
                  if (props.selectedFarm.FMAP_INNB) {
                    highlightFarmMapFill(
                      props.selectedFarm.FMAP_INNB,
                      "rgba(0, 0, 255, 0.0)"
                    );
                  }
                }
              }
            }}
          />
        }
        className={`farmworkSelect`}
      >
        <div className="farmworkSelect-modal-container">
          <div className="input-group contents-col full-width">
            <div className="title-text">
              <span className="required-marker">*</span>필지 선택
            </div>
            <div
              className="select-zone farmwork flex-center"
              onClick={() => {
                const geojsondata = JSON.parse(props.selectItrst.geojson);
                console.log("geojsondata", geojsondata);
                const center = turf.center(geojsondata);
                const bbox = turf.bbox(geojsondata);
                const centerCoord5179 = center.geometry.coordinates;
                const centerCoord4326 = proj4(
                  "EPSG:5179",
                  "EPSG:4326",
                  centerCoord5179
                );
                if (
                  centerCoord5179[0] > 124.0 &&
                  centerCoord5179[0] < 132.0 &&
                  centerCoord5179[1] > 32.0 &&
                  centerCoord5179[1] < 39.0
                ) {
                  props.handleCoord({
                    centerX: centerCoord5179[0],
                    centerY: centerCoord5179[1],
                    bbox: bbox,
                  });
                }

                if (
                  centerCoord4326[0] > 124.0 &&
                  centerCoord4326[0] < 132.0 &&
                  centerCoord4326[1] > 32.0 &&
                  centerCoord4326[1] < 39.0
                ) {
                  props.handleCoord({
                    centerX: centerCoord4326[0],
                    centerY: centerCoord4326[1],
                    bbox: bbox,
                  });
                }

                // 팝업 off
                props.setRegistFarmwork(false);
                // 주제도????
                // props.setShowTheme(false);
                // 팜맵 on/off
                props.onFarmmapHandler(true);
                // 농작업 선점 - 필지 선택 flag
                props.farmSelectHandler(true);
              }}
            >
              {props.selectedFarm ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {props.selectedFarm.address ? (
                    <>
                      <div>{props.selectedFarm.address}</div>
                      <div>({props.selectedFarm.FMAP_INNB})</div>
                    </>
                  ) : (
                    <>
                      {props.selectedFarm.FMAP_INNB ? (
                        <>
                          <div>
                            {props.selectedFarm.LGL_EMD_NM}{" "}
                            {props.selectedFarm.LNM}
                          </div>
                          <div>({props.selectedFarm.FMAP_INNB})</div>
                        </>
                      ) : (
                        <div>선택됨</div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                // '익산시 황등면 황등리 1528'
                "※ 클릭시 필지 선택 화면으로 이동합니다."
              )}
            </div>
          </div>
          <div className="contents-row">
            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>요청 날짜
              </div>
              <div className="select-date farmwork contents-row">
                <DatePicker
                  className="datepicker"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy-MM-dd"
                  minDate={new Date()}
                ></DatePicker>
                <i className="calendar alternate outline icon" />
              </div>
            </div>

            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>대상 작물
              </div>
              <div className="contents-row">
                <input
                  className="crop farmwork full-width"
                  onKeyDown={handleKeyDown}
                  value={farmReqCrop}
                  onChange={handleFarmReqCrop}
                ></input>
              </div>
            </div>
          </div>

          <div className="contents-row">
            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>농작업 선택
              </div>
              <Select
                className="select-farmwork farmwork"
                options={farmwork_option}
                onChange={handleFarmReqType}
              ></Select>
            </div>
          </div>

          {/* Enter가 안됨 */}
          <div className="contents-row">
            <div className="input-group contents-col full-width">
              <div className="title-text">기타 의견</div>
              <div className="contents-row">
                <textarea
                  style={{
                    fontFamily: "Noto Sans KR",
                    fontWeight: 500,
                    fontSize: "15px",
                    textAlign: "left",
                    padding: "8px",
                    color: "var(--default-text-color)",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    borderRadius: "5px",
                    height: "35px",
                    margin: "0 15px",
                    minHeight: "100px",
                    lineHeight: "140%",
                  }}
                  className="full-width"
                  value={opinion}
                  onChange={handleOpinion}
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="button-group flex-center">
          <button
            className="gc-btn farmworkSelect-cancel"
            onClick={() => {
              props.setRegistFarmwork(false);
              if (
                props.selectedFarm !== null &&
                props.selectedFarm !== undefined
              ) {
                if (
                  props.selectedFarm.shp_id !== null &&
                  props.selectedFarm.shp_id !== undefined
                ) {
                  highlightShpFillcrop([
                    "match",
                    ["get", "shp_id"],
                    props.selectedFarm.shp_id,
                    "rgba(0, 0, 255, 0.0)",
                    "rgba(0, 0, 0, 0.0)",
                  ]);
                } else {
                  if (props.selectedFarm.FMAP_INNB) {
                    highlightFarmMapFill(
                      props.selectedFarm.FMAP_INNB,
                      "rgba(0, 0, 255, 0.0)"
                    );
                  }
                }
              }
              props.setSelectedFarm();
              props.farmSelectHandler(false);
            }}
          >
            취소
          </button>
          <button
            className="gc-btn farmworkSelect-register"
            onClick={() => {
              regFarmWork();
              props.farmSelectHandler(false);
              // console.log(
              //   "props.farmworkCoord[0]",
              //   props.farmworkCoord[0],
              //   "  ",
              //   props.farmworkCoord[1]
              // );
            }}
          >
            등록
          </button>
        </div>
      </Modal>

      <Popup
        show={showPopup}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowPopup(false);
              props.setSelectedFarm();
              props.farmSelectHandler(false);
              props.setRegistFarmwork(false);
            }}
          />
        }
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);
              props.setRegistFarmwork(false);
              props.setSelectedFarm();
              props.onFarmmapHandler(false);
              props.farmSelectHandler(false);
              // props.farmworkSelectHandler(false);
              // props.setShowTheme(true);
              props.searchFarmworkSelectList();

              if (props.mapRef.current) {
                const map = props.mapRef.current.getMap();
                const mapLayers = map.getStyle().layers;
                mapLayers.forEach((layer) => {
                  if (layer.id.startsWith("tn_fmap_info")) {
                    map.setPaintProperty(
                      "tn_fmap_info",
                      "fill-color",
                      "rgba(255, 255, 255, 0.0)"
                    );
                  }
                  if (layer.id.startsWith(props.selectItrst.itrst_zone_nm)) {
                    map.setPaintProperty(
                      props.selectItrst.itrst_zone_nm,
                      "fill-color",
                      "rgba(255, 255, 255, 0.0)"
                    );
                  }
                });
              }
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}
export default NewFarmworkRequest;

import "./instRequestList.css";
import React, { useEffect, useState } from "react";

import config from "../../config/config";

import axios from "axios";
import { Select, Checkbox, TabPane, Tab } from "semantic-ui-react";
import DatePicker from "react-datepicker";

import Modal from "../../shared/components/uiElements/Modal";
import Popup from "../../shared/components/uiElements/Popup";

// TURF
import turf from "turf";

// Proj4
import proj4 from "proj4";

import moment from "moment";

import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: ${rotate} 1s infinite linear;
`;

function InstRequestList(props) {
  const APIToken = localStorage.getItem("login-token");

  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState();

  // 지원 기관 수
  const [totalCount, setTotalCount] = useState(0);

  // 지원 기관 리스트
  const [instList, setInstList] = useState();
  const [instClick, setInstClick] = useState();

  // 로딩
  const [loading, setLoading] = useState(false);

  const instListHandler = (e) => {
    setInstList(e);
  };

  useEffect(() => {
    instReqeuestList();
  }, []);

  const instReqeuestList = () => {
    if (props.curActiveLayer) {
      setLoading(true);
      axios({
        method: "get",
        url:
          `/api/instFarmwork/requestList?` +
          `frmwkt_req_id=${props.curActiveLayer.frmwkt_req_id}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          setLoading(false);
          if (result.data.success) {
            instListHandler(result.data.data);
            setTotalCount(result.data.data.length);
          } else {
            instListHandler();
            setTotalCount(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const validation = () => {
    if (instClick === undefined || instClick === null) {
      setPopupMsg("기관을 선택해주세요.");
      setShowPopup(true);
      return false;
    }
    return true;
  };

  const specifyInst = () => {
    let isOK = validation();
    if (isOK) {
      setLoading(true);
      axios({
        method: "post",
        url: `/api/instFarmwork/specifyInst`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: {
          frmwkt_req_id: `${instList[instClick].frmwkt_req_id}`, // 농작업 요청 아이디
          frmwkt_proc_id: `${instList[instClick].frmwkt_proc_id}`, // 농작업 지원 아이디
        },
      })
        .then((result) => {
          setLoading(false);
          if (result.data.success) {
            setPopupMsg("기관 지정이 완료되었습니다.");
            setShowPopup(true);
          } else {
            setPopupMsg(
              "기관 지정에 실패하였습니다. 관리자에게 문의 부탁드립니다."
            );
            setShowPopup(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={props.checkInst}
        header="기관 선정"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              props.setCheckInst(false);
            }}
          />
        }
        // className={`themeMap ${props.isMobile ? "mobile" : ""}`}
        className={`instRequestList`}
      >
        {loading ? (
          <div
            style={{
              minHeight: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Noto Sans KR",
              fontSize: "17px",
            }}
          >
            <Spinner />
            <div>처리중입니다.</div>
          </div>
        ) : totalCount !== 0 ? (
          <>
            <div
              style={{
                margin: "10px 10px 0 10px",
                backgroundColor: "var(--theme-color)",
                color: "white",
                padding: "7px",
                fontSize: "14px",
              }}
            >
              총 {totalCount}개의 기관이 검색되었습니다.
            </div>
            {instList &&
              instList.map((e, i) => {
                return (
                  <>
                    <div
                      key={i}
                      className="instList"
                      onClick={() => {
                        setInstClick(i);
                      }}
                    >
                      <div
                        className={`instList_search${
                          instClick === i ? "_active" : "_null"
                        }`}
                      >
                        <div
                          style={{
                            width: "20%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {e.inst_nm}
                        </div>
                        <div
                          style={{
                            border: "1.5px solid #999999",
                            margin: "0 15px",
                            height: "100%",
                          }}
                          className="devider"
                        ></div>
                        <div className="contents-col">
                          <div>드론 제조사명 : {e.drone_mkr_nm}</div>
                          <div>드론 기체: {e.drone_model_nm}</div>
                          <div>드론 타입 : {e.drone_type}</div>
                          <div>기관 의견 : {e.frmwkt_cn}</div>
                          {/* <div>{props.curActiveLayer.trgt_crps}</div> */}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

            <div className="button-group flex-center">
              <button
                className="gc-btn farmworkSelect-cancel"
                onClick={() => {
                  props.setCheckInst(false);
                }}
              >
                취소
              </button>
              <button
                className="gc-btn farmworkSelect-register"
                onClick={() => {
                  specifyInst();
                }}
              >
                선택완료
              </button>
            </div>
          </>
        ) : (
          <div
            style={{
              minHeight: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Noto Sans KR",
              fontSize: "17px",
            }}
          >
            지원한 기관이 없습니다.
          </div>
        )}
      </Modal>

      <Popup
        show={showPopup}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowPopup(false);
            }}
          />
        }
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);
              props.setCheckInst(false);
              // 농작업 요청(경작자) 리스트 다시 불러오기
              props.setSearchFlag(false);
              // props.setShowFarmworkSelect(true);
              props.curActiveLayerHandler(undefined);
              props.setSearchFlag(true);
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}
export default InstRequestList;

import './farmStatus.css';
import './droneLogChart.css';

import React, { useEffect, useState, useRef, forwardRef } from 'react';

import Legend from '../../shared/components/uiElements/legend';
import Popup from '../../shared/components/uiElements/Popup';
import Modal from '../../shared/components/uiElements/Modal';
import useResponsive from '../../shared/hooks/screenSize-hook';

import ReactSlider from 'react-slider';

import Farmworkdetail from '../farmMng/components/farmworkdetail';
import InstRequestList from '../farmworkSelect/instRequestList';

import DroneLogChart from './droneLogChart';

// AXIOS
import axios from 'axios';
import config from '../../config/config';

import { FileInputButton, FileCard } from '@files-ui/react';

import turf, { polygon } from 'turf';

import c3 from 'c3';
import 'c3/c3.css';
import mapboxgl from 'mapbox-gl';
import styled, { keyframes } from 'styled-components';

import FileDownload from '../../shared/components/uiElements/FileDownload';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: ${rotate} 1s infinite linear;
`;

const DroneImage = forwardRef(function DroneImage(props, ref) {
  const [map, setMap] = useState();
  // const { isTabletHeight, isTabletWidth } = useResponsive();

  useEffect(() => {
    if (props.mapRef.current) {
      setMap(props.mapRef.current.getMap());
    }
  }, [props.mapRef.current]);

  // console.log("props.curActiveLayer", props.curActiveLayer.meta_frmwrk_id);
  const APIToken = localStorage.getItem('login-token');
  const userId = localStorage.getItem('user-id');

  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('');

  const [visibleDrn, setVisibleDrn] = useState(false);
  const [visibleThm, setVisibleThm] = useState(false);
  const [visibleFrm, setVisibleFrm] = useState(false);
  const [visibleChart, setVisibleChart] = useState(true);
  const [visibleDrnLog, setVisibleDrnLog] = useState(false);
  const [visibleFrmSelect, setVisibleFrmSelect] = useState(false);
  const [visibleInstFarmwork, setVisibleInstFarmwork] = useState(false);

  const [showModifyLegend, setShowModifyLegend] = useState(false);

  // 드론영상 대비
  const [contrastLevel, setContrastLevel] = useState(0.0);
  // 드론영상 밝기
  const [brightnessLevel, setBrightnessLevel] = useState(0.0);
  // 드론영상 밝기
  const [gammaLevel, setGammaLevel] = useState(0.5);
  // loading animation
  const [loading, setLoading] = useState(false);

  const [showDetail, setShowDetail] = useState(false);

  // *************************************
  const inputFile = useRef(null);
  const submitButton = useRef(null);
  const [files, setFiles] = React.useState([]);
  const [fileName, setFileName] = useState(null);
  const [beforeSelect, setBeforeSelect] = useState(true);
  const [displayState, setDisplayState] = useState('none');

  const [curClickNum, setCurClickNum] = useState(null);
  const newChart = useRef(null);

  const [fileLoading, setFileLoading] = useState(false);

  const mobileRef = useRef(null);

  let widthFlag = beforeSelect === true ? '70%' : 'auto';

  let chart2 = newChart.current;
  let chart_m = mobileRef.current;

  let chart_mobile;
  const [chartState, setChartState] = useState(null);

  const [sprayChecked, setSprayChecked] = useState(true);
  const [batteryChecked, setBatteryChecked] = useState(true);
  const [altitudeChecked, setAltitudeChecked] = useState(true);

  //로그파일
  const [fileList, setFileList] = useState([]);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const [isBtnClick, setIsBtnClick] = useState(null);
  const [previousFrmwrkId, setPreviousFrmwrkId] = useState(null);
  const [isClickable, setIsClickable] = useState(true);
  const [chartFileNm, setChartFileNm] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showFullName, setShowFullName] = useState(null); // 전체 이름 표시 여부
  const [logCreateFlag, setLogCreateFlag] = useState(null);

  const newLegendKey = {
    1: '적음',
    2: '보통',
    3: '많음',
  };

  const fileNameModalClick = (e) => {
    // setShowFullName(null);
    e.stopPropagation();
    setShowFullName(null);
  };
  const FullFileName = (fileName, index) => {
    setShowFullName(fileName, index);
  };

  const handleClick = (fileName) => {
    if (isDownloading) return;
    setIsDownloading(true);

    if (isClickable) {
      var folderNm = props.curActiveLayer.meta_frmwrk_id;

      var fileNm = fileName;
      let extension = fileNm.slice(((fileNm.lastIndexOf('.') - 1) >>> 0) + 2);
      let url;

      if (props.isMine && userId === props.curActiveLayer.user_id) {
        if (extension == 'log' || extension == 'bin') {
          url =
            '/download/geojson' +
            '?filebase=' +
            folderNm +
            '&filename=' +
            fileNm +
            '&fileCtgy=' +
            'frmwrkFile';
        } else {
          url = '/api/data/frmwrkLog' + '/' + folderNm + '/' + fileNm;
        }
        window.location.href = url;
      }
    }

    setIsClickable(false);
    setTimeout(() => {
      setIsDownloading(false);
      setIsClickable(true);
    }, 3000);
  };

  const removeKeyFromObject = (obj, keyToRemove) => {
    const { [keyToRemove]: removedKey, ...newObj } = obj;
    return newObj;
  };

  const handleButtonClick = (file, index) => {
    const currentMetaFrmId = props.curActiveLayer.meta_frmwrk_id;
    if (isBtnClick === index) {
      setIsBtnClick(null);

      const updatedDrnLog = removeKeyFromObject(
        props.droneLog,
        currentMetaFrmId
      );
      const updatedCsvLog = removeKeyFromObject(props.csvLog, currentMetaFrmId);
      props.drnLogHandler(updatedDrnLog);
      props.csvLogHandler(updatedCsvLog);
      removeAllLayer();
      props.setActChart(false);
      props.setShowChart(false);
    } else {
      const updatedDrnLog = removeKeyFromObject(
        props.droneLog,
        currentMetaFrmId
      );
      const updatedCsvLog = removeKeyFromObject(props.csvLog, currentMetaFrmId);
      props.drnLogHandler(updatedDrnLog);
      props.csvLogHandler(updatedCsvLog);

      props.setActChart(true);
      props.setShowChart(true);
      logChartCreate(file);
      setIsBtnClick(index);
    }
  };

  //보기버튼(mobile / web)
  async function logChartCreate(file) {
    if (map.getStyle() !== undefined) {
      let folderNum = props.curActiveLayer.meta_frmwrk_id;

      setChartFileNm(file);

      const headers = {
        Authorization: `Bearer {${APIToken}}`,
        // "Content-Type": "multipart/form-data",
      };

      await axios
        .post(
          '/api/droneLog/create',
          { folderNum: folderNum, fileName: file },
          headers
        )
        .then(async (response) => {
          let clickNum = props.curActiveLayer.meta_frmwrk_id;

          if (response.data.result == 'success') {
            let res = response.data.data.result;
            const missionResponse = response.data.data.cmd;

            let uniqueData = [];
            let seenSeconds = new Set();

            res.features.forEach((item) => {
              const timeInSeconds = Math.floor(item.properties.time / 200);
              if (!seenSeconds.has(timeInSeconds)) {
                seenSeconds.add(timeInSeconds);
                // 데이터를 결과 배열에 추가
                uniqueData.push(item);
              }
            });

            res.features = uniqueData;

            let autoArray = [];
            let manualArray = [];

            res.features.forEach(function (item) {
              if (item.properties.ModeNum == 5) {
                manualArray.push(item);
              } else {
                autoArray.push(item);
              }
            });

            // setChartLoading(false);

            await drawMobileChartDetail(res, response.data.result);
            await drawChart(res, response.data.result);
            await drawMissionLayer(missionResponse.features, clickNum);

            if (autoArray && autoArray.length !== 0) {
              await drawAutoLayer(autoArray, clickNum);
            }
            if (manualArray && manualArray.length !== 0) {
              await drawManualLayer(manualArray, clickNum);
            }
            props.setActChart(true);
          } else if (response.data.result == 'csv_data') {
            // setChartLoading(false);
            let res = response.data.data.csv_data;

            let uniqueData = [];
            let seenSeconds = new Set();

            res.features.forEach((item) => {
              // time을 0.2초 단위로 변환
              const timeInSeconds = Math.floor(item.properties.time / 200);

              // 만약 이 시간이 아직 처리되지 않았다면
              if (!seenSeconds.has(timeInSeconds)) {
                // 이 시간을 처리된 것으로 기록하고
                seenSeconds.add(timeInSeconds);
                // 데이터를 결과 배열에 추가
                uniqueData.push(item);
              }
            });
            res.features = uniqueData;

            await drawMobileChartDetail(res, response.data.result);
            await drawChart(res, response.data.result);
            await drawCSVLayer(res.features, clickNum);

            props.setActChart(true);
          } else if (response.data.result == 'local') {
            setCurClickNum();
            props.setActChart(false);
            props.setShowChart(false);
          } else if (response.data.success == 'false') {
            alert('생성에 실패하였습니다.');
          } else {
            if (response.data.msg) {
              alert(response.data.msg);
            } else {
              alert('로그 생성에 실패하였습니다.');
            }

            setCurClickNum();
            props.setActChart(false);
          }
        });
    }
  }

  // 로그리스트
  async function callLogList() {
    if (props.curActiveLayer) {
      if (
        props.curActiveLayer.layerType === 3 &&
        props.curActiveLayer.meta_frmwrk_id !== null
      ) {
        // if(props.farmWorkLogFlag){
        // setChartLoading(true);
        axios({
          method: 'get',
          url:
            `/api/drone/logList?` +
            `frmwrkId=${props.curActiveLayer.meta_frmwrk_id}`,
          headers: {
            Authorization: `Bearer {${APIToken}}`,
          },
        })
          .then((result) => {
            if (result.data.success) {
              const currentFrmId = props.curActiveLayer.frmwkt_req_id;
              const currentMetaFrmId = props.curActiveLayer.meta_frmwrk_id;

              if (result.data.data.length == 0) {
                setFileList([]);
                props.setActChart(false);
              } else {
                const updatedDrnLog = removeKeyFromObject(
                  props.droneLog,
                  currentMetaFrmId
                );
                const updatedCsvLog = removeKeyFromObject(
                  props.csvLog,
                  currentMetaFrmId
                );

                props.drnLogHandler(updatedDrnLog);
                props.csvLogHandler(updatedCsvLog);

                setFileList(result.data.data);
                logChartCreate(result.data.data[0]);
                setIsBtnClick(0);
                // setChartLoading(false);
                props.setActChart(true);
                props.setShowChart(true);

                setPreviousFrmwrkId(currentFrmId);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    // }
  }

  // 농작업 지원(기관) 작업 완료 농작업 조회 start -------------------------------------------
  useEffect(() => {
    if (props.curActiveLayer) {
      if (
        (props.curActiveLayer.layerType === 6 &&
          props.curActiveLayer.proc_status === '4') ||
        (props.curActiveLayer.layerType === 4 &&
          props.curActiveLayer.frmwkt_stts_cd === 4)
      ) {
        if (props.curActiveLayer.meta_frmwrk_id !== null) {
          props.setDrone(true);
          // drawLayer(props.curActiveLayer.meta_frmwrk_id);
        } else {
          props.setDrone(false);
        }
        // 311 -> props.curActiveLayer.meta_frmwrk_id 로 대체 가능
      } else {
        props.setDrone(false);
      }
    }
  }, [props.curActiveLayer]);

  // 파일 리스트 리스트 클릭했을 때,
  useEffect(() => {
    // setIsBtnClick();

    if (props.curActiveLayer) {
      if (
        props.curActiveLayer.layerType === 3 &&
        props.curActiveLayer.meta_frmwrk_id !== null
      ) {
        //함수실행
        callLogList();
      }
    }
  }, [props.curActiveLayer]);

  //새로 파일 올렸을 때, curAct 바꿨을 때
  // useEffect(() => {
  //   // setIsBtnClick();

  //   if (props.curActiveLayer) {
  //     if (
  //       props.curActiveLayer.layerType === 3 &&
  //       props.curActiveLayer.meta_frmwrk_id !== null
  //     ) {
  //       if(props.farmWorkLogFlag){
  //       axios({
  //         method: 'get',
  //         url:
  //           `/api/drone/logList?` +
  //           `frmwrkId=${props.curActiveLayer.meta_frmwrk_id}`,
  //         headers: {
  //           Authorization: `Bearer {${APIToken}}`,
  //         },
  //       })
  //         .then((result) => {
  //           if (result.data.success) {
  //             const currentFrmId = props.curActiveLayer.frmwkt_req_id;
  //             const currentMetaFrmId = props.curActiveLayer.meta_frmwrk_id;

  //             if (result.data.data.length == 0) {
  //               setFileList([]);
  //               props.setActChart(false);
  //             } else {
  //               const updatedDrnLog = removeKeyFromObject(
  //                 props.droneLog,
  //                 currentMetaFrmId
  //               );
  //               const updatedCsvLog = removeKeyFromObject(
  //                 props.csvLog,
  //                 currentMetaFrmId
  //               );

  //               props.drnLogHandler(updatedDrnLog);
  //               props.csvLogHandler(updatedCsvLog);

  //               setFileList(result.data.data);
  //               logChartCreate(result.data.data[0]);
  //               setIsBtnClick(0);
  //               props.setActChart(true);
  //               props.setShowChart(true);

  //               setPreviousFrmwrkId(currentFrmId);
  //             }
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //   }
  //   }
  // }, [props.curActiveLayer, downloadFlag]);

  // useEffect(() => {
  //   console.log("curAct");

  //   //내curActId랑 딕셔너리 속 번호랑 같은것만
  //   if (props.curActiveLayer) {
  //     if (
  //       props.curActiveLayer.layerType === 3 &&
  //       props.curActiveLayer.meta_frmwrk_id !== null
  //     ) {

  //       console.log("isBtnClick " +isBtnClick);
  //       console.log("lastClickedIndex "+lastClickedIndex);

  //       props.drnLogHandler({});
  //       props.csvLogHandler({});
  //       if (lastClickedIndex !== null ) {

  //         setIsBtnClick(lastClickedIndex);
  //       } else {
  //         setIsBtnClick(0);
  //       }

  //     }
  //   }
  // }, [props.curActiveLayer]);

  // const drawLayer = async (clickNum) => {
  //   if (clickNum != null) {
  //     await axios({
  //       method: "get",
  //       url: config.metaSystemUrl + "/frm_wrk_list_detail_response",
  //       params: { frm_wrk_id: clickNum },
  //     })
  //       .then(async (response) => {
  //         if (response.data.result == "success") {
  //           await props.setDrone(true);

  //           let res = response.data.result_data;
  //           const missionResponse = response.data.mission_data;

  //           let uniqueData = [];
  //           let seenSeconds = new Set();

  //           await res.features.forEach((item) => {
  //             const timeInSeconds = Math.floor(item.properties.time / 200);
  //             if (!seenSeconds.has(timeInSeconds)) {
  //               seenSeconds.add(timeInSeconds);
  //               // 데이터를 결과 배열에 추가
  //               uniqueData.push(item);
  //             }
  //           });

  //           res.features = uniqueData;

  //           let autoArray = [];
  //           let manualArray = [];

  //           await res.features.forEach(function (item) {
  //             if (item.properties.ModeNum == 5) {
  //               manualArray.push(item);
  //             } else {
  //               autoArray.push(item);
  //             }
  //           });

  //           // props.setDrone(true);

  //           await drawChart(res, response.data.result);
  //           await drawMissionLayer(missionResponse.features, clickNum);

  //           if (autoArray && autoArray.length !== 0) {
  //             await drawAutoLayer(autoArray, clickNum);
  //           }
  //           if (manualArray && manualArray.length !== 0) {
  //             await drawManualLayer(manualArray, clickNum);
  //           }
  //           const mapLayers = map.getStyle().layers;
  //           // console.log("ALL LAYER => ", mapLayers);
  //         } else if (response.data.result == "csv_data") {
  //           await props.setDrone(true);
  //           let res = response.data.result_data;
  //           let uniqueData = [];
  //           let seenSeconds = new Set();

  //           await res.features.forEach((item) => {
  //             // time을 0.2초 단위로 변환
  //             const timeInSeconds = Math.floor(item.properties.time / 200);

  //             // 만약 이 시간이 아직 처리되지 않았다면
  //             if (!seenSeconds.has(timeInSeconds)) {
  //               // 이 시간을 처리된 것으로 기록하고
  //               seenSeconds.add(timeInSeconds);
  //               // 데이터를 결과 배열에 추가
  //               uniqueData.push(item);
  //             }
  //           });
  //           res.features = uniqueData;

  //           await drawChart(res, response.data.result);
  //           await drawCSVLayer(res.features, clickNum);

  //           // props.setDrone(false);
  //           // props.droneHandler(false);
  //         } else {
  //           // alert(
  //           //   "드론 로그는 업로드 되었으나, 드론 경로가 생성되지 않았습니다. 관리자에게 문의 부탁드립니다."
  //           // );
  //           props.setDrone(false);
  //         }
  //       })
  //       .catch((error) => {
  //         props.setDrone(false);
  //         props.droneHandler(false);
  //         // alert(
  //         //   "드론 로그는 업로드 되었으나, 드론 경로가 생성되지 않았습니다. 관리자에게 문의 부탁드립니다."
  //         // );
  //         props.setDrone(false);
  //       });
  //   }
  // };

  // 농작업 지원(기관) 작업 완료 농작업 조회 done -------------------------------------------

  useEffect(() => {
    if (ref.current != null) {
      setCurClickNum();
      props.setActChart(false);
    }
  }, [ref.current]);

  useEffect(() => {
    return () => {
      setCurClickNum();
      props.setDrone(true);
      props.setActChart(false);
    };
  }, []);

  const handleUpload = (e) => {
    if (submitButton.current) {
      submitButton.current.click();
    }
  };

  const cancel = () => {
    setFiles();
    setFileName();
    setBeforeSelect(true);
    setDisplayState('none');
    setCurClickNum();
    props.setActChart(false);
  };

  const removeAllLayer = () => {
    if (map !== undefined && map !== null) {
      const mapLayers = map.getStyle().layers;
      mapLayers.forEach((layer) => {
        let changeName = layer.id.replace('Layer', 'Source');
        if (layer.id.startsWith('missionLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
        if (layer.id.startsWith('autoLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
        if (layer.id.startsWith('manualLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
        if (layer.id.startsWith('csvLayer')) {
          map.removeLayer(layer.id);
          map.removeSource(changeName);
          map.removeSource(layer.source);
        }
      });
    }
  };

  const delFile = (file) => {
    let folderNum = props.curActiveLayer.meta_frmwrk_id;

    const headers = {
      Authorization: `Bearer {${APIToken}}`,
      // "Content-Type": "multipart/form-data",
    };

    axios
      .post(
        '/api/droneLog/frmDelete',
        { folderNum: folderNum, fileName: file },
        headers
      )
      .then((result) => {
        if (result.data.success) {
          // console.log(result.data.data);
          setFileList(result.data.data);

          // console.log("!!!!! "+(result.data.data).length);

          if (result.data.data.length == 0) {
            const currentMetaFrmId = props.curActiveLayer.meta_frmwrk_id;

            const updatedDrnLog = removeKeyFromObject(
              props.droneLog,
              currentMetaFrmId
            );
            const updatedCsvLog = removeKeyFromObject(
              props.csvLog,
              currentMetaFrmId
            );

            props.drnLogHandler(updatedDrnLog);
            props.csvLogHandler(updatedCsvLog);

            props.setActChart(false);
          } else {
            const currentMetaFrmId = props.curActiveLayer.meta_frmwrk_id;

            const updatedDrnLog = removeKeyFromObject(
              props.droneLog,
              currentMetaFrmId
            );
            const updatedCsvLog = removeKeyFromObject(
              props.csvLog,
              currentMetaFrmId
            );

            props.drnLogHandler(updatedDrnLog);
            props.csvLogHandler(updatedCsvLog);

            logChartCreate(result.data.data[0]);
          }

          // props.setActChart(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleButton = (e) => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const fileChange = (e) => {
    if (e.target.value) {
      setBeforeSelect(false);
      setDisplayState('flex');
      setFiles(e.target.files[0]);
      setFileName(e.target.files[0].name);
    } else {
      cancel();
    }
    e.target.value = '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFileLoading(true);
    setDownloadFlag(false);

    let folderNum = props.curActiveLayer.meta_frmwrk_id;

    const formData = new FormData();

    formData.append('folderNum', folderNum);
    // formData.append('file', new File([files], escapedFileName));
    formData.append('file', files);

    const headers = {
      Authorization: `Bearer {${APIToken}}`,
      // "Content-Type": "multipart/form-data",
    };

    axios.post('/api/droneLog/file/upload', formData, headers).then((res) => {
      // setFileLoading(false);
      props.setFarmWorkLogFlag(false);

      if (res.data.result === 'success') {
        alert('업로드가 완료되었습니다.');

        // cancel();
        setFiles();
        setFileName();
        setBeforeSelect(true);
        setDisplayState('none');
        // setDownloadFlag(true);
        props.drnLogHandler({});
        props.csvLogHandler({});
        props.setActChart(true);
        setFileLoading(false);
        props.setFarmWorkLogFlag(true);
        callLogList();
      } else if (res.data.result === 'file_exist') {
        alert(res.data.msg);
        setFileLoading(false);
        setCurClickNum();
        props.setActChart(false);
      } else {
        alert('업로드에 실패하였습니다.');

        alert(res.data.msg);
        setFileLoading(false);
        setCurClickNum();
        props.setActChart(false);
      }
      // else {
      //   if (res.data.result === 'fail') {
      //     alert(res.data.msg);
      //   } else {
      //     alert('업로드에 실패하였습니다.');
      //   }

      //   setCurClickNum();
      //   props.setActChart(false);
      // }
    });
  };

  const removeLayer = (id, clickNum) => {
    let changeName = id.replace('Layer', 'Source');
    map.removeLayer(id);
    map.removeSource(id);
    map.removeSource(changeName);
  };

  const drawMobileChart = async (clickNum) => {
    let chartUrl;
    let chartMethod;

    if (props.curActiveLayer.frmwkt_req_id !== undefined) {
      chartMethod = 'post';
      chartUrl = '/api/droneLog/create';
    }

    if (props.curActiveLayer.log_sn !== undefined) {
      chartMethod = 'get';
      chartUrl =
        `/api/droneLog/${clickNum}?` +
        `&itrstZoneId=${props.selectItrst.itrst_zone_id}`;
    }

    // props.setShowChart(true);
    if (clickNum != null) {
      axios({
        method: chartMethod,
        url: chartUrl,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data:
          chartMethod === 'post'
            ? { folderNum: clickNum, fileName: chartFileNm }
            : null,
      })
        .then(async (response) => {
          if (response.data.result == 'success') {
            // props.setShowChart(true);
            let res;

            if (chartMethod === 'post') {
              res = response.data.data.result;
            } else {
              res = response.data.result_data;
            }
            // let res = response.data.result_data;

            let uniqueData = [];
            let seenSeconds = new Set();

            res.features.forEach((item) => {
              // time을 0.2초 단위로 변환
              const timeInSeconds = Math.floor(item.properties.time / 200);

              // 만약 이 시간이 아직 처리되지 않았다면
              if (!seenSeconds.has(timeInSeconds)) {
                // 이 시간을 처리된 것으로 기록하고
                seenSeconds.add(timeInSeconds);
                // 데이터를 결과 배열에 추가
                uniqueData.push(item);
              }
            });

            res.features = uniqueData;

            await drawMobileChartDetail(res, response.data.result);
          } else if (response.data.result == 'csv_data') {
            let res;

            if (chartMethod === 'post') {
              res = response.data.data.csv_data;
            } else {
              res = response.data.result_data;
            }

            // let res = response.data.result_data;
            let uniqueData = [];
            let seenSeconds = new Set();

            res.features.forEach((item) => {
              // time을 0.2초 단위로 변환
              const timeInSeconds = Math.floor(item.properties.time / 200);

              // 만약 이 시간이 아직 처리되지 않았다면
              if (!seenSeconds.has(timeInSeconds)) {
                // 이 시간을 처리된 것으로 기록하고
                seenSeconds.add(timeInSeconds);
                // 데이터를 결과 배열에 추가
                uniqueData.push(item);
              }
            });
            res.features = uniqueData;
            await drawMobileChartDetail(res, response.data.result);
          } else {
            props.setShowChart(false);
            props.setNoChart(true);
          }
        })
        .catch((error) => {
          props.setShowChart(false);
          props.setNoChart(true);
        });
    }
  };

  const drawMobileChartDetail = async (res, type) => {
    let bat = [];
    let alt = [];
    let amount = [];
    let date_time = [];

    if (type == 'csv_data') {
      await res.features.forEach((item) => {
        alt.push(item.properties['altitude']);
        date_time.push(item.properties['time']);
      });

      chart_mobile = await c3.generate({
        bindto: '#chart_mobile',
        data: {
          x: 'x',
          columns: [
            ['x', ...date_time],
            ['고도', ...alt],
          ],
          type: 'line',
          selection: {
            enabled: true,
          },
        },
        axis: {
          x: {
            label: {
              text: '시간(hh:mm:ss:ms)',
              position: 'outer-center',
            },
            type: 'timeseries',
            categories: date_time,
            tick: {
              format: '%H:%M:%S.%L',
              fit: true,
              count: 4,
              // culling: false,
            },
          },
        },
        zoom: {
          enabled: true,
        },
        point: {
          // show: true,
          focus: {
            expand: {
              enabled: true,
              r: 6, // 확대될 때의 포인트 반지름
            },
          },
        },
        tooltip: {
          horizontal: true,
        },
        size: {
          // width: 360,
          height: 240,
        },
        padding: {
          top: 10,
          right: 35,
          bottom: 0,
          // left: 70,
        },
      });
      // setChartState(chart_mobile_1);
      props.setReadyCSVChart(true);
      props.setReadyChart(false);
    } else {
      await res.features.forEach((item) => {
        bat.push(item.properties['Volt']);
        alt.push(item.properties['altitude']);
        amount.push(item.properties['C9']);
        date_time.push(item.properties['time']);
      });

      // C3 차트 생성
      chart_mobile = await c3.generate({
        bindto: '#chart_mobile',
        data: {
          x: 'x',
          columns: [
            ['x', ...date_time],
            ['살포량', ...amount],
            ['배터리 잔량', ...bat],
            ['고도', ...alt],
          ],
          type: 'line',
          selection: {
            enabled: true,
          },
        },
        axis: {
          x: {
            label: {
              text: '시간(hh:mm:ss:ms)',
              position: 'outer-center',
            },
            type: 'timeseries',
            categories: date_time,
            tick: {
              format: '%H:%M:%S.%L',
              fit: true,
              count: 4,
              // culling: false,
            },
          },
        },
        zoom: {
          enabled: true,
        },
        point: {
          // show: true,
          focus: {
            expand: {
              enabled: true,
              r: 6, // 확대될 때의 포인트 반지름
            },
          },
        },
        tooltip: {
          horizontal: true,
        },
        size: {
          // width: 360,
          height: 240,
        },
        padding: {
          top: 10,
          right: 35,
          bottom: 0,
          // left: 70,
        },
      });
      // setChartState(chart_mobile_1);
      props.setReadyCSVChart(false);
      props.setReadyChart(true);
    }
    // props.setShowChart(true);
  };

  const drawChart = async (res, type) => {
    // setChartLoading(false);

    let bat = [];
    let alt = [];
    let amount = [];
    let date_time = [];

    if (type == 'csv_data') {
      await res.features.forEach((item) => {
        alt.push(item.properties['altitude']);
        date_time.push(item.properties['time']);
      });

      chart2 = await c3.generate({
        bindto: '#chart2',
        data: {
          x: 'x',
          columns: [
            ['x', ...date_time],
            ['고도', ...alt],
          ],
          type: 'line',
          selection: {
            enabled: true,
          },
        },
        axis: {
          x: {
            label: {
              text: '시간(hh:mm:ss:ms)',
              position: 'outer-center',
            },
            type: 'timeseries',
            categories: date_time,
            tick: {
              format: '%H:%M:%S.%L',
              fit: true,
              count: 4,
              // culling: false,
            },
          },
        },
        zoom: {
          enabled: true,
        },
        point: {
          // show: true,
          focus: {
            expand: {
              enabled: true,
              r: 6, // 확대될 때의 포인트 반지름
            },
          },
        },
        tooltip: {
          horizontal: true,
        },
        padding: {
          right: 40,
          top: 10,
        },
      });
      // setChartLoading(false);
    } else {
      await res.features.forEach((item) => {
        bat.push(item.properties['Volt']);
        alt.push(item.properties['altitude']);
        amount.push(item.properties['C9']);
        date_time.push(item.properties['time']);
      });

      // C3 차트 생성
      chart2 = await c3.generate({
        bindto: '#chart2',
        data: {
          x: 'x',
          columns: [
            ['x', ...date_time],
            ['살포량', ...amount],
            ['배터리 잔량', ...bat],
            ['고도', ...alt],
          ],
          type: 'line',
          selection: {
            enabled: true,
          },
        },
        axis: {
          x: {
            label: {
              text: '시간(hh:mm:ss:ms)',
              position: 'outer-center',
            },
            type: 'timeseries',
            categories: date_time,
            tick: {
              format: '%H:%M:%S.%L',
              fit: true,
              count: 4,
              // culling: false,
            },
          },
          y: {
            label: {
              text: 'Y축 살포량',
              position: 'outer-middle',
            },
            tick: {
              culling: false, // 글자가 겹치지 않도록 설정
            },
          },
        },
        zoom: {
          enabled: true,
        },
        point: {
          // show: true,
          focus: {
            expand: {
              enabled: true,
              r: 6, // 확대될 때의 포인트 반지름
            },
          },
        },
        tooltip: {
          horizontal: true,
        },
        padding: {
          right: 40,
          top: 10,
        },
        // size,
      });
    }
    // setChartLoading(false);
    // console.log('chartLoading ' + chartLoading);
  };

  const drawMissionLayer = async (res, clickNum) => {
    const isLayer = map.getLayer('missionLayer' + clickNum);

    map.getStyle().layers.forEach((layer) => {
      if (layer.id.endsWith(clickNum)) {
        removeLayer(layer.id);
      }
    });

    let array = [];
    res.forEach((item) => {
      let point = new mapboxgl.LngLat(
        item.geometry.coordinates[0],
        item.geometry.coordinates[1]
      );

      array.push(point);
    });

    // Source 생성
    const missionSource = {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: array.map((coord) => [coord.lng, coord.lat]),
        },
      },
    };

    // Layer 생성
    const missionLayer = {
      id: 'missionLayer' + clickNum,
      source: missionSource,
      type: 'line',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': '#46ce3c',
        'line-width': 2,
      },
    };

    let id = 'missionSource' + clickNum;

    // Source 및 Layer를 지도에 추가
    map.addSource(id, missionSource);
    map.addLayer(missionLayer);
  };

  const drawAutoLayer = (res, clickNum) => {
    // const isLayer = map.getLayer("autoLayer" + clickNum);
    // if (isLayer) {
    //   removeLayer("autoLayer" + clickNum);
    // }

    // Source 생성
    const autoSource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: res,
      },
    };

    // Layer 생성
    const autoLayer = {
      id: 'autoLayer' + clickNum,
      source: autoSource,
      type: 'circle',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'circle-radius': 4,
        'circle-color': 'blue',
      },
    };
    // Source 및 Layer를 지도에 추가
    map.addSource('autoSource' + clickNum, autoSource);
    map.addLayer(autoLayer);

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on('mouseenter', 'autoLayer' + clickNum, (e) => {
      map.getCanvas().style.cursor = 'pointer';

      let selectedFeature = e.features[0];
      let match_time = selectedFeature.properties.time;
      let match_data = null;

      for (let data of res) {
        if (match_time == data.properties['time']) {
          match_data = data;
          break;
        }
      }
      if (match_data) {
        let coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        let dt = new Date(e.features[0].properties.time);
        const date =
          dt.getFullYear() +
          '-' +
          ('0' + (dt.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + dt.getDate()).slice(-2);

        const time =
          ('0' + dt.getHours()).slice(-2) +
          ':' +
          ('0' + dt.getMinutes()).slice(-2) +
          ':' +
          ('0' + dt.getSeconds()).slice(-2) +
          '.' +
          ('00' + dt.getMilliseconds()).slice(-3);

        // 팝업에 들어갈 content
        let content =
          "<table style='border:1px solid #000; font-family:Noto Sans KR;font-weight: 600;'>" +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>날짜</td><td style='border:1px solid #000; padding:5px 8px 5px 8px; text-align: left;'>" +
          date +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>시간</td><td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          time +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>경도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lng.toFixed(7) +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>위도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lat.toFixed(7) +
          '</td></tr>' +
          '</table>';

        coordinates = e.lngLat;
        popup.setLngLat(coordinates).setHTML(content).addTo(map);

        // point에 mouseenter 되었을 때, 차트 확대 하는 부분
        chart2.zoom([
          e.features[0].properties.time - 15000,
          e.features[0].properties.time + 15000,
        ]);
        chart2.tooltip.show({ x: e.features[0].properties.time });
      }
    });

    // point에 mouseleave 되었을 때, 차트 확대 해제 및 팝업 제거
    map.on('mouseleave', 'autoLayer' + clickNum, () => {
      map.getCanvas().style.cursor = '';
      chart2.tooltip.show(false);
      chart2.internal.hideTooltip();
      popup.remove();
      chart2.unzoom();
      chart2.flush(); // 차트 갱신
    });
  };

  const drawManualLayer = (res, clickNum) => {
    // Source 생성
    const manualSource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: res,
      },
    };

    // Layer 생성
    const manualLayer = {
      id: 'manualLayer' + clickNum,
      source: manualSource,
      type: 'circle',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'circle-radius': 4,
        'circle-color': 'red',
      },
    };

    // Source 및 Layer를 지도에 추가
    map.addSource('manualSource' + clickNum, manualSource);
    map.addLayer(manualLayer, null);

    props.setDroneLog((prevStates) => ({
      ...prevStates,
      [clickNum]: prevStates.hasOwnProperty(clickNum)
        ? prevStates[clickNum]
        : true,
    }));

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on('mouseenter', 'manualLayer' + clickNum, (e) => {
      map.getCanvas().style.cursor = 'pointer';

      let selectedFeature = e.features[0];
      let match_time = selectedFeature.properties.time;
      let match_data = null;

      for (let data of res) {
        if (match_time == data.properties['time']) {
          match_data = data;
          break;
        }
      }
      if (match_data) {
        let coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        let dt = new Date(e.features[0].properties.time);
        const date =
          dt.getFullYear() +
          '-' +
          ('0' + (dt.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + dt.getDate()).slice(-2);

        const time =
          ('0' + dt.getHours()).slice(-2) +
          ':' +
          ('0' + dt.getMinutes()).slice(-2) +
          ':' +
          ('0' + dt.getSeconds()).slice(-2) +
          '.' +
          ('00' + dt.getMilliseconds()).slice(-3);

        // 팝업에 들어갈 content
        let content =
          "<table style='border:1px solid #000; font-family:Noto Sans KR;font-weight: 600;'>" +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>날짜</td><td style='border:1px solid #000; padding:5px 8px 5px 8px; text-align: left;'>" +
          date +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>시간</td><td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          time +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>경도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lng.toFixed(7) +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>위도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lat.toFixed(7) +
          '</td></tr>' +
          '</table>';

        coordinates = e.lngLat;
        popup.setLngLat(coordinates).setHTML(content).addTo(map);

        // point에 mouseenter 되었을 때, 차트 확대 하는 부분
        chart2.zoom([
          e.features[0].properties.time - 15000,
          e.features[0].properties.time + 15000,
        ]);
        chart2.tooltip.show({ x: e.features[0].properties.time });
      }
    });

    // point에 mouseleave 되었을 때, 차트 확대 해제 및 팝업 제거
    map.on('mouseleave', 'manualLayer' + clickNum, () => {
      map.getCanvas().style.cursor = '';
      chart2.tooltip.show(false);
      chart2.internal.hideTooltip();
      popup.remove();
      chart2.unzoom();
      chart2.flush(); // 차트 갱신
    });
  };

  const drawCSVLayer = async (res, clickNum) => {
    map.getStyle().layers.forEach((layer) => {
      if (layer.id.endsWith(clickNum)) {
        removeLayer(layer.id, clickNum);
      }
    });

    // Source 생성
    const csvSource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: res,
      },
    };

    // Layer 생성
    const csvLayer = {
      id: 'csvLayer' + clickNum,
      source: csvSource,
      type: 'circle',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'circle-radius': 4,
        'circle-color': 'blue',
      },
    };

    let id = 'csvSource' + clickNum;
    // Source 및 Layer를 지도에 추가
    map.addSource(id, csvSource);
    map.addLayer(csvLayer);

    props.setCsvLog((prevStates) => ({
      ...prevStates,
      [clickNum]: prevStates[clickNum] === true ? true : true,
    }));

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on('mouseenter', 'csvLayer' + clickNum, (e) => {
      map.getCanvas().style.cursor = 'pointer';

      let selectedFeature = e.features[0];
      let match_time = selectedFeature.properties.time;
      let match_data = null;

      for (let data of res) {
        if (match_time == data.properties['time']) {
          match_data = data;
          break;
        }
      }
      if (match_data) {
        let coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        let dt = new Date(e.features[0].properties.time);
        const date =
          dt.getFullYear() +
          '-' +
          ('0' + (dt.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + dt.getDate()).slice(-2);

        const time =
          ('0' + dt.getHours()).slice(-2) +
          ':' +
          ('0' + dt.getMinutes()).slice(-2) +
          ':' +
          ('0' + dt.getSeconds()).slice(-2) +
          '.' +
          ('00' + dt.getMilliseconds()).slice(-3);

        // 팝업에 들어갈 content
        let content =
          "<table style='border:1px solid #000; font-family:Noto Sans KR;font-weight: 600;'>" +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>날짜</td><td style='border:1px solid #000; padding:5px 8px 5px 8px; text-align: left;'>" +
          date +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>시간</td><td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          time +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>경도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lng.toFixed(7) +
          '</td></tr>' +
          "<tr><td style='border:1px solid #000;padding:5px 8px 5px 8px;background-color: #1976d2;color: white;'>위도<td style='border:1px solid #000; padding:5px 8px 5px 8px;text-align: left;'>" +
          e.lngLat.lat.toFixed(7) +
          '</td></tr>' +
          '</table>';

        coordinates = e.lngLat;
        popup.setLngLat(coordinates).setHTML(content).addTo(map);

        // point에 mouseenter 되었을 때, 차트 확대 하는 부분
        chart2.zoom([
          e.features[0].properties.time - 15000,
          e.features[0].properties.time + 15000,
        ]);
        chart2.tooltip.show({ x: e.features[0].properties.time });
      }
    });

    // point에 mouseleave 되었을 때, 차트 확대 해제 및 팝업 제거
    map.on('mouseleave', 'csvLayer' + clickNum, () => {
      map.getCanvas().style.cursor = '';
      chart2.tooltip.show(false);
      chart2.internal.hideTooltip();
      popup.remove();
      chart2.unzoom();
      chart2.flush(); // 차트 갱신
    });

    // map.setCenter(res[res.length / 2].geometry.coordinates);
  };

  useEffect(() => {
    props.setClickNum(null);
    function fetchFun() {
      // removeLayer(curClickNum);
      // drawLog(curClickNum); //meta_id
    }
    if (props.actChart) {
      // setChartLoading(false);

      fetchFun();
    }
  }, [props.actChart]);

  const handleCheckboxChange = (id) => {
    switch (id) {
      case 'spray':
        setSprayChecked(!sprayChecked);
        if (sprayChecked) {
          chartState.hide('살포량');
        } else {
          chartState.show('살포량');
        }
        break;
      case 'battery':
        setBatteryChecked(!batteryChecked);
        if (batteryChecked) {
          chartState.hide('배터리 잔량');
        } else {
          chartState.show('배터리 잔량');
        }
        break;
      case 'altitude':
        setAltitudeChecked(!altitudeChecked);
        if (altitudeChecked) {
          chartState.hide('고도');
        } else {
          chartState.show('고도');
        }
        break;
      default:
        break;
    }
  };

  // *************************************

  const handleShowDetail = (event) => {
    setShowDetail(event);
  };

  useEffect(() => {
    if (props.mobileLegendMenu) {
      setShowModifyLegend(true);
    }
  }, [props.mobileLegendMenu]);

  useEffect(() => {
    if (props.curActiveLayer) {
      if (
        props.curActiveLayer.layerType == 1 &&
        props.curActiveLayer.contrast_info != null
      ) {
        let contrast = props.curActiveLayer.contrast_info.contrast;
        let bright = props.curActiveLayer.contrast_info.bright;
        let gamma = props.curActiveLayer.contrast_info.gamma;

        setContrastLevel(contrast);
        setBrightnessLevel(bright);
        setGammaLevel(gamma);
      }
    } else {
      setContrastLevel(0.0);
      setBrightnessLevel(0.0);
      setGammaLevel(0.5);
    }
  }, [props.curActiveLayer]);

  useEffect(() => {
    if (props.curActiveLayer) {
      if (
        props.curActiveLayer.layerType == 1 &&
        props.curActiveLayer.contrast_info != null
      ) {
        if (props.mapRef.current != null) {
          const map = props.mapRef.current.getMap();
          if (
            map.getLayer('drone_image_layer_' + props.curActiveLayer.drn_img_id)
          ) {
            map.setPaintProperty(
              'drone_image_layer_' + props.curActiveLayer.drn_img_id,
              'raster-contrast',
              contrastLevel
            );

            if (brightnessLevel >= 0) {
              map.setPaintProperty(
                'drone_image_layer_' + props.curActiveLayer.drn_img_id,
                'raster-brightness-min',
                brightnessLevel
              );
              map.setPaintProperty(
                'drone_image_layer_' + props.curActiveLayer.drn_img_id,
                'raster-brightness-max',
                1
              );
            } else {
              map.setPaintProperty(
                'drone_image_layer_' + props.curActiveLayer.drn_img_id,
                'raster-brightness-max',
                brightnessLevel + 1
              );
              map.setPaintProperty(
                'drone_image_layer_' + props.curActiveLayer.drn_img_id,
                'raster-brightness-min',
                0
              );
            }
          }
        }
      }
    }
  }, [contrastLevel, brightnessLevel, gammaLevel]);

  const contrastLevelHandler = (e) => {
    if (props.mapRef.current) {
      const map = props.mapRef.current.getMap();
      map.setPaintProperty(
        'drone_image_layer_' + props.curActiveLayer.drn_img_id,
        'raster-contrast',
        e
      );

      axios({
        method: 'post',
        url: `/api/drone/style/contrast`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: {
          drnId: props.curActiveLayer.drn_img_id,
          contrast: e,
          bright: brightnessLevel,
          gamma: gammaLevel,
        },
      })
        .then((result) => {
          props.listReturnFlagHandler(true);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    setContrastLevel(e);
  };

  const brightnessLevelHandler = (e) => {
    if (props.mapRef.current) {
      const map = props.mapRef.current.getMap();

      if (e >= 0) {
        map.setPaintProperty(
          'drone_image_layer_' + props.curActiveLayer.drn_img_id,
          'raster-brightness-min',
          e
        );
        map.setPaintProperty(
          'drone_image_layer_' + props.curActiveLayer.drn_img_id,
          'raster-brightness-max',
          1
        );
      } else {
        map.setPaintProperty(
          'drone_image_layer_' + props.curActiveLayer.drn_img_id,
          'raster-brightness-max',
          e + 1
        );
        map.setPaintProperty(
          'drone_image_layer_' + props.curActiveLayer.drn_img_id,
          'raster-brightness-min',
          0
        );
      }

      axios({
        method: 'post',
        url: `/api/drone/style/contrast`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: {
          drnId: props.curActiveLayer.drn_img_id,
          contrast: contrastLevel,
          bright: e,
          gamma: gammaLevel,
        },
      })
        .then((result) => {
          props.listReturnFlagHandler(true);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    setBrightnessLevel(e);
  };

  const gammaLevelHandler = (e) => {
    axios({
      method: 'post',
      url: `/api/drone/style/contrast`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      data: {
        drnId: props.curActiveLayer.drn_img_id,
        contrast: contrastLevel,
        bright: brightnessLevel,
        gamma: e,
      },
    })
      .then((result) => {})
      .catch((error) => {
        // console.log(error);
      });
    setGammaLevel(e);
  };

  const [legendRed, setLegendRed] = useState();
  const [legendYellow, setLegendYellow] = useState();
  const [legendGreen, setLegendGreen] = useState();
  const [farmLegend, setFarmLegend] = useState();
  const [farmLegendCount, setFarmLegendCount] = useState();

  useEffect(() => {
    if (props.curActiveLayer) {
      if (props.curActiveLayer.layerType == 1) {
        setVisibleDrn(true);
        setVisibleThm(false);
        setVisibleFrm(false);
        setVisibleChart(false);
        setVisibleFrmSelect(false);
        setVisibleInstFarmwork(false);
      } else if (props.curActiveLayer.layerType == 2) {
        setVisibleDrn(false);
        setVisibleThm(true);
        setVisibleFrm(false);
        setVisibleChart(false);
        setVisibleFrmSelect(false);
        setVisibleInstFarmwork(false);
      } else if (props.curActiveLayer.layerType == 3) {
        setVisibleDrn(false);
        setVisibleThm(false);
        setVisibleFrm(true);
        setVisibleChart(true);
        setVisibleFrmSelect(false);
        setVisibleInstFarmwork(false);
        // console.log(props.curActiveLayer.geoJson.features);
        if (
          props.curActiveLayer.geoJson !== null &&
          props.curActiveLayer.geoJson !== undefined
        ) {
          let geojson = props.curActiveLayer.geoJson.features;

          let newGeojson = [];
          for (let i = 0; i < geojson.length; i++) {
            if (geojson[i].properties.value !== null) {
              newGeojson.push(geojson[i]);
            }
          }
          geojson = newGeojson;

          let valueArray = [];

          geojson.forEach((feature) => {
            feature.properties.AREA = turf.area(feature);
            let val = feature.properties.value;
            valueArray.push(val);
          });

          var maxValue = Math.max(...valueArray);
          var minValue = Math.min(...valueArray);

          const legend = {};
          const counts = { allArea: 0, allGrid: 0 };

          for (let i = 0; i < geojson.length; i++) {
            const { value, AREA, color } = geojson[i].properties;

            if (!legend[value]) {
              legend[value] = { count: 0, totalArea: 0, color: '' };
            }
            legend[value].count += 1;
            legend[value].totalArea += Number(AREA);
            if (value !== null) {
              if (color === undefined) {
                legend[value].color = getColorForPercentage(
                  value,
                  minValue,
                  maxValue
                );
              } else {
                legend[value].color = color;
              }
            } else {
              legend[value].color = 'rgba(255, 255, 255, 0)';
            }
          }

          Object.keys(legend).map((value) => {
            counts.allArea += Number(legend[value].totalArea);
            counts.allGrid += Number(legend[value].count);
          });

          setFarmLegend(legend);
          setFarmLegendCount(counts);
        }
      } else if (props.curActiveLayer.layerType === 4) {
        setVisibleDrn(false);
        setVisibleThm(false);
        setVisibleFrm(false);
        setVisibleChart(false);
        setVisibleFrmSelect(true);
        setVisibleInstFarmwork(false);
      } else if (props.curActiveLayer.layerType === 6) {
        setVisibleDrn(false);
        setVisibleThm(false);
        setVisibleFrm(false);
        setVisibleChart(false);
        setVisibleFrmSelect(false);
        setVisibleInstFarmwork(true);
      } else {
        setVisibleDrn(false);
        setVisibleThm(false);
        setVisibleFrm(false);
        setVisibleChart(false);
        setVisibleFrmSelect(false);
        setVisibleInstFarmwork(false);
      }
    }
  }, [props.curActiveLayer]);

  var getColorForPercentage = function (pct, min, max) {
    var percentColors = [
      { pct: min, color: { r: 0x00, g: 0xff, b: 0x00 } },
      { pct: (min + max) / 2, color: { r: 0xff, g: 0xff, b: 0x00 } },
      { pct: max, color: { r: 0xff, g: 0x00, b: 0x00 } },
    ];

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };
    // console.log("color", percentColors);
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  };

  useEffect(() => {
    if (!props.vegIndexImage) {
      props.setSelVIindex(-1);
    }
  }, [props.vegIndexImage]);

  function setInitImage() {
    if (props.mapRef.current) {
      const map = props.mapRef.current.getMap();
      map.setPaintProperty(
        'drone_image_layer_' + props.curActiveLayer.drn_img_id,
        'raster-contrast',
        0.0
      );
      map.setPaintProperty(
        'drone_image_layer_' + props.curActiveLayer.drn_img_id,
        'raster-brightness-min',
        0.0
      );
      map.setPaintProperty(
        'drone_image_layer_' + props.curActiveLayer.drn_img_id,
        'raster-brightness-max',
        1.0
      );
    }

    axios({
      method: 'post',
      url: `/api/drone/style/contrast`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      data: {
        drnId: props.curActiveLayer.drn_img_id,
        contrast: 0.0,
        bright: 0.0,
        gamma: 0.5,
      },
    })
      .then((result) => {})
      .catch((error) => {
        // console.log(error);
      });

    setContrastLevel(0.0);
    setBrightnessLevel(0.0);
    setGammaLevel(0.5);
  }

  return (
    <React.Fragment>
      {props.activeLayerList ? (
        props.activeLayerList.length === 0 ? null : (
          <div
            className={`farmstatus-container contents-col ${
              props.mobileAbstractMenu ? '' : 'hidden'
            }`}
          >
            <div className="farmstatus-menu contents-row">
              {props.curActiveLayer && props.curActiveLayer.layerType == 1 ? (
                <>
                  {props.isMobile ? (
                    <>
                      <div
                        className={`subcontents-info drnLog-info ${
                          visibleDrn ? 'active' : ''
                        }`}
                      >
                        {visibleDrn ? (
                          <div
                            className="mobile-gc-shrink_icon"
                            onClick={() => {
                              setVisibleDrn(!visibleDrn);
                            }}
                          />
                        ) : (
                          <div
                            className="mobile-gc-expansion_icon"
                            onClick={() => {
                              setVisibleDrn(!visibleDrn);
                            }}
                          />
                        )}
                        {visibleDrn ? (
                          <div className="drnLog-info-content contents-col">
                            {props.curActiveLayer ? (
                              <>
                                <div className="main-info contents-col">
                                  <div className="contents-row">
                                    <div
                                      className="title"
                                      style={{ margin: '5px 5px 5px 16px' }}
                                    >
                                      드론영상 정보 요약
                                    </div>
                                  </div>
                                  <table className="mobile_table_tr">
                                    <tbody>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>제목</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {props.curActiveLayer.drn_img_ttl}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>고유식별자</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {JSON.parse(
                                            props.curActiveLayer.meta_data_cn
                                          ).uid != undefined ? (
                                            <div>
                                              {
                                                JSON.parse(
                                                  props.curActiveLayer
                                                    .meta_data_cn
                                                ).uid
                                              }
                                            </div>
                                          ) : (
                                            <div>{'생성 전입니다.'}</div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>작성일자</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {props.curActiveLayer.reg_ymd.substr(
                                            0,
                                            10
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>다운로드</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {props.curActiveLayer &&
                                          props.curActiveLayer.orgnl_file_nm &&
                                          props.isMine ? (
                                            userId ===
                                            props.curActiveLayer.user_id ? (
                                              // <div
                                              //   onClick={() => {
                                              //     // const droneimageURL = 'http://geocapture.co.kr:3005' + props.curActiveLayer.url;
                                              //     // const droneimageURL = config.backend + props.curActiveLayer.url;
                                              //     // window.open(droneimageURL, "_blank", "noreferrer");
                                              //     window.location.href =
                                              //       props.curActiveLayer.url ===
                                              //       null
                                              //         ? '/api/data/dronImg/' +
                                              //           JSON.parse(
                                              //             props.curActiveLayer
                                              //               .meta_data_cn
                                              //           ).obs_id +
                                              //           '/' +
                                              //           props.curActiveLayer.drn_img_file_path_2
                                              //             .split('/')
                                              //             .pop()
                                              //         : props.curActiveLayer
                                              //             .url;
                                              //   }}
                                              // >
                                              //   {props.curActiveLayer
                                              //     .orgnl_file_nm === null
                                              //     ? props.curActiveLayer
                                              //         .orgnl_file_nm_2
                                              //     : props.curActiveLayer
                                              //         .orgnl_file_nm}
                                              // </div>
                                              <FileDownload
                                                fileURL={
                                                  props.curActiveLayer.url
                                                }
                                                fileName={
                                                  props.curActiveLayer
                                                    .orgnl_file_nm
                                                }
                                                isMobile={props.isMobile}
                                              />
                                            ) : (
                                              <div>
                                                파일 다운로드 권한이 없습니다.
                                              </div>
                                            )
                                          ) : // <div
                                          //   onClick={() => {
                                          //     window.location.href =
                                          //       props.curActiveLayer.url ===
                                          //       null
                                          //         ? '/api/data/dronImg/' +
                                          //           JSON.parse(
                                          //             props.curActiveLayer
                                          //               .meta_data_cn
                                          //           ).obs_id +
                                          //           '/' +
                                          //           props.curActiveLayer.drn_img_file_path_2
                                          //             .split('/')
                                          //             .pop()
                                          //         : props.curActiveLayer.url;
                                          //   }}
                                          // >
                                          //   {props.curActiveLayer
                                          //     .orgnl_file_nm === null
                                          //     ? props.curActiveLayer
                                          //         .orgnl_file_nm_2
                                          //     : 'asdasdas'}
                                          // </div>
                                          props.isMine &&
                                            userId ===
                                              props.curActiveLayer.user_id ? (
                                            <FileDownload
                                              fileURL={props.curActiveLayer.url}
                                              fileName={
                                                props.curActiveLayer
                                                  .orgnl_file_nm
                                              }
                                              isMobile={props.isMobile}
                                            />
                                          ) : (
                                            <div>
                                              파일 다운로드 권한이 없습니다.
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>식생지수</div>
                                        </td>
                                        <td
                                          className="mobile_table_td"
                                          style={{
                                            display: 'flex',
                                            borderBottom: 'none',
                                          }}
                                        >
                                          {props.curActiveLayer.ndvi_gen &&
                                            props.curActiveLayer.ndvi_gen.map(
                                              (vi, index) => {
                                                return (
                                                  <div
                                                    key={index}
                                                    className={`item-index  ${
                                                      props.selVIindex == index
                                                        ? 'active'
                                                        : ''
                                                    }`}
                                                    onClick={() => {
                                                      props.setSelVIindex(
                                                        index
                                                      );
                                                      let dummy = {};
                                                      // Delete layer
                                                      if (
                                                        props.vegIndexImage &&
                                                        props.vegIndexImage
                                                          .name ==
                                                          props.curActiveLayer
                                                            .ndvi_path[index]
                                                            .name
                                                      ) {
                                                        dummy = null;
                                                        props.setSelVIindex(-1);
                                                      }
                                                      // Add layer
                                                      else {
                                                        if (
                                                          !props.curActiveLayer
                                                            .ndvi_path[index]
                                                        ) {
                                                          // console.log('ndvi_path invalid');
                                                          // alert('유효하지 않은 식생지수 입니다.');
                                                          setShowPopup(true);
                                                          return;
                                                        }

                                                        if (
                                                          props.curActiveLayer.ndvi_path[
                                                            index
                                                          ].hasOwnProperty(
                                                            'coords'
                                                          )
                                                        ) {
                                                          dummy = {
                                                            type: 'Image',
                                                            name: props
                                                              .curActiveLayer
                                                              .ndvi_path[index]
                                                              .name,
                                                            path: props
                                                              .curActiveLayer
                                                              .ndvi_path[index]
                                                              .url,
                                                            coords: {
                                                              topLeft:
                                                                props
                                                                  .curActiveLayer
                                                                  .ndvi_path[
                                                                  index
                                                                ].coords
                                                                  .topLeft,
                                                              topRight:
                                                                props
                                                                  .curActiveLayer
                                                                  .ndvi_path[
                                                                  index
                                                                ].coords
                                                                  .topRight,
                                                              bottomRight:
                                                                props
                                                                  .curActiveLayer
                                                                  .ndvi_path[
                                                                  index
                                                                ].coords
                                                                  .bottomRight,
                                                              bottomLeft:
                                                                props
                                                                  .curActiveLayer
                                                                  .ndvi_path[
                                                                  index
                                                                ].coords
                                                                  .bottomLeft,
                                                            },
                                                          };
                                                        } else {
                                                          dummy = {
                                                            type: 'GeoserverLayer',
                                                            name: props
                                                              .curActiveLayer
                                                              .ndvi_path[index]
                                                              .name,
                                                            path: props
                                                              .curActiveLayer
                                                              .ndvi_path[index]
                                                              .layerNm,
                                                            bbox: props
                                                              .curActiveLayer
                                                              .bbox,
                                                            style:
                                                              props
                                                                .curActiveLayer
                                                                .ndvi_path[
                                                                index
                                                              ].styleNm,
                                                          };
                                                        }
                                                      }
                                                      props.vegIndexImageHandler(
                                                        dummy
                                                      );
                                                    }}
                                                  >
                                                    {vi}
                                                  </div>
                                                );
                                              }
                                            )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            ) : null}
                          </div>
                        ) : (
                          <div className="main-info contents-col">
                            <div className="drnLog-info-content contents-col">
                              <div className="contents-row">
                                <div
                                  className="title"
                                  style={{ margin: '5px 5px 5px 16px' }}
                                >
                                  드론영상 정보 요약
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          className={`user-handling-menu contents-col flex-center ${
                            visibleDrn ? 'active' : ''
                          }`}
                        >
                          <div className="contents-row">
                            <div className="text">색상 렌더링</div>
                            <button
                              className="init-image gc-btn flex-center"
                              onClick={setInitImage}
                            >
                              초기화
                            </button>
                          </div>
                          <div className="contents-col color-handling-menu">
                            <div className="contents-row flex-center">
                              <div className="sub-text">대비</div>
                              <ReactSlider
                                className="horizontal-slider flex-center"
                                thumbClassName="example-thumb"
                                trackClassName="example-track"
                                // defaultValue={0.00}
                                min={-1.0}
                                max={1.0}
                                step={0.05}
                                value={contrastLevel}
                                pearling
                                onAfterChange={contrastLevelHandler}
                              />
                            </div>
                            <div className="contents-row flex-center">
                              <div className="sub-text">밝기</div>
                              <ReactSlider
                                className="horizontal-slider-double flex-center"
                                thumbClassName="example-thumb"
                                trackClassName="example-track"
                                // defaultValue={0.00}
                                min={-1.0}
                                max={1.0}
                                step={0.05}
                                minDistance={0.05}
                                value={brightnessLevel}
                                ariaLabel={['Lower thumb', 'Upper thumb']}
                                pearling
                                onAfterChange={brightnessLevelHandler}
                              />
                            </div>
                            {/* <div className="contents-row flex-center">
                                  <div className="sub-text">감마</div>
                                  <ReactSlider
                                      className="horizontal-slider-double flex-center"
                                      thumbClassName="example-thumb"
                                      trackClassName="example-track"
                                      // defaultValue={0.50}
                                      min={0.00}
                                      max={1.00}
                                      step={0.05}
                                      minDistance={0.05}
                                      value={gammaLevel}
                                      ariaLabel={['Lower thumb', 'Upper thumb']}
                                      pearling
                                      onAfterChange={gammaLevelHandler}
                                  ></ReactSlider>
                              </div> */}
                          </div>
                        </div>
                        {/* ) : null} */}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="contents-col droneimage">
                        <div
                          className={`subcontents-info droneimage-summary ${
                            visibleDrn ? 'active' : ''
                          }`}
                        >
                          드론영상 정보 요약
                          {visibleDrn ? (
                            <div
                              className="gc-shrink_icon"
                              onClick={() => {
                                setVisibleDrn(!visibleDrn);
                              }}
                            />
                          ) : (
                            <div
                              className="gc-expansion_icon"
                              onClick={() => {
                                setVisibleDrn(!visibleDrn);
                              }}
                            />
                          )}
                          {visibleDrn ? (
                            <div className="droneimage-summary-content contents-col">
                              {props.curActiveLayer ? (
                                <div>
                                  <div className="contents-row">
                                    <div className="subtitle">제목</div>
                                    {props.curActiveLayer &&
                                    props.curActiveLayer.drn_img_ttl ? (
                                      <div className="info title">
                                        {props.curActiveLayer.drn_img_ttl}
                                      </div>
                                    ) : (
                                      <div className="info" />
                                    )}
                                  </div>
                                  <div className="contents-row">
                                    <div className="subtitle">고유식별자</div>
                                    {JSON.parse(
                                      props.curActiveLayer.meta_data_cn
                                    ).uid != undefined ? (
                                      <div className="info identity">
                                        {
                                          // JSON.parse(
                                          //   props.curActiveLayer.meta_data_cn
                                          // ).uid
                                          (() => {
                                            const { uid } = JSON.parse(
                                              props.curActiveLayer.meta_data_cn
                                            );

                                            return (
                                              <div
                                                className="uid-container"
                                                data-full={uid}
                                              >
                                                <span className="uid-text">
                                                  {/* {uid.length > 25 ? `${uid.slice(0, 25)}` : uid} */}
                                                  {uid.length > 25
                                                    ? `${uid.slice(0, 25)} ...`
                                                    : uid}
                                                </span>
                                              </div>
                                            );
                                          })()
                                        }
                                      </div>
                                    ) : (
                                      <div className="info">생성 전입니다.</div>
                                    )}
                                  </div>
                                  <div className="contents-row">
                                    <div className="subtitle">작성일자</div>
                                    {props.curActiveLayer &&
                                    props.curActiveLayer.reg_ymd ? (
                                      <div className="info">
                                        {props.curActiveLayer.reg_ymd.substr(
                                          0,
                                          10
                                        )}
                                      </div>
                                    ) : (
                                      <div className="info" />
                                    )}
                                  </div>
                                  <div className="contents-row">
                                    <div className="subtitle">다운로드</div>
                                    {props.curActiveLayer &&
                                    props.curActiveLayer.orgnl_file_nm &&
                                    props.isMine ? (
                                      userId ===
                                      props.curActiveLayer.user_id ? (
                                        <>
                                          {/* <div
                                          className="info download"
                                          onClick={() => {
                                            // const droneimageURL = 'http://geocapture.co.kr:3005' + props.curActiveLayer.url;
                                            // const droneimageURL = config.backend + props.curActiveLayer.url;
                                            // window.open(droneimageURL, "_blank", "noreferrer");
                                            window.location.href =
                                              props.curActiveLayer.url === null
                                                ? '/api/data/dronImg/' +
                                                  JSON.parse(
                                                    props.curActiveLayer
                                                      .meta_data_cn
                                                  ).obs_id +
                                                  '/' +
                                                  props.curActiveLayer.drn_img_file_path_2
                                                    .split('/')
                                                    .pop()
                                                : props.curActiveLayer.url;
                                          }}
                                        >
                                          {props.curActiveLayer
                                            .orgnl_file_nm === null
                                            ? props.curActiveLayer
                                                .orgnl_file_nm_2
                                            : props.curActiveLayer
                                                .orgnl_file_nm}
                                        </div> */}
                                          <div className="contents-row fileDownload">
                                            <FileDownload
                                              fileURL={
                                                props.curActiveLayer.url ===
                                                null
                                                  ? '/api/data/dronImg/' +
                                                    JSON.parse(
                                                      props.curActiveLayer
                                                        .meta_data_cn
                                                    ).obs_id +
                                                    '/' +
                                                    props.curActiveLayer.drn_img_file_path_2
                                                      .split('/')
                                                      .pop()
                                                  : props.curActiveLayer.url
                                              }
                                              fileName={
                                                props.curActiveLayer
                                                  .orgnl_file_nm === null
                                                  ? props.curActiveLayer
                                                      .orgnl_file_nm_2
                                                  : props.curActiveLayer
                                                      .orgnl_file_nm
                                              }
                                              isMobile={props.isMobile}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <div className="info download">
                                          파일 다운로드 권한이 없습니다.
                                        </div>
                                      )
                                    ) : // <div
                                    //   className="info download"
                                    //   onClick={() => {
                                    //     window.location.href =
                                    //       props.curActiveLayer.url === null
                                    //         ? '/api/data/dronImg/' +
                                    //           JSON.parse(
                                    //             props.curActiveLayer
                                    //               .meta_data_cn
                                    //           ).obs_id +
                                    //           '/' +
                                    //           props.curActiveLayer.drn_img_file_path_2
                                    //             .split('/')
                                    //             .pop()
                                    //         : props.curActiveLayer.url;
                                    //   }}
                                    // >
                                    //   {props.curActiveLayer.orgnl_file_nm ===
                                    //   null
                                    //     ? props.curActiveLayer.orgnl_file_nm_2
                                    //     : 'asdasdas'}
                                    // </div>
                                    props.isMine &&
                                      userId ===
                                        props.curActiveLayer.user_id ? (
                                      <div className="contents-row fileDownload">
                                        <FileDownload
                                          fileURL={
                                            props.curActiveLayer.url === null
                                              ? '/api/data/dronImg/' +
                                                JSON.parse(
                                                  props.curActiveLayer
                                                    .meta_data_cn
                                                ).obs_id +
                                                '/' +
                                                props.curActiveLayer.drn_img_file_path_2
                                                  .split('/')
                                                  .pop()
                                              : props.curActiveLayer.url
                                          }
                                          fileName={
                                            props.curActiveLayer
                                              .orgnl_file_nm === null
                                              ? props.curActiveLayer
                                                  .orgnl_file_nm_2
                                              : null
                                          }
                                          isMobile={props.isMobile}
                                        />
                                      </div>
                                    ) : (
                                      <div className="info download">
                                        파일 다운로드 권한이 없습니다.
                                      </div>
                                    )}
                                  </div>
                                  <div className="contents-row">
                                    <div className="subtitle">식생지수</div>
                                    {props.curActiveLayer &&
                                    props.curActiveLayer.ndvi_gen ? (
                                      <div className="info">
                                        {props.curActiveLayer.ndvi_gen &&
                                          props.curActiveLayer.ndvi_gen.map(
                                            (vi, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className={`item-index  ${
                                                    props.selVIindex == index
                                                      ? 'active'
                                                      : ''
                                                  }`}
                                                  onClick={() => {
                                                    props.setSelVIindex(index);
                                                    let dummy = {};
                                                    // Delete layer
                                                    if (
                                                      props.vegIndexImage &&
                                                      props.vegIndexImage
                                                        .name ==
                                                        props.curActiveLayer
                                                          .ndvi_path[index].name
                                                    ) {
                                                      dummy = null;
                                                      props.setSelVIindex(-1);
                                                    }
                                                    // Add layer
                                                    else {
                                                      if (
                                                        !props.curActiveLayer
                                                          .ndvi_path[index]
                                                      ) {
                                                        // console.log('ndvi_path invalid');
                                                        // alert('유효하지 않은 식생지수 입니다.');
                                                        setShowPopup(true);
                                                        return;
                                                      }

                                                      if (
                                                        props.curActiveLayer.ndvi_path[
                                                          index
                                                        ].hasOwnProperty(
                                                          'coords'
                                                        )
                                                      ) {
                                                        dummy = {
                                                          type: 'Image',
                                                          name: props
                                                            .curActiveLayer
                                                            .ndvi_path[index]
                                                            .name,
                                                          path: props
                                                            .curActiveLayer
                                                            .ndvi_path[index]
                                                            .url,
                                                          coords: {
                                                            topLeft:
                                                              props
                                                                .curActiveLayer
                                                                .ndvi_path[
                                                                index
                                                              ].coords.topLeft,
                                                            topRight:
                                                              props
                                                                .curActiveLayer
                                                                .ndvi_path[
                                                                index
                                                              ].coords.topRight,
                                                            bottomRight:
                                                              props
                                                                .curActiveLayer
                                                                .ndvi_path[
                                                                index
                                                              ].coords
                                                                .bottomRight,
                                                            bottomLeft:
                                                              props
                                                                .curActiveLayer
                                                                .ndvi_path[
                                                                index
                                                              ].coords
                                                                .bottomLeft,
                                                          },
                                                        };
                                                      } else {
                                                        dummy = {
                                                          type: 'GeoserverLayer',
                                                          name: props
                                                            .curActiveLayer
                                                            .ndvi_path[index]
                                                            .name,
                                                          path: props
                                                            .curActiveLayer
                                                            .ndvi_path[index]
                                                            .layerNm,
                                                          bbox: props
                                                            .curActiveLayer
                                                            .bbox,
                                                          style:
                                                            props.curActiveLayer
                                                              .ndvi_path[index]
                                                              .styleNm,
                                                        };
                                                      }
                                                    }
                                                    props.vegIndexImageHandler(
                                                      dummy
                                                    );
                                                  }}
                                                >
                                                  {vi}
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    ) : (
                                      <div className="info">─</div>
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                          {visibleDrn && props.curActiveLayer ? (
                            <div
                              className="droneimage-detail"
                              onClick={() => {
                                props.handleActiveImg(2);
                                props.handleShowImageReg(true);
                              }}
                            >
                              상세보기
                            </div>
                          ) : null}
                        </div>
                        {/* <div className="division-A"></div> */}
                        {/* <div className={`subcontents-info droneimage-summary ${visibleDrn? "active" : ""}`} > */}
                        {/* {visibleDrn ? (
                        // <div className="user-handling-menu contents-col flex-center"> */}
                        <div
                          className={`user-handling-menu contents-col flex-center ${
                            visibleDrn ? 'active' : ''
                          }`}
                        >
                          <div className="contents-row">
                            <div className="text">색상 렌더링</div>
                            <button
                              className="init-image gc-btn flex-center"
                              onClick={setInitImage}
                            >
                              초기화
                            </button>
                          </div>
                          <div className="contents-col color-handling-menu">
                            <div className="contents-row flex-center">
                              <div className="sub-text">대비</div>
                              <ReactSlider
                                className="horizontal-slider flex-center"
                                thumbClassName="example-thumb"
                                trackClassName="example-track"
                                // defaultValue={0.00}
                                min={-1.0}
                                max={1.0}
                                step={0.05}
                                value={contrastLevel}
                                pearling
                                onAfterChange={contrastLevelHandler}
                              />
                            </div>
                            <div className="contents-row flex-center">
                              <div className="sub-text">밝기</div>
                              <ReactSlider
                                className="horizontal-slider-double flex-center"
                                thumbClassName="example-thumb"
                                trackClassName="example-track"
                                // defaultValue={0.00}
                                min={-1.0}
                                max={1.0}
                                step={0.05}
                                minDistance={0.05}
                                value={brightnessLevel}
                                ariaLabel={['Lower thumb', 'Upper thumb']}
                                pearling
                                onAfterChange={brightnessLevelHandler}
                              ></ReactSlider>
                            </div>
                            {/* <div className="contents-row flex-center">
                                  <div className="sub-text">감마</div>
                                  <ReactSlider
                                      className="horizontal-slider-double flex-center"
                                      thumbClassName="example-thumb"
                                      trackClassName="example-track"
                                      // defaultValue={0.50}
                                      min={0.00}
                                      max={1.00}
                                      step={0.05}
                                      minDistance={0.05}
                                      value={gammaLevel}
                                      ariaLabel={['Lower thumb', 'Upper thumb']}
                                      pearling
                                      onAfterChange={gammaLevelHandler}
                                  ></ReactSlider>
                              </div> */}
                          </div>
                        </div>
                        {/* ) : null} */}
                      </div>
                    </>
                  )}
                </>
              ) : props.curActiveLayer &&
                props.curActiveLayer.layerType == 2 ? (
                <>
                  {props.isMobile ? (
                    <>
                      <div
                        className={`subcontents-info drnLog-info ${
                          visibleThm ? 'active' : ''
                        }`}
                      >
                        {visibleThm ? (
                          <div
                            className="mobile-gc-shrink_icon"
                            onClick={() => {
                              setVisibleThm(!visibleThm);
                            }}
                          />
                        ) : (
                          <div
                            className="mobile-gc-expansion_icon"
                            onClick={() => {
                              setVisibleThm(!visibleThm);
                            }}
                          />
                        )}
                        {visibleThm ? (
                          <div className="drnLog-info-content contents-col">
                            {props.curActiveLayer ? (
                              <>
                                <div className="main-info contents-col">
                                  <div className="contents-row">
                                    <div
                                      className="title"
                                      style={{ margin: '5px 5px 5px 16px' }}
                                    >
                                      주제도 정보 요약
                                    </div>
                                  </div>
                                  <table className="mobile_table_tr">
                                    <tbody>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>제목</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {props.curActiveLayer.theme_map_ttl}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>작성일자</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {props.curActiveLayer.reg_dt.substr(
                                            0,
                                            10
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>기준일자</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {props.curActiveLayer.crtr_ymd.substr(
                                            0,
                                            10
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>생성방식</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {props.curActiveLayer.crt_ty_cd == 1
                                            ? '속성정보'
                                            : props.curActiveLayer.crt_ty_cd ==
                                              2
                                            ? '회귀식'
                                            : props.curActiveLayer.crt_ty_cd ==
                                              3
                                            ? '래스터 계산'
                                            : props.curActiveLayer.crt_ty_cd ==
                                              4
                                            ? '육안판독'
                                            : props.curActiveLayer.crt_ty_cd ==
                                              5
                                            ? '직접입력'
                                            : null}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>다운로드</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {props.curActiveLayer &&
                                          props.curActiveLayer.url &&
                                          props.isMine ? (
                                            // <div
                                            //   onClick={() => {
                                            //     window.location.href =
                                            //       props.curActiveLayer.url;
                                            //   }}
                                            // >
                                            //   {props.curActiveLayer.orgnl_file_nm
                                            //     ? props.curActiveLayer.orgnl_file_nm
                                            //     : // props.curActiveLayer.url? props.curActiveLayer.url :
                                            //       '주제도 다운로드'}
                                            // </div>
                                            <FileDownload
                                              fileURL={props.curActiveLayer.url}
                                              fileName={
                                                props.curActiveLayer
                                                  .orgnl_file_nm
                                                  ? props.curActiveLayer
                                                      .orgnl_file_nm
                                                  : '주제도 다운로드'
                                              }
                                              isMobile={props.isMobile}
                                            />
                                          ) : (
                                            <div className="info download">
                                              파일 다운로드 권한이 없습니다.
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  {visibleDrn ? (
                                    // <div className="user-handling-menu contents-col flex-center">
                                    <div
                                      className={`user-handling-menu contents-col flex-center ${
                                        visibleDrn ? 'active' : ''
                                      }`}
                                      style={{ padding: '0px' }}
                                    >
                                      {/* <div className="division-A"></div> */}
                                      <div className="contents-row">
                                        <div className="text">색상 렌더링</div>
                                        <button
                                          className="init-image gc-btn flex-center"
                                          onClick={setInitImage}
                                        >
                                          초기화
                                        </button>
                                      </div>
                                      <div className="contents-col color-handling-menu">
                                        <div className="contents-row flex-center">
                                          <div className="sub-text">대비</div>
                                          <ReactSlider
                                            className="horizontal-slider flex-center"
                                            thumbClassName="example-thumb"
                                            trackClassName="example-track"
                                            // defaultValue={0.00}
                                            min={-1.0}
                                            max={1.0}
                                            step={0.05}
                                            value={contrastLevel}
                                            pearling
                                            onAfterChange={contrastLevelHandler}
                                          />
                                        </div>
                                        <div className="contents-row flex-center">
                                          <div className="sub-text">밝기</div>
                                          <ReactSlider
                                            className="horizontal-slider-double flex-center"
                                            thumbClassName="example-thumb"
                                            trackClassName="example-track"
                                            // defaultValue={0.00}
                                            min={-1.0}
                                            max={1.0}
                                            step={0.05}
                                            minDistance={0.05}
                                            value={brightnessLevel}
                                            ariaLabel={[
                                              'Lower thumb',
                                              'Upper thumb',
                                            ]}
                                            pearling
                                            onAfterChange={
                                              brightnessLevelHandler
                                            }
                                          ></ReactSlider>
                                        </div>
                                        {/* <div className="contents-row flex-center">
                                  <div className="sub-text">감마</div>
                                  <ReactSlider
                                      className="horizontal-slider-double flex-center"
                                      thumbClassName="example-thumb"
                                      trackClassName="example-track"
                                      // defaultValue={0.50}
                                      min={0.00}
                                      max={1.00}
                                      step={0.05}
                                      minDistance={0.05}
                                      value={gammaLevel}
                                      ariaLabel={['Lower thumb', 'Upper thumb']}
                                      pearling
                                      onAfterChange={gammaLevelHandler}
                                  ></ReactSlider>
                              </div> */}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                          </div>
                        ) : (
                          <div className="main-info contents-col">
                            <div className="drnLog-info-content contents-col">
                              <div className="contents-row">
                                <div
                                  className="title"
                                  style={{ margin: '5px 5px 5px 16px' }}
                                >
                                  주제도 정보 요약
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div
                      className={`subcontents-info thememap-attribute ${
                        visibleThm ? 'active' : ''
                      }`}
                    >
                      <span>주제도 정보 요약</span>
                      {visibleThm ? (
                        <div
                          className="gc-shrink_icon"
                          onClick={() => {
                            setVisibleThm(!visibleThm);
                          }}
                        />
                      ) : (
                        <div
                          className="gc-expansion_icon"
                          onClick={() => {
                            setVisibleThm(!visibleThm);
                          }}
                        />
                      )}
                      {visibleThm ? (
                        <div className="thememap-attribute-content contents-col">
                          {props.curActiveLayer ? (
                            <div>
                              <div className="contents-row">
                                <div className="subtitle">제목</div>
                                {props.curActiveLayer &&
                                props.curActiveLayer.theme_map_ttl ? (
                                  <div className="info title">
                                    {props.curActiveLayer.theme_map_ttl}
                                  </div>
                                ) : (
                                  <div className="info" />
                                )}
                              </div>
                              <div className="contents-row">
                                <div className="subtitle">작성일자</div>
                                {props.curActiveLayer &&
                                props.curActiveLayer.reg_dt ? (
                                  <div className="info regdate">
                                    {props.curActiveLayer.reg_dt.substr(0, 10)}
                                  </div>
                                ) : (
                                  <div className="info" />
                                )}
                              </div>
                              <div
                                className={`${
                                  props.isMobile ? 'contents-row mobile' : ''
                                }`}
                              >
                                <div className="contents-row">
                                  <div className="subtitle">기준일자</div>
                                  {props.curActiveLayer &&
                                  props.curActiveLayer.crtr_ymd ? (
                                    <div className="info ymd">
                                      {props.curActiveLayer.crtr_ymd.substr(
                                        0,
                                        10
                                      )}
                                    </div>
                                  ) : (
                                    <div className="info" />
                                  )}
                                </div>
                                <div className="contents-row">
                                  <div className="subtitle">생성방식</div>
                                  {props.curActiveLayer &&
                                  props.curActiveLayer.crt_ty_cd ? (
                                    <div className="info">
                                      {props.curActiveLayer.crt_ty_cd == 1
                                        ? '속성정보'
                                        : props.curActiveLayer.crt_ty_cd == 2
                                        ? '회귀식'
                                        : props.curActiveLayer.crt_ty_cd == 3
                                        ? '래스터 계산'
                                        : props.curActiveLayer.crt_ty_cd == 4
                                        ? '육안판독'
                                        : props.curActiveLayer.crt_ty_cd == 5
                                        ? '직접입력'
                                        : null}
                                    </div>
                                  ) : (
                                    <div className="info" />
                                  )}
                                </div>
                              </div>
                              <div className="contents-row">
                                <div className="subtitle">다운로드</div>
                                {props.curActiveLayer &&
                                props.curActiveLayer.url &&
                                props.isMine ? (
                                  <>
                                    {/* <div
                                      className="info download"
                                      onClick={() => {
                                        window.location.href =
                                          props.curActiveLayer.url;
                                      }}
                                    >
                                      {props.curActiveLayer.orgnl_file_nm
                                        ? props.curActiveLayer.orgnl_file_nm
                                        : // props.curActiveLayer.url? props.curActiveLayer.url :
                                          '주제도 다운로드'}
                                    </div> */}
                                    <div className="contents-row fileDownload">
                                      <FileDownload
                                        fileURL={props.curActiveLayer.url}
                                        fileName={
                                          props.curActiveLayer.orgnl_file_nm
                                            ? props.curActiveLayer.orgnl_file_nm
                                            : '주제도 다운로드'
                                        }
                                        isMobile={props.isMobile}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="info download">
                                    파일 다운로드 권한이 없습니다.
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {visibleThm && props.curActiveLayer ? (
                        <div
                          className="themeMap-modify"
                          onClick={() => {
                            props.handleActiveTheme(2);
                            props.handleShowThemeReg(true);
                          }}
                        >
                          상세보기
                        </div>
                      ) : null}
                    </div>
                  )}
                </>
              ) : props.curActiveLayer &&
                props.curActiveLayer.layerType == 3 ? (
                <>
                  {props.isMobile ? (
                    <>
                      <div
                        // ref={divRef}
                        className={`subcontents-info farmwork-info ${
                          visibleFrm ? 'active' : ''
                        }`}
                      >
                        {visibleFrm ? (
                          <div
                            className="mobile-gc-shrink_icon"
                            onClick={() => {
                              setVisibleFrm(!visibleFrm);
                            }}
                          />
                        ) : (
                          <div
                            className="mobile-gc-expansion_icon"
                            onClick={() => {
                              setVisibleFrm(!visibleFrm);
                            }}
                          />
                        )}
                        {visibleFrm ? (
                          <div className="farmwork-info-content contents-col">
                            {props.curActiveLayer ? (
                              <>
                                <div className="main-info contents-col">
                                  <div className="contents-row">
                                    <div
                                      className="title"
                                      style={{ margin: '5px 5px 5px 16px' }}
                                    >
                                      농작업 정보 요약
                                      {/* ({props.curActiveLayer.frmwkt_req_id}) */}
                                    </div>
                                  </div>
                                  <table className="mobile_chart_table">
                                    <tbody style={{ width: '100%' }}>
                                      <tr className="mobile_chart_table_tr">
                                        <td className="mobile_chart_table_th">
                                          <div>필지</div>
                                        </td>
                                        <td
                                          className="mobile_chart_table_td"
                                          colSpan={3}
                                        >
                                          {props.curActiveLayer.fmap_innb}
                                        </td>
                                      </tr>
                                      <tr className="mobile_chart_table_tr">
                                        <td className="mobile_chart_table_th">
                                          <div>고유식별자</div>
                                        </td>
                                        <td
                                          className="mobile_chart_table_td"
                                          colSpan={3}
                                        >
                                          {props.curActiveLayer.uid !== ''
                                            ? props.curActiveLayer.uid
                                            : '정보없음'}
                                        </td>
                                      </tr>
                                      <tr className="mobile_chart_table_tr">
                                        <td className="mobile_chart_table_th">
                                          <div>업체명</div>
                                        </td>
                                        <td className="mobile_chart_table_td">
                                          {props.curActiveLayer.oprtp_nm !==
                                          null
                                            ? props.curActiveLayer.oprtp_nm
                                            : '정보 없음'}
                                        </td>
                                        <td className="mobile_chart_table_th">
                                          <div>상태</div>
                                        </td>
                                        <td className="mobile_chart_table_td">
                                          {props.curActiveLayer
                                            .frmwkt_stts_cd === 1
                                            ? '요청완료'
                                            : '작업완료'}
                                        </td>
                                      </tr>
                                      <tr className="mobile_chart_table_tr">
                                        <td className="mobile_chart_table_th">
                                          <div>
                                            계획경로 <br></br>다운로드
                                          </div>
                                        </td>
                                        <td
                                          className="mobile_chart_table_td"
                                          colSpan={3}
                                        >
                                          {props.curActiveLayer &&
                                          props.waypoint ? (
                                            // <div
                                            //   className="farmwork-download"
                                            //   onClick={() => {
                                            //     window.location.href =
                                            //       '/api/data/frmwrk/' +
                                            //       props.curActiveLayer
                                            //         .meta_frmwrk_id +
                                            //       '/' +
                                            //       props.curActiveLayer
                                            //         .meta_frmwrk_id +
                                            //       'spray_path.waypoints';
                                            //   }}
                                            // >
                                            //   {props.curActiveLayer
                                            //     .meta_frmwrk_id +
                                            //     'spray_path.waypoints'}
                                            // </div>
                                            <FileDownload
                                              fileURL={
                                                '/api/data/frmwrk/' +
                                                props.curActiveLayer
                                                  .meta_frmwrk_id +
                                                '/' +
                                                props.curActiveLayer
                                                  .meta_frmwrk_id +
                                                'spray_path.waypoints'
                                              }
                                              fileName={
                                                props.curActiveLayer
                                                  .meta_frmwrk_id +
                                                'spray_path.waypoints'
                                              }
                                              isMobile={props.isMobile}
                                            />
                                          ) : (
                                            '계획 경로가 없습니다.'
                                          )}
                                        </td>
                                      </tr>
                                      <tr
                                        className="mobile_chart_table_tr"
                                        style={{
                                          display: 'flex',
                                          height: '55px',
                                        }}
                                      >
                                        <td
                                          className="mobile_chart_table_th"
                                          style={{
                                            width: '25%',
                                          }}
                                        >
                                          <div>다운로드</div>
                                        </td>
                                        <td
                                          className="mobile_chart_table_td"
                                          colSpan={3}
                                          style={{
                                            overflowY: 'scroll',
                                            overflowX: 'hidden',
                                            width: '70%',
                                            flex: '1',
                                          }}
                                        >
                                          {/* <div> */}
                                          {fileList.map((file, index) => (
                                            <li
                                              key={index}
                                              className="fileItem"
                                            >
                                              {/* <div className="logFileList "> */}
                                              <div
                                                className={`logFileList ${
                                                  isBtnClick === index
                                                    ? 'active'
                                                    : ''
                                                } `}
                                              >
                                                <i className="file outline icon"></i>
                                                <div className="drnLog-download">
                                                  <span
                                                    className="fileName"
                                                    onClick={() => {
                                                      FullFileName(file);
                                                    }}
                                                  >
                                                    {file.length > 10
                                                      ? `${file.slice(
                                                          0,
                                                          10
                                                        )}...`
                                                      : file}
                                                  </span>
                                                </div>
                                              </div>
                                              <button
                                                // className="gc-btn logCreateBtn"
                                                className={`
                                              gc-btn logCreateBtn  ${
                                                isBtnClick === index
                                                  ? 'active'
                                                  : ''
                                              } 
                                              `}
                                                style={{
                                                  backgroundColor:
                                                    isBtnClick === index
                                                      ? '#df8b8b'
                                                      : '#cbbbc3',
                                                  color: 'white',
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                  handleButtonClick(file, index)
                                                }
                                              >
                                                보기
                                              </button>
                                              <div
                                                className={`fa-solid fa-trash-can`}
                                                // onClick={cancel}
                                                onClick={() => {
                                                  delFile(file);
                                                }}
                                              ></div>
                                            </li>
                                          ))}

                                          {showFullName && (
                                            <div className="fileFullName">
                                              <div className="fileFullName-content">
                                                <p
                                                  className="file-fullname-text"
                                                  onClick={() => {
                                                    handleClick(showFullName);
                                                  }}
                                                >
                                                  <i
                                                    className="download icon"
                                                    style={{ flex: '1' }}
                                                  />
                                                  {/* {showFullName} */}
                                                  <span
                                                    style={{
                                                      flex: '7',
                                                      textDecoration:
                                                        'underline',

                                                      // }}>{showFullName}</span>
                                                    }}
                                                  >
                                                    {showFullName}
                                                    {/* <FileDownload
                                                      fileURL={
                                                        // {logFileUrl}
                                                        logFileUrl ? logFileUrl : handleClick(showFullName)
                                                      }
                                                      fileName={
                                                        props.curActiveLayer
                                                          .meta_frmwrk_id
                                                          ? showFullName
                                                          : null
                                                      }
                                                      isMobile={props.isMobile}
                                                    /> */}
                                                  </span>
                                                  <span
                                                    className="x-icon"
                                                    onClick={fileNameModalClick}
                                                    style={{ flex: '1' }}
                                                  ></span>
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div className="main-info contents-col">
                                  <div className="chart-btn">
                                    <div
                                      className="chart-detail"
                                      onClick={() => {
                                        setShowDetail(true);
                                      }}
                                    >
                                      상세보기
                                    </div>
                                    <div
                                      className="chart"
                                      onClick={() => {
                                        // props.setShowChart(false);
                                        // props.setNoChart(false);
                                        // handleMobileChart();
                                        props.setShowChart(true);
                                        drawMobileChart(
                                          props.curActiveLayer.meta_frmwrk_id
                                        );
                                      }}
                                    >
                                      2D차트
                                    </div>
                                  </div>
                                </div>

                                <div className="main-info contents-col">
                                  <div
                                    className="mobile-contents-row"
                                    style={{ marginTop: '5px' }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          placeContent: 'center',
                                          gap: '4px',
                                          flexWrap: 'wrap',
                                          width: '100%',
                                          justifyContent: 'space-around',
                                          // alignContent: "center",
                                          // marginTop: "15px",
                                        }}
                                      >
                                        {fileLoading ? (
                                          <>
                                            <Spinner />
                                            <div>처리중입니다.</div>
                                          </>
                                        ) : (
                                          <>
                                            {/* 안보여지는 부분 */}
                                            <form
                                              onSubmit={handleSubmit}
                                              style={{ display: 'none' }}
                                            >
                                              <input
                                                type="file"
                                                name="logFile"
                                                ref={inputFile}
                                                onChange={fileChange} //onchange 가 아닌 다른걸로 변경 해야할듯?
                                                accept={
                                                  '.log, .bin, .csv, .txt'
                                                } //.log, .bin .csv
                                                style={{ display: 'none' }}
                                              ></input>
                                              <input
                                                type="submit"
                                                ref={submitButton}
                                                style={{ display: 'none' }}
                                              />
                                            </form>

                                            {/* 보여지는 부분 */}
                                            <div
                                              style={{
                                                display: displayState,
                                                alignItems: 'center',
                                                padding: '0 5px 0 5px',
                                                border: '2px solid #A6A6A6',
                                                borderRadius: '5px',
                                                width: '70%',
                                                height: '30px',
                                                justifyContent: 'space-between',
                                                fontFamily: 'Noto Sans KR',
                                              }}
                                            >
                                              <div style={{ fontSize: '16px' }}>
                                                {fileName}
                                              </div>
                                              <div
                                                className="x-icon"
                                                onClick={cancel}
                                              ></div>
                                            </div>
                                            <FileInputButton
                                              style={{
                                                width: widthFlag,
                                                height: '80%',
                                              }}
                                              label={
                                                beforeSelect
                                                  ? '파일 선택'
                                                  : '업로드'
                                              }
                                              onClick={
                                                beforeSelect
                                                  ? handleButton
                                                  : handleUpload
                                              }
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '12px',
                                          margin: ' 4px 0 4px 0',
                                          textAlign: 'center',
                                        }}
                                      >
                                        업로드에 성공하면 기존에 있던 로그파일은
                                        삭제됩니다.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        ) : (
                          <div className="main-info contents-col">
                            <div className="farmwork-info-content contents-col">
                              <div className="contents-row">
                                <div
                                  className="title"
                                  style={{ margin: '5px 5px 5px 16px' }}
                                >
                                  농작업 정보 요약
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {props.showChart ? (
                        <div
                          className={`mobile-chart ${
                            visibleFrm ? 'active' : ''
                          }`}
                        >
                          {/* log,bin */}
                          {props.readyChart ? (
                            <div className="mobile-chart-top">
                              <div className="mobile-chart-OnOff">
                                <span className="mobile-chart-top-title">
                                  차트 On/Off
                                </span>
                                <span className="log_data">
                                  <input
                                    type="checkbox"
                                    id="spray"
                                    checked={sprayChecked}
                                    onChange={() =>
                                      handleCheckboxChange('spray')
                                    }
                                  />
                                  <label className="chartCheck">살포량</label>

                                  <input
                                    type="checkbox"
                                    id="battery"
                                    checked={batteryChecked}
                                    onChange={() =>
                                      handleCheckboxChange('battery')
                                    }
                                  />
                                  <label className="chartCheck">
                                    배터리 잔량
                                  </label>

                                  <input
                                    type="checkbox"
                                    id="altitude"
                                    checked={altitudeChecked}
                                    onChange={() =>
                                      handleCheckboxChange('altitude')
                                    }
                                  />
                                  <label className="chartCheck">고도</label>
                                </span>
                              </div>
                              <div className="mobile-chart-clostBtn">
                                <i
                                  className="fa-regular fa-xmark div-delete-icon"
                                  onClick={() => {
                                    props.setShowChart(false);
                                    props.setReadyChart(false);
                                  }}
                                ></i>
                              </div>
                            </div>
                          ) : null}

                          {/* csv */}
                          {props.readyCSVChart ? (
                            <div className="mobile-chart-top">
                              <div className="mobile-chart-OnOff">
                                <span className="mobile-chart-top-title">
                                  차트 On/Off
                                </span>
                                <span className="log_data">
                                  <input
                                    type="checkbox"
                                    id="altitude"
                                    checked={altitudeChecked}
                                    onChange={() =>
                                      handleCheckboxChange('altitude')
                                    }
                                  />
                                  <label className="chartCheck">고도</label>
                                </span>
                              </div>
                              <div className="mobile-chart-clostBtn">
                                <i
                                  className="fa-regular fa-xmark div-delete-icon"
                                  onClick={() => {
                                    props.setShowChart(false);
                                    props.setReadyCSVChart(false);
                                  }}
                                ></i>
                              </div>
                            </div>
                          ) : null}
                          <div className="mobile-chart-2">
                            <div id="chart_mobile" ref={mobileRef}></div>
                          </div>
                        </div>
                      ) : null}
                      {props.noChart ? (
                        <div className={`mobile-chart-noData`}>
                          <div>해당되는 차트 데이터가 없습니다.</div>
                          <div className="mobile-chart-clostBtn">
                            <i
                              className="fa-regular fa-xmark div-delete-icon"
                              onClick={() => {
                                props.setNoChart(false);
                              }}
                            ></i>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div
                      // ref={divRef}
                      className={`subcontents-info farmwork-info 
                        active
                      `}

                      // className={`subcontents-info farmwork-info ${
                      //   visibleFrm ? 'active' : ''
                      // }`}
                    >
                      <span>농작업 정보 요약</span>
                      {visibleFrm ? (
                        <div
                          className="gc-shrink_icon"
                          onClick={() => {
                            setVisibleFrm(!visibleFrm);
                          }}
                        />
                      ) : (
                        <div
                          className="gc-expansion_icon"
                          onClick={() => {
                            setVisibleFrm(!visibleFrm);
                            logChartCreate(chartFileNm);
                            setIsBtnClick(0);
                            props.setActChart(true);
                            props.setShowChart(true);
                          }}
                        />
                      )}

                      {visibleFrm ? (
                        <div className="farmwork-info-content contents-col">
                          {/* <div className="progress-image contents-row">
                                  <div className="step contents-row">
                                      <div className="image" id="step-1"></div>
                                      <div className="text">작업요청</div>
                                  </div>
                                  <div className="step contents-row">
                                      <div className="image" id="step-2"></div>
                                      <div className="text">수행업체<p></p>선정</div>
                                  </div>
                                  <div className="step contents-row">
                                      <div className="image" id="step-3"></div>
                                      <div className="text">작업진행</div>
                                  </div>
                                  <div className="step contents-row">
                                      <div className="image" id="step-4"></div>
                                      <div className="text">작업완료</div>
                                  </div>
                              </div> */}
                          {props.curActiveLayer ? (
                            <div className="main-info contents-col">
                              {/* <div className="contents-row">
                                      <div className="text">작업내역명</div>
                                      <div className="info title">20220623_액체방제_배추_미네토스타</div>
                                  </div> */}
                              <table className="table_all">
                                <tbody style={{ width: '500px' }}>
                                  <tr className="table_tr">
                                    <td className="table_th">
                                      <div>고유식별자</div>
                                    </td>
                                    <td
                                      colSpan={3}
                                      className="table_info"
                                      style={{ textAlign: 'left' }}
                                    >
                                      <div>
                                        {props.curActiveLayer.uid !== ''
                                          ? props.curActiveLayer.uid
                                          : '정보없음'}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="table_tr">
                                    <td className="table_th">
                                      <div>수행업체명</div>
                                    </td>
                                    <td className="table_info">
                                      <div>
                                        {props.curActiveLayer.oprtp_nm !== null
                                          ? props.curActiveLayer.oprtp_nm
                                          : '정보없음'}
                                      </div>
                                    </td>
                                    <td className="table_th">
                                      <div>현재 상태</div>
                                    </td>
                                    <td className="table_info">
                                      <div>
                                        {props.curActiveLayer.frmwkt_stts_cd ===
                                        1
                                          ? '요청완료'
                                          : '작업완료'}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="table_tr">
                                    <td className="table_th">
                                      계획 경로
                                      <br />
                                      다운로드
                                    </td>
                                    <td
                                      colSpan={3}
                                      className="table_info"
                                      style={{ textAlign: 'left' }}
                                    >
                                      {props.curActiveLayer &&
                                      props.waypoint ? (
                                        // <div
                                        //   className="farmwork-download"
                                        //   onClick={() => {
                                        //     window.location.href =
                                        //       '/api/data/frmwrk/' +
                                        //       props.curActiveLayer
                                        //         .meta_frmwrk_id +
                                        //       '/' +
                                        //       props.curActiveLayer
                                        //         .meta_frmwrk_id +
                                        //       'spray_path.waypoints';
                                        //   }}
                                        // >
                                        //   {props.waypoint
                                        //     ? props.curActiveLayer
                                        //         .meta_frmwrk_id +
                                        //       'spray_path.waypoints'
                                        //     : // props.curActiveLayer.url? props.curActiveLayer.url :
                                        //       '계획 경로가 없습니다.'}
                                        // </div>
                                        <div style={{ width: 'fit-content' }}>
                                          <FileDownload
                                            fileURL={
                                              '/api/data/frmwrk/' +
                                              props.curActiveLayer
                                                .meta_frmwrk_id +
                                              '/' +
                                              props.curActiveLayer
                                                .meta_frmwrk_id +
                                              'spray_path.waypoints'
                                            }
                                            fileName={
                                              props.waypoint
                                                ? props.curActiveLayer
                                                    .meta_frmwrk_id +
                                                  'spray_path.waypoints'
                                                : '계획 경로가 없습니다.'
                                            }
                                            isMobile={props.isMobile}
                                          />
                                        </div>
                                      ) : (
                                        '계획 경로가 없습니다.'
                                      )}
                                    </td>
                                  </tr>
                                  <tr
                                    className="table_tr"
                                    style={{
                                      display: 'flex',
                                      maxHeight: '100px',
                                    }}
                                  >
                                    <td className="table_th">
                                      <div>다운로드</div>
                                    </td>
                                    <td
                                      colSpan={3}
                                      className="table_info"
                                      style={{
                                        overflowY: 'auto',
                                      }}
                                    >
                                      <>
                                        {fileList.map((file, index) => {
                                          const cleanedFileName = file.replace(
                                            /\s*\(.*?\)\s*/g,
                                            ''
                                          );
                                          return (
                                            <li
                                              key={index}
                                              className="fileItem"
                                            >
                                              {/* <div className="logFileList "> */}
                                              <div
                                                className={`logFileList ${
                                                  isBtnClick === index
                                                    ? 'active'
                                                    : ''
                                                } `}
                                              >
                                                <i
                                                  className="file outline icon"
                                                  style={{
                                                    flexShrink: '0',
                                                    marginLeft: '5px',
                                                  }}
                                                ></i>

                                                <div className="drnLog-download">
                                                  <span
                                                    className={`fileName ${
                                                      cleanedFileName.length >
                                                      30
                                                        ? 'overflow'
                                                        : ''
                                                    }`}
                                                    data-full={cleanedFileName}
                                                    onClick={() => {
                                                      handleClick(file);
                                                    }}
                                                  >
                                                    {cleanedFileName}
                                                    {/* {cleanedFileName.length > 25
                                                      ? `${cleanedFileName.slice(
                                                          0,
                                                          25
                                                        )} ...`
                                                      : cleanedFileName} */}
                                                  </span>
                                                </div>
                                              </div>
                                              <button
                                                // className="gc-btn logCreateBtn"
                                                className={`
                                              gc-btn logCreateBtn  ${
                                                isBtnClick === index
                                                  ? 'active'
                                                  : ''
                                              } 
                                              `}
                                                style={{
                                                  backgroundColor:
                                                    isBtnClick === index
                                                      ? '#df8b8b'
                                                      : '#cbbbc3',
                                                  color: 'white',
                                                }}
                                                onClick={() =>
                                                  handleButtonClick(file, index)
                                                }
                                              >
                                                보기
                                              </button>
                                              <div
                                                className={`fa-solid fa-trash-can`}
                                                style={{ cursor: 'pointer' }}
                                                // onClick={cancel}
                                                onClick={() => {
                                                  delFile(file);
                                                }}
                                              ></div>
                                            </li>
                                          );
                                        })}
                                      </>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : null}
                          <div className="subInfo">
                            <div className="sub-info contents-row">
                              <div
                                className="showdetail"
                                onClick={() => {
                                  setShowDetail(true);
                                }}
                              >
                                상세보기
                              </div>
                            </div>
                            <div className="sub-info contents-row">
                              <div className="subtext">
                                ※ 작업 완료 전에는 지도에 경로를 띄울 수
                                없습니다.
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* sidebar에서 클릭 후 보이는 차트 */}

                      {/* 파일 업로드 후 보이는 차트 */}

                      {visibleFrm ? (
                        props.actChart ? (
                          <React.Fragment>
                            <div className="test_1 contents-col b">
                              <div className={`test_2 active`}>
                                <div id="chart2" ref={newChart}></div>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : null
                      ) : null}

                      {visibleFrm ? <div className="divideArea"></div> : null}

                      {visibleFrm ? (
                        props.isMine ? (
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'centPer',
                                justifyContent: 'center',
                                gap: '8px',
                                flexWrap: 'wrap',
                                width: '100%',
                                alignContent: 'center',
                                marginTop: '15px',
                              }}
                            >
                              {fileLoading ? (
                                <>
                                  <Spinner />
                                  <div>처리중입니다.</div>
                                </>
                              ) : (
                                <>
                                  {/* 안보여지는 부분 */}
                                  <form
                                    onSubmit={handleSubmit}
                                    style={{ display: 'none' }}
                                  >
                                    <input
                                      type="file"
                                      name="logFile"
                                      ref={inputFile}
                                      onChange={fileChange}
                                      accept={'.log, .bin, .csv'} //.log, .bin .csv
                                      style={{ display: 'none' }}
                                    ></input>
                                    <input
                                      type="submit"
                                      ref={submitButton}
                                      style={{ display: 'none' }}
                                    />
                                  </form>

                                  {/* 보여지는 부분 */}
                                  <div
                                    style={{
                                      display: displayState,
                                      alignItems: 'center',
                                      padding: '0 10px 0 10px',
                                      border: '2px solid #A6A6A6',
                                      borderRadius: '5px',
                                      width: '70%',
                                      height: '36.5px',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <div style={{ fontSize: '15px' }}>
                                      {fileName}
                                    </div>
                                    <div
                                      className="x-icon"
                                      onClick={cancel}
                                    ></div>
                                  </div>
                                  <FileInputButton
                                    style={{
                                      width: beforeSelect ? '80%' : '100px',
                                      backgroundColor: '#cf8686',
                                    }}
                                    label={
                                      beforeSelect ? '파일 선택' : '업로드'
                                    }
                                    onClick={
                                      beforeSelect ? handleButton : handleUpload
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              margin: '5%',
                            }}
                          >
                            {' '}
                            로그 생성 권한이 없습니다.
                          </div>
                        )
                      ) : null}
                    </div>
                  )}
                </>
              ) : props.curActiveLayer &&
                props.curActiveLayer.layerType == 4 ? (
                <>
                  {props.isMobile ? (
                    <>
                      <div
                        className={`subcontents-info farmwork-info ${
                          visibleFrm ? 'active' : ''
                        }`}
                      >
                        {visibleFrm ? (
                          <div
                            className="mobile-gc-shrink_icon"
                            onClick={() => {
                              setVisibleFrmSelect(!visibleFrmSelect);
                            }}
                          />
                        ) : (
                          <div
                            className="mobile-gc-expansion_icon"
                            onClick={() => {
                              setVisibleFrmSelect(!visibleFrmSelect);
                            }}
                          />
                        )}
                        {visibleFrmSelect ? (
                          <div className="farmwork-info-content contents-col">
                            {props.curActiveLayer ? (
                              <>
                                <div className="main-info contents-col">
                                  <div className="contents-row">
                                    <div className="title">
                                      필지 : {props.curActiveLayer.fmap_innb}
                                      {/* ({props.curActiveLayer.frmwkt_req_id}) */}
                                    </div>
                                  </div>
                                  <div className="mobile-contents-row">
                                    <div className="text">고유식별자</div>
                                    <div className="info">
                                      {props.curActiveLayer.uid !== ''
                                        ? props.curActiveLayer.uid
                                        : '정보없음'}
                                    </div>
                                  </div>
                                  <div className="mobile-contents-row">
                                    <div className="text">업체명</div>
                                    <div className="info">
                                      {props.curActiveLayer.oprtp_nm !== null
                                        ? props.curActiveLayer.oprtp_nm
                                        : '정보 없음'}
                                    </div>
                                    <div className="text">상태</div>
                                    <div className="info">
                                      {props.curActiveLayer.frmwkt_stts_cd === 1
                                        ? '요청완료'
                                        : '작업완료'}
                                    </div>
                                  </div>
                                  <div className="mobile-contents-row">
                                    <div className="text">
                                      계획 경로
                                      <br />
                                      다운로드
                                    </div>
                                    <div className="info">
                                      {props.curActiveLayer &&
                                      props.waypoint ? (
                                        <div
                                          className="farmwork-download"
                                          onClick={() => {
                                            window.location.href =
                                              '/api/data/frmwrk/' +
                                              props.curActiveLayer
                                                .meta_frmwrk_id +
                                              '/' +
                                              props.curActiveLayer
                                                .meta_frmwrk_id +
                                              'spray_path.waypoints';
                                          }}
                                        >
                                          {props.curActiveLayer.meta_frmwrk_id +
                                            'spray_path.waypoints'}
                                        </div>
                                      ) : (
                                        '계획 경로가 없습니다.'
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="main-info contents-col">
                                  <div className="mobile-contents-row">
                                    <div
                                      className="mobile-showdetail"
                                      onClick={() => {
                                        setShowDetail(true);
                                      }}
                                    >
                                      상세보기
                                    </div>
                                    <div
                                      className="mobile-showChart"
                                      onClick={() => {
                                        props.setShowChart(false);
                                        props.setNoChart(false);
                                        drawMobileChart(
                                          props.curActiveLayer.meta_frmwrk_id
                                        );
                                      }}
                                    >
                                      2D차트
                                    </div>
                                  </div>
                                </div>

                                <div className="main-info contents-col">
                                  <div
                                    className="mobile-contents-row"
                                    style={{ marginTop: '5px' }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          placeContent: 'center',
                                          gap: '8px',
                                          flexWrap: 'wrap',
                                          width: '100%',
                                          justifyContent: 'space-around',
                                          // alignContent: "center",
                                          // marginTop: "15px",
                                        }}
                                      >
                                        {fileLoading ? (
                                          <>
                                            <Spinner />
                                            <div>처리중입니다.</div>
                                          </>
                                        ) : (
                                          <>
                                            {/* 안보여지는 부분 */}
                                            <form
                                              onSubmit={handleSubmit}
                                              style={{ display: 'none' }}
                                            >
                                              <input
                                                type="file"
                                                name="logFile"
                                                ref={inputFile}
                                                onChange={fileChange} //onchange 가 아닌 다른걸로 변경 해야할듯?
                                                accept={
                                                  '.log, .bin, .csv, .txt'
                                                } //.log, .bin .csv
                                                style={{ display: 'none' }}
                                              ></input>
                                              <input
                                                type="submit"
                                                ref={submitButton}
                                                style={{ display: 'none' }}
                                              />
                                            </form>

                                            {/* 보여지는 부분 */}
                                            <div
                                              style={{
                                                display: displayState,
                                                alignItems: 'center',
                                                padding: '0 10px 0 10px',
                                                border: '2px solid #A6A6A6',
                                                borderRadius: '5px',
                                                width: '70%',
                                                height: '35px',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              <div style={{ fontSize: '16px' }}>
                                                {fileName}
                                              </div>
                                              <div
                                                className="x-icon"
                                                onClick={cancel}
                                              ></div>
                                            </div>
                                            <FileInputButton
                                              label={
                                                beforeSelect
                                                  ? '파일 선택'
                                                  : '업로드'
                                              }
                                              onClick={
                                                beforeSelect
                                                  ? handleButton
                                                  : handleUpload
                                              }
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '13px',
                                          marginTop: '8px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        업로드에 성공하면 기존에 있던 로그파일은
                                        삭제됩니다.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        ) : (
                          <div className="main-info contents-col">
                            <div className="farmwork-info-content contents-col">
                              <div className="contents-row">
                                <div
                                  className="title"
                                  style={{ margin: '5px 5px 5px 16px' }}
                                >
                                  필지 : {props.curActiveLayer.fmap_innb}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {props.showChart ? (
                        <div
                          className={`mobile-chart ${
                            visibleFrm ? 'active' : ''
                          }`}
                        >
                          {props.readyChart ? (
                            <div className="mobile-chart-top">
                              <div className="mobile-chart-OnOff">
                                <span className="mobile-chart-top-title">
                                  차트 On/Off
                                </span>
                                <span className="log_data">
                                  <input
                                    type="checkbox"
                                    id="spray"
                                    checked={sprayChecked}
                                    onChange={() =>
                                      handleCheckboxChange('spray')
                                    }
                                  />
                                  <label className="chartCheck">살포량</label>

                                  <input
                                    type="checkbox"
                                    id="battery"
                                    checked={batteryChecked}
                                    onChange={() =>
                                      handleCheckboxChange('battery')
                                    }
                                  />
                                  <label className="chartCheck">
                                    배터리 잔량
                                  </label>

                                  <input
                                    type="checkbox"
                                    id="altitude"
                                    checked={altitudeChecked}
                                    onChange={() =>
                                      handleCheckboxChange('altitude')
                                    }
                                  />
                                  <label className="chartCheck">고도</label>
                                </span>
                              </div>
                              <div className="mobile-chart-clostBtn">
                                <i
                                  className="fa-regular fa-xmark div-delete-icon"
                                  onClick={() => {
                                    props.setShowChart(false);
                                    props.setReadyChart(false);
                                  }}
                                ></i>
                              </div>
                            </div>
                          ) : null}
                          {props.readyCSVChart ? (
                            <div className="mobile-chart-top">
                              <div className="mobile-chart-OnOff">
                                <span className="mobile-chart-top-title">
                                  차트 On/Off
                                </span>
                                <span className="log_data">
                                  <input
                                    type="checkbox"
                                    id="altitude"
                                    checked={altitudeChecked}
                                    onChange={() =>
                                      handleCheckboxChange('altitude')
                                    }
                                  />
                                  <label className="chartCheck">고도</label>
                                </span>
                              </div>
                              <div className="mobile-chart-clostBtn">
                                <i
                                  className="fa-regular fa-xmark div-delete-icon"
                                  onClick={() => {
                                    props.setShowChart(false);
                                    props.setReadyCSVChart(false);
                                  }}
                                ></i>
                              </div>
                            </div>
                          ) : null}
                          <div className="mobile-chart-2">
                            <div id="chart_mobile" ref={mobileRef}></div>
                          </div>
                        </div>
                      ) : null}
                      {props.noChart ? (
                        <div className={`mobile-chart-noData`}>
                          <div>해당되는 차트 데이터가 없습니다.</div>
                          <div className="mobile-chart-clostBtn">
                            <i
                              className="fa-regular fa-xmark div-delete-icon"
                              onClick={() => {
                                props.setNoChart(false);
                              }}
                            ></i>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div
                      className={`subcontents-info farmwork-info ${
                        visibleFrmSelect ? 'active' : ''
                      }`}
                      style={{ width: '624px', padding: '12px' }}
                    >
                      <span>농작업 요청 정보</span>
                      {visibleFrmSelect ? (
                        <div
                          className="gc-shrink_icon"
                          onClick={() => {
                            props.setDrone(true);
                            setVisibleFrmSelect(!visibleFrmSelect);
                          }}
                        />
                      ) : (
                        <div
                          className="gc-expansion_icon"
                          onClick={() => {
                            // props.setDrone(true);
                            setVisibleFrmSelect(!visibleFrmSelect);
                          }}
                        />
                      )}
                      {visibleFrmSelect ? (
                        <div className="farmwork-info-content contents-col">
                          {props.curActiveLayer ? (
                            <div className="main-info contents-col">
                              <table className="table_all">
                                <tbody>
                                  {/* <tr className="table_tr">
                                <td className="table_th">
                                  <div>고유식별자</div>
                                </td>
                                <td
                                  colSpan={3}
                                  className="table_info"
                                  style={{ textAlign: "left" }}
                                >
                                  <div>
                                    {props.curActiveLayer.uid !== ""
                                      ? props.curActiveLayer.uid
                                      : "정보없음"}
                                  </div>
                                </td>
                              </tr> */}
                                  <tr className="table_tr">
                                    <td className="table_th">
                                      <div>필지</div>
                                    </td>
                                    <td
                                      className="table_info"
                                      // style={{ textAlign: "left" }}
                                    >
                                      {props.curActiveLayer.fmap_innb !== null
                                        ? props.curActiveLayer.fmap_innb
                                        : '정보없음'}
                                    </td>
                                    <td className="table_th">
                                      <div>작업 요청일</div>
                                    </td>
                                    <td
                                      className="table_info"
                                      // style={{ textAlign: "left" }}
                                    >
                                      {props.curActiveLayer.frmwkt_ymd !== null
                                        ? props.curActiveLayer.frmwkt_ymd
                                        : '정보없음'}
                                    </td>
                                  </tr>
                                  <tr className="table_tr">
                                    <td className="table_th">
                                      <div>수행업체명</div>
                                    </td>
                                    <td className="table_info">
                                      <div>
                                        {props.curActiveLayer.frmwkt_stts_cd ===
                                        1 ? (
                                          <button
                                            className={`gc-btn search-add-button ${
                                              props.isMine ? null : 'hidden'
                                            }`}
                                            onClick={() => {
                                              // setCheckInst(true);
                                            }}
                                            style={{
                                              fontFamily: 'Noto Sans KR',
                                              fontWeight: '600',
                                              border: 'none',
                                              height: '30px',
                                            }}
                                          >
                                            지원 기관 목록
                                          </button>
                                        ) : (
                                          `${
                                            props.curActiveLayer.specifyInst
                                              ? props.curActiveLayer.specifyInst
                                              : '정보 없음'
                                          }`
                                        )}
                                      </div>
                                    </td>
                                    <td className="table_th">
                                      <div>현재 상태</div>
                                    </td>
                                    <td className="table_info">
                                      <div>
                                        {props.curActiveLayer.frmwkt_stts_cd ===
                                        1
                                          ? '작업 요청'
                                          : props.curActiveLayer
                                              .frmwkt_stts_cd === 2
                                          ? '업체 선정 완료'
                                          : '작업 완료'}
                                      </div>
                                    </td>
                                  </tr>
                                  {props.curActiveLayer.frmwkt_stts_cd === 4 ? (
                                    <tr className="table_tr">
                                      <td className="table_th">결과 의견</td>
                                      <td
                                        colSpan={3}
                                        className="table_info"
                                        style={{ textAlign: 'left' }}
                                      >
                                        {props.curActiveLayer.frmwkt_rslt_cn
                                          ? props.curActiveLayer.frmwkt_rslt_cn
                                          : '정보 없음'}
                                      </td>
                                    </tr>
                                  ) : null}
                                  {props.curActiveLayer.frmwkt_stts_cd === 2 ||
                                  4 ? (
                                    <tr className="table_tr">
                                      <td className="table_th">
                                        계획 경로
                                        <br />
                                        다운로드
                                      </td>
                                      <td
                                        colSpan={3}
                                        className="table_info"
                                        style={{ textAlign: 'left' }}
                                      >
                                        {props.curActiveLayer &&
                                        props.waypoint ? (
                                          <div
                                            className="farmwork-download"
                                            onClick={() => {
                                              window.location.href =
                                                '/api/data/frmwrk/' +
                                                props.curActiveLayer
                                                  .meta_frmwrk_id +
                                                '/' +
                                                props.curActiveLayer
                                                  .meta_frmwrk_id +
                                                'spray_path.waypoints';
                                            }}
                                          >
                                            {props.waypoint
                                              ? props.curActiveLayer
                                                  .meta_frmwrk_id +
                                                'spray_path.waypoints'
                                              : // props.curActiveLayer.url? props.curActiveLayer.url :
                                                '계획 경로가 없습니다.'}
                                          </div>
                                        ) : (
                                          '계획 경로가 없습니다.'
                                        )}
                                      </td>
                                    </tr>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                          ) : null}
                          <div className="subInfo">
                            <div className="sub-info contents-row">
                              <div
                                className="showdetail"
                                onClick={() => {
                                  setShowDetail(true);
                                }}
                              >
                                상세보기
                              </div>
                            </div>
                            <div className="sub-info contents-row">
                              <div className="subtext">
                                ※ 작업 완료 전에는 지도에 경로를 띄울 수
                                없습니다.
                              </div>
                            </div>
                          </div>
                          {props.drone ? (
                            <React.Fragment>
                              {/* <div className="divideArea"></div> */}
                              <div className="test_1 contents-col a">
                                <div className={`test_2 active`}>
                                  <div className="test_3">
                                    <div className="abc">
                                      <div id="chart2"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ) : null}
                        </div>
                      ) : null}
                      {/* sidebar에서 클릭 후 보이는 차트 */}
                    </div>
                  )}
                </>
              ) : props.curActiveLayer &&
                props.curActiveLayer.layerType == 6 ? (
                <>
                  {props.isMobile ? (
                    <>
                      <div
                        className={`subcontents-info farmwork-info ${
                          visibleInstFarmwork ? 'active' : ''
                        }`}
                      >
                        {visibleInstFarmwork ? (
                          <div
                            className="mobile-gc-shrink_icon"
                            onClick={() => {
                              setVisibleInstFarmwork(!visibleInstFarmwork);
                            }}
                          />
                        ) : (
                          <div
                            className="mobile-gc-expansion_icon"
                            onClick={() => {
                              setVisibleInstFarmwork(!visibleInstFarmwork);
                            }}
                          />
                        )}
                        {visibleInstFarmwork ? (
                          <div className="farmwork-info-content contents-col">
                            {props.curActiveLayer ? (
                              <>
                                <div className="main-info contents-col">
                                  <div className="contents-row">
                                    <div className="title">
                                      필지 : {props.curActiveLayer.fmap_innb}
                                      {/* ({props.curActiveLayer.frmwkt_req_id}) */}
                                    </div>
                                  </div>
                                  <div className="mobile-contents-row">
                                    <div className="text">고유식별자</div>
                                    <div className="info">
                                      {props.curActiveLayer.uid !== ''
                                        ? props.curActiveLayer.uid
                                        : '정보없음'}
                                    </div>
                                  </div>
                                  <div className="mobile-contents-row">
                                    <div className="text">업체명</div>
                                    <div className="info">
                                      {props.curActiveLayer.oprtp_nm !== null
                                        ? props.curActiveLayer.oprtp_nm
                                        : '정보 없음'}
                                    </div>
                                    <div className="text">상태</div>
                                    <div className="info">
                                      {props.curActiveLayer.frmwkt_stts_cd === 1
                                        ? '요청완료'
                                        : '작업완료'}
                                    </div>
                                  </div>
                                  <div className="mobile-contents-row">
                                    <div className="text">
                                      계획 경로
                                      <br />
                                      다운로드
                                    </div>
                                    <div className="info">
                                      {props.curActiveLayer &&
                                      props.waypoint ? (
                                        <div
                                          className="farmwork-download"
                                          onClick={() => {
                                            window.location.href =
                                              '/api/data/frmwrk/' +
                                              props.curActiveLayer
                                                .meta_frmwrk_id +
                                              '/' +
                                              props.curActiveLayer
                                                .meta_frmwrk_id +
                                              'spray_path.waypoints';
                                          }}
                                        >
                                          {props.curActiveLayer.meta_frmwrk_id +
                                            'spray_path.waypoints'}
                                        </div>
                                      ) : (
                                        '계획 경로가 없습니다.'
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="main-info contents-col">
                                  <div className="mobile-contents-row">
                                    <div
                                      className="mobile-showdetail"
                                      onClick={() => {
                                        setShowDetail(true);
                                      }}
                                    >
                                      상세보기
                                    </div>
                                    <div
                                      className="mobile-showChart"
                                      onClick={() => {
                                        props.setShowChart(false);
                                        props.setNoChart(false);
                                        drawMobileChart(
                                          props.curActiveLayer.meta_frmwrk_id
                                        );
                                      }}
                                    >
                                      2D차트
                                    </div>
                                  </div>
                                </div>

                                <div className="main-info contents-col">
                                  <div
                                    className="mobile-contents-row"
                                    style={{ marginTop: '5px' }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          placeContent: 'center',
                                          gap: '8px',
                                          flexWrap: 'wrap',
                                          width: '100%',
                                          justifyContent: 'space-around',
                                          // alignContent: "center",
                                          // marginTop: "15px",
                                        }}
                                      >
                                        {fileLoading ? (
                                          <>
                                            <Spinner />
                                            <div>처리중입니다.</div>
                                          </>
                                        ) : (
                                          <>
                                            {/* 안보여지는 부분 */}
                                            <form
                                              onSubmit={handleSubmit}
                                              style={{ display: 'none' }}
                                            >
                                              <input
                                                type="file"
                                                name="logFile"
                                                ref={inputFile}
                                                onChange={fileChange} //onchange 가 아닌 다른걸로 변경 해야할듯?
                                                accept={
                                                  '.log, .bin, .csv, .txt'
                                                } //.log, .bin .csv
                                                style={{ display: 'none' }}
                                              ></input>
                                              <input
                                                type="submit"
                                                ref={submitButton}
                                                style={{ display: 'none' }}
                                              />
                                            </form>

                                            {/* 보여지는 부분 */}
                                            <div
                                              style={{
                                                display: displayState,
                                                alignItems: 'center',
                                                padding: '0 10px 0 10px',
                                                border: '2px solid #A6A6A6',
                                                borderRadius: '5px',
                                                width: '62%',
                                                height: '35px',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              <div style={{ fontSize: '16px' }}>
                                                {fileName}
                                              </div>
                                              <div
                                                className="x-icon"
                                                onClick={cancel}
                                              ></div>
                                            </div>
                                            <FileInputButton
                                              label={
                                                beforeSelect
                                                  ? '파일 선택'
                                                  : '업로드'
                                              }
                                              onClick={
                                                beforeSelect
                                                  ? handleButton
                                                  : handleUpload
                                              }
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '13px',
                                          marginTop: '8px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        업로드에 성공하면 기존에 있던 로그파일은
                                        삭제됩니다.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        ) : (
                          <div className="main-info contents-col">
                            <div className="farmwork-info-content contents-col">
                              <div className="contents-row">
                                <div
                                  className="title"
                                  style={{ margin: '5px 5px 5px 16px' }}
                                >
                                  필지 : {props.curActiveLayer.fmap_innb}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {props.showChart ? (
                        <div
                          className={`mobile-chart ${
                            visibleFrm ? 'active' : ''
                          }`}
                        >
                          {props.readyChart ? (
                            <div className="mobile-chart-top">
                              <div className="mobile-chart-OnOff">
                                <span className="mobile-chart-top-title">
                                  차트 On/Off
                                </span>
                                <span className="log_data">
                                  <input
                                    type="checkbox"
                                    id="spray"
                                    checked={sprayChecked}
                                    onChange={() =>
                                      handleCheckboxChange('spray')
                                    }
                                  />
                                  <label className="chartCheck">살포량</label>

                                  <input
                                    type="checkbox"
                                    id="battery"
                                    checked={batteryChecked}
                                    onChange={() =>
                                      handleCheckboxChange('battery')
                                    }
                                  />
                                  <label className="chartCheck">
                                    배터리 잔량
                                  </label>

                                  <input
                                    type="checkbox"
                                    id="altitude"
                                    checked={altitudeChecked}
                                    onChange={() =>
                                      handleCheckboxChange('altitude')
                                    }
                                  />
                                  <label className="chartCheck">고도</label>
                                </span>
                              </div>
                              <div className="mobile-chart-clostBtn">
                                <i
                                  className="fa-regular fa-xmark div-delete-icon"
                                  onClick={() => {
                                    props.setShowChart(false);
                                    props.setReadyChart(false);
                                  }}
                                ></i>
                              </div>
                            </div>
                          ) : null}
                          {props.readyCSVChart ? (
                            <div className="mobile-chart-top">
                              <div className="mobile-chart-OnOff">
                                <span className="mobile-chart-top-title">
                                  차트 On/Off
                                </span>
                                <span className="log_data">
                                  <input
                                    type="checkbox"
                                    id="altitude"
                                    checked={altitudeChecked}
                                    onChange={() =>
                                      handleCheckboxChange('altitude')
                                    }
                                  />
                                  <label className="chartCheck">고도</label>
                                </span>
                              </div>
                              <div className="mobile-chart-clostBtn">
                                <i
                                  className="fa-regular fa-xmark div-delete-icon"
                                  onClick={() => {
                                    props.setShowChart(false);
                                    props.setReadyCSVChart(false);
                                  }}
                                ></i>
                              </div>
                            </div>
                          ) : null}
                          <div className="mobile-chart-2">
                            <div id="chart_mobile" ref={mobileRef}></div>
                          </div>
                        </div>
                      ) : null}
                      {props.noChart ? (
                        <div className={`mobile-chart-noData`}>
                          <div>해당되는 차트 데이터가 없습니다.</div>
                          <div className="mobile-chart-clostBtn">
                            <i
                              className="fa-regular fa-xmark div-delete-icon"
                              onClick={() => {
                                props.setNoChart(false);
                              }}
                            ></i>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div
                      className={`subcontents-info farmwork-info ${
                        visibleInstFarmwork ? 'active' : ''
                      }`}
                      style={{ width: '624px', padding: '12px' }}
                    >
                      <span>농작업 요청 정보</span>
                      {visibleInstFarmwork ? (
                        <div
                          className="gc-shrink_icon"
                          onClick={() => {
                            setVisibleInstFarmwork(!visibleInstFarmwork);
                          }}
                        />
                      ) : (
                        <div
                          className="gc-expansion_icon"
                          onClick={() => {
                            setVisibleInstFarmwork(!visibleInstFarmwork);
                          }}
                        />
                      )}
                      {visibleInstFarmwork ? (
                        <div className="farmwork-info-content contents-col">
                          {props.curActiveLayer ? (
                            <div className="main-info contents-col">
                              <table className="table_all">
                                <tbody>
                                  <tr className="table_tr">
                                    <td className="table_th">
                                      <div>고유식별자</div>
                                    </td>
                                    <td
                                      colSpan={3}
                                      className="table_info"
                                      style={{ textAlign: 'left' }}
                                    >
                                      <div>
                                        {props.curActiveLayer.uid !==
                                          undefined &&
                                        props.curActiveLayer.uid !== '' &&
                                        props.curActiveLayer.uid !== null
                                          ? props.curActiveLayer.uid
                                          : '정보없음'}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="table_tr">
                                    <td className="table_th">
                                      <div>필지</div>
                                    </td>
                                    <td className="table_info">
                                      {props.curActiveLayer.fmap_innb !== null
                                        ? props.curActiveLayer.fmap_innb
                                        : '정보없음'}
                                    </td>
                                    <td className="table_th">
                                      <div>작업 요청일</div>
                                    </td>
                                    <td
                                      className="table_info"
                                      // style={{ textAlign: "left" }}
                                    >
                                      {props.curActiveLayer.frmwkt_ymd !== null
                                        ? props.curActiveLayer.frmwkt_ymd
                                        : '정보없음'}
                                    </td>
                                  </tr>
                                  <tr className="table_tr">
                                    <td className="table_th">
                                      <div>수행업체명</div>
                                    </td>
                                    <td className="table_info">
                                      <div>
                                        {props.curActiveLayer.frmwkt_stts_cd ===
                                        1 ? (
                                          <button
                                            className={`gc-btn search-add-button ${
                                              props.isMine ? null : 'hidden'
                                            }`}
                                            onClick={() => {
                                              // setCheckInst(true);
                                            }}
                                            style={{
                                              fontFamily: 'Noto Sans KR',
                                              fontWeight: '600',
                                              border: 'none',
                                              height: '30px',
                                            }}
                                          >
                                            요청 기관 목록
                                          </button>
                                        ) : (
                                          `${
                                            props.curActiveLayer.specifyInst
                                              ? props.curActiveLayer.specifyInst
                                              : '정보 없음'
                                          }`
                                        )}
                                      </div>
                                    </td>
                                    <td className="table_th">
                                      <div>현재 상태</div>
                                    </td>
                                    <td className="table_info">
                                      <div>
                                        {props.curActiveLayer.frmwkt_stts_cd ===
                                        1
                                          ? '작업 요청'
                                          : props.curActiveLayer
                                              .frmwkt_stts_cd === 2
                                          ? '업체 선정 완료'
                                          : '작업 완료'}
                                      </div>
                                    </td>
                                  </tr>
                                  {props.curActiveLayer.frmwkt_stts_cd === 2 ||
                                  4 ? (
                                    <tr className="table_tr">
                                      <td className="table_th">
                                        계획 경로
                                        <br />
                                        다운로드
                                      </td>
                                      <td
                                        colSpan={3}
                                        className="table_info"
                                        style={{ textAlign: 'left' }}
                                      >
                                        {props.curActiveLayer &&
                                        props.waypoint ? (
                                          <div
                                            className="farmwork-download"
                                            onClick={() => {
                                              window.location.href =
                                                '/api/data/frmwrk/' +
                                                props.curActiveLayer
                                                  .meta_frmwrk_id +
                                                '/' +
                                                props.curActiveLayer
                                                  .meta_frmwrk_id +
                                                'spray_path.waypoints';
                                            }}
                                          >
                                            {props.waypoint
                                              ? props.curActiveLayer
                                                  .meta_frmwrk_id +
                                                'spray_path.waypoints'
                                              : // props.curActiveLayer.url? props.curActiveLayer.url :
                                                '계획 경로가 없습니다.'}
                                          </div>
                                        ) : (
                                          '계획 경로가 없습니다.'
                                        )}
                                      </td>
                                    </tr>
                                  ) : null}
                                </tbody>
                              </table>
                              <div className="subInfo">
                                <div className="sub-info contents-row">
                                  <div
                                    className="showdetail"
                                    onClick={() => {
                                      setShowDetail(true);
                                    }}
                                  >
                                    상세보기
                                  </div>
                                </div>
                                <div className="sub-info contents-row">
                                  <div className="subtext">
                                    ※ 작업 완료 전에는 지도에 경로를 띄울 수
                                    없습니다.
                                  </div>
                                </div>
                              </div>
                              {/* sidebar에서 클릭 후 보이는 차트 */}
                              {props.drone ? (
                                <React.Fragment>
                                  {/* <div className="divideArea"></div> */}
                                  <div className="test_1 contents-col a">
                                    <div className={`test_2 active`}>
                                      <div className="test_3">
                                        <div className="abc">
                                          <div id="chart2"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  )}
                </>
              ) : props.curActiveLayer &&
                props.curActiveLayer.layerType == 5 ? (
                <>
                  {props.isMobile ? (
                    <>
                      <div
                        className={`subcontents-info drnLog-info ${
                          props.visibleDrnLog ? 'active' : ''
                        }`}
                      >
                        {props.visibleDrnLog ? (
                          <div
                            className="mobile-gc-shrink_icon"
                            onClick={() => {
                              props.setVisibleDrnLog(!props.visibleDrnLog);
                            }}
                          />
                        ) : (
                          <div
                            className="mobile-gc-expansion_icon"
                            onClick={() => {
                              props.setVisibleDrnLog(!props.visibleDrnLog);
                            }}
                          />
                        )}
                        {props.visibleDrnLog ? (
                          <div className="drnLog-info-content contents-col">
                            {props.curActiveLayer ? (
                              <>
                                <div className="main-info contents-col">
                                  <div className="contents-row">
                                    <div
                                      className="title"
                                      style={{ margin: '5px 5px 5px 16px' }}
                                    >
                                      드론로그 정보 요약
                                    </div>
                                  </div>

                                  <table className="mobile_table_tr">
                                    <tbody>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>제목</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {props.curActiveLayer.ttl_nm}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>작업일</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {props.curActiveLayer.job_ymd}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="mobile_table_th">
                                          <div>다운로드</div>
                                        </td>
                                        <td className="mobile_table_td">
                                          {props.curActiveLayer &&
                                          props.curActiveLayer.orgnl_file_nm &&
                                          props.isMine ? (
                                            userId ===
                                            props.curActiveLayer.user_id ? (
                                              <div
                                                className="drnLog-download"
                                                onClick={() => {
                                                  var folderNm =
                                                    props.curActiveLayer.strg_file_nm.split(
                                                      '.'
                                                    )[0];
                                                  var fileNm =
                                                    props.curActiveLayer
                                                      .strg_file_nm;

                                                  let extension = fileNm.slice(
                                                    ((fileNm.lastIndexOf('.') -
                                                      1) >>>
                                                      0) +
                                                      2
                                                  );
                                                  if (extension == 'log') {
                                                    window.location.href =
                                                      '/download/geojson' +
                                                      '?filebase=' +
                                                      folderNm +
                                                      '&filename=' +
                                                      fileNm +
                                                      '&fileCtgy=' +
                                                      'drnLogFile';
                                                  } else {
                                                    window.location.href =
                                                      '/api/data/drnLog' +
                                                      '/' +
                                                      folderNm +
                                                      '/' +
                                                      fileNm;
                                                  }
                                                }}
                                              >
                                                {
                                                  props.curActiveLayer
                                                    .orgnl_file_nm
                                                }
                                              </div>
                                            ) : (
                                              <div
                                                onClick={() => {
                                                  window.location.href =
                                                    '/api/data/drnLog' +
                                                    props.curActiveLayer
                                                      .strg_path;
                                                }}
                                              >
                                                {
                                                  props.curActiveLayer
                                                    .orgnl_file_nm
                                                }
                                              </div>
                                            )
                                          ) : (
                                            <div>다운로드 권한이 없습니다.</div>
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div className="main-info contents-col">
                                  <div className="chart-btn">
                                    <div
                                      className="chart-detail"
                                      onClick={() => {
                                        props.handleActiveDrnLog(2);
                                        props.setShowDrnLog(true);
                                      }}
                                    >
                                      상세보기
                                    </div>
                                    <div
                                      className="chart"
                                      onClick={() => {
                                        props.setShowChart(false);
                                        props.setNoChart(false);
                                        drawMobileChart(
                                          props.curActiveLayer.log_sn
                                        );
                                      }}
                                    >
                                      2D차트
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        ) : (
                          <div className="main-info contents-col">
                            <div className="drnLog-info-content contents-col">
                              <div className="contents-row">
                                <div
                                  className="title"
                                  style={{ margin: '5px 5px 5px 16px' }}
                                >
                                  드론로그 정보 요약
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {props.showChart ? (
                        <div
                          className={`mobile-chart drnLog ${
                            props.visibleDrnLog ? 'active' : ''
                          }`}
                        >
                          {props.readyChart ? (
                            <div className="mobile-chart-top">
                              <div className="mobile-chart-OnOff">
                                <span className="mobile-chart-top-title">
                                  차트 On/Off
                                </span>
                                <span className="log_data">
                                  <input
                                    type="checkbox"
                                    id="spray"
                                    checked={sprayChecked}
                                    onChange={() =>
                                      handleCheckboxChange('spray')
                                    }
                                  />
                                  <label className="chartCheck">살포량</label>

                                  <input
                                    type="checkbox"
                                    id="battery"
                                    checked={batteryChecked}
                                    onChange={() =>
                                      handleCheckboxChange('battery')
                                    }
                                  />
                                  <label className="chartCheck">
                                    배터리 잔량
                                  </label>

                                  <input
                                    type="checkbox"
                                    id="altitude"
                                    checked={altitudeChecked}
                                    onChange={() =>
                                      handleCheckboxChange('altitude')
                                    }
                                  />
                                  <label className="chartCheck">고도</label>
                                </span>
                              </div>
                              <div className="mobile-chart-clostBtn">
                                <i
                                  className="fa-regular fa-xmark div-delete-icon"
                                  onClick={() => {
                                    props.setShowChart(false);
                                    props.setReadyChart(false);
                                  }}
                                ></i>
                              </div>
                            </div>
                          ) : null}
                          {props.readyCSVChart ? (
                            <div className="mobile-chart-top">
                              <div className="mobile-chart-OnOff">
                                <span className="mobile-chart-top-title">
                                  차트 On/Off
                                </span>
                                <span className="log_data">
                                  <input
                                    type="checkbox"
                                    id="altitude"
                                    checked={altitudeChecked}
                                    onChange={() =>
                                      handleCheckboxChange('altitude')
                                    }
                                  />
                                  <label className="chartCheck">고도</label>
                                </span>
                              </div>
                              <div className="mobile-chart-clostBtn">
                                <i
                                  className="fa-regular fa-xmark div-delete-icon"
                                  onClick={() => {
                                    props.setShowChart(false);
                                    props.setReadyCSVChart(false);
                                  }}
                                ></i>
                              </div>
                            </div>
                          ) : null}
                          <div className="mobile-chart-2">
                            <div id="chart_mobile" ref={mobileRef}></div>
                          </div>
                        </div>
                      ) : null}
                      {props.noChart ? (
                        <div className={`mobile-chart-noData drnLog`}>
                          <div>해당되는 차트 데이터가 없습니다.</div>
                          <div className="mobile-chart-clostBtn">
                            <i
                              className="fa-regular fa-xmark div-delete-icon"
                              onClick={() => {
                                props.setNoChart(false);
                              }}
                            ></i>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div className="contents-col droneLog">
                      <div
                        className={`subcontents-info droneLog-summary  
                     active`}
                      >
                        드론 로그 정보 요약
                        <div className="droneLog-summary-content contents-col">
                          {props.curActiveLayer ? (
                            <div className="main-info contents-col">
                              <table className="table_all">
                                <tbody style={{ width: '500px' }}>
                                  <tr className="table_tr">
                                    <td className="table_th">
                                      <div>제목</div>
                                    </td>
                                    <td
                                      className="table_info"
                                      style={{ textAlign: 'left' }}
                                    >
                                      <div>
                                        {props.curActiveLayer.ttl_nm !== ''
                                          ? props.curActiveLayer.ttl_nm
                                          : null}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="table_tr">
                                    <td className="table_th">
                                      <div>작업일</div>
                                    </td>
                                    <td
                                      className="table_info"
                                      style={{ textAlign: 'left' }}
                                    >
                                      <div>
                                        {props.curActiveLayer.job_ymd !== ''
                                          ? props.curActiveLayer.job_ymd
                                          : null}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="table_tr">
                                    <td className="table_th">다운로드</td>
                                    <td
                                      className="table_info"
                                      style={{ textAlign: 'left' }}
                                    >
                                      {props.curActiveLayer &&
                                      props.curActiveLayer.orgnl_file_nm &&
                                      props.isMine ? (
                                        userId ===
                                        props.curActiveLayer.user_id ? (
                                          <div
                                            className="drnLog-download"
                                            onClick={() => {
                                              var folderNm =
                                                props.curActiveLayer.strg_file_nm.split(
                                                  '.'
                                                )[0];
                                              var fileNm =
                                                props.curActiveLayer
                                                  .strg_file_nm;

                                              let extension = fileNm.slice(
                                                ((fileNm.lastIndexOf('.') -
                                                  1) >>>
                                                  0) +
                                                  2
                                              );
                                              if (extension == 'log') {
                                                window.location.href =
                                                  '/download/geojson' +
                                                  '?filebase=' +
                                                  folderNm +
                                                  '&filename=' +
                                                  fileNm +
                                                  '&fileCtgy=' +
                                                  'drnLogFile';
                                              } else {
                                                window.location.href =
                                                  '/api/data/drnLog' +
                                                  '/' +
                                                  folderNm +
                                                  '/' +
                                                  fileNm;
                                              }
                                            }}
                                          >
                                            {props.curActiveLayer.orgnl_file_nm}
                                          </div>
                                        ) : (
                                          <div
                                            onClick={() => {
                                              window.location.href =
                                                '/api/data/drnLog' +
                                                props.curActiveLayer.strg_path;
                                            }}
                                          >
                                            {props.curActiveLayer.orgnl_file_nm}
                                          </div>
                                        )
                                      ) : (
                                        <div>다운로드 권한이 없습니다.</div>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : null}
                        </div>
                        <div
                          className="droneLog-detail"
                          onClick={() => {
                            props.handleActiveDrnLog(2);
                            props.setShowDrnLog(true);
                          }}
                        >
                          상세보기
                        </div>
                        <React.Fragment>
                          <div className="test_1 contents-col a">
                            <div className={`test_2 active`}>
                              <div id="Logchart" ref={ref}></div>
                            </div>
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="subcontents-info hidden"></div>
              )}
            </div>

            {props.curActiveLayer && props.curActiveLayer.layerType === 3 ? (
              <div
                style={{
                  backgroundColor: 'white',
                  width: 'auto',
                  // height: "200px",
                  height: 'auto',
                  fontFamily: 'Noto Sans KR',
                  fontWeight: 700,
                  fontSize: '16px',
                  padding: '10px',
                  borderRadius: '15px',
                  position: 'fixed',
                  bottom: '70px',
                  right: '60px',
                }}
              >
                {farmLegend !== undefined
                  ? Object.keys(farmLegend).map((value) => {
                      return (
                        <div
                          className="contents-row"
                          style={{ textAlign: 'left' }}
                        >
                          <div
                            style={{
                              backgroundColor: farmLegend[value].color,
                              width: '25px',
                              height: '25px',
                              margin: '5px 10px 5px 5px',
                              border: '0.5px solid #BABABA',
                            }}
                          ></div>
                          <div className="text flex-center">
                            {newLegendKey[value]} ( {farmLegend[value].count}
                            {' grid / '}
                            {Number(
                              farmLegend[value].totalArea / 10000
                            ).toFixed(3)}
                            {' ha / '}
                            {(
                              (Number(farmLegend[value].totalArea) /
                                Number(farmLegendCount.allArea)) *
                              100
                            ).toFixed(3)}
                            %)
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : null}

            {visibleThm ? (
              <Legend
                selectItrst={props.selectItrst}
                curActiveLayer={props.curActiveLayer}
                visibleDrn={visibleDrn}
                visibleThm={visibleThm}
                setVisibleDrn={setVisibleDrn}
                setVisibleThm={setVisibleThm}
                showModifyLegend={showModifyLegend}
                setShowModifyLegend={setShowModifyLegend}
                setLoading={props.setLoading}
                activeLayerList={props.activeLayerList}
                activeLayerListHandler={props.activeLayerListHandler}
                delModeHandler={props.delModeHandler}
                setRefreshFlag={props.setRefreshFlag}
                setDummybbox={props.setDummybbox}
                setClickValue={props.setClickValue}
                isMobile={props.isMobile}
                // mobileLegendMenu={props.mobileLegendMenu}
                // setMobileLegendMenu={props.setMobileLegendMenu}
              ></Legend>
            ) : null}

            {showDetail ? (
              <Farmworkdetail
                curActiveLayer={props.curActiveLayer}
                curActiveLayerHandler={props.curActiveLayerHandler}
                showDetail={showDetail}
                setShowDetail={setShowDetail}
                handleShowDetail={handleShowDetail}
                isMobile={props.isMobile}
              ></Farmworkdetail>
            ) : null}

            {loading == true ? (
              <div className="loading-container">
                <div id="loading"></div>
              </div>
            ) : null}
          </div>
        )
      ) : null}

      <Popup
        show={showPopup}
        header="알림"
        icon={<i className="code x icon" onClick={() => setShowPopup(false)} />}
      >
        <div className="modal-info">
          <p>유효하지 않은 식생지수 입니다.</p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
});

export default DroneImage;

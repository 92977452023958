import "./instFarmwork.css";
import React, { useEffect, useState } from "react";

import axios from "axios";

import Modal from "../../shared/components/uiElements/Modal";
import Popup from "../../shared/components/uiElements/Popup";

import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: ${rotate} 1s infinite linear;
`;

function InstFarmwork(props) {
  const APIToken = localStorage.getItem("login-token");

  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState();

  const [failInstData, setFailInstData] = useState();
  useEffect(() => {
    if (props.curActiveLayer !== undefined) {
      if (props.showFarmworkFail) {
        axios({
          method: "get",
          url:
            `/api/instFarmwork/getInstData?` +
            `userId=${props.curActiveLayer.frmwkt_wrkr_id}` +
            `&procId=${props.curActiveLayer.frmwkt_proc_id}`,
          headers: {
            Authorization: `Bearer {${APIToken}}`,
          },
        })
          .then((result) => {
            // console.log("실패 기관 조회 결과", result);
            if (result.data.success) {
              if (result.data.data.frmwkt_stts_cd === "7") {
                props.setLoading(false);
                setFailInstData(result.data.data);
              }
            } else {
              props.setLoading(false);
              setPopupMsg(
                "실패 결과가 조회되지 않습니다.\n 관리자에게 문의 부탁드립니다"
              );
              setShowPopup(true);
            }
          })
          .catch((error) => {
            props.setLoading(false);
            console.log(error);
          });
      }
    }
  }, [props.showFarmworkFail]);

  return (
    <React.Fragment>
      <Modal
        show={props.showFarmworkFail}
        header="농작업 지원 실패"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              props.setShowFarmworkFail(false);
              setFailInstData();
            }}
          />
        }
        className={`instFarmwork`}
      >
        {failInstData ? (
          <div className="farmworkSelect-modal-container">
            <div className="input-group contents-col">
              <div className="title-text">
                <span className="required-marker">*</span>재배관리맵 선택
              </div>
              <input
                className="crop farmwork"
                value={failInstData.theme_map_ttl}
                disabled
              />
            </div>

            <div className="input-group contents-col">
              <div className="title-text">
                <span className="required-marker">*</span>드론 기제선택
              </div>
              <input
                className="crop farmwork"
                value={`${
                  failInstData.drone_model_nm
                    ? failInstData.drone_model_nm
                    : "정보없음"
                } / ${
                  failInstData.drone_mkr_nm
                    ? failInstData.drone_mkr_nm
                    : "정보없음"
                } / ${
                  failInstData.drone_type ? failInstData.drone_type : "정보없음"
                }`}
                disabled
              />
            </div>

            <div className="contents-row">
              <div className="input-group contents-col half-width">
                <div className="title-text">
                  <span className="required-marker">*</span>드론 회전 거리(m)
                </div>
                <div className="contents-row">
                  <input
                    className="crop farmwork full-width"
                    value={failInstData.rot_dist}
                    disabled
                  />
                </div>
              </div>
              <div className="input-group contents-col half-width">
                <div className="title-text">
                  <span className="required-marker">*</span>비행 고도(m)
                </div>
                <div className="contents-row">
                  <input
                    className="crop farmwork full-width"
                    value={failInstData.flgt_alt}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="contents-row">
              <div className="input-group contents-col th-width">
                <div className="title-text">
                  <span className="required-marker">*</span>PWM (하)
                </div>
                <div className="contents-row">
                  <input
                    className="crop farmwork full-width"
                    value={failInstData.pwm_low}
                    disabled
                  />
                </div>
              </div>
              <div className="input-group contents-col th-width">
                <div className="title-text">
                  <span className="required-marker">*</span>PWM (중)
                </div>
                <div className="contents-row">
                  <input
                    className="crop farmwork full-width"
                    value={failInstData.pwm_mid}
                    disabled
                  />
                </div>
              </div>
              <div className="input-group contents-col th-width">
                <div className="title-text">
                  <span className="required-marker">*</span>PWM (상)
                </div>
                <div className="contents-row">
                  <input
                    className="crop farmwork full-width"
                    value={failInstData.pwm_high}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="contents-row">
              <div className="input-group contents-col half-width">
                <div className="title-text">
                  <span className="required-marker">*</span>약제살포단위
                </div>
                <div className="contents-row">
                  <input
                    className="crop farmwork full-width"
                    value={failInstData.spray_unit}
                    disabled
                  />
                </div>
              </div>

              <div className="input-group contents-col half-width">
                <div className="title-text">
                  <span className="required-marker">*</span>측간거리(m)
                </div>
                <input
                  className="crop farmwork"
                  value={failInstData.grid_size}
                  disabled
                />
              </div>
            </div>

            {/* Enter가 안됨 */}
            <div className="contents-row">
              <div className="input-group contents-col full-width">
                <div className="title-text">기타 의견</div>
                <div className="contents-row">
                  <textarea
                    style={{
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                      fontSize: "15px",
                      textAlign: "left",
                      padding: "8px",
                      color: "var(--default-text-color)",
                      border: "1px solid rgba(0, 0, 0, 0.2)",
                      borderRadius: "5px",
                      height: "35px",
                      margin: "0 15px 30px 15px ",
                      minHeight: "100px",
                      lineHeight: "140%",
                      resize: "none",
                    }}
                    className="full-width"
                    value={failInstData.frmwkt_cn}
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // 실패 데이터 가져오는 중(loading 표시)
          <div
            className="farmworkSelect-modal-container"
            style={{
              height: "619px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
            <div style={{ margin: "15px 0 5px 0" }}>처리중입니다.</div>
          </div>
        )}
      </Modal>

      <Popup
        show={showPopup}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowPopup(false);
            }}
          />
        }
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);
              props.setShowFarmworkFail(false);
              setFailInstData();
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}
export default InstFarmwork;

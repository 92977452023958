import './imageReg.css';

import React, { useEffect, useState, useRef } from 'react';

import Modal from '../../shared/components/uiElements/Modal';
import Popup from '../../shared/components/uiElements/Popup';
import questionMarkGuide from '../../config/questionMarkGuide';

import { Checkbox, Pagination, Select, Icon } from 'semantic-ui-react';

import { ko } from 'date-fns/esm/locale';

// AXIOS
import axios from 'axios';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';

import ReactDragListView from 'react-drag-listview';

import MetaDataList from './metaDataList';

import GeoTIFF, { fromBlob } from 'geotiff';
// TURF
import turf from 'turf';

// Proj4
import proj4 from 'proj4';

//config
import config from '../../config/config';
import Label from './components/Label/Label';
import InputField from './components/InputField/InputField';
import FileUpload from './components/FileUpload/FileUpload';

proj4.defs(
  'EPSG:5179',
  '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);
proj4.defs(
  'EPSG:5181',
  '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'
);
proj4.defs(
  'EPSG:5186',
  '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs'
);
proj4.defs('EPSG:4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs');
proj4.defs(
  'EPSG:32652',
  '+proj=utm +zone=52 +ellps=WGS84 +datum=WGS84 +units=m +no_defs '
);

function ImageReg(props) {

  // Authorization
  const APIToken = localStorage.getItem('login-token');
  const userId = localStorage.getItem('user-id');

  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');
  const [showDrnCanPopup, setShowDrnCanPopup] = useState(false);
  const [showDrnModPopup, setShowDrnModPopup] = useState(false);
  const [showDrnDelPopup, setShowDrnDelPopup] = useState(false);

  // MetadataModal
  const [showMeta, setShowMeta] = useState(false);
  const [showMetaDetail, setShowMetaDetail] = useState(false);

  // 드론 메타데이터 업로드
  const [droneMetaUpl, setDroneMetaUpl] = useState('');
  // 드론 메타데이터 기준일자
  const [droneCrtrYmd, setDroneCrtrYmd] = useState(moment().format('YYYY-MM-DD'));
  // 제목
  const [drnImgTitlestate, setDrnImgTitle] = useState('');
  // 작성일
  const [regYMDstate, setRegYMD] = useState(moment().format('YYYY-MM-DD'));
  // 식생지수 생성
  const [ndviGenstate, setNdviGen] = useState([]);
  // 기타내용
  const [etcCnstate, setEtcCn] = useState('');
  // 메타데이터 외부시스템 아이디
  const [metaDataExtIdstate, setMetaDataExtId] = useState('');
  // 메타데이터 내용
  const [metaDataCnstate, setMetaDataCn] = useState('');
  // tif
  const [drnImgstate, setDrnImg] = useState('');
  // tif 영상 밴드 수
  const [numberOfBands, setNumberOfBands] = useState(null);
  // Popup
  const [showAddPopup, setShowAddPopup] = useState(false);
  // 첨부파일 - 파일업로드
  const [tifname, setTifname] = useState('');

  const [delFlag, setDelFlag] = useState(false);
  // 더미
  const [dummyDrnImgArray, setDummyDrnImgArray] = useState();
  // 드론영상 id
  const [drnIdstate, setDrnIdstate] = useState();

  // 원본영상인지 정사영상인지 상태
  const [isImgPrcs, setIsImgPrcs] = useState(false);
  // 파일 여러개 업로드
  const [files, setFiles] = useState([]);
  // 생성 옵션 체크
  const [isCabbDtetOpt, setIsCabbDtetOpt] = useState(false);
  const [isRdtCaliOpt, setIsRdtCaliOpt] = useState(false);

  // 정사영상, 원본영상
  const handleChkValue = (e) => {
    const { name, value } = e.target;
    if (name === 'img_prcs_type') {
      setIsImgPrcs(value === 'True');
    }
  };

  // 메타 상세보기 토글
  const toggleMetaDetail = () => {
    setShowMetaDetail((prevState) => !prevState);
  };

  // 파일 업로드 핸들러
  const handleFilesUpdate = (newFiles) => {
    setFiles(newFiles);
  };

  // 입력 값 체크
  const handleCabbDtetOptChange = (event, data) => {
    setIsCabbDtetOpt(data.checked);
  };
  const handleRdtCaliOptChange = (event, data) => {
    setIsRdtCaliOpt(data.checked);
  };

  const handleDrnImgTitle = (event) => {
    event.preventDefault();
    setDrnImgTitle(event.target.value);
  };

  const handleRegYMD = (event) => {
    event.preventDefault();
    setRegYMD(event.target.value);
  };

  const handleEtcCn = (event) => {
    event.preventDefault();
    setEtcCn(event.target.value);
  };

  const closeMetaHandler = () => {
    setShowMeta(false);
  };

  const openMetaHandler = () => {
    setShowMeta(true);
  };

  function getSrid(geoKey) {
    let srId = null;

    const SRID_TABLE = [
      // ['4166', undefined, undefined, undefined, undefined], // = 4326
      ['2097', 127, 38, 1000000, 500000],
      ['5174', 127.0028902777778, 38, 200000, 500000],
      ['5178', 127.5, 38, 1000000, 2000000],
      ['5178', 127.5, 38, 1000000, 2000000],
      ['5179', 127.5, 38, 1000000, 2000000],
      ['5181', 128, 38, 200000, 500000],
      ['5186', 127, 38, 200000, 600000],
      ['3857', 0.0, 0.0, 0.0, 0], // = 900913, 102113
    ];

    SRID_TABLE.forEach((item) => {
      if (
        item[1] === geoKey.ProjNatOriginLongGeoKey &&
        item[2] === geoKey.ProjNatOriginLatGeoKey &&
        item[3] === geoKey.ProjFalseEastingGeoKey &&
        item[4] === geoKey.ProjFalseNorthingGeoKey
      ) {
        srId = item[0];
        return false;
      }
    });

    if (!srId) {
      SRID_TABLE.forEach((item) => {
        if (Number(item[0]) === geoKey.ProjectedCSTypeGeoKey) {
          srId = item[0];
          return false;
        }
      });
    }

    if (!srId && geoKey.ProjectedCSTypeGeoKey) {
      srId = geoKey.ProjectedCSTypeGeoKey.toString();
    }

    return srId;
  }

  const [drnImgChange, setDrnImgChange] = useState(false); // 첨부파일 변경되었는지 확인을 위한...


  // 대상지역이 관측영상 지역과 일치하는지 확인
  const onTiffChangeHandler = async (file) => {
    if (!file) return false;

    setDrnImgChange(true);

    const tiff = await fromBlob(file);
    const image = await tiff.getImage();
    const bbox = image.getBoundingBox();
    const bands = image.getSamplesPerPixel();

    setNumberOfBands(bands);

    let ret = {
      bbox: bbox,
      srid: getSrid(image.getGeoKeys()),
    };

    let tiffBboxLL = proj4(`EPSG:${ret.srid}`, 'EPSG:4326', [
      Number(ret.bbox[0]),
      Number(ret.bbox[1]),
    ]);
    let tiffBboxUR = proj4(`EPSG:${ret.srid}`, 'EPSG:4326', [
      Number(ret.bbox[2]),
      Number(ret.bbox[3]),
    ]);

    let tiffBbox = [tiffBboxLL[0], tiffBboxLL[1], tiffBboxUR[0], tiffBboxUR[1]];
    let itrstBBox = turf.bbox(JSON.parse(props.selectItrst.geojson));

    let poly1 = turf.polygon([
      [
        [tiffBbox[0], tiffBbox[1]],
        [tiffBbox[0], tiffBbox[3]],
        [tiffBbox[2], tiffBbox[3]],
        [tiffBbox[2], tiffBbox[1]],
        [tiffBbox[0], tiffBbox[1]],
      ],
    ]);

    let poly2 = turf.polygon([
      [
        [itrstBBox[0], itrstBBox[1]],
        [itrstBBox[0], itrstBBox[3]],
        [itrstBBox[2], itrstBBox[3]],
        [itrstBBox[2], itrstBBox[1]],
        [itrstBBox[0], itrstBBox[1]],
      ],
    ]);

    var intersection = turf.intersect(poly1, poly2);

    if (intersection == undefined) {
      setPopupMsg(
        '관측영상의 대상지역이 현재 선택된 관심구역과<br>일치하지 않습니다.'
      );
      setShowAddPopup(true);
      onClearFile();
      const newFiles = [...files];
      newFiles.pop();
      setFiles(newFiles);
      return true;
    } else {
      setTifname(file.name);
      setDrnImg(file);
      return false;
    }
  };

  const onClearFile = () => {
    setDrnImg('');
    setTifname('');
  };

  const onClearMetaFile = () => {
    setDroneMetaUpl('');
    setMetaDataCn('');
    setDroneCrtrYmd(moment().format('YYYY-MM-DD'));
  };

  const onClearSearch = () => {
    setStartDate(null);
    setEndDate(null);
    setModStartDate(null);
    setModEndDate(null);
    setSearchFilters({});
    setActivePage(1);
    setFilteredData(sortMetaDataList);
  };

  const onMetaUplHandler = (e) => {
    const formData = new FormData();
    formData.append('drnMeta', e.target.files[0]);

    axios({
      method: 'post',
      url: `/api/drone/meta/upload`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      data: formData,
    })
      .then((result) => {
        setMetaDataCn(result.data.data);
        setDroneMetaUpl(result.data.file.orgFileNm);
        setDroneCrtrYmd(result.data.data.shoot_start_dt);
        setShowPopup(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function checkTitleDuplicate(title, date) {
    try {
      const response = await axios({
        method: 'get',
        url: `/api/drone/check/name?value=${title}&date=${date}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      });
  
      if (response.data.success) {
        setShowDrnModPopup(false);
        return true; 
      } else {
        setShowDrnModPopup(false);
        setPopupMsg('이미 존재하는 제목입니다');
        setShowAddPopup(true);
        return false; 
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  
  // 드론 영상 등록
  const registerObsImg = async(formData) =>{
    try{
      props.setLoading(true);

      const response = await axios({
        method: 'post',
        url: `/api/drone/img/add`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })

      if (response.data.success) {
        setPopupMsg('관측영상 등록이 완료되었습니다.');
      } else {
        setPopupMsg('관측영상 등록 실패되었습니다. </br> 다시 업로드해 주세요.');
      }
    } catch (error) {
      setPopupMsg('서버와의 통신 중 오류가 발생했습니다.');
    } finally {
      props.setLoading(false);
      setShowAddPopup(true);
    }
  }

  // 드론 영상 수정
  const updateObsImg = async (formModData) => {
    try {
      props.setLoading(true);

      const response = await axios({
        method: 'post',
        url: `/api/drone/img/update`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formModData,
      })

      if (response.data.success) {
        setPopupMsg('관측영상 수정이 완료되었습니다.');
      } else {
        const errorMessage = response.data.msg && Object.keys(response.data.msg).length > 0
          ? response.data.msg.split(':')[1]?.trim()
          : '관측영상 등록 실패';
        setPopupMsg(errorMessage);
      }
    } catch (error) {
      setPopupMsg('서버와의 통신 중 오류가 발생했습니다.');
    } finally{
      props.setLoading(false);
      setShowAddPopup(true);
      setShowDrnModPopup(false);
      setDroneModified(false);
    }
  }

  // 드론 영상 삭제
  const onDroneDelete = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `/api/drone/img/delete`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: { drnId: props.curActiveLayer.drn_img_id },
      });

      if (response.data.success) {
        const { drn_img_id } = response.data.data.drn_img_id;
        props.activeLayerList.forEach((layer, index) => {
          if (layer.layerType === 1 && layer.drn_img_id === drn_img_id) {
            props.layerDel(index);
            props.delModeHandler(index);
            setDelFlag(true);
            props.setClickValue('');
          }
        });
        setPopupMsg('드론 영상이 삭제되었습니다.');
      } else {
        setPopupMsg('드론 영상 삭제에 실패했습니다.');
      }
    } catch (error) {
      setPopupMsg('서버와의 통신 중 오류가 발생했습니다.');
    } finally {
      setShowAddPopup(true);
    }
  };

  
  const validation = async () => {

    if (isImgPrcs) {
      if (droneMetaUpl == '') {
        setPopupMsg('메타데이터를 선택해주세요.');
        return false;
      }

      if (files.length == 1) {
        files.forEach((file) => {
          if (file.extension === 'zip') {
            setPopupMsg('일사량 자료 [csv 파일]이 필요합니다.');
          } else if (file.extension === 'csv') {
            setPopupMsg('원본영상 [zip 파일]이 필요합니다.');
          }
        });
        return false;
      }
    }

    if (sensId_state === '') {
      setPopupMsg('카메라 정보를 입력해주세요.');
      return false;
    }

    if (drnImgTitlestate.length == 0) {
      setPopupMsg('관측영상 제목을 입력해주세요.');
      return false;
    }

    if (files == '' || files.length <= 0) {
      setPopupMsg('첨부 파일을 업로드해주세요.');
      return false;
    }

    if (props.activeImg === 1 || (props.activeImg === 3 && props.curActiveLayer.drn_img_ttl !== drnImgTitlestate)) {
      const isDuplicate = await checkTitleDuplicate(drnImgTitlestate, droneCrtrYmd.slice(0, 10));
      if (!isDuplicate) {
        setPopupMsg('이미 존재하는 제목입니다');
        return false;
      }
    }

    if (props.activeImg === 1 && !isImgPrcs) {
      if (sensId_state == 1000) {
        if (numberOfBands != customBandArray.length) {
          setPopupMsg(
            '입력 밴드 수가 영상과 일치하지 않습니다.<br>' +
              '영상 밴드 수 : ' +
              numberOfBands
          );
          return false;
        }
      } else {
        if (numberOfBands != sensBandInfo.length) {
          setPopupMsg(
            '입력 밴드 수가 영상과 일치하지 않습니다.<br>' +
              '영상 밴드 수 : ' +
              numberOfBands
          );
          return false;
        }
      }
    } else if (props.activeImg === 3) {
      if (sensId_state == 1000) {
        if (
          props.curActiveLayer.sens_preset_id !== sensId_state ||
          !drnImgChange
        ) {
          if (props.curActiveLayer.band_num != customBandArray.length) {
            setPopupMsg(
              '입력 밴드 수가 영상과 일치하지 않습니다.<br>' +
                '영상 밴드 수 : ' +
                numberOfBands
            );
            setShowDrnModPopup(false);
            return false;
          }
        }
      } else {
        if (
          props.curActiveLayer.sens_preset_id !== sensId_state ||
          !drnImgChange
        ) {
          if (props.curActiveLayer.band_num != sensBandInfo.length) {
            setPopupMsg(
              '입력 밴드 수가 영상과 일치하지 않습니다.<br>' +
                '영상 밴드 수 : ' +
                numberOfBands
            );
            setShowDrnModPopup(false);
            return false;
          }
        }
      }
    }
    setShowAddPopup(false);
    return true;
  }

  const classifyFiles = (formData, files) => {
    files.forEach((file) => {
      if (isImgPrcs) {
        if (file.extension === 'zip') {
          formData.append('drnRawImg', file.fileObject);
        } else if (file.extension === 'csv') {
          formData.append('solarFile', file.fileObject);
        }
      } else {
        if (file.extension === 'tif') {
          formData.append('drnImg', file.fileObject);
        }
      }
    });
  };

  // 등록
  const onDroneAdd = async () => {
    
    let isOK = await validation();

    if (isOK) {
      const formData = new FormData();
      classifyFiles(formData, files);
      formData.append('isImgPrcs', isImgPrcs);
      formData.append('zoneId', props.selectItrst.itrst_zone_id);
      formData.append('drnImgTitle', drnImgTitlestate);
      formData.append('regYMD', droneCrtrYmd);
      formData.append('ndviGen', ndviGenstate !== null ? ndviGenstate : '');
      formData.append('sensPresetId', sensId_state);
      formData.append('etcCn', etcCnstate);
      formData.append('metaDataExtId', metaDataExtIdstate);
      formData.append('metaDataCn', JSON.stringify(metaDataCnstate));
      if (sensId_state == 1000) {
        formData.append('customBandInfo', JSON.stringify(customBandArray));
        formData.append(
          'cstBandNNameInfo',
          JSON.stringify(customBandNameArray)
        );
      }
      if (isImgPrcs) {
        formData.append('obsId', metaDataCnstate.obs_id);
        formData.append('isCabbDtetOpt', isCabbDtetOpt);
        formData.append('isRdtCaliOpt', isRdtCaliOpt);
        formData.append('geojson', props.selectItrst.geojson);
      }
      registerObsImg(formData);
    }else{
      setShowAddPopup(true);
    };
  };

  const [selectedData, setSelectedData] = useState({}); // 메타데이터
  // 수정 SET
  useEffect(() => {
    if (props.activeImg === 2 || props.activeImg === 3) {
      // 생성 영상 옵션 체크
      setIsImgPrcs(props.curActiveLayer.drn_orign_img_yn == 'Y' ? true : false);
      // 배추 탐지 옵션 체크
      setIsCabbDtetOpt(props.curActiveLayer.cabb_dtet_yn == 'Y' ? true : false);
      // 복사 보정 옵션 체크
      setIsRdtCaliOpt(props.curActiveLayer.rdt_cali_yn == 'Y' ? true : false);
      // 관측영상 제목
      setDrnImgTitle(props.curActiveLayer.drn_img_ttl);
      // 관측영상 내용
      setEtcCn(props.curActiveLayer.etc_cn || '');
      // 관측영상 카메라 아이디
      // setSelectCamera(props.curActiveLayer.sens_preset_id);
      setSensId(props.curActiveLayer.sens_preset_id);
      // 관측영상 식생지수
      setNdviGen(props.curActiveLayer.ndvi_gen);
      // 메타데이터 CN
      setMetaDataCn(props.curActiveLayer.meta_data_cn);
      // 관측영상
      setDrnImg(props.curActiveLayer.drn_img_file_path);
      // 메타데이터 제목
      // setDroneMetaUpl(metaData.uid);
      setDroneMetaUpl(JSON.parse(props.curActiveLayer.meta_data_cn).uid);
      // 관측영상 첨부파일 이름
      setTifname(props.curActiveLayer.orgnl_file_nm);
      // 관측영상 밴드정보
      setSensBandInfo(
        props.curActiveLayer.sens_band_info
          .replace(/[\[\]"'\s]/g, '')
          .split(',')
      );
      // 관측영상 아이디
      setDrnIdstate(props.curActiveLayer.drn_img_id);
      // 기준일자
      setDroneCrtrYmd(props.curActiveLayer.reg_ymd);
    }
  }, [props.activeImg, selectedData]);

  // 수정을 취소하시겠습니까?
  function drnImgModNo() {
    setIsImgPrcs(dummyDrnImgArray.isImgPrcs);
    setIsCabbDtetOpt(dummyDrnImgArray.isCabbDtetOpt);
    setIsRdtCaliOpt(dummyDrnImgArray.isRdtCaliOpt);
    setDrnIdstate(dummyDrnImgArray.drnId);
    setDrnImgTitle(dummyDrnImgArray.drnImgTitle);
    setRegYMD(dummyDrnImgArray.regYMD);
    setNdviGen(dummyDrnImgArray.ndviGen);
    setEtcCn(dummyDrnImgArray.etcCn);
    setMetaDataExtId(dummyDrnImgArray.metaDataExtId);
    setMetaDataCn(dummyDrnImgArray.metaDataCn);
    setDrnImg(dummyDrnImgArray.drnImg);
    setSensId(dummyDrnImgArray.sensPresetId);
    setDroneModified(false);
    setShowDrnCanPopup(false);
    props.handleActiveImg(2);
  }

  // 수정사항을 저장하시겠습니까?
  const drnImgModYes = async () => {
    let isOK = await validation();

    if (isOK) {
      const formModData = new FormData();
      classifyFiles(formModData, files);
      formModData.append('isImgPrcs', isImgPrcs);
      formModData.append('drnId', props.curActiveLayer.drn_img_id);
      formModData.append('drnImgTitle', drnImgTitlestate);
      formModData.append('regYMD', droneCrtrYmd);
      formModData.append('ndviGen', ndviGenstate !== null ? ndviGenstate : '');
      formModData.append('sensPresetId', sensId_state);
      formModData.append('etcCn', etcCnstate);
      formModData.append('metaDataExtId', metaDataExtIdstate);
      if (metaDataCnstate === props.curActiveLayer.meta_data_cn) {
        formModData.append('metaDataCn', metaDataCnstate);
      } else {
        formModData.append('metaDataCn', JSON.stringify(metaDataCnstate));
      }
      if (sensId_state == 1000) {
        formModData.append('customBandInfo', JSON.stringify(customBandArray));
        formModData.append(
          'cstBandNNameInfo',
          JSON.stringify(customBandNameArray)
        );
      }
      if (isImgPrcs) {
        formModData.append('obsId', metaDataCnstate.obs_id);
        formModData.append('isCabbDtetOpt', isCabbDtetOpt);
        formModData.append('isRdtCaliOpt', isRdtCaliOpt);
        formModData.append('geojson',
          JSON.stringify(props.selectItrst.geojson)
        );
      }
      updateObsImg(formModData);
    }else{
      setShowAddPopup(true);
    };
  }

  const [droneModified, setDroneModified] = useState(false);

  function onModDrnHandler() {
    props.handleActiveImg(3);
    if (droneModified) {
      setShowDrnCanPopup(true);
    } else {
      setDroneModified(true);
      setDummyDrnImgArray({
        isImgPrcs: props.curActiveLayer.drn_orign_img_yn,
        isCabbDtetOpt: props.curActiveLayer.cabb_dtet_yn,
        isRdtCaliOpt: props.curActiveLayer.rdt_cali_yn,
        drnId: props.curActiveLayer.drn_img_id,
        drnImgTitle: drnImgTitlestate,
        regYMD: regYMDstate,
        ndviGen: ndviGenstate,
        etcCn: etcCnstate,
        metaDataExtId: metaDataExtIdstate,
        metaDataCn: JSON.stringify(metaDataCnstate),
        drnImg: drnImgstate,
        sensPresetId: sensId_state,
        files: files,
      });
    }
  }

  function onSaveDrnHandler() {
    if (droneModified) {
      setShowDrnModPopup(true);
    } else {
      props.handleActiveImg(1);
      props.setShowImageReg(false);
    }
  }

  const handleCamera = (e, { value }) => {
    e.preventDefault();
    setSensId(value);
  };

  const [camera_ArrList, setCamera_ArrList] = useState([]);
  const [sensId_state, setSensId] = useState('');
  const [sensBandInfo, setSensBandInfo] = useState();

  const bansdIfoLabels = [
    'B1',
    'B2',
    'B3',
    'B4',
    'B5',
    'B6',
    'B7',
    'B8',
    'B9',
    'B10',
  ];

  useEffect(() => {
    for (let i = 0; i < camera_ArrList.length; i++) {
      if (camera_ArrList[i].value === sensId_state) {
        setSensId(camera_ArrList[i].value);
        setSensBandInfo(
          camera_ArrList[i].bandinfo.replace(/[\[\]"'\s]/g, '').split(',')
        );
        break;
      }
    }
  }, [sensId_state]);

  const fileMetaRef = useRef(null);

  const handleMetaClick = () => {
    fileMetaRef.current.click();
  };

  // 촬영 일자 가져오기
  useEffect(() => {
    if (droneMetaUpl === '') {
      const currentDate = moment().format('YYYY-MM-DD');
      const metaDataObject = {
        shoot_start_dt: currentDate,
      };
      setMetaDataCn(metaDataObject);
    }
  }, [droneMetaUpl]);

  // 카메라 센서 정보 가져오기
  const [cameraList, setCameraList] = useState(null);
  useEffect(() => {
    axios({
      method: 'get',
      url: `/api/drone/sens`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        setCameraList(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // 카메라 리스트 가져오기
  useEffect(() => {
    let camera_Arr = [];
    if (cameraList !== null && cameraList) {
      for (let i = 0; i < cameraList.length; i++) {
        camera_Arr.push({
          key: i,
          text: cameraList[i].sens_preset_nm,
          value: cameraList[i].sens_preset_id,
          bandinfo: cameraList[i].sens_band_info,
        });
      }
    }
    setCamera_ArrList(camera_Arr);
  }, [cameraList]);

  //  관측영상 조회 카메라 정보
  const [foundSensNm, setFoundSensNm] = useState('');
  useEffect(() => {
    if (props.activeImg === 2) {
      const foundSensInfo =
        cameraList &&
        cameraList.find(
          (item) => item.sens_preset_id === props.curActiveLayer.sens_preset_id
        );
      if (foundSensInfo) {
        setFoundSensNm(foundSensInfo.sens_preset_nm);
      }
    }
  }, [cameraList]);

  // 메타데이터 불러오기
  const [metaDataList, setmetaDataList] = useState([]);
  useEffect(() => {
    axios({
      method: 'get',
      url: 'ex-api/' + config.metaSystemAPI + '/api/obs/',
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      params: { imgPrcs: isImgPrcs },
    })
      .then((result) => {
        if (result.data.success) {
          setmetaDataList(result.data.data);
        } else {
          setPopupMsg('메타데이터를 불러오지 못하였습니다.');
          setShowAddPopup(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [isImgPrcs]);

  // 드론 영상 메타데이터 체크박스
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemSelect = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((item) => item !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  // 검색 필터링
  const [searchFilters, setSearchFilters] = useState({
    shoot_start_dt: '', // 시작날짜
    shoot_end_dt: '', // 끝 날짜
    shoot_place: null, // 장소
    shoot_trgt: null, // 촬영대상
    last_chg_dt: '', // 최종수정일
  });

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setFilteredData(sortMetaDataList);
  }, [metaDataList]);

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayedData, setDisplayedData] = useState([]);
  const pageSize = 5;
  // 촬영일
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // 최종수정일
  const [modStartDate, setModStartDate] = useState(null);
  const [modEndDate, setModEndDate] = useState(null);

  const handleSearch = () => {
    const filtered = sortMetaDataList.filter((data) => {
      const { shoot_place, shoot_trgt } = searchFilters;
      const dataStartDate = moment(data.shoot_start_dt);
      const dataEndDate = moment(data.shoot_start_dt);
      const dataModStartDate = moment(data.last_chg_dt);
      const dataModEndDate = moment(data.last_chg_dt);
      return (
        (!shoot_place || data.shoot_place.includes(shoot_place)) &&
        (!shoot_trgt || data.shoot_trgt.includes(shoot_trgt)) &&
        (!startDate || dataStartDate.isSameOrAfter(startDate)) &&
        (!endDate || dataEndDate.isSameOrBefore(endDate)) &&
        (!modStartDate || dataModStartDate.isSameOrAfter(modStartDate)) &&
        (!modEndDate || dataModEndDate.isSameOrBefore(modEndDate))
      );
    });

    setFilteredData(filtered);
    updateDisplayedData(filtered, 1);
  };

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
    updateDisplayedData(filteredData, activePage);
  };

  const updateDisplayedData = (data, page) => {
    const startIdx = (page - 1) * pageSize;
    const endIdx = startIdx + pageSize;
    const displayed = data.slice(startIdx, endIdx);
    setDisplayedData(displayed);
  };

  useEffect(() => {
    // 필터링된 데이터 페이지네이션 처리
    const dataPerPage = 5; // 한 페이지에 표시할 데이터 수
    const startIndex = (activePage - 1) * dataPerPage;
    const endIndex = startIndex + dataPerPage;
    const displayed = filteredData.slice(startIndex, endIndex);

    setDisplayedData(displayed);
    setTotalPages(Math.ceil(filteredData.length / dataPerPage));
  }, [filteredData, activePage]);

  const sortMetaDataList = metaDataList.sort((a, b) => b.obs_id - a.obs_id);

  // 선택완료
  const closeMetaClickHandler = () => {
    setShowMeta(false);
    setShowPopup(false);
    setMetaDataCn(selectedData);
    setDroneMetaUpl(selectedData.uid);
    setDroneCrtrYmd(selectedData.shoot_start_dt);
  };

  // 식생지수
  let arr = [];
  const handleCheck = (e, data) => {
    if (data && data.checked === true) {
      const newdata = data.value;
      setNdviGen([...ndviGenstate, newdata]);
    }
  };

  const handleCheckboxChange = (checkboxName, values, checked) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }));

    if (!Array.isArray(ndviGenstate)) {
      setNdviGen([]);
    } else {
      if (ndviGenstate.includes(checkboxName)) {
        setNdviGen(ndviGenstate.filter((item) => item !== checkboxName));
      } else {
        setNdviGen([...ndviGenstate, checkboxName]);
      }
    }
  };

  useEffect(() => {
    if (props.activeImg !== 2) {
      setCheckboxStates({
        NDVI: false,
        NDRE: false,
        GNDVI: false,
        RVI: false,
        GRVI: false,
      });
    }
    setCustomBandArray([]);
    setCustomBandNameArray([]);
    setNdviGen([]);
  }, [sensId_state]);

  useEffect(() => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      NDVI:
        props.activeImg === 2 || props.activeImg === 3
          ? props.curActiveLayer.ndvi_gen &&
            props.curActiveLayer.ndvi_gen.includes('NDVI')
          : prevState.NDVI,
      NDRE:
        props.activeImg === 2 || props.activeImg === 3
          ? props.curActiveLayer.ndvi_gen &&
            props.curActiveLayer.ndvi_gen.includes('NDRE')
          : prevState.NDRE,
      GNDVI:
        props.activeImg === 2 || props.activeImg === 3
          ? props.curActiveLayer.ndvi_gen &&
            props.curActiveLayer.ndvi_gen.includes('GNDVI')
          : prevState.GNDVI,
      RVI:
        props.activeImg === 2 || props.activeImg === 3
          ? props.curActiveLayer.ndvi_gen &&
            props.curActiveLayer.ndvi_gen.includes('RVI')
          : prevState.RVI,
      GRVI:
        props.activeImg === 2 || props.activeImg === 3
          ? props.curActiveLayer.ndvi_gen &&
            props.curActiveLayer.ndvi_gen.includes('GRVI')
          : prevState.GRVI,
    }));
    setCustomBandArray([]);
    setCustomBandNameArray([]);
  }, [props.activeImg, props.curActiveLayer]);

  // 메타데이터 행 클릭
  const [selectedRow, setSelectedRow] = useState(null);
  const handleRowClick = (index) => {
    setSelectedRow(index);
    setSelectedData(displayedData[index]);
  };

  const renderInqBands = () => {
    let inqSensBands = props.curActiveLayer.sens_band_info;
    if (inqSensBands && inqSensBands.length > 0) {
      if (props.curActiveLayer.sens_preset_id == 1000) {
        let inqSensBands_Name = props.curActiveLayer.band_nnm_info;

        let formattedStr = inqSensBands.replace(/[\[\]"'\s]/g, '');
        let inqSensBandsArr = formattedStr.split(',');
        let formattedStrName = inqSensBands_Name.replace(/[\[\]"'\s]/g, '');
        let inqSensBandsArrName = formattedStrName.split(',');
        return (
          inqSensBandsArr &&
          inqSensBandsArr.map((data, index) => {
            return (
              <div
                className={
                  'contents-row field group active band-item-container flex-center'
                }
                key={index}
              >
                <label className="band-number flex-center">
                  {bansdIfoLabels[index]}
                </label>
                <div className="band-info flex-center">
                  {/* {inqSensBandsArrName[index]} */}
                  {data}
                </div>
              </div>
            );
          })
        );
      } else {
        let formattedStr = inqSensBands.replace(/[\[\]"'\s]/g, '');
        let inqSensBandsArr = formattedStr.split(',');
        return (
          inqSensBandsArr &&
          inqSensBandsArr.map((data, index) => {
            return (
              <div
                className={
                  'contents-row field group active band-item-container flex-center'
                }
                key={index}
              >
                <label className="band-number flex-center">
                  {bansdIfoLabels[index]}
                </label>
                <div className="band-info flex-center">{data}</div>
              </div>
            );
          })
        );
      }
    }
  };

  const options = [
    { key: 1, value: 'R', text: 'Red' },
    { key: 2, value: 'G', text: 'Green' },
    { key: 3, value: 'B', text: 'Blue' },
    { key: 4, value: 'NIR', text: 'Near-infrared' },
    { key: 5, value: 'Rededge', text: 'Red-edge' },
    { key: 6, value: 'Alpha', text: 'Alpha' },
    { key: 7, value: 'Panchromatic', text: 'Panchromatic' },
    { key: 8, value: 'Thermal', text: 'Thermal' },
  ];

  const [customBandArray, setCustomBandArray] = useState([]);
  const [customBandNameArray, setCustomBandNameArray] = useState([]);

  const handleCustomBand = (e, data) => {
    if (data.value) {
      const dummyArray = [...customBandArray];
      if (dummyArray.length == 0) {
        dummyArray.push(data.value);
        setCustomBandArray(dummyArray);
        setSensBandInfo(dummyArray);
      } else if (dummyArray.length > 0) {
        let index = -1;
        dummyArray.map((e, i) => {
          if (e == data.value) {
            index = i;
          }
        });
        if (index == -1) {
          dummyArray.push(data.value);
          setCustomBandArray(dummyArray);
          setSensBandInfo(dummyArray);
        }
      }
    }
  };

  const handleCustomBandName = (e) => {
    const dummyArray = [...customBandNameArray];
    dummyArray[e.target.name] = e.target.value;
    setCustomBandNameArray(dummyArray);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      // handleDragEnd
      const bandInfo = [...customBandArray];
      const item = bandInfo.splice(fromIndex, 1)[0];
      bandInfo.splice(toIndex, 0, item);
      setCustomBandArray(bandInfo);

      const bandNameInfo = [...customBandNameArray];
      const itemName = bandNameInfo.splice(fromIndex, 1)[0];
      bandNameInfo.splice(toIndex, 0, itemName);
      setCustomBandNameArray(bandNameInfo);
    },
    nodeSelector: 'li',
    handleSelector: 'li',
  };

  const brCount = (popupMsg.match(/<br>/g) || []).length;
  const paddingValue =
    brCount === 0 ? '35px 0' : brCount === 1 ? '25px 0' : '15px 0';
  const fontSizeValue = brCount === 0 ? '18px' : '16px';

  const [checkboxStates, setCheckboxStates] = useState({
    NDVI: false,
    NDRE: false,
    GNDVI: false,
    RVI: false,
    GRVI: false,
  });

  // 모바일
  const [mobileActiveDrnImg, setMobileActiveDrnImg] = useState(1);
  const [isActive, setIsActive] = useState(true);

  // 물음표 가이드
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <React.Fragment>
      <Modal
        show={props.showImageReg}
        // onCancel={()=>{props.setShowImageReg(false)}}
        header={
          props.activeImg === 2
            ? '관측영상 조회'
            : props.activeImg === 3
            ? '관측영상 수정'
            : '관측영상 등록'
        }
        icon={
          <i
            className="code x icon"
            onClick={() => {
              props.setShowImageReg(false);
            }}
          />
        }
        isMobile={props.isMobile}
        className={`imageReg ${props.isMobile ? 'mobile' : ''}`}
      >
        <div className="contents-row main-container">
          {props.isMobile ? (
            <div className="contents-row mobile-container">
              {/* <div className='drn-img info' onClick={() => {setMobileActiveDrnImg(1)}}>기본 정보</div> */}
              <div
                className={`drn-img info ${
                  mobileActiveDrnImg == 1 ? 'active' : ''
                }`}
                onClick={() => {
                  setMobileActiveDrnImg(1);
                  if (
                    props.curActiveLayer.meta_data_cn != 'undefined' &&
                    props.curActiveLayer.meta_data_cn != ''
                  ) {
                    setShowMetaDetail(false);
                  }
                }}
              >
                기본 정보
              </div>
              <div
                className={`drn-img meta ${
                  mobileActiveDrnImg == 2 ? 'active' : ''
                }`}
                onClick={() => {
                  setMobileActiveDrnImg(2);
                  if (
                    props.curActiveLayer.meta_data_cn != 'undefined' &&
                    props.curActiveLayer.meta_data_cn != ''
                  ) {
                    setShowMetaDetail(true);
                  }
                }}
              >
                메타 데이터
              </div>
            </div>
          ) : null}

          {mobileActiveDrnImg === 1 ? (
            <div
              className="drnImgList-container"
              style={
                showMetaDetail == true && props.isTablet
                  ? { display: 'none' }
                  : {}
              }
            >
              <div
                className={`contents-row field group position-btn ${
                  props.activeImg === 2 ? 'hidden' : ''
                }`}
                style={{ display: props.activeImg == 1 ? '' : 'none' }}
              >
                <div className="tab-container">
                  <div className="tabs">
                    <button
                      className={`tab ${!isImgPrcs ? 'active' : ''}`}
                      onClick={() =>
                        handleChkValue({
                          target: { name: 'img_prcs_type', value: 'False' },
                        })
                      }
                    >
                      정사영상
                    </button>
                    <button
                      className={`tab ${isImgPrcs ? 'active' : ''}`}
                      onClick={() =>
                        handleChkValue({
                          target: { name: 'img_prcs_type', value: 'True' },
                        })
                      }
                    >
                      원본영상
                    </button>
                  </div>
                </div>
              </div>
              <div className="contents-col image-reg-form input-form flex-center dron-img">
                <div className="contents-row image-reg">
                  <div className="contents-col inside">
                    <div className="input-field-group">
                      <Label
                        required={isImgPrcs}
                        active={props.activeImg}
                        label={'메타데이터'}
                        explan={
                          props.activeImg === 2 ? 
                          "고유식별자 클릭 시 메타데이터가 표시됩니다." : 
                          "메타 데이터 선택 시 촬영 시작 일자 정보가 바뀝니다."
                        }
                      />
                      <InputField
                        onClick={() => {
                          if (
                            !props.isMobile &&
                            props.curActiveLayer.meta_data_cn != 'undefined' &&
                            props.curActiveLayer.meta_data_cn != ''
                          ) {
                            setShowMetaDetail(!showMetaDetail);
                          }
                        }}
                        active={props.activeImg}
                        readOnly={true}
                        text={
                          props.curActiveLayer?.meta_data_cn
                            ? JSON.parse(props.curActiveLayer?.meta_data_cn).uid
                            : '메타데이터 정보 없음'
                        }
                        data={droneMetaUpl}
                        onClickhandler={openMetaHandler}
                        placeholder= "메타데이터를 선택해 주세요."
                      >
                        {droneMetaUpl && (
                          <a title="메타데이터 삭제">
                            <div
                              className="icon-area"
                              onClick={onClearMetaFile}
                            >
                              <i className="x icon" />
                            </div>
                          </a>
                        )}
                      </InputField>
                    </div>
                    <div className="contents-row image-reg inside zero">
                      <div className="input-field-group">
                        <Label
                          required={true}
                          label={'카메라'}
                          active={props.activeImg}
                        />
                        {props.activeImg === 2 ? (
                          <>
                            <div
                              className="input-img camera"
                            >
                              {foundSensNm ? foundSensNm : null}
                            </div>
                            <div
                              className="contents-row up-douwn-box"
                              style={props.isMobile ? { display: 'none' } : {}}
                            >
                              <div
                                className={`up-douwn ${
                                  isActive || props.isMobile ? 'rotate-90' : ''
                                }`}
                                style={
                                  props.isMobile ? { display: 'none' } : {}
                                }
                              ></div>
                            </div>
                          </>
                        ) : (
                          <Select
                            options={camera_ArrList}
                            className="input-img camera"
                            placeholder="카메라 선택"
                            id="camera"
                            name="camera"
                            onChange={handleCamera}
                            value={sensId_state}
                          />
                        )}
                      </div>
                      <div
                        className="input-field-group"
                        style={
                          props.isMobile
                            ? {}
                            : {
                                paddingLeft: '20px',
                                marginTop:
                                  props.activeImg === 2 ? undefined : '13px',
                              }
                        }
                      >
                        <Label
                          required={false}
                          label={'촬영 시작 일자'}
                          active={props.activeImg}
                        />
                        <InputField
                          active={props.activeImg}
                          label="촬영 시작 일자"
                          text={props.curActiveLayer?.reg_ymd.substr('', 10)}
                          onChangehandler={handleRegYMD}
                          data={droneCrtrYmd ? droneCrtrYmd.slice(0, 10) : ''}
                          placeholder=""
                          children={
                            <i
                              className="calendar outline icon"
                              style={{ fontSize: '15px' }}
                            ></i>
                          }
                        />
                      </div>
                    </div>
                    <div className="input-field-group">
                      <Label
                        required={true}
                        label={'제목'}
                        active={props.activeImg}
                      />
                      <InputField
                        active={props.activeImg}
                        text={props.curActiveLayer?.drn_img_ttl}
                        onChangehandler={handleDrnImgTitle}
                        data={drnImgTitlestate}
                        placeholder="제목을 입력해 주세요."
                      />
                    </div>
                  </div>
                  <div
                    className={`contents-row field ${
                      props.isMobile ? 'group' : ''
                    } position-btn`}
                  >
                    <div
                      className="input-field-group"
                      style={props.isMobile ? {} : { paddingLeft: '15px' }}
                    >
                      <Label
                        required={false}
                        label={'식생지수'}
                        active={props.activeImg}
                        children={
                          props.activeImg != 2 ? (
                            <i
                              className={`fa-solid fa-circle-question ${
                                isHovered ? 'hover' : ''
                              }`}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              <span
                                className={`tooltip ${
                                  isHovered ? 'hover' : ''
                                }`}
                                dangerouslySetInnerHTML={{
                                  __html: questionMarkGuide.ndvigen,
                                }}
                              />
                            </i>
                          ) : null
                        }
                      />
                      <div className="contents-row input-img-gray input-img choose scrollable">
                        {/* {!props.isMobile ? ( */}
                        <React.Fragment>
                          <div className="contents-row">
                            <Checkbox
                              label="NDVI"
                              type="checkbox"
                              name="gen"
                              disabled={
                                !['NIR', 'R'].every(
                                  (value) =>
                                    sensBandInfo && sensBandInfo.includes(value)
                                )
                              }
                              checked={checkboxStates.NDVI}
                              onChange={(e, { checked }) =>
                                handleCheckboxChange('NDVI', ['NIR', 'R'])
                              }
                              readOnly={props.activeImg === 2}
                            />
                          </div>
                          <div className="contents-row">
                            <Checkbox
                              label="NDRE"
                              type="checkbox"
                              name="gen"
                              disabled={
                                !['NIR', 'Rededge'].every(
                                  (value) =>
                                    sensBandInfo && sensBandInfo.includes(value)
                                )
                              }
                              checked={checkboxStates.NDRE}
                              onChange={(e, { checked }) =>
                                handleCheckboxChange('NDRE', ['NIR', 'Rededge'])
                              }
                              readOnly={props.activeImg === 2}
                            />
                          </div>
                          <div className="contents-row">
                            <Checkbox
                              label="GNDVI"
                              type="checkbox"
                              name="gen"
                              disabled={
                                !['NIR', 'G'].every(
                                  (value) =>
                                    sensBandInfo && sensBandInfo.includes(value)
                                )
                              }
                              checked={checkboxStates.GNDVI}
                              onChange={(e, { checked }) =>
                                handleCheckboxChange('GNDVI', ['NIR', 'G'])
                              }
                              readOnly={props.activeImg === 2}
                            />
                          </div>
                          <div className="contents-row">
                            <Checkbox
                              label="RVI"
                              type="checkbox"
                              name="gen"
                              disabled={
                                !['NIR', 'R'].every(
                                  (value) =>
                                    sensBandInfo && sensBandInfo.includes(value)
                                )
                              }
                              checked={checkboxStates.RVI}
                              onChange={(e, { checked }) =>
                                handleCheckboxChange('RVI', ['NIR', 'R'])
                              }
                              readOnly={props.activeImg === 2}
                            />
                          </div>
                          <div className="contents-row">
                            <Checkbox
                              label="GRVI"
                              type="checkbox"
                              name="gen"
                              disabled={
                                !['NIR', 'G'].every(
                                  (value) =>
                                    sensBandInfo && sensBandInfo.includes(value)
                                )
                              }
                              checked={checkboxStates.GRVI}
                              onChange={(e, { checked }) =>
                                handleCheckboxChange('GRVI', ['NIR', 'G'])
                              }
                              readOnly={props.activeImg === 2}
                            />
                          </div>
                        </React.Fragment>
                        {/* ) : (
                            <React.Fragment>
                              {props.curActiveLayer.ndvi_gen &&
                                props.curActiveLayer.ndvi_gen.map((item, index) => (
                                  <div className="gc-btn ndvi-item" key={index}>
                                    {item}
                                  </div>
                                ))}
                            </React.Fragment>
                          )} */}
                      </div>
                    </div>
                    <div
                      className={`contents-col band ${
                        props.activeImg !== 2 ? 'input-field-group' : ''
                      }`}
                      style={props.isMobile ? {} : {}}
                    >
                      <Label
                        required={true}
                        label={'밴드명'}
                        active={props.activeImg}
                      />
                      <div
                        className={`band-area ${
                          props.activeImg == 2 &&
                          props.curActiveLayer.sens_band_info.length > 5
                            ? 'scrollable'
                            : ''
                        }`}
                      >
                        {props.activeImg === 2 ? (
                          !props.isMobile ? (
                            renderInqBands()
                          ) : isActive ? (
                            renderInqBands()
                          ) : null
                        ) : sensId_state == 1000 ? (
                          <div className="custom-band-container contents-col">
                            <div className="select-container contents-row flex-center">
                              <Select
                                options={options}
                                className="flex-center"
                                // value={selBand}
                                onChange={handleCustomBand}
                                placeholder="밴드 선택"
                              ></Select>
                            </div>
                            <div className="result-container contents-col">
                              <ReactDragListView {...dragProps}>
                                <ol>
                                  {customBandArray &&
                                    customBandArray.map((item, index) => {
                                      if (index < 10) {
                                        return (
                                          <li
                                            className="contents-row"
                                            key={index}
                                          >
                                            <div className="contents-row field group flex-center band-item-container">
                                              <label className="band-number flex-center">
                                                {bansdIfoLabels[index]}
                                              </label>
                                              <input
                                                name={index}
                                                className="band-name"
                                                value={item}
                                                onChange={handleCustomBandName}
                                              ></input>
                                              {/* <div className="band-info flex-center">
                                                ({item == "Rededge" ? "RE" : item}
                                                )
                                              </div> */}
                                              <i
                                                className="minus icon"
                                                onClick={() => {
                                                  const dummyArr = [
                                                    ...customBandArray,
                                                  ];
                                                  dummyArr.splice(index, 1);
                                                  setCustomBandArray(dummyArr);
                                                }}
                                              />
                                            </div>
                                          </li>
                                        );
                                      }
                                    })}
                                </ol>
                              </ReactDragListView>
                            </div>
                          </div>
                        ) : (
                          sensBandInfo &&
                          sensBandInfo.map((item, index) => {
                            return (
                              <li
                                className="contents-row field group flex-center band-item-container"
                                key={index}
                              >
                                <label className="band-number flex-center">
                                  {bansdIfoLabels[index]}
                                </label>
                                <div className="band-info flex-center">
                                  {item}
                                </div>
                              </li>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-field-group">
                  <Label
                    active={props.activeImg}
                    required={false}
                    label={'내용'}
                    explan={props.activeImg !== 2 ? '최대 500자까지 입력 가능합니다.': null}
                  />
                  <div className="textarea-container">
                    <textarea
                      type="textarea"
                      className="form-control textarea"
                      onChange={handleEtcCn}
                      disabled={props.activeImg === 2}
                      value={
                        props.activeImg === 2
                          ? props.curActiveLayer.etc_cn !== ''
                            ? props.curActiveLayer.etc_cn ||
                              '입력하지 않은 데이터 입니다.'
                            : ''
                          : etcCnstate || ''
                      }
                      placeholder="내용을 입력해 주세요."
                      spellCheck="false"
                      maxLength={500}
                    />
                    {props.activeImg !== 2 && (
                      <span className="char-count">
                        <span className="char-blue-color">
                          {etcCnstate.length}
                        </span>
                        / 500
                      </span>
                    )}
                  </div>
                </div>
                {isImgPrcs && (
                  <div className="input-field-group">
                    <Label active={props.activeImg} label={'생성 옵션'} />
                    <div className="add-option-area">
                      <div className="contents-row check-opt">
                        <Checkbox
                          label="배추 탐지"
                          type="checkbox"
                          name="option"
                          checked={
                            props.activeImg === 2
                              ? props.curActiveLayer.cabb_dtet_yn == 'Y'
                                ? true
                                : false
                              : isCabbDtetOpt
                          }
                          onChange={handleCabbDtetOptChange}
                          readOnly={props.activeImg === 2}
                        />
                      </div>
                      <div className="contents-row check-opt">
                        <Checkbox
                          label="복사 보정"
                          type="checkbox"
                          name="option"
                          checked={
                            props.activeImg === 2
                              ? props.curActiveLayer.rdt_cali_yn == 'Y'
                                ? true
                                : false
                              : isRdtCaliOpt
                          }
                          onChange={handleRdtCaliOptChange}
                          readOnly={props.activeImg === 2}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="input-field-group">
                  <Label
                    active={props.activeImg}
                    required={true}
                    label={'첨부파일'}
                    explan={
                      props.activeImg !== 2
                        ? isImgPrcs
                          ? '원본영상 [zip파일] 과 일사량 자료 [csv파일] 2개의 파일이 필요합니다.'
                          : '관심 구역과 일치하는 관측영상 [tif파일] 총 1개의 파일이 필요합니다.'
                        : null
                    }
                  />
                  <FileUpload
                    isImgPrcs={isImgPrcs}
                    active={props.activeImg}
                    setShowAddPopup={setShowAddPopup}
                    setPopupMsg={setPopupMsg}
                    files={files}
                    handleFilesUpdate={handleFilesUpdate}
                    onTiffChangeHandler={onTiffChangeHandler}
                    curActiveLayer={
                      props.curActiveLayer ? props.curActiveLayer : null
                    }
                  />
                </div>
                <Popup
                  show={showPopup}
                  onCancel={() => setShowPopup(false)}
                  header="알림"
                  icon={
                    <i
                      className="code x icon"
                      onClick={() => setShowPopup(false)}
                    />
                  }
                >
                  <div className="modal-info">
                    <p style={{ padding: '25px 0' }}>
                      메타데이터를
                      <br />
                      어떤 방식으로 불러올까요?
                    </p>
                    <div className="btn-img-group meta">
                      <label
                        className="gc-btn btn-img upload"
                        onClick={handleMetaClick}
                      >
                        파일업로드
                      </label>
                      <div
                        className="gc-btn btn-img system"
                        onClick={openMetaHandler}
                      >
                        시스템 연동
                      </div>
                    </div>
                    <input
                      id="metaUpload"
                      type="file"
                      accept=".csv"
                      style={{ visibility: 'hidden' }}
                      onChange={onMetaUplHandler}
                      ref={fileMetaRef}
                    ></input>
                  </div>
                </Popup>

                {props.activeImg == 1 ? (
                  <div className="contents-row btn-img-bottom mt-auto">
                    <button className="gc-btn btn-img" onClick={onDroneAdd}>
                      등록
                    </button>
                    <button
                      className="gc-btn btn-img text-color"
                      onClick={() => {
                        props.setShowImageReg(false);
                      }}
                    >
                      취소
                    </button>
                  </div>
                ) : props.activeImg == 2 || props.activeImg == 3 ? (
                  <div className="contents-row btn-img-bottom mt-auto">
                    {!props.isMobile ? (
                      <>
                        {droneModified ? null : userId ===
                            props.curActiveLayer.user_id ||
                          localStorage.getItem('user-level') >= 10 ? (
                          <button
                            className={'gc-btn btn-img red'}
                            onClick={() => setShowDrnDelPopup(true)}
                          >
                            삭제
                          </button>
                        ) : null}
                        {droneModified ? (
                          <>
                            <button
                              onClick={onSaveDrnHandler}
                              className="gc-btn btn-img"
                            >
                              저장
                            </button>
                            <button
                              onClick={onModDrnHandler}
                              className="gc-btn btn-img text-color"
                            >
                              취소
                            </button>
                          </>
                        ) : userId === props.curActiveLayer.user_id ||
                          localStorage.getItem('user-level') >= 10 ? (
                          props.curActiveLayer.drn_orign_img_yn == 'N' &&
                          props.curActiveLayer.layer_nm &&
                          props.curActiveLayer.layer_stl_nm && (
                            <button
                              onClick={onModDrnHandler}
                              className="gc-btn btn-img text-color green"
                            >
                              수정
                            </button>
                          )
                        ) : null}
                        {droneModified ? null : (
                          <button
                            onClick={onSaveDrnHandler}
                            className="gc-btn btn-img text-color"
                          >
                            닫기
                          </button>
                        )}
                      </>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          {props.activeImg === 2 && showMetaDetail === true ? (
            <MetaDataList
              isTablet={props.isTablet}
              toggleMetaDetail={toggleMetaDetail}
              curActiveLayer={props.curActiveLayer}
            />
          ) : null}
          <div className="contents-row mobile-btn">
            {props.isMobile ? (
              <div
                className="gc-btn mobile-ok"
                onClick={() => {
                  props.setShowImageReg(false);
                }}
              >
                확인
              </div>
            ) : null}
          </div>
        </div>
      </Modal>

      <Modal
        show={showMeta}
        onCancel={closeMetaHandler}
        header=""
        icon={<i className="code x icon" onClick={closeMetaHandler} />}
        className={'metadata'}
        isMobile={props.isMobile}
      >
        <div className="contents-col meta_container">
          <div className="meta_title">메타데이터 불러오기</div>
          <hr></hr>
          <div className="contents-row metadata_info">
            <i className="search icon"></i>
            <div className="metadata_label">검색</div>
          </div>
          <div className="contents-row field">
            <label className="label top">장소검색</label>
            <input
              className="input-img top line-none "
              type="text"
              id="place"
              name="place"
              value={searchFilters.shoot_place || ''}
              onChange={(e) =>
                setSearchFilters({
                  ...searchFilters,
                  shoot_place: e.target.value,
                })
              }
              placeholder="촬영 장소를 입력하세요"
              autoComplete="off"
            />
            <label className="label top">촬영일</label>
            <div className="contents-row input-img top line-none date">
              <DatePicker
                className="input-img datepicker"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                locale={ko}
              />
              <i
                className="calendar alternate outline icon"
                onClick={() => {}}
              />
              <div className="date-wave">~</div>
              <DatePicker
                className="input-img datepicker"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="yyyy-MM-dd"
                locale={ko}
              />
              <i
                className="calendar alternate outline icon two"
                onClick={() => {}}
              />
            </div>
          </div>
          <div className="contents-row field">
            <label className="label">촬영대상</label>
            <input
              className="input-img line-none"
              type="text"
              id="place"
              name="place"
              value={searchFilters.shoot_trgt || ''}
              onChange={(e) =>
                setSearchFilters({
                  ...searchFilters,
                  shoot_trgt: e.target.value,
                })
              }
              // onChange={''}
              placeholder="촬영 대상 작물을 입력하세요"
              autoComplete="off"
            />
            <label className="label">최종수정일</label>
            <div className="contents-row input-img line-none date">
              <DatePicker
                className="input-img datepicker"
                selected={modStartDate}
                onChange={(date) => setModStartDate(date)}
                dateFormat="yyyy-MM-dd"
                locale={ko}
              />
              <i
                className="calendar alternate outline icon"
                onClick={() => {}}
              />
              <div className="date-wave">~</div>
              <DatePicker
                className="input-img datepicker"
                selected={modEndDate}
                onChange={(date) => setModEndDate(date)}
                dateFormat="yyyy-MM-dd"
                locale={ko}
              />
              <i
                className="calendar alternate outline icon two"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="contents-row btn_group">
            <button className="gc-btn btn-img search" onClick={handleSearch}>
              검색
            </button>
            <button className="gc-btn btn-img return" onClick={onClearSearch}>
              초기화
            </button>
          </div>
          <div className="contents-row metadata_info two">
            <div className="drone_icon"></div>
            <div className="metadata_label two">드론 영상 메타데이터</div>
          </div>
          <table className="meta_table dr">
            <thead>
              <tr className="drone_label">
                <th width="5%">선택</th>
                <th width="5%">ID.</th>
                <th width="10%">촬영일자</th>
                <th width="40%">고유식별자</th>
                <th width="25%">장소</th>
                <th width="10%">최종수정일</th>
              </tr>
            </thead>
            <tbody>
              {displayedData &&
                displayedData.map((meta, index) => {
                  const itemIndex = index + 1 + (activePage - 1) * pageSize;
                  return (
                    <tr
                      key={index}
                      className={selectedRow === index ? 'selected' : ''}
                      onClick={() => handleRowClick(index)}
                    >
                      <td>
                        {meta.last_chg_dt && (
                          <input
                            type="radio"
                            name="meta"
                            defaultValue={index}
                            checked={selectedRow === index}
                            onChange={() => {}}
                          />
                        )}
                      </td>
                      <td>{meta.obs_id}</td>
                      <td>
                        {meta.shoot_start_dt &&
                          meta.shoot_start_dt.substring(0, 10)}
                      </td>
                      <td>{meta.uid}</td>
                      <td>{meta.shoot_place}</td>
                      <td>
                        {meta.last_chg_dt && meta.last_chg_dt.substring(0, 10)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="btn_group selected">
            <div
              className="gc-btn btn-img selected"
              onClick={closeMetaClickHandler}
            >
              선택완료
            </div>
          </div>
          <div className="group">
            <div className="pagination">
              <Pagination
                className="pagination"
                activePage={activePage}
                totalPages={totalPages}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                onPageChange={handlePageChange}
              ></Pagination>
            </div>
          </div>
        </div>
      </Modal>

      <Popup
        show={showDrnDelPopup}
        onCancel={() => setShowDrnDelPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => setShowDrnDelPopup(false)}
          />
        }
      >
        <div className="modal-info">
          <p>관측영상을 삭제하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowDrnDelPopup(false);
            }}
          >
            아니오
          </button>
          <button className="zoneinfo_ok_btn" onClick={onDroneDelete}>
            예
          </button>
        </div>
      </Popup>

      <Popup
        show={showDrnCanPopup}
        // onCancel={() => setShowCanPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => setShowDrnCanPopup(false)}
          />
        }
      >
        <div className="modal-info">
          <p>수정을 취소하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowDrnCanPopup(false);
            }}
          >
            아니오
          </button>
          <button className="zoneinfo_ok_btn" onClick={drnImgModNo}>
            예
          </button>
        </div>
      </Popup>

      <Popup
        show={showDrnModPopup}
        onCancel={() => setShowDrnModPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => setShowDrnModPopup(false)}
          />
        }
      >
        <div className="modal-info">
          <p>수정사항을 저장하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowDrnModPopup(false);
            }}
          >
            아니오
          </button>
          <button className="zoneinfo_ok_btn" onClick={drnImgModYes}>
            예
          </button>
        </div>
      </Popup>

      <Popup
        show={showAddPopup}
        // onCancel={() => setShowAddPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowAddPopup(false);
              if (
                popupMsg.includes('등록') ||
                popupMsg.includes('삭제') ||
                popupMsg.includes('수정')
              ) {
                props.setShowImageReg(false);
                props.handleShowImage(true);
                // props.activeFsMenuHandler(5);
                props.setAddCheckDrn(true);
              }
            }}
          />
        }
      >
        <div className="modal-info">
          <p
            style={{
              fontSize: fontSizeValue,
              padding: paddingValue,
            }}
            dangerouslySetInnerHTML={{ __html: popupMsg }}
          ></p>
          <button
            className="close_btn"
            onClick={() => {
              setShowAddPopup(false);
              if (
                popupMsg.includes('등록') ||
                popupMsg.includes('삭제') ||
                popupMsg.includes('수정')
              ) {
                props.setShowImageReg(false);
                props.handleShowImage(true);
                // props.activeFsMenuHandler(5);
                props.setAddCheckDrn(true);
                if (delFlag) {
                  props.curActiveLayerHandler();
                  setDelFlag(false);
                }
              }
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}
export default ImageReg;

import './FileDownload.css';

const FileDownload = (props) => {
  return (
    <div
      className="fileDownload_url"
      onClick={() => {
        window.location.href = props.fileURL;
      }}
    >
      <i className="file outline icon fileDownload_icon"></i>
      <div
        className="fileDownload_name"
        style={props.isMobile ? { fontWeight: '700' } : null}
      >
        {props.fileName}
      </div>
    </div>
  );
};

export default FileDownload;

import './ModifyLegend.css';

import React, { useEffect, useState } from 'react';

import { Select } from 'semantic-ui-react';

import Modal from './Modal';
import ReactSlider from 'react-slider';

import Popup from './Popup';
// AXIOS
import axios from 'axios';
// import config from '../../config/config';
import colormap from 'colormap';
import questionMarkGuide from '../../../config/questionMarkGuide';

function NewModifyLegend(props) {
  const APIToken = localStorage.getItem('login-token');

  // 최대 / 최소 입력값
  const [modInputMinMax, modsetInputMinMax] = useState(props.inputMinMax);
  // 최대 / 최소 기본값
  const [moddefaultMinMax, modsetDefaultMinMax] = useState(props.defaultMinMax);
  // 분류방식
  const [modclassifyType, modsetClassifyType] = useState(
    props.classifyType ? props.classifyType : null
  );
  // 컬러맵
  const [modcolormapType, modsetColormapType] = useState(1);
  // 라벨
  const [modlegendLabel, modsetLegendLabel] = useState(props.legendLabel);
  // 면적
  const [modlegendArea, modsetLegendArea] = useState(props.legendArea);
  // 값
  const [modlegendValue, modsetLegendValue] = useState(props.legendValue);
  // 단계
  const [modlegendStep, modsetLegendStep] = useState(props.legendStep);
  // NoData
  const [modnodata, modsetNodata] = useState(props.nodata);
  // 속성
  const [modattribute, modsetAttribute] = useState(
    props.attribute ? props.attribute : null
  );
  // 속성리스트
  const [modattrOption, modsetAttrOption] = useState(props.attrOption);
  // 범례 타이틀
  const [modTitle, modsetTitle] = useState(props.legendTitle);

  const [attrFlag, setAttrFlag] = useState(false);

  useEffect(() => {
    if (modattribute !== undefined && modattribute !== null) {
      setAttrFlag(true);

      if (
        props.curActiveLayer &&
        props.curActiveLayer.hasOwnProperty('geoJson')
      ) {
        if (
          props.curActiveLayer.geoJson.features[0].properties.hasOwnProperty(
            modattribute
          )
        ) {
          if (modattribute === props.attribute) {
            modsetClassifyType(props.classifyType);
            modsetLegendStep(props.legendStep);
            modsetLegendLabel(props.legendLabel);
            modsetLegendValue(props.legendValue);
          } else {
            if (
              typeof props.curActiveLayer.geoJson.features[0].properties[
                modattribute
              ] !== 'number'
            ) {
              modsetClassifyType(6);
              modsetLegendStep(0);
            } else {
              if (props.curActiveLayer.legend_info === null) {
                modsetClassifyType(4);
                modsetLegendStep(2);
              }
              // modsetLegendLabel([]);
              // let tempLabel = [];
              // if (modlegendStep) {
              //   for (let i = 0; i < modlegendStep; i++) {
              //     if (modlegendLabel[i]) {
              //       tempLabel.push(modlegendLabel[i]);
              //     } else {
              //       tempLabel.push('분류 ' + (i + 1));
              //     }
              //   }
              // }
              // modsetLegendLabel(tempLabel);
            }
          }

          if (props.showModifyLegend) {
            getNewLegend();
          }
          // if (props.curActiveLayer.legend_info !== null) {
          //   getNewLegend();
          // }
        }
      }
    } else {
      if (props.curActiveLayer.expt_ty === 'tif') {
        setAttrFlag(true);
      } else {
        setAttrFlag(false);
      }
    }
  }, [modattribute]);

  // 물음표 가이드
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (props.showModifyLegend) {
      if (props.curActiveLayer.legend_info !== null) {
        modsetClassifyType(props.classifyType);
        modsetColormapType(props.colormapType);
        modsetLegendLabel(props.legendLabel);
        modsetLegendArea(props.legendArea);
        modsetLegendValue(props.legendValue);
        modsetLegendStep(props.legendStep);
        modsetNodata(props.nodata);
        let minmax = props.inputMinMax;
        modsetInputMinMax([minmax[0], minmax[1]]);
        modsetDefaultMinMax(props.defaultMinMax);
        modsetVisdetecLegend(props.visdetecLegend);
        modsetAttribute(props.attribute);
        modsetAttrOption(props.attrOption);
        modsetTitle(props.legendTitle);
      } else {
        modsetColormapType(1);
        modsetAttribute(null);
      }

      if (
        props.curActiveLayer &&
        props.curActiveLayer.hasOwnProperty('geoJson')
      ) {
        if (
          props.curActiveLayer.geoJson.features[0].properties.hasOwnProperty(
            modattribute
          )
        ) {
          if (
            typeof props.curActiveLayer.geoJson.features[0].properties[
              modattribute
            ] !== 'number'
          ) {
            modsetClassifyType(6);
            modsetLegendStep(0);
          } else {
            if (props.curActiveLayer.legend_info === null) {
              modsetClassifyType(4);
              modsetLegendStep(2);
            }
          }
        }
      }

      if (
        props.curActiveLayer.legend_info !== null &&
        props.curActiveLayer.expt_ty !== 'tif'
      ) {
        getNewLegend();
      }

      // if (modclassifyType === 6) {
      //   modsetLegendStep(props.curActiveLayer.legend_info.legend.length);
      // }
      // console.log(props.curActiveLayer.geoJson.features[0].properties.(props.curActiveLayer.attr_info))
    }
  }, [props.showModifyLegend]);

  // 육안판독 전용 범례
  const [modvisdetecLegend, modsetVisdetecLegend] = useState(
    props.visdetecLegend
  );

  const [colorMapGrey, setColorMapGrey] = useState([]);
  const [colorMapRed, setColorMapRed] = useState([]);
  const [colorMapGreen, setColorMapGreen] = useState([]);
  const [colorMapSpectral, setColorMapSpectral] = useState([]);
  const [colorMapGreyReverse, setColorMapGreyReverse] = useState([]);
  const [colorMapRedReverse, setColorMapRedReverse] = useState([]);
  const [colorMapGreenReverse, setColorMapGreenReverse] = useState([]);
  const [colorMapSpectralReverse, setColorMapSpectralReverse] = useState([]);

  // color 추가
  const [colorMapRdYlGn, setColorMapRdYlGn] = useState([]);
  const [colorMapGnYlRd, setColorMapGnYlRd] = useState([]);
  const [colorMapBuRd, setColorMapBuRd] = useState([]);
  const [colorMapRdBu, setColorMapRdBu] = useState([]);
  const [colorMapHot, setColorMapHot] = useState([]);
  const [colorMapHotReverse, setColorMapHotReverse] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('');

  const classify_options_Vector = [
    // { key: 1, value: 1, text: '연속' },
    { key: 4, value: 4, text: 'Natural Breaks' },
    { key: 2, value: 2, text: '등간격' },
    { key: 3, value: 3, text: '등분위' },
    { key: 5, value: 5, text: '직접입력' },
  ];

  const classify_options_Vector_string = [{ key: 6, value: 6, text: '분류값' }];

  const classify_options_Raster = [
    { key: 1, value: 1, text: '연속' },
    { key: 2, value: 2, text: '등간격' },
    { key: 3, value: 3, text: '등분위' },
    // { key: 4, value: 4, text: "Natural Breaks" },
    { key: 5, value: 5, text: '직접입력' },
  ];

  const colormaps = [
    'RdYlGn',
    'RdYlGn',
    'RdBu',
    'RdBu',
    'jet',
    'jet',
    'hot',
    'hot',
  ];
  // const colormaps = [
  //   [
  //     { index: 0, rgb: [0, 0, 131] },
  //     { index: 0.125, rgb: [0, 60, 170] },
  //     { index: 0.375, rgb: [5, 255, 255] },
  //     { index: 0.625, rgb: [255, 255, 0] },
  //     { index: 0.875, rgb: [250, 0, 0] },
  //     { index: 1, rgb: [128, 0, 0] },
  //   ],
  // ];

  // 직접 정의한 색상 맵
  const customColormaps = {
    // RdYlGn: ["#d73027", "#fc8d59", "#fee08b", "#d9ef8b", "#91cf60", "#1a9850"],
    RdYlGn: ['#d7191c', '#fdae61', '#ffffc0', '#a6d96a', '#1a9641'],
  };

  const getColorGradient = (name, direction) => {
    if (customColormaps[name]) {
      return `linear-gradient(${direction}, ${customColormaps[name].join(
        ', '
      )})`;
    }

    // 기본 colormap 라이브러리 사용
    return `linear-gradient(${direction}, ${colormap({
      colormap: name,
      nshades: 10,
      format: 'hex',
      alpha: 1,
    }).join(', ')})`;
  };

  // Select 컴포넌트의 옵션으로 사용할 배열 생성
  const colormap_options = colormaps.map((name, index) => {
    let direction;
    if (index % 2 === 0) {
      direction = 'to left';
    } else {
      direction = 'to right';
    }

    // const gradient = `linear-gradient(${direction}, ${colormap({
    //   colormap: name, // 사용할 색상 맵 이름
    //   format: "rgba", // 색상의 형식 ('hex', 'rgb', 'rgba')
    //   nshades: 6,
    //   alpha: 1, // 투명도 (0에서 1 사이)
    // }).join(", ")})`;

    const gradient = getColorGradient(name, direction);

    return {
      key: index + 1,
      value: index + 1,
      text: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            height: '30px',
          }}
        >
          <div
            className='colorMap'
            style={{
              background: gradient,
              width: '230px',
              height: '25px',
            }}
          ></div>
          <div style={{ width: '110px', textAlign: 'center' }}>
            {index === 0
              ? 'GnYlRd'
              : index === 1
              ? 'RdYlGn'
              : index === 2
              ? 'RdBu'
              : index === 3
              ? 'BuRd'
              : index === 4
              ? 'Spectral'
              : index === 5
              ? 'Spectral(반전)'
              : index === 6
              ? 'Hot'
              : 'Hot(반전)'}
          </div>
        </div>
      ),
    };

    // return (
    //   <div
    //     role="option"
    //     key={index + 1}
    //     value={index + 1}
    //     style={{
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "space-evenly",
    //       height: "40px",
    //     }}
    //   >
    //     <div
    //       className="colorMap"
    //       style={{
    //         background: `linear-gradient(${direction}, ${colormap({
    //           colormap: name, // 사용할 색상 맵 이름
    //           nshades: 10, // 생성할 색상의 개수
    //           format: "hex", // 색상의 형식 ('hex', 'rgb', 'rgba')
    //           alpha: 1, // 투명도 (0에서 1 사이)
    //         }).join(", ")})`,
    //         width: "230px",
    //         height: "35px",
    //       }}
    //     ></div>
    //     <div style={{ width: "110px", textAlign: "center" }}>{name}</div>
    //   </div>
    // );
  });

  // {modcolormapType && (
  //   <div>
  //     <h2>Selected Colormap: {modcolormapType.label}</h2>
  //     <div
  //       style={{
  //         background: `linear-gradient(to right, ${colormap({
  //           colormap: modcolormapType.label,
  //           nshades: 10,
  //           format: "hex",
  //           alpha: 1,
  //         }).join(", ")})`,
  //         width: "100%",
  //         height: "100px",
  //       }}
  //     ></div>
  //   </div>
  // )}

  // const colormap_options = [
  //   { key: 1, value: 1, text: "Grey" },
  //   { key: 2, value: 2, text: "Red" },
  //   { key: 3, value: 3, text: "Green" },
  //   { key: 4, value: 4, text: "Spectral" },
  //   { key: 5, value: 5, text: "Grey(Rev)" },
  //   { key: 6, value: 6, text: "Red(Rev)" },
  //   { key: 7, value: 7, text: "Green(Rev)" },
  //   { key: 8, value: 8, text: "Spectral(Rev)" },
  // ];

  const legendMinMaxHandler = (e) => {
    modsetInputMinMax([e[0], e[1]]);
  };
  const legendMinHandler = (e) => {
    modsetInputMinMax([e.target.value, modInputMinMax[1]]);
  };
  const legendMaxHandler = (e) => {
    modsetInputMinMax([modInputMinMax[0], e.target.value]);
  };
  const handleClassifyType = (e, data) => {
    modsetClassifyType(data.value);
  };
  const handleColormapType = (e, data) => {
    modsetColormapType(data.value);
  };
  const handleLegendLabel = (e, i) => {
    const dummyLabel = [...modlegendLabel];
    dummyLabel[i] = e.target.value;
    modsetLegendLabel(dummyLabel);
  };
  const handleLegendValue1 = (e, i) => {
    const inputValue = e.target.value;

    const regex = /^-?\d*\.?\d{0,3}$/; // 부동소수점 숫자 및 정수 허용;

    if (regex.test(inputValue) || inputValue === '') {
      let dummyValue = [...modlegendValue];
      dummyValue[i - 1] = String(inputValue);

      modsetLegendValue(dummyValue);
      modsetClassifyType(5);
    }
  };
  const handleLegendValue2 = (e, i) => {
    const inputValue = e.target.value;

    const regex = /^-?\d*\.?\d{0,3}$/; // 부동소수점 숫자 및 정수 허용;

    if (regex.test(inputValue) || inputValue === '') {
      let dummyValue = [...modlegendValue];
      dummyValue[i] = String(inputValue);

      modsetLegendValue(dummyValue);
      modsetClassifyType(5);
    }
  };
  const handleLegendStep = (e) => {
    modsetLegendStep(e.target.value);
  };
  const handleNodata = (e) => {
    modsetNodata(e.target.value);
  };
  const handleAttribute = (e, data) => {
    modsetAttribute(data.value);

    if (data.value === props.attribute) {
      modsetClassifyType(props.classifyType);
      modsetLegendStep(props.legendStep);
      modsetLegendLabel(props.legendLabel);
    } else {
      if (
        typeof props.curActiveLayer.geoJson.features[0].properties[
          data.value
        ] !== 'number'
      ) {
        modsetClassifyType(6);
        modsetLegendStep(0);
      } else {
        modsetClassifyType(4);
        modsetLegendStep(2);
      }
    }

    // if (
    //   typeof props.curActiveLayer.geoJson.features[0].properties[data.value] !==
    //   'number'
    // ) {
    //   modsetClassifyType(6);
    //   modsetLegendStep(0);
    // } else {
    //   modsetClassifyType(4);
    //   // modsetLegendStep(2);
    // }
  };
  const handleLegendTitle = (data) => {
    modsetTitle(data.target.value);
  };

  useEffect(() => {
    if (props.curActiveLayer) {
      if (props.curActiveLayer.hasOwnProperty('geoJson')) {
        var calMinMax = calGeoJson(modattribute);
        modsetDefaultMinMax(calMinMax);
        modsetInputMinMax(calMinMax);
      }
    }
  }, [modattribute]);

  useEffect(() => {
    let tempcolorMapGrey = [];
    let tempcolorMapRed = [];
    let tempcolorMapGreen = [];
    let tempcolorMapSpectral = [];
    let tempcolorMapGreyReverse = [];
    let tempcolorMapRedReverse = [];
    let tempcolorMapGreenReverse = [];
    let tempcolorMapSpectralReverse = [];
    // color 추가
    let tempcolorMapRdYlGn = [];
    let tempcolorMapGnYlRd = [];
    let tempcolorMapRdBu = [];
    let tempcolorMapBuRd = [];
    let tempcolorMapHot = [];
    let tempcolorMapHotReverse = [];

    if (modlegendStep) {
      for (let i = 0; i < modlegendStep; i++) {
        const getColorGrey = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'Grey'
        );
        const getColorRed = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'Red'
        );
        const getColorGreen = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'Green'
        );
        const getColorSpectral = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'Spectral'
        );
        const getColorGreyReverse = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'Grey_Reverse'
        );
        const getColorRedReverse = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'Red_Reverse'
        );
        const getColorGreenReverse = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'Green_Reverse'
        );
        const getColorSpectralReverse = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'Spectral_Reverse'
        );
        const getColorRdYlGn = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'RdYlGn'
        );
        const getColoGnYlRd = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'GnYlRd'
        );
        const getColorRdBu = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'RdBu'
        );
        const getColorBuRd = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'BuRd'
        );
        const getColorHot = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'hot'
        );
        const getColorHotReverse = getColorForPercentage(
          (100 / (modlegendStep - 1)) * i,
          'hot_Reverse'
        );

        tempcolorMapGrey.push(getColorGrey);
        tempcolorMapRed.push(getColorRed);
        tempcolorMapGreen.push(getColorGreen);
        tempcolorMapSpectral.push(getColorSpectral);
        tempcolorMapGreyReverse.push(getColorGreyReverse);
        tempcolorMapRedReverse.push(getColorRedReverse);
        tempcolorMapGreenReverse.push(getColorGreenReverse);
        tempcolorMapSpectralReverse.push(getColorSpectralReverse);
        //추가
        tempcolorMapRdYlGn.push(getColorRdYlGn);
        tempcolorMapGnYlRd.push(getColoGnYlRd);
        tempcolorMapRdBu.push(getColorRdBu);
        tempcolorMapBuRd.push(getColorBuRd);
        tempcolorMapHot.push(getColorHot);
        tempcolorMapHotReverse.push(getColorHotReverse);
      }
    }
    setColorMapGrey(tempcolorMapGrey);
    setColorMapRed(tempcolorMapRed);
    setColorMapGreen(tempcolorMapGreen);
    setColorMapSpectral(tempcolorMapSpectral);
    setColorMapGreyReverse(tempcolorMapGreyReverse);
    setColorMapRedReverse(tempcolorMapRedReverse);
    setColorMapGreenReverse(tempcolorMapGreenReverse);
    setColorMapSpectralReverse(tempcolorMapSpectralReverse);
    // 추가
    setColorMapRdYlGn(tempcolorMapRdYlGn);
    setColorMapGnYlRd(tempcolorMapGnYlRd);
    setColorMapRdBu(tempcolorMapRdBu);
    setColorMapBuRd(tempcolorMapBuRd);
    setColorMapHot(tempcolorMapHot);
    setColorMapHotReverse(tempcolorMapHotReverse);

    if (props.showModifyLegend) {
      // let tempValue = [];
      // if (modlegendStep) {
      //   for (let i = 0; i < modlegendStep; i++) {
      //     const getValue = getValueForPercentage(
      //       (100 / modlegendStep) * (i + 1),
      //       moddefaultMinMax[0],
      //       moddefaultMinMax[1]
      //     );
      //     tempValue.push(getValue.toFixed(3));
      //   }
      // }
      // console.log("tempValue", tempValue);
      // modsetLegendValue(tempValue);

      if (modclassifyType !== 6) {
        let tempLabel = [];
        if (modlegendStep) {
          for (let i = 0; i < modlegendStep; i++) {
            if (modlegendLabel[i]) {
              tempLabel.push(modlegendLabel[i]);
            } else {
              tempLabel.push('분류 ' + (i + 1));
            }
          }
        }
        modsetLegendLabel(tempLabel);
      }
      // else {
      //   let tempLabel = [];
      //   if (modlegendStep) {
      //     for (let i = 0; i < modlegendStep; i++) {
      //       tempLabel.push(modlegendValue[i]);
      //     }
      //   }
      //   modsetLegendLabel(tempLabel);
      // }
    }
  }, [modlegendStep]);

  var getValueForPercentage = function (pct, min, max) {
    return min + (max - min) * (pct / 100);
  };

  var getColorForPercentage = function (pct, colorType) {
    pct = isNaN(pct) ? 0 : pct;
    var percentColors = [];

    if (colorType == 'Grey') {
      percentColors = [
        { pct: 0, color: { r: 221, g: 221, b: 221 } },
        { pct: 50, color: { r: 150, g: 150, b: 150 } },
        { pct: 100, color: { r: 79, g: 79, b: 79 } },
      ];
    }
    if (colorType == 'Red') {
      percentColors = [
        { pct: 0, color: { r: 246, g: 189, b: 192 } },
        { pct: 50, color: { r: 230, g: 100, b: 100 } },
        { pct: 100, color: { r: 215, g: 15, b: 10 } },
      ];
    }
    if (colorType == 'Green') {
      percentColors = [
        { pct: 0, color: { r: 197, g: 232, b: 183 } },
        { pct: 50, color: { r: 131, g: 212, b: 117 } },
        { pct: 100, color: { r: 46, g: 182, b: 44 } },
      ];
    }
    if (colorType == 'Spectral') {
      // percentColors = [
      //   { pct: 0, color: { r: 215, g: 25, b: 28 } },
      //   { pct: 50, color: { r: 255, g: 255, b: 191 } },
      //   { pct: 100, color: { r: 43, g: 131, b: 186 } },
      // ];
      percentColors = [
        { pct: 0, color: { r: 128, g: 0, b: 0 } },
        { pct: 12.5, color: { r: 255, g: 0, b: 0 } },
        { pct: 37.5, color: { r: 255, g: 255, b: 0 } },
        { pct: 62.5, color: { r: 5, g: 255, b: 255 } },
        { pct: 87.5, color: { r: 0, g: 60, b: 170 } },
        { pct: 100, color: { r: 0, g: 0, b: 131 } },
      ];
      // percentColors = [
      //   { pct: 0, color: { r: 215, g: 25, b: 28 } },
      //   { pct: 25, color: { r: 253, g: 174, b: 97 } },
      //   { pct: 50, color: { r: 255, g: 255, b: 191 } },
      //   { pct: 75, color: { r: 171, g: 221, b: 164 } },
      //   { pct: 100, color: { r: 43, g: 131, b: 186 } },
      // ];
    }
    if (colorType == 'Grey_Reverse') {
      percentColors = [
        { pct: 0, color: { r: 79, g: 79, b: 79 } },
        { pct: 50, color: { r: 150, g: 150, b: 150 } },
        { pct: 100, color: { r: 221, g: 221, b: 221 } },
      ];
    }
    if (colorType == 'Red_Reverse') {
      percentColors = [
        { pct: 0, color: { r: 215, g: 15, b: 10 } },
        { pct: 50, color: { r: 230, g: 100, b: 100 } },
        { pct: 100, color: { r: 246, g: 189, b: 192 } },
      ];
    }
    if (colorType == 'Green_Reverse') {
      percentColors = [
        { pct: 0, color: { r: 46, g: 182, b: 44 } },
        { pct: 50, color: { r: 131, g: 212, b: 117 } },
        { pct: 100, color: { r: 197, g: 232, b: 183 } },
      ];
    }
    if (colorType == 'Spectral_Reverse') {
      // percentColors = [
      //   { pct: 0, color: { r: 43, g: 131, b: 186 } },
      //   { pct: 50, color: { r: 255, g: 255, b: 191 } },
      //   { pct: 100, color: { r: 215, g: 25, b: 28 } },
      // ];
      percentColors = [
        { pct: 0, color: { r: 0, g: 0, b: 131 } },
        { pct: 12.5, color: { r: 0, g: 60, b: 170 } },
        { pct: 37.5, color: { r: 5, g: 255, b: 255 } },
        { pct: 62.5, color: { r: 255, g: 255, b: 0 } },
        { pct: 87.5, color: { r: 255, g: 0, b: 0 } },
        { pct: 100, color: { r: 128, g: 0, b: 0 } },
      ];
      // percentColors = [
      //   { pct: 0, color: { r: 43, g: 131, b: 186 } },
      //   { pct: 25, color: { r: 171, g: 221, b: 164 } },
      //   { pct: 50, color: { r: 255, g: 255, b: 191 } },
      //   { pct: 75, color: { r: 253, g: 174, b: 97 } },
      //   { pct: 100, color: { r: 215, g: 25, b: 28 } },
      // ];
    }
    // color 추가
    if (colorType == 'RdYlGn') {
      // percentColors = [
      //   { pct: 0, color: { r: 26, g: 152, b: 80 } },
      //   { pct: 12.5, color: { r: 145, g: 207, b: 96 } },
      //   { pct: 37.5, color: { r: 217, g: 239, b: 139 } },
      //   { pct: 62.5, color: { r: 254, g: 224, b: 139 } },
      //   { pct: 87.5, color: { r: 252, g: 141, b: 89 } },
      //   { pct: 100, color: { r: 215, g: 48, b: 39 } },
      // ];
      percentColors = [
        { pct: 0, color: { r: 26, g: 150, b: 65 } },
        { pct: 25, color: { r: 166, g: 217, b: 106 } },
        { pct: 50, color: { r: 255, g: 255, b: 192 } },
        { pct: 75, color: { r: 253, g: 174, b: 97 } },
        { pct: 100, color: { r: 215, g: 25, b: 28 } },
      ];
    }
    if (colorType == 'GnYlRd') {
      // percentColors = [
      //   { pct: 0, color: { r: 215, g: 48, b: 39 } },
      //   { pct: 12.5, color: { r: 252, g: 141, b: 89 } },
      //   { pct: 37.5, color: { r: 254, g: 224, b: 139 } },
      //   { pct: 62.5, color: { r: 217, g: 239, b: 139 } },
      //   { pct: 87.5, color: { r: 145, g: 207, b: 96 } },
      //   { pct: 100, color: { r: 26, g: 152, b: 80 } },
      // ];
      percentColors = [
        { pct: 0, color: { r: 215, g: 25, b: 28 } },
        { pct: 25, color: { r: 253, g: 174, b: 97 } },
        { pct: 50, color: { r: 255, g: 255, b: 192 } },
        { pct: 75, color: { r: 166, g: 217, b: 106 } },
        { pct: 100, color: { r: 26, g: 150, b: 65 } },
      ];
    }
    if (colorType == 'BuRd') {
      percentColors = [
        { pct: 0, color: { r: 5, g: 10, b: 172 } },
        { pct: 35, color: { r: 106, g: 137, b: 247 } },
        { pct: 50, color: { r: 190, g: 190, b: 190 } },
        { pct: 60, color: { r: 220, g: 170, b: 132 } },
        { pct: 70, color: { r: 230, g: 145, b: 90 } },
        { pct: 100, color: { r: 178, g: 10, b: 28 } },
      ];
    }
    if (colorType == 'RdBu') {
      percentColors = [
        { pct: 0, color: { r: 178, g: 10, b: 28 } },
        { pct: 35, color: { r: 230, g: 145, b: 90 } },
        { pct: 50, color: { r: 220, g: 170, b: 132 } },
        { pct: 60, color: { r: 190, g: 190, b: 190 } },
        { pct: 70, color: { r: 106, g: 137, b: 247 } },
        { pct: 100, color: { r: 5, g: 10, b: 172 } },
      ];
    }
    if (colorType == 'hot_Reverse') {
      percentColors = [
        { pct: 0, color: { r: 0, g: 0, b: 0 } },
        { pct: 30, color: { r: 230, g: 0, b: 0 } },
        { pct: 60, color: { r: 255, g: 210, b: 0 } },
        { pct: 100, color: { r: 255, g: 255, b: 255 } },
      ];
    }
    if (colorType == 'hot') {
      percentColors = [
        { pct: 0, color: { r: 255, g: 255, b: 255 } },
        { pct: 30, color: { r: 255, g: 210, b: 0 } },
        { pct: 60, color: { r: 230, g: 0, b: 0 } },
        { pct: 100, color: { r: 0, g: 0, b: 0 } },
      ];
    }

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }

    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };
    return [color.r, color.g, color.b, 255];
  };

  function plusOneStep() {
    if (modlegendStep < 10) {
      let temp = modlegendStep;
      temp = temp + 1;
      modsetLegendStep(temp);
    }
  }

  function minusOneStep() {
    if (props.curActiveLayer.expt_ty !== 'tif') {
      if (modlegendStep > 1) {
        let temp = modlegendStep;
        temp = temp - 1;
        modsetLegendStep(temp);
      }
    } else {
      if (modlegendStep > 2) {
        let temp = modlegendStep;
        temp = temp - 1;
        modsetLegendStep(temp);
      }
    }
  }

  function validation() {
    if (props.curActiveLayer.expt_ty === 'shp') {
      if (modattribute === null || modattribute === undefined) {
        setPopupMsg('속성 지정 후 범례를 변경해주세요.');
        setShowPopup(true);
        return false;
      }
    }

    if (modTitle !== undefined) {
      if (modTitle.length > 30) {
        setPopupMsg('범례 제목은 30자까지 입력 가능합니다.');
        setShowPopup(true);
        return false;
      }
    }

    if (modclassifyType !== 6) {
      // 범례 범위 check
      if (modlegendValue) {
        for (let i = 1; i < modlegendValue.length - 1; i++) {
          if (Number(modlegendValue[i - 1]) > Number(modlegendValue[i])) {
            setPopupMsg(
              <>
                범례의 범위가 유효하지 않습니다.
                <br />
                {i + 1}번째 범위를 확인해 주시기 바랍니다.
              </>
            );
            setShowPopup(true);
            return false;
          }
        }
      }
    }
    return true;
  }

  function applyLegend() {
    props.setLoading(true);

    if (modInputMinMax[0] > modInputMinMax[1]) {
      setPopupMsg('범위 최댓값이 최솟값보다 작습니다.');
      setShowPopup(true);
      props.setLoading(false);
    } else {
      let colorMap = [];

      for (let i = 0; i < modlegendStep; i++) {
        if (modcolormapType == 1) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapRdYlGn[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapRdYlGn[i],
              label: modlegendLabel[i],
            });
          }
        } else if (modcolormapType == 2) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapGnYlRd[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapGnYlRd[i],
              label: modlegendLabel[i],
            });
          }
        } else if (modcolormapType == 3) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapRdBu[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({ color: colorMapRdBu[i], label: modlegendLabel[i] });
          }
        } else if (modcolormapType == 4) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapBuRd[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({ color: colorMapBuRd[i], label: modlegendLabel[i] });
          }
        } else if (modcolormapType == 5) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapSpectral[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapSpectral[i],
              label: modlegendLabel[i],
            });
          }
        } else if (modcolormapType == 6) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapSpectralReverse[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapSpectralReverse[i],
              label: modlegendLabel[i],
            });
          }
        } else if (modcolormapType == 7) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapHot[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({ color: colorMapHot[i], label: modlegendLabel[i] });
          }
        } else if (modcolormapType == 8) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapHotReverse[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapHotReverse[i],
              label: modlegendLabel[i],
            });
          }
        }
      }

      if (modclassifyType == 5) {
        for (let i = 0; i < colorMap.length; i++) {
          if (typeof colorMap[i].value === 'string') {
            colorMap[i].value = Number(colorMap[i].value);
          }
        }
      }

      let isOK = validation();
      if (isOK) {
        let test = {
          themeId: props.curActiveLayer.them_map_id,
          min: modInputMinMax[0],
          max: modInputMinMax[1],
          noData: modnodata,
          legendTy: modclassifyType,
          legend: JSON.stringify(colorMap),
          colorTy: modcolormapType,
          attrKey: modattribute,
          legendTitle: modTitle,
        };

        axios({
          method: 'post',
          url: `/api/theme-map/legend`,
          headers: {
            Authorization: `Bearer {${APIToken}}`,
          },
          data: {
            themeId: props.curActiveLayer.them_map_id,
            min: modInputMinMax[0],
            max: modInputMinMax[1],
            noData: modnodata,
            legendTy: modclassifyType,
            legend: JSON.stringify(colorMap),
            colorTy: modcolormapType,
            attrKey: modattribute,
            legendTitle: modTitle,
          },
        })
          .then((result) => {
            if (result.data.success === true) {
              props.setShowModifyLegend(false);

              result.data.data.layerType = 2;
              props.setLegendLabel(result.data.data.legend_info.legend.length);
              let index = -1;
              let dummyArray = [...props.activeLayerList];
              dummyArray.map((e, i) => {
                if (e.them_map_id == props.curActiveLayer.them_map_id) {
                  index = i;
                }
              });

              // Temp bbox
              if (dummyArray[index].bbox != null) {
                let dummybbox = dummyArray[index].bbox;
                props.setDummybbox(dummybbox);
              }

              // Delete Layer
              dummyArray.splice(index, 1);
              props.activeLayerListHandler(dummyArray);
              props.delModeHandler(index);

              // Set New Layer
              props.setRefreshFlag(result.data.data);

              props.setLoading(false);
            } else {
              props.setShowModifyLegend(false);
              setPopupMsg('범례 변경에 실패하였습니다.');
              setShowPopup(true);
            }
          })
          .catch((error) => {
            console.log(error);
            props.setLoading(false);
          });
        props.setClickValue('');
        modsetAttribute();
        modsetAttrOption();
      }
      props.setLoading(false);
    }
  }

  const colorRendering = () => {
    const colorArray = [];
    for (let i = 0; i < modlegendStep; i++) {
      colorArray.push(
        <div
          className='item color-factor flex-center'
          style={{
            backgroundColor:
              modcolormapType == 1
                ? rgbToHex(colorMapRdYlGn[i])
                : modcolormapType == 2
                ? rgbToHex(colorMapGnYlRd[i])
                : modcolormapType == 3
                ? rgbToHex(colorMapRdBu[i])
                : modcolormapType == 4
                ? rgbToHex(colorMapBuRd[i])
                : modcolormapType == 5
                ? rgbToHex(colorMapSpectral[i])
                : modcolormapType == 6
                ? rgbToHex(colorMapSpectralReverse[i])
                : modcolormapType == 7
                ? rgbToHex(colorMapHot[i])
                : modcolormapType == 8
                ? rgbToHex(colorMapHotReverse[i])
                : '#FFFFFF',
          }}
          key={i}
        ></div>
      );
    }
    return colorArray;
  };

  const valueRendering = () => {
    const valueArray = [];
    if (
      modclassifyType === 2 ||
      modclassifyType === 3 ||
      modclassifyType === 4 ||
      modclassifyType === 5
    ) {
      if (modlegendStep > 1) {
        for (let i = 0; i < modlegendStep; i++) {
          valueArray.push(
            <div className='item value-factor flex-center' key={i}>
              <input
                className='item-input'
                value={
                  i === 0 || i === modlegendStep - 1
                    ? ''
                    : modlegendValue[i - 1]
                }
                onChange={(e) => {
                  if (i !== 0 || i !== modlegendStep - 1)
                    handleLegendValue1(e, i);
                }}
                style={{ whiteSpace: 'pre' }}
                readOnly={i === 0 || i === modlegendStep - 1 ? true : false}
                onDragStart={(event) => event.preventDefault()}
              ></input>
              {i === 0 ? '<  ' : i === modlegendStep - 1 ? '>=  ' : '  -  '}
              <input
                className='item-input'
                value={
                  i === modlegendStep - 1
                    ? modlegendValue[i - 1]
                    : modlegendValue[i]
                }
                onChange={(e) => {
                  if (i !== modlegendStep - 1) handleLegendValue2(e, i);
                }}
                style={{ whiteSpace: 'pre' }}
                readOnly={i === modlegendStep - 1 ? true : false}
                onDragStart={(event) => event.preventDefault()}
              ></input>
            </div>
          );
        }
      } else {
        valueArray.push(
          <div className='item value-factor flex-center'>
            <input
              className='item-input'
              value={modlegendValue[0]}
              // onChange={(e) => {
              //   // if (i !== 0 || i !== modlegendStep - 1)
              //   handleLegendValue1(e, i);
              // }}
              style={{ whiteSpace: 'pre' }}
              // readOnly={i === 0 || i === modlegendStep - 1 ? true : false}
              onDragStart={(event) => event.preventDefault()}
            ></input>
            {'  -  '}
            <input
              className='item-input'
              value={modlegendValue[0]}
              // onChange={(e) => {
              //   // if (i !== modlegendStep - 1)
              //   handleLegendValue2(e, i);
              // }}
              style={{ whiteSpace: 'pre' }}
              // readOnly={i === modlegendStep - 1 ? true : false}
              onDragStart={(event) => event.preventDefault()}
            ></input>
          </div>
        );
      }
    } else if (modclassifyType === 6) {
      for (let i = 0; i < modlegendStep; i++) {
        valueArray.push(
          <div className='item value-factor flex-center' key={i}>
            <input
              className='item-input'
              value={modlegendValue[i]}
              style={{ whiteSpace: 'pre' }}
              readOnly={true}
              onDragStart={(event) => event.preventDefault()}
            ></input>
          </div>
        );
      }
    } else {
      for (let i = 0; i < modlegendStep; i++) {
        valueArray.push(
          <div className='item value-factor flex-center'>
            <input
              className='item-input'
              value={'-'}
              // onChange={(e) => {
              //   handleLegendValue(e, i);
              // }}
              style={{ whiteSpace: 'pre' }}
            ></input>
          </div>
        );
      }
    }
    return valueArray;
  };

  const labelRendering = () => {
    const labelArray = [];
    for (let i = 0; i < modlegendStep; i++) {
      labelArray.push(
        <div className='item label-factor flex-center' key={i}>
          <input
            className='item-input'
            value={modclassifyType != 1 ? modlegendLabel[i] : '-'}
            onChange={(e) => {
              handleLegendLabel(e, i);
            }}
            onDragStart={(event) => event.preventDefault()}
          ></input>
        </div>
      );
    }
    return labelArray;
  };

  function calGeoJson(modattribute) {
    if (
      props.curActiveLayer.hasOwnProperty('geoJson') &&
      props.curActiveLayer.geoJson != null
    ) {
      let valueArray = [];
      for (let i = 0; i < props.curActiveLayer.geoJson.features.length; i++) {
        // let val = props.curActiveLayer.geoJson.features[i].properties[props.curActiveLayer.attr_info];
        let val =
          props.curActiveLayer.geoJson.features[i].properties[modattribute];
        valueArray.push(val);
      }
      var maxValue = Math.max(...valueArray);
      var minValue = Math.min(...valueArray);

      return [minValue, maxValue];
    }
  }

  function rgbToHex(arr) {
    if (arr) {
      return (
        '#' +
        ((1 << 24) | (arr[0] << 16) | (arr[1] << 8) | arr[2])
          .toString(16)
          .slice(1)
      );
    }
  }
  const getNewLegend = async () => {
    props.setLoading(true);

    if (modInputMinMax[0] > modInputMinMax[1]) {
      setPopupMsg('범위 최댓값이 최솟값보다 작습니다.');
      setShowPopup(true);
      props.setLoading(false);
    } else {
      let colorMap = [];

      for (let i = 0; i < modlegendStep; i++) {
        if (modcolormapType == 1) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapRdYlGn[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapRdYlGn[i],
              label: modlegendLabel[i],
            });
          }
        } else if (modcolormapType == 2) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapGnYlRd[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapGnYlRd[i],
              label: modlegendLabel[i],
            });
          }
        } else if (modcolormapType == 3) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapRdBu[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapRdBu[i],
              label: modlegendLabel[i],
            });
          }
        } else if (modcolormapType == 4) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapBuRd[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapBuRd[i],
              label: modlegendLabel[i],
            });
          }
        } else if (modcolormapType == 5) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapSpectral[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapSpectral[i],
              label: modlegendLabel[i],
            });
          }
        } else if (modcolormapType == 6) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapSpectralReverse[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapSpectralReverse[i],
              label: modlegendLabel[i],
            });
          }
        } else if (modcolormapType == 7) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapHot[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapHot[i],
              label: modlegendLabel[i],
            });
          }
        } else if (modcolormapType == 8) {
          if (modclassifyType == 5) {
            colorMap.push({
              color: colorMapHotReverse[i],
              label: modlegendLabel[i],
              value:
                i === modlegendStep - 1 ? modInputMinMax[1] : modlegendValue[i],
            });
          } else {
            colorMap.push({
              color: colorMapHotReverse[i],
              label: modlegendLabel[i],
            });
          }
        }
      }

      let test = {
        themeId: props.curActiveLayer.them_map_id,
        min: modInputMinMax[0],
        max: modInputMinMax[1],
        noData: modnodata,
        legendTy: modclassifyType,
        legend: JSON.stringify(colorMap),
        colorTy: modcolormapType,
        attrKey: modattribute,
        legendTitle: modTitle,
      };

      await axios({
        method: 'post',
        url: `/api/theme-map/newLegend`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: test,
      }).then((result) => {
        props.setLoading(false);

        if (result.data.success === true) {
          if (props.curActiveLayer.expt_ty !== 'tif') {
            if (result.data.data.legendTy !== 6) {
              let newLegend = result.data.data.legend;
              let newArray = [];

              newLegend.forEach((data) => {
                if (data.value !== null) {
                  newArray.push(String(Number(data.value).toFixed(3)));
                } else {
                  newArray.push(null);
                }
              });

              modsetLegendValue(newArray);

              if (props.attribute !== modattribute) {
                let tempLabel = [];
                if (modlegendStep) {
                  for (let i = 0; i < modlegendStep; i++) {
                    // if (modlegendLabel[i]) {
                    //   tempLabel.push(modlegendLabel[i]);
                    // } else {
                    tempLabel.push('분류 ' + (i + 1));
                    // }
                  }
                }
                modsetLegendLabel(tempLabel);
              } else {
                modsetLegendValue(props.legendValue);
              }
            } else {
              let newLegend = result.data.data.vectorBuf;
              modsetLegendValue(newLegend);
              modsetLegendLabel(newLegend);
              modsetLegendStep(newLegend.length);
              colorRendering();
              valueRendering();
              labelRendering();
            }
          } else {
            let newLegend = result.data.data.legend;
            let newArray = [];

            newLegend.forEach((data) => {
              if (data.value !== null) {
                newArray.push(String(Number(data.value).toFixed(3)));
              } else {
                newArray.push(null);
              }
            });

            modsetLegendValue(newArray);
          }
        } else {
          setPopupMsg('범례 생성에 실패하였습니다.');
          setShowPopup(true);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={props.showModifyLegend}
        // onCancel={()=>{props.setShowModifyLegend(false)}}
        header='주제도 범례 변경'
        icon={
          <i
            className='code x icon'
            onClick={() => {
              props.setShowModifyLegend(false);
            }}
          />
        }
        className={`modifyLegend ${props.isMobile ? 'mobile' : ''}`}
        // className={'modifyLegend'}
        isMobile={props.isMobile}
      >
        <div className='modify-legend-container-box contents-col'>
          <div className='modify-legend-container contents-col'>
            <div className='contents-row full-width'>
              <div className='main-text'>범례 제목</div>
              <div
                style={{
                  margin: '5px 0',
                  fontFamily: 'Noto Sans KR',
                  width: '100%',
                  minHeight: '30px',
                }}
              >
                <input
                  className='nodata-input'
                  value={modTitle}
                  onChange={handleLegendTitle}
                  style={{
                    fontWeight: '600',
                    fontSize: '12px',
                    border: '1px solid #CCC',
                    borderRadius: '5px',
                    textAlign: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                ></input>
              </div>
            </div>
            <div className='contents-row full-width'>
              <div className='main-text'>속성</div>
              <Select
                className='select'
                placeholder={
                  props.curActiveLayer &&
                  props.curActiveLayer.raster_stat != null
                    ? '래스터 주제도는 속성을 선택할 수 없습니다.'
                    : modattribute
                }
                options={modattrOption}
                value={modattribute}
                onChange={handleAttribute}
                disabled={
                  props.curActiveLayer &&
                  props.curActiveLayer.raster_stat != null
                    ? true
                    : false
                }
                style={{ border: '1px solid rgb(204, 204, 204)' }}
              ></Select>
            </div>
            {attrFlag ? (
              <>
                {/* 속성 선택에 따른 범위 지정 On/Off */}
                {props.curActiveLayer &&
                props.curActiveLayer.hasOwnProperty('geoJson') ? (
                  props.curActiveLayer.geoJson.features[0].properties.hasOwnProperty(
                    modattribute
                  ) ? (
                    typeof props.curActiveLayer.geoJson.features[0].properties[
                      modattribute
                    ] === 'number' ? (
                      <div className='contents-row full-width'>
                        <div className='main-text'>범위 지정</div>
                        <div
                          className='minmax-controller contents-row flex-center'
                          style={{
                            border: '1px  solid rgb(204, 204, 204)',
                            borderRadius: '5px',
                            margin: '5px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <ReactSlider
                            className='horizontal-slider-double flex-center'
                            thumbClassName='example-thumb'
                            trackClassName='example-track'
                            // defaultValue={moddefaultMinMax}
                            min={moddefaultMinMax[0]}
                            max={moddefaultMinMax[1]}
                            value={
                              modInputMinMax[0] === undefined
                                ? props.inputMinMax
                                : modInputMinMax
                            }
                            ariaLabel={['Lower thumb', 'Upper thumb']}
                            pearling
                            step={0.01}
                            minDistance={0.01}
                            onChange={legendMinMaxHandler}
                            onAfterChange={legendMinMaxHandler}
                            style={{ margin: '0' }}
                          ></ReactSlider>

                          <div
                            className='contents-row'
                            style={{ margin: '0 10px' }}
                          >
                            <input
                              className='input min'
                              value={
                                modInputMinMax[0] === undefined
                                  ? props.inputMinMax[0]
                                  : Number.isInteger(modInputMinMax[0])
                                  ? modInputMinMax[0]
                                  : Number(modInputMinMax[0]).toFixed(3)
                              }
                              onChange={legendMinHandler}
                              style={{ width: '80px', margin: '0' }}
                            ></input>
                            <div
                              className='divider flex-center'
                              style={{ whiteSpace: 'pre', fontSize: '21px' }}
                            >
                              {'  ~  '}
                            </div>
                            <input
                              className='input max'
                              value={
                                modInputMinMax[1] === undefined
                                  ? props.inputMinMax[1]
                                  : Number.isInteger(modInputMinMax[1])
                                  ? modInputMinMax[1]
                                  : Number(modInputMinMax[1]).toFixed(3)
                              }
                              onChange={legendMaxHandler}
                              style={{ width: '80px', margin: '0' }}
                            ></input>
                          </div>
                        </div>
                      </div>
                    ) : null
                  ) : null
                ) : (
                  <div className='contents-row full-width'>
                    <div className='main-text'>범위 지정</div>
                    <div
                      className='minmax-controller contents-row flex-center'
                      style={{
                        border: '1px  solid rgb(204, 204, 204)',
                        borderRadius: '5px',
                        margin: '5px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <ReactSlider
                        className='horizontal-slider-double flex-center'
                        thumbClassName='example-thumb'
                        trackClassName='example-track'
                        // defaultValue={moddefaultMinMax}
                        min={moddefaultMinMax[0]}
                        max={moddefaultMinMax[1]}
                        value={
                          modInputMinMax[0] === undefined
                            ? props.inputMinMax
                            : modInputMinMax
                        }
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                        pearling
                        step={0.01}
                        minDistance={0.01}
                        onChange={legendMinMaxHandler}
                        onAfterChange={legendMinMaxHandler}
                        style={{ margin: '0' }}
                      ></ReactSlider>

                      <div
                        className='contents-row'
                        style={{ margin: '0 10px' }}
                      >
                        <input
                          className='input min'
                          value={
                            modInputMinMax[0] === undefined
                              ? props.inputMinMax[0]
                              : Number.isInteger(modInputMinMax[0])
                              ? modInputMinMax[0]
                              : Number(modInputMinMax[0]).toFixed(3)
                          }
                          onChange={legendMinHandler}
                          style={{ width: '80px', margin: '0' }}
                        ></input>
                        <div
                          className='divider flex-center'
                          style={{ whiteSpace: 'pre', fontSize: '21px' }}
                        >
                          {'  ~  '}
                        </div>
                        <input
                          className='input max'
                          value={
                            modInputMinMax[1] === undefined
                              ? props.inputMinMax[1]
                              : Number.isInteger(modInputMinMax[1])
                              ? modInputMinMax[1]
                              : Number(modInputMinMax[1]).toFixed(3)
                          }
                          onChange={legendMaxHandler}
                          style={{ width: '80px', margin: '0' }}
                        ></input>
                      </div>
                    </div>
                  </div>
                )}

                {/* NoData */}
                {props.curActiveLayer ? (
                  props.curActiveLayer.expt_ty === 'tif' ? (
                    <div className='contents-row full-width'>
                      <div className='main-text'>NODATA</div>
                      <div className='nodata-controller contents-row flex-center'>
                        <input
                          className='nodata-input'
                          value={modnodata}
                          onChange={handleNodata}
                        ></input>
                      </div>
                    </div>
                  ) : null
                ) : null}

                <div
                  className='contents-row full-width'
                  style={{ height: '75%' }}
                >
                  <div className='main-text'>
                    범례 항목
                    <i
                      className={`fa-solid fa-circle-question ${
                        isHovered ? 'hover' : ''
                      }`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <span
                        className={`tooltip ${isHovered ? 'hover' : ''}`}
                        dangerouslySetInnerHTML={{
                          __html: questionMarkGuide.legend,
                        }}
                      />
                    </i>
                  </div>
                  <div
                    className='legend-type flex-center contents-col'
                    style={{ justifyContent: 'space-around', margin: '5px 0' }}
                  >
                    <div className='classify-type contents-row flex-center'>
                      <Select
                        className='select legend'
                        placeholder='분류방식 선택'
                        options={
                          props.curActiveLayer &&
                          props.curActiveLayer.hasOwnProperty('geoJson')
                            ? props.curActiveLayer.geoJson.features[0].properties.hasOwnProperty(
                                modattribute
                              )
                              ? typeof props.curActiveLayer.geoJson.features[0]
                                  .properties[modattribute] === 'number'
                                ? classify_options_Vector
                                : classify_options_Vector_string
                              : null
                            : classify_options_Raster
                        }
                        value={modclassifyType}
                        onChange={handleClassifyType}
                      ></Select>
                      {modclassifyType === 6 ? (
                        <div className='contents-row'>
                          <input
                            className='classify_step'
                            value={modlegendStep}
                            readOnly
                          ></input>
                          <div className='contents-col'>
                            <button
                              className='button'
                              onClick={plusOneStep}
                              disabled
                              style={{
                                fontSize: '11px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              ▲
                            </button>
                            <button
                              className='button'
                              onClick={minusOneStep}
                              disabled
                              style={{
                                fontSize: '11px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              ▼
                            </button>
                          </div>
                        </div>
                      ) : modclassifyType != 1 ? (
                        <div className='contents-row'>
                          <input
                            className='classify_step'
                            value={modlegendStep}
                            onChange={handleLegendStep}
                            readOnly
                          ></input>
                          <div className='contents-col'>
                            <button
                              className='button'
                              onClick={plusOneStep}
                              style={{
                                fontSize: '11px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              ▲
                            </button>
                            <button
                              className='button'
                              onClick={minusOneStep}
                              style={{
                                fontSize: '11px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              ▼
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className='contents-row'>
                          <input
                            className='classify_step'
                            value={'-'}
                            disabled
                          ></input>
                          <div className='contents-col'>
                            <button
                              className='button'
                              onClick={plusOneStep}
                              disabled
                              style={{
                                fontSize: '11px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              ▲
                            </button>
                            <button
                              className='button'
                              onClick={minusOneStep}
                              disabled
                              style={{
                                fontSize: '11px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              ▼
                            </button>
                          </div>
                        </div>
                      )}
                      <button
                        style={{
                          fontFamily: 'Noto Sans KR',
                          minWidth: '50px',
                          height: '30px',
                          marginLeft: '10px',
                          fontSize: '15px',
                        }}
                        onClick={() => {
                          getNewLegend();
                        }}
                      >
                        분류
                      </button>
                    </div>
                    <Select
                      className='select colormap'
                      placeholder='컬러맵 선택'
                      options={colormap_options}
                      value={modcolormapType}
                      onChange={handleColormapType}
                    ></Select>
                    <div className='colormap-list contents-row'>
                      <div className='color contents-col'>
                        <div className='title flex-center'>색상</div>
                        {colorRendering()}
                      </div>

                      {props.curActiveLayer &&
                      props.curActiveLayer.hasOwnProperty('geoJson') ? (
                        props.curActiveLayer.geoJson.features[0].properties.hasOwnProperty(
                          modattribute
                        ) ? (
                          typeof props.curActiveLayer.geoJson.features[0]
                            .properties[modattribute] === 'number' ? (
                            <>
                              <div className='value contents-col'>
                                <div className='title flex-center'>범위</div>
                                {valueRendering()}
                              </div>
                              <div className='label contents-col'>
                                <div className='title flex-center'>라벨</div>
                                {labelRendering()}
                              </div>
                            </>
                          ) : (
                            <div className='value contents-col'>
                              <div className='title flex-center'>값</div>
                              {valueRendering()}
                            </div>
                          )
                        ) : null
                      ) : (
                        <>
                          <div className='value contents-col'>
                            <div className='title flex-center'>범위</div>
                            {valueRendering()}
                          </div>
                          <div className='label contents-col'>
                            <div className='title flex-center'>라벨</div>
                            {labelRendering()}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className='modify-legend button-group contents-row flex-center'>
            <button
              className='gc-btn cancel button'
              onClick={() => {
                props.setShowModifyLegend(false);
                let minmax = props.inputMinMax;
                modsetInputMinMax([minmax[0], minmax[1]]);
                modsetDefaultMinMax(props.defaultMinMax);
                modsetClassifyType(props.classifyType);
                modsetColormapType(props.colormapType);
                modsetLegendLabel(props.legendLabel);
                modsetLegendArea(props.legendArea);
                modsetLegendValue(props.legendValue);
                modsetLegendStep(props.legendStep);
                modsetNodata(props.nodata);
                modsetAttribute(props.attribute);
                modsetAttrOption(props.attrOption);
                modsetVisdetecLegend(props.visdetecLegend);
                modsetTitle(props.legendTitle);
              }}
            >
              취소
            </button>
            <button className='gc-btn apply button' onClick={applyLegend}>
              적용
            </button>
          </div>
        </div>
      </Modal>

      <Popup
        show={showPopup}
        onCancel={() => {
          setShowPopup(false);
        }}
        header='알림'
        icon={
          <i
            className='code x icon'
            onClick={() => {
              setShowPopup(false);
            }}
          />
        }
      >
        <div className='modal-info'>
          <p>{popupMsg}</p>
          <button
            className='close_btn'
            onClick={() => {
              setShowPopup(false);
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}

export default NewModifyLegend;

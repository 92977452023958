const APPROVE = `관리 : 내가 만든 관심구역이거나 권한이 있는 경우<br/>조회 : 권한이 있는 경우<br/>신청 : 권한이 없는 경우 권한 신청<br/>승인대기 : 권한 신청 후 승인 대기중 <br/>승인거부 : 권한신청 후 승인 거부`;
const DRAW = `영역을 직접 그려 관심구역을 선택할 수 있습니다`;
const FILE = `원하는 지역에 데이터가 있는 경우 SHP파일을 업로드할 수 있습니다`;
const NDVIGEN = `선택한 카메라의 밴드정보를 통해 만들 수 있는 식생지수만 선택할 수 있습니다`;
const CRTTYCD = `총 5가지  방식 중 사용자가 원하는 방법을 선택해서 생성할 수 있습니다`;
const LEGEND = `분류 버튼을 클릭하면, 분류 방식마다의 범위를 미리볼 수 있습니다.`;

const questionMarkGuide = {
  approve: APPROVE,
  draw: DRAW,
  file: FILE,
  ndvigen: NDVIGEN,
  crttycd: CRTTYCD,
  legend: LEGEND,
};

module.exports = questionMarkGuide;

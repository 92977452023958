import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="app-container footer">
                <div className="footer-container">
                    <div className="footer-image"></div>
                    <p className="footer-text">
                        전북 완주군 이서면 농생명로 166 | 대표번호 063-238-2000 | 팩스 063-238-3811 | 대표메일 nass@korea.kr <br></br>
                        Copyright ⓒ National Institute of Agricultural Sciences. All Rights Reserved.
                    </p>
                </div>
            </div>
        </footer>

    )
}

export default Footer;
import "./metaDataList.css";

import React, { useEffect, useState } from "react";

import axios from "axios";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: ${rotate} 1s infinite linear;
`;

function MetaDataList(props) {
  const APIToken = localStorage.getItem("login-token");

  // db에서 메타데이터 불러옴
  const [dbMetaData, setDbMetaData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [resultMessage, setResultMessage] = useState("Loading...");

  useEffect(() => {
    let obsId = JSON.parse(props.curActiveLayer.meta_data_cn).obs_id;
    axios({
      method: "get",
      url: `/api/metaData/${obsId}`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        if (result.data.data !== null) {
          setDbMetaData(result.data.data);
          setIsLoading(false);
        } else {
          setResultMessage("해당되는 메타데이터가 없습니다.");
        }
      })
      .catch((error) => {});
  }, []);

  if (isLoading) {
    return (
      <div
        className="metadata-detail-form"
        style={{
          display: "flex",
          flexDirection:"column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {resultMessage === "Loading..." ? (
          <>
            <Spinner />
            <div className="metadata-maintext">Loading...</div>
          </>
        ) : (
          <React.Fragment>
            <div className="metadata-maintext">{resultMessage}</div>
            {props.isTablet && 
              <div className="btn-bottom">
                <button onClick={props.toggleMetaDetail} className="gc-btn green">뒤로가기</button>
              </div>
            }
          </React.Fragment>
        )}
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="metadata-detail-form">
        <div className="metadata-maintext">비행체/탑재센서 정보</div>
        <div className="metadata-dronesensor contents-col">
          <div className="contents-row">
            <div className="contents-row full-width">
              <div className="metadata-title">드론 제작사</div>
              <div className="metadata-content">
                {dbMetaData.drone_mkr_nm ? dbMetaData.drone_mkr_nm : "-"}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">드론 모델명</div>
              <div className="metadata-content">
                {dbMetaData.drone_model_nm ? dbMetaData.drone_model_nm : "-"}
              </div>
            </div>
          </div>
          <div className="contents-row">
            <div className="contents-row full-width">
              <div className="metadata-title">기체타입</div>
              <div className="metadata-content">
                {dbMetaData.drone_type ? dbMetaData.drone_type : "-"}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">센서 제작사</div>
              <div className="metadata-content">
                {dbMetaData.sensor_mkr_nm ? dbMetaData.sensor_mkr_nm : "-"}
              </div>
            </div>
          </div>
          <div className="contents-row">
            <div className="contents-row full-width">
              <div className="metadata-title">센서 모델명</div>
              <div className="metadata-content">
                {dbMetaData.sensor_model_nm ? dbMetaData.sensor_model_nm : "-"}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">센서 고유번호</div>
              <div className="metadata-content">
                {dbMetaData.sensor_serial_num
                  ? dbMetaData.sensor_serial_num
                  : "-"}
              </div>
            </div>
          </div>
        </div>
        <div className="metadata-maintext">촬영정보</div>
        <div className="metadata-film contents-col">
          <div className="contents-row full-width">
            <div className="contents-row full-width">
              <div className="metadata-title">촬영기관</div>
              <div className="metadata-content">
                {dbMetaData.inst_nm ? dbMetaData.inst_nm : "-"}
                {/* {instNm} */}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">촬영장소</div>
              <div className="metadata-content">
                {dbMetaData.shoot_place
                  ? dbMetaData.shoot_place.substr(0, 8)
                  : "-"}
              </div>
            </div>
          </div>
          <div className="contents-row">
            <div className="contents-row full-width">
              <div className="metadata-title">촬영대상</div>
              <div className="metadata-content">
                {dbMetaData.shoot_trgt ? dbMetaData.shoot_trgt : "-"}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">촬영일</div>
              <div className="metadata-content">
                {dbMetaData.shoot_start_dt
                  ? dbMetaData.shoot_start_dt.match(/\d{4}-\d{2}-\d{2}/)[0]
                  : "-"}
              </div>
            </div>
          </div>
          <div className="contents-row">
            <div className="contents-row full-width">
              <div className="metadata-title">시작시간</div>
              {/* <div className="metadata-content">{dbMetaData.shoot_start_dt? dbMetaData.shoot_start_dt.substr(0,10) : '-'}</div> */}
              <div className="metadata-content">
                {dbMetaData.shoot_start_dt ? dbMetaData.shoot_start_dt : "-"}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">종료시간</div>
              {/* <div className="metadata-content">{dbMetaData.shoot_end_dt? dbMetaData.shoot_end_dt.substr(0,10) : '-'}</div> */}
              <div className="metadata-content">
                {dbMetaData.shoot_end_dt ? dbMetaData.shoot_end_dt : "-"}
              </div>
            </div>
          </div>
          <div className="contents-row">
            <div className="contents-row full-width">
              <div className="metadata-title">풍속</div>
              <div className="metadata-content">
                {(dbMetaData.wspd_min ? dbMetaData.wspd_min : "") +
                  " ~ " +
                  (dbMetaData.wspd_max ? dbMetaData.wspd_max : "")}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">운량</div>
              <div className="metadata-content">
                {dbMetaData.clouds ? dbMetaData.clouds : "-"}
              </div>
            </div>
          </div>
          <div className="contents-row">
            <div className="contents-row full-width">
              <div className="metadata-title">촬영간격</div>
              <div className="metadata-content">
                {dbMetaData.shoot_interval ? dbMetaData.shoot_interval : "-"}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">촬영목적</div>
              <div className="metadata-content">
                {dbMetaData.shoot_purpose ? dbMetaData.shoot_purpose : "-"}
              </div>
            </div>
          </div>
          <div className="contents-row">
            <div className="contents-row full-width">
              <div className="metadata-title">비행고도</div>
              <div className="metadata-content">
                {dbMetaData.flt_alt ? dbMetaData.flt_alt : "-"}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">비행속도</div>
              <div className="metadata-content">
                {dbMetaData.flt_spd ? dbMetaData.flt_spd : "-"}
              </div>
            </div>
          </div>
          <div className="contents-row">
            <div className="contents-row full-width">
              <div className="metadata-title">비행방향</div>
              <div className="metadata-content">
                {dbMetaData.flt_drct ? dbMetaData.flt_drct : "-"}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">중복도(종,횡)</div>
              <div className="metadata-content">
                {dbMetaData.path_rdd_col !== undefined &&
                dbMetaData.path_rdd_row !== undefined
                  ? "종 : " +
                    dbMetaData.path_rdd_col +
                    " / 횡 : " +
                    dbMetaData.path_rdd_row
                  : "-"}
              </div>
            </div>
          </div>
          <div className="contents-row">
            <div className="contents-row full-width">
              <div className="metadata-title">지상보정판 여부</div>
              <div className="metadata-content">
                {dbMetaData.grnd_crct_yn !== null
                  ? JSON.stringify(dbMetaData.grnd_crct_yn)
                  : "-"}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">열조건 설정(기온)(°C)</div>
              <div className="metadata-content">
                {dbMetaData.heat_cnd_stng_tp !== undefined &&
                dbMetaData.heat_cnd_stng_tp !== null
                  ? dbMetaData.heat_cnd_stng_tp
                  : "-"}
              </div>
            </div>
          </div>
          <div className="contents-row">
            <div className="contents-row full-width">
              <div className="metadata-title">
                열조건 설정
                <br />
                (상대습도)(%)
              </div>
              <div className="metadata-content">
                {dbMetaData.heat_cnd_stng_hum !== undefined &&
                dbMetaData.heat_cnd_stng_hum !== null
                  ? dbMetaData.heat_cnd_stng_hum
                  : "-"}
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="metadata-title">열조건 설정(방사율)</div>
              <div className="metadata-content">
                {dbMetaData.heat_cnd_stng_ems !== undefined &&
                dbMetaData.heat_cnd_stng_ems !== null
                  ? dbMetaData.heat_cnd_stng_ems
                  : "-"}
              </div>
            </div>
          </div>
        </div>
        {dbMetaData.img_prcs_yn ? (
          <div className="metadata-maintext">영상처리 정보</div>
        ) : null}
        {dbMetaData.img_prcs_yn ? (
          <div className="metadata-imageprocess contents-col">
            <div className="contents-row full-width">
              <div className="contents-row full-width">
                <div className="metadata-title">프로그램 명칭</div>
                <div className="metadata-content">
                  {dbMetaData.progrm_nm ? dbMetaData.progrm_nm : "-"}
                </div>
              </div>
              <div className="contents-row full-width">
                <div className="metadata-title">프로그램 버전</div>
                <div className="metadata-content">
                  {dbMetaData.progrm_ver ? dbMetaData.progrm_ver : "-"}
                </div>
              </div>
            </div>
            <div className="contents-row">
              <div className="contents-row full-width">
                <div className="metadata-title">좌표계</div>
                <div className="metadata-content">
                  {dbMetaData.epsg_cd ? dbMetaData.epsg_cd : "-"}
                </div>
              </div>
              <div className="contents-row full-width">
                <div className="metadata-title">복사보정 수준</div>
                <div className="metadata-content">
                  {dbMetaData.rdt_level ? dbMetaData.rdt_level : "-"} /{" "}
                  {dbMetaData.rdt_level_add_cn
                    ? dbMetaData.rdt_level_add_cn
                    : "-"}
                </div>
              </div>
            </div>
            <div className="contents-row">
              {/* <div className="contents-row full-width">
              <div className="metadata-title">복사보정 추가내용</div>
              <div className="metadata-content">{"-"}</div>
            </div> */}
              <div className="contents-row full-width">
                <div className="metadata-title">기하보정 수준</div>
                <div className="metadata-content">
                  {dbMetaData.geom_level ? dbMetaData.geom_level : "-"}
                </div>
              </div>
              <div className="contents-row full-width">
                <div className="metadata-title">태양광반사점</div>
                <div className="metadata-content">
                  {dbMetaData.sun_glint ? dbMetaData.sun_glint : "-"}
                </div>
              </div>
            </div>
            <div className="contents-row full-width">
              <div className="contents-row full-width">
                <div className="metadata-title">비네트 효과</div>
                <div className="metadata-content">
                  {dbMetaData.vnet_efct ? dbMetaData.vnet_efct : "-"}
                </div>
              </div>
              <div className="contents-row full-width">
                <div className="metadata-title">블러링 효과</div>
                <div className="metadata-content">
                  {dbMetaData.blur_efct ? dbMetaData.blur_efct : "-"}
                </div>
              </div>
            </div>
            <div className="contents-row">
              <div className="contents-row full-width">
                <div className="metadata-title">보고서 여부</div>
                <div className="metadata-content">
                  {dbMetaData.report_yn ? dbMetaData.report_yn : "-"}
                </div>
              </div>
              <div className="contents-row full-width">
                <div className="metadata-title">파일용량</div>
                <div className="metadata-content">
                  {dbMetaData.img_file_size ? dbMetaData.img_file_size : "-"}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {props.isTablet && 
          <div className="btn-bottom">
            <button onClick={props.toggleMetaDetail} className="gc-btn green">뒤로가기</button>
          </div>
        }
      </div>
    </React.Fragment>
  );
}
export default MetaDataList;

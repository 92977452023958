import { useMediaQuery } from "react-responsive";

const useResponsive = () => {
  const isTabletHeight = useMediaQuery({ query: "(max-height: 780px)" });
  const isTabletWidth = useMediaQuery({
    query:
      "(min-width: 501px) and (max-width: 1074px) and (orientation: portrait)",
  });

  return {
    isTabletHeight,
    isTabletWidth,
  };
};

export default useResponsive;

import React from 'react';
import "./InputField.style.css"

const InputField = ({
  active,
  onClick,
  text,
  onClickhandler,
  onChangehandler,
  data,
  placeholder,
  children,
  readOnly
}) => {

  const classNames = [
    'form-control',
    active === 2 ? 'viewer' : '', 
    readOnly ? 'read-only' : ''].filter(Boolean).join(' ');

  return (
  <div className="input-field-container">
    {active === 2 ? (
      <div className={`form-control padding`}
        onClick={onClick}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
      >
        {text ? text : '입력하지 않은 데이터 입니다.'}
      </div>
    ) : (
      <div className="input-wrapper ">
        <input
          className={classNames}
          type="text"
          onClick={onClickhandler}
          onChange={onChangehandler}
          autoComplete="off"
          value={active === 2 ? text : data}
          spellCheck="false"
          disabled={active === 2}
          placeholder={placeholder}
        />
        {children && <div className="input-children">{children}</div>}
      </div>
    )}
  </div>
  );
};

export default InputField;

import "./instSearch.css";

import React, { useEffect, useState } from "react";
import { Pagination, Icon } from "semantic-ui-react";
import Modal from "../../../shared/components/uiElements/Modal";
import InstAdd from "./instAdd";

// AXIOS
import axios from "axios";

function InstSearch(props) {
  const APIToken =
    "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJhZG1pbl9kZXYiLCJ1c2VyVHkiOjMsImluc3RJZCI6MiwibGV2ZWxDZCI6MjAsImlhdCI6MTY4MDIyNzg5OCwiZXhwIjoxNzE2MjI3ODk4fQ.TrrO-oFeSCGprkMoff517tY9mGKeCfA19W9LoA-VWV9wYBuLJdtwmjen8sSj7Fxy3bsx7m3RgCVj5Ezfw0Eyug";

  const [showInstPopup, setShowInstPopup] = useState(false);

  const [showList, setShowList] = useState(true);

  const [instAddFinish, setInstAddFinish] = useState(false);

  // 기관 검색
  const [searchInst, setSearchInst] = useState("");
  const [searchInstResults, setSearchInstResults] = useState([]);

  // 페이징
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 5;
  let calculatedTotalPages = "";
  let totalCount = "";
  const handlePageChange = (event, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handleInstSearch = () => {
    axios({
      method: "get",
      //   url:
      //     `/api/inst/list?` +
      //     `instNm=${searchInst}&instTy=${props.userTy}&page=${currentPage}&perPage=${itemsPerPage}`,
      url:
        `/api/inst/allList?` +
        `instNm=${searchInst}&instTy=${props.userTy}&page=${currentPage}&perPage=${itemsPerPage}`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        const dummyArray = [];
        const allDataResults = result.data.data;
        allDataResults &&
          allDataResults.map((e) => {
            if (e.aprv_stts_cd != 3 && e.actvtn_cd == 1) {
              dummyArray.push(e);
            }
          });
        setSearchInstResults(dummyArray);
        totalCount = result.data.total;
        calculatedTotalPages = Math.ceil(totalCount / itemsPerPage);
        setTotalPages(calculatedTotalPages);
        setShowList(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const instAddClick = () => {
    setShowInstPopup(true);
  };

  useEffect(() => {
    handleInstSearch();
  }, [props.userTy, currentPage]);

  const handleInstInput = (e, data) => {
    e.preventDefault();
    setSearchInst(e.target.value);
  };

  useEffect(() => {
    if (instAddFinish) {
      handleInstSearch();
    }
  }, [instAddFinish]);

  return (
    <Modal
      className="InstSearch-Modal"
      show={props.showInstPopup}
      onCancel={() => props.setShowInstPopup(false)}
      header={
        props.userTy == 1
          ? "영농조합 검색"
          : props.userTy == 2
          ? "영농조합 검색"
          : props.userTy == 3
          ? "기관 검색"
          : props.userTy == 4
          ? "업체 검색"
          : // props.userTy == 5? "기관신청 (기타)" :
            null
      }
      icon={
        <i
          className="code x icon"
          onClick={() => props.setShowInstPopup(false)}
        />
      }
      isMobile={props.isMobile}
    >
      <div className="instsearch-container">
        <div className="main">
          <div className="text">
            {props.userTy == 1
              ? "조합명"
              : props.userTy == 2
              ? "조합명"
              : props.userTy == 3
              ? "기관명"
              : props.userTy == 4
              ? "업체명"
              : // props.userTy == 5? "기관명" :
                null}
          </div>
          <input
            className="input"
            type="text"
            value={searchInst}
            onChange={handleInstInput}
          ></input>
          <button className="button" onClick={handleInstSearch}>
            검색
          </button>
          <div className="gc-btn button apply" onClick={instAddClick}>
            신청
          </div>
        </div>
        {/* <div className="main">
                <div className="text">조합명</div>
                <input 
                    className="input" 
                    type="text"
                    value={searchFilters.inst_nm}
                    onChange={(e) => setSearchFilters({ ...searchFilters, inst_nm: e.target.value })}
                ></input>
                <button className="button" onClick={handleInstSearch}>검색</button>
                <button className="button" onClick={handleSearch}>검색</button>
                <button className="button" onClick={()=>{
                    setShowList(true);
                    getList();
                }}>검색</button>
            </div> */}
        <InstAdd
          showInstPopup={showInstPopup}
          setShowInstPopup={setShowInstPopup}
          instAddFinish={instAddFinish}
          setInstAddFinish={setInstAddFinish}
          instTy={props.userTy}
        ></InstAdd>
        {showList ? (
          <div className="list">
            <table className="table">
              <thead>
                <tr>
                  <th>기관명</th>
                  <th>기관코드</th>
                </tr>
              </thead>
              <tbody>
                {searchInstResults &&
                  searchInstResults.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td
                          width="70%"
                          onClick={() => {
                            props.setShowInstPopup(false);
                            props.handleInstId(e.inst_id);
                            props.handleInstNm(e.inst_nm);
                          }}
                        >
                          {e.inst_nm}
                        </td>
                        <td width="30%">{e.inst_cd}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : null}

        <div className="pagination">
          <Pagination
            className="pagination"
            activePage={currentPage}
            totalPages={totalPages}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            onPageChange={handlePageChange}
          ></Pagination>
        </div>
      </div>
    </Modal>
  );
}

export default InstSearch;

import React from 'react';
import "./Label.style.css"

const Label = ({ required, label, explan, active, children }) => {
  
  const classNames = [
    'input-field-label',
    children ? 'relative' : ''].filter(Boolean).join(' ');

  return (
    <div className='text-area'>
      <label className={classNames}>
        {active !== 2 ? (required && <span className="required">*</span>) : null}
        {label}
        {active !== 2 ? children : null}
      </label>
      <span className="explan">{explan}</span>
    </div>
  );
};

export default Label;
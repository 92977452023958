import "./farmworkDelete.css";
import React, { useEffect, useState } from "react";

import Modal from "../../../shared/components/uiElements/Modal";
import Popup from "../../../shared/components/uiElements/Popup";

import axios from "axios";

function FarmworkDelete(props) {
  const APIToken = localStorage.getItem("login-token");

  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState();

  const onDeleteFarmwork = () => {
    if (props.selectedFrmwrkId.length === 0) {
      setPopupMsg("선택된 농작업이 없습니다.");
      setShowPopup(true);
      return;
    }

    axios({
      method: "post",
      url: `/api/farmwork/delete`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      data: {
        selectedFrmwrkId: props.selectedFrmwrkId,
        selectedMetaId: props.selectedMetaId,
      },
    })
      .then((result) => {
        if (result.data.success) {
          props.setLoading(false);
          console.log(result);
          // props.setShowDeleteFarmworkModal(false);
          setPopupMsg("농작업 삭제가 완료되었습니다.");
          setShowPopup(true);
          props.setSelectedFrmwrkId([]);
          props.setSelectedMetaId([]);
          // if (props.selectItrst) {
          //   axios({
          //     method: "get",
          //     url:
          //       `/api/farmwork/list?` +
          //       `zoneId=${props.selectItrst.itrst_zone_id}`,
          //     headers: {
          //       Authorization: `Bearer {${APIToken}}`,
          //     },
          //   })
          //     .then((result) => {
          //       console.log("result : ", result);
          //       if (result.data.success) {
          //         props.setSelectedFrmwrkId([]);
          //         props.setSelectedMetaId([]);
          //         props.farmworkListHandler(result.data.data);
          //         props.setTotalCount(result.data.data.length);
          //         props.setCurrentPage(1);
          //       } else {
          //         props.setSelectedFrmwrkId([]);
          //         props.setSelectedMetaId([]);
          //         props.farmworkListHandler();
          //         props.setTotalCount(0);
          //         props.setTotalPages(0);
          //       }
          //     })
          //     .catch((error) => {
          //       console.log(error);
          //     });
          // }
          props.setSearchFlag(true);
        } else {
          props.setLoading(false);
          // props.setShowDeleteFarmworkModal(false);
          setPopupMsg("농작업 삭제 실패");
          setShowPopup(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <Modal
        show={props.showDeleteFarmworkModal}
        header="농작업 삭제"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              props.setShowDeleteFarmworkModal(false);
            }}
          />
        }
        className={"farmwork"}
        isMobile={props.isMobile}
      >
        <div className="farmwork-delete">
          <div className="title-text">
            선택된 {props.selectedFrmwrkId.length}개를 삭제하시겠습니까?
          </div>
        </div>
        <div className="button-group flex-center">
          <button
            className="gc-btn cancel"
            onClick={() => {
              props.setShowDeleteFarmworkModal(false);
            }}
          >
            취소
          </button>
          <button
            className="gc-btn delete"
            onClick={() => {
              onDeleteFarmwork();
            }}
          >
            삭제
          </button>
        </div>
      </Modal>

      <Popup
        show={showPopup}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              props.setShowDeleteFarmworkModal(false);
              setShowPopup(false);
            }}
          />
        }
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);
              props.setShowDeleteFarmworkModal(false);
              // props.setSelectedFarm();
              // props.onFarmmapHandler(false);
              // // props.setRequestFarmwork(true);
              // props.farmworkSelectHandler(false);
              // props.setShowTheme(true);
              // props.searchFarmworkList();
              // props.setRequestFarmwork(false);
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}

export default FarmworkDelete;

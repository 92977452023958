// import React, { useEffect } from 'react';
// import { useState } from 'react';
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import Backdrop from "./Backdrop";
import "./Modal.css";
import React, { useState, useEffect, useRef } from "react";
import useResponsive from "../../hooks/screenSize-hook";

const ModalOverlay = (props) => {
  const [header, setHeader] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isFixed, setIstFixed] = useState(false);
  // 태블릿 높이일 때
  const { isTabletHeight, isTabletWidth } = useResponsive();

  useEffect(() => {
    setHeader(props.header);
    setIsMobile(props.isMobile);
    setIstFixed(props.isFixed);
  }, [props.header, props.isMobile, props.isFixed]);

  //모달 위치변경
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 }); //마우스 포인터 위치
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 }); // 모달 현재 위치
  const modalRef = useRef(null);

  const [height, setHeight] = useState({});
  const modalcontentRef = useRef(null);

  // 모달의 초기 중앙 위치 계산
  useEffect(() => {
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2.3;
    setModalPosition({ x: centerX, y: centerY });
  }, []);

  useEffect(() => {
    if (isTabletHeight && modalcontentRef.current) {
      const contentHeight = modalcontentRef.current.scrollHeight;
      const containerHeight = window.innerHeight * 0.9;

      if (contentHeight < containerHeight) {
        setHeight({ height: "auto" });
      } else {
        setHeight({
          height: "calc(100% - var(--header-height) - var(--footer-height))",
        });
      }
    } else {
      setHeight({});
    }
  }, [isTabletHeight, props.children]);

  useEffect(() => {
    if (isFixed) return;

    const handleMouseMove = (event) => {
      if (!isDragging) return;
      const offsetX = event.clientX - dragStartPosition.x;
      const offsetY = event.clientY - dragStartPosition.y;

      setModalPosition((prevPosition) => ({
        x: prevPosition.x + offsetX,
        y: prevPosition.y + offsetY,
      }));

      setDragStartPosition({ x: event.clientX, y: event.clientY });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, dragStartPosition, isFixed]);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStartPosition({ x: event.clientX, y: event.clientY });
  };

  const combinedStyle = {
    ...(isTabletHeight || isTabletWidth
      ? {
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          margin: 0,
        }
      : {
          top: 50,
          left: 50,
          transform: `translate(-50%, -50%) translate(${modalPosition.x}px, ${modalPosition.y}px)`,
        }),
    ...props.style,
    ...(isTabletHeight && {
      ...height,
    }),
  };

  const content = (
    <div
      className={`modal ${props.className}`}
      ref={modalRef}
      style={!isMobile ? combinedStyle : {}}
    >
      {header ? (
        <header
          className={`modal__header ${props.headerClass}`}
          onMouseDown={handleMouseDown}
        >
          <span className="modal_header">{props.header}</span>
          <span className="modal_icon" style={props.style}>
            {props.icon}
          </span>
        </header>
      ) : (
        <span className="modal_icon" style={props.style}>
          {props.icon}
        </span>
      )}
      <form
        onSubmit={
          props.onSubmit ? props.onSubmit : (event) => event.preventDefault()
        }
      >
        <div
          className={`modal__content ${props.contentClass} custom-scrollbar`}
          ref={modalcontentRef}
          style={
            isTabletHeight || isMobile ?
            {
              overflowY: "scroll",
              overflowX: "hidden",
              WebkitOverflowScrolling: "touch",
            } : {}
          }
        >
          {props.children}
        </div>
        {/* <footer className={`modal__footer ${props.footerClass}`}>
          {props.footer}
        </footer> */}
      </form>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;

import config from "../../../config/config";
import "./Landing.css";

import { Link } from "react-router-dom";

function Landing(props) {
  return (
    <div className="contents-col landing-container">
      <div className="landing-logo"></div>
      <div className="flex-center main-title landing-desktop">
        드론 영상관측 및 농작업 정보 시스템
      </div>
      <div className="flex-center main-title landing-mobile">
        드론 영상관측 및 <br /> 농작업 정보 시스템
      </div>
      <div className="contents-row sub-menu">
        <a href="/home" className="menu-container">
          <div className="sub-title">드론 활용 플랫폼</div>
          <div className="sub-info">
            <p>농작업 데이터 수집 / 관리</p>
            <p>드론 데이터 가공 / 주제도 작성</p>
            <p>농작업 영상정보 관리</p>
          </div>
        </a>
        <a href={`${config.metaSystemUrl}`} className="menu-container">
          <div className="sub-title">메타데이터 수집 시스템</div>
          <div className="sub-info">
            <p>드론 관측, 농작업 메타데이터 관리</p>
            <p>고유 식별자 생성</p>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Landing;

import "./instAdd.css";

import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import Modal from "../../../shared/components/uiElements/Modal";
import Popup from "../../../shared/components/uiElements/Popup";
// AXIOS
import axios from "axios";

function InstAdd(props) {
  const APIToken = localStorage.getItem("login-token");

  // 등록성공
  const [isOK, setIsOK] = useState(false);

  const [instNm, setInstNm] = useState();
  const [instCd, setInstCd] = useState();
  const [instTy, setInstTy] = useState(1);

  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");

  const [instType, setInstType] = useState("");

  const handleName = (event) => {
    setInstNm(event.target.value);
  };

  const handleCode = (event) => {
    let condValue = "";
    let lowercaseValue = "";
    condValue = event.target.value;
    lowercaseValue = condValue.toLowerCase().replace(/[^a-z]/g, "");
    setInstCd(lowercaseValue);
  };

  const handleType = (event, data) => {
    setInstTy(data.value);
  };

  const options = [
    { key: 1, text: "개인(영농조합)", value: 1 },
    { key: 2, text: "영농조합 관리자", value: 2 },
    { key: 3, text: "기관", value: 3 },
    { key: 4, text: "농작업 수행업체", value: 4 },
    // { key: 5, text: '기타', value: 5 },
  ];

  const addInst = () => {
    axios({
      method: "post",
      url: `/api/inst/add`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      data: {
        instNm: instNm,
        instTy: instTy,
        instCd: instCd,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setPopupMsg("신청이 완료되었습니다.");
          setShowPopup(true);
          props.setShowInstPopup(false);
          setInstCd("");
          setIsOK(true);
          if (
            props.setInstAddFinish !== undefined &&
            props.setInstAddFinish !== null
          ) {
            props.setInstAddFinish(true);
          }
        } else {
          setShowPopup(true);
          setPopupMsg(result.data.msg);
          setInstCd("");
          if (
            props.setInstAddFinish !== undefined &&
            props.setInstAddFinish !== null
          ) {
            props.setInstAddFinish(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (
      props.setInstAddFinish !== undefined &&
      props.setInstAddFinish !== null
    ) {
      props.setInstAddFinish(false);
      if (props.instTy === "1") {
        setInstType("개인(영농조합)");
        setInstTy(1);
      } else if (props.instTy === "2") {
        setInstType("영농조합 관리자");
        setInstTy(2);
      } else if (props.instTy === "3") {
        setInstType("기관");
        setInstTy(3);
      } else if (props.instTy === "4") {
        setInstType("농작업 수행업체");
        setInstTy(4);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Modal
        // header={header}
        className="InstAdd-Modal"
        show={props.showInstPopup}
        onCancel={() => props.setShowInstPopup(false)}
        icon={
          <i
            className="code x icon"
            onClick={() => props.setShowInstPopup(false)}
          />
        }
        isMobile={props.isMobile}
      >
        <div className="instadd-container">
          <div className="main-text">
            <div className="textA">기관 등록 요청</div>
            <div className="textB">추가할 기관 정보를 입력하세요.</div>
          </div>

          <div className="division"></div>

          <div className="name contents-row">
            <div className="sub-text">기관명</div>
            <input className="input" type="text" onChange={handleName}></input>
          </div>

          <div className="code contents-row">
            <div className="sub-text">기관 코드</div>
            <input
              className="input"
              type="text"
              maxLength={4}
              value={instCd}
              onChange={handleCode}
              placeholder="영문 소문자 4자리"
            ></input>
          </div>

          <div className="type contents-row">
            <div className="sub-text">기관 종류</div>
            {props.setInstAddFinish !== undefined &&
            props.setInstAddFinish !== null ? (
              <input
                type="text"
                className="input"
                value={instType}
                disabled={true}
              />
            ) : (
              <Dropdown
                className="input"
                selection
                options={options}
                onChange={handleType}
              ></Dropdown>
            )}
          </div>

          <button className="button" onClick={addInst}>
            요청
          </button>

          {/* <div className="link">이전으로</div> */}
        </div>
      </Modal>

      <Popup
        show={showPopup}
        onCancel={() => {
          setShowPopup(false);
          if (isOK) {
            props.setShowInstPopup(false);
            if (window.location.pathname == "/adminmode") {
              window.location.reload();
            }
          }
        }}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowPopup(false);
              if (isOK) {
                props.setShowInstPopup(false);
                if (window.location.pathname == "/adminmode") {
                  window.location.reload();
                }
              }
            }}
          />
        }
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);
              if (isOK) {
                props.setShowInstPopup(false);
                if (window.location.pathname == "/adminmode") {
                  window.location.reload();
                }
              }
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}

export default InstAdd;

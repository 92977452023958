import "./adminmode.css";
import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Pagination, Icon } from "semantic-ui-react";

import InstAdd from "../components/instAdd";
import Popup from "../../../shared/components/uiElements/Popup";

// AXIOS
import axios from "axios";

// DatePicker
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

function Adminmode(props) {
  const APIToken = localStorage.getItem("login-token");

  const [instList, setInstList] = useState(null);

  const [showInstPopup, setShowInstPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");

  const pageSize = 10;

  const statusOptions = [
    { key: 1, text: "대기 중", color: "blue", value: 1 },
    { key: 2, text: "승인 완료", color: "red", value: 2 },
    { key: 3, text: "승인 거절", color: "red", value: 3 },
  ];

  // 승인여부
  const [aprvSttsCd, setAprvSttsCd] = useState(false);
  const [aprvSttsRrefusal, setAprvSttsRrefusal] = useState(false);
  const handleChange = (event, data, code) => {
    if (data.value === 2) {
      axios({
        method: "post",
        url: `/api/inst/aprv`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: {
          instCd: code,
          aprv: "true",
        },
      })
        .then((result) => {
          setAprvSttsCd(true);
          instSearchList();
        })
        .catch((error) => {});
    } else if (data.value === 3) {
      axios({
        method: "post",
        url: `/api/inst/aprv`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: {
          instCd: code,
          aprv: "false",
        },
      })
        .then((result) => {
          setAprvSttsRrefusal(true);
          instSearchList();
        })
        .catch((error) => {});
    }
  };
  const [instNm, setInstNm] = useState("");
  const [instCd, setInstCd] = useState("");

  const today = new Date();
  const lastYear = new Date();
  lastYear.setFullYear(today.getFullYear() - 1);

  const [startYmd, setStartYmd] = useState(lastYear);
  const [endYmd, setEndYmd] = useState(today);

  const [sttsWaiting, setSttsWaiting] = useState(true); // 대기
  const [sttsAprved, setSttsAprved] = useState(true); // 승인
  const [sttsDenied, setSttsDenied] = useState(true); // 거부

  const handleInstNm = (e) => {
    e.preventDefault();
    setInstNm(e.target.value);
  };
  const handleInstCd = (e) => {
    e.preventDefault();
    setInstCd(e.target.value);
  };

  const handleSttsWaiting = () => {
    setSttsWaiting(!sttsWaiting);
  };
  const handleSttsAprved = () => {
    setSttsAprved(!sttsAprved);
  };
  const handleSttsDenied = () => {
    setSttsDenied(!sttsDenied);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  let calculatedTotalPages = "";
  let totalCount = "";
  const handlePageChange = (event, { activePage }) => {
    setCurrentPage(activePage);
  };

  // 초기화
  const [isClear, setIsClear] = useState(false);
  const onClearSearch = () => {
    setInstNm("");
    setInstCd("");
    setStartYmd(lastYear);
    setEndYmd(today);
    setSttsWaiting(true);
    setSttsAprved(true);
    setSttsDenied(true);
    setIsClear(true);
    // instSearchList();
  };

  // 기관 비활성화
  const [disabledOk, setDisabledOk] = useState(false);

  const disabledClick = () => {
    const alreadyDisabled = [];

    instList.forEach((item) => {
      if (selectedItems.includes(item.inst_id) && item.actvtn_cd === 2) {
        alreadyDisabled.push(item.inst_id);
      }
    });

    if (alreadyDisabled.length > 0) {
      setPopupMsg("이미 비활성화된 기관입니다.");
      setShowPopup(true);
    } else {
      axios({
        method: "post",
        url: `/api/inst/status`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: {
          instId: JSON.stringify(selectedItems),
          actvtnCd: 2,
        },
      })
        .then((result) => {
          const updatedList = instList.map((item) => {
            if (selectedItems.includes(item.inst_id)) {
              return {
                ...item,
              };
            }
            return item;
          });
          setInstList(updatedList);

          if (result.data.success) {
            setPopupMsg("선택하신 기관이 비활성화 되었습니다.");
            setShowPopup(true);
            setDisabledOk(true);
            // instSearchList();
            setSelectedItems([]);
          }
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    instSearchList();
  }, [currentPage, aprvSttsCd, aprvSttsRrefusal, disabledOk, isClear]);

  // 기관 검색
  const instSearchList = () => {
    axios({
      method: "get",
      url: `/api/inst/list?instNm=${instNm}&instCd=${instCd}&sttsWaiting=${sttsWaiting}&sttsAprved=${sttsAprved}&sttsDenied=${sttsDenied}&strtYmd=${startYmd.toDateString()}&endYmd=${endYmd.toDateString()}&page=${currentPage}&perPage=${pageSize}`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        setInstList(result.data.data);
        totalCount = result.data.total;
        calculatedTotalPages = Math.ceil(totalCount / pageSize);
        setTotalPages(calculatedTotalPages);
        setCurrentPage(currentPage);
      })
      .catch((error) => {});
  };

  // 드론 영상 메타데이터 체크박스
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemSelect = (itemId) => {
    if (Array.isArray(selectedItems)) {
      if (selectedItems.includes(itemId)) {
        setSelectedItems(selectedItems.filter((item) => item !== itemId));
      } else {
        setSelectedItems([...selectedItems, itemId]);
      }
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allItemIds = instList && instList.map((item) => item.inst_id);
      setSelectedItems(allItemIds);
    }
    setSelectAll(!selectAll);
  };

  const isItemSelected = (itemId) => {
    return selectedItems.includes(itemId);
  };

  let areAllItemsSelected = false;
  if (instList) {
    areAllItemsSelected = selectedItems.length === instList.length;
  }

  const handleRowClick = (index) => {
    const clickedItemId = instList[index].inst_id;
    if (selectedItems.includes(clickedItemId)) {
      setSelectedItems(selectedItems.filter((item) => item !== clickedItemId));
    } else {
      setSelectedItems([...selectedItems, clickedItemId]);
    }
  };

  return (
    <div
      className="adminmode-container"
      onClick={(e) => {
        if (props.showSubmenu) {
          props.handleShowSubmenu(false);
        }
      }}
    >
      <div className="adminmode-contents">
        <div className="main-title">등록 기관 관리</div>
        <div className="main-text">검색</div>
        <div className="Search">
          <table className="content">
            <thead>
              <tr>
                <td width="10%" height="40px">
                  기관명
                </td>
                <th width="40%">
                  <input
                    type="text"
                    className="input"
                    value={instNm || ""}
                    onChange={handleInstNm}
                  ></input>
                </th>
                <td width="10%" height="40px">
                  등록일
                </td>
                <th width="40%">
                  <div className="datepick">
                    <div className="datepicker-container">
                      <DatePicker
                        className="datepicker"
                        selected={startYmd}
                        onChange={(date) => setStartYmd(date)}
                        dateFormat="yyyy-MM-dd"
                        maxDate={endYmd}
                        locale={ko}
                      ></DatePicker>
                      <i className="calendar alternate outline icon" />
                    </div>
                    <div className="divisionC">~</div>

                    <div className="datepicker-container">
                      <DatePicker
                        className="datepicker"
                        selected={endYmd}
                        onChange={(date) => setEndYmd(date)}
                        dateFormat="yyyy-MM-dd"
                        minDate={startYmd}
                        maxDate={0}
                        locale={ko}
                      ></DatePicker>
                      <i className="calendar alternate outline icon" />
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <td width="10%" height="40px">
                  기관코드
                </td>
                <th width="40%">
                  <input
                    type="text"
                    className="input"
                    value={instCd || ""}
                    onChange={handleInstCd}
                  ></input>
                </th>
                <td width="10%" height="40px">
                  등록상태
                </td>
                <th width="40%">
                  <div className="check-container">
                    <Checkbox
                      checked={sttsAprved}
                      onChange={handleSttsAprved}
                    ></Checkbox>
                    <div className="text typeA">승인완료</div>
                    <Checkbox
                      checked={sttsWaiting}
                      onChange={handleSttsWaiting}
                    ></Checkbox>
                    <div className="text typeB">대기중</div>
                    <Checkbox
                      checked={sttsDenied}
                      onChange={handleSttsDenied}
                    ></Checkbox>
                    <div className="text typeC">승인거절</div>
                  </div>
                </th>
              </tr>
            </thead>
            {/* <tbody></tbody> */}
          </table>
          <div className="contents-row search-btn">
            <button className="btn ok" onClick={instSearchList}>
              검색
            </button>
            <button className="btn init" onClick={onClearSearch}>
              초기화
            </button>
          </div>
        </div>

        <div className="main-text">기관 조회</div>

        <div className="List">
          <table className="content">
            <thead>
              <tr>
                <th width="5%">
                  <input
                    type="checkbox"
                    checked={areAllItemsSelected || ""}
                    onChange={handleSelectAll}
                  />
                </th>
                <th width="5%">No.</th>
                <th width="30%">기관명</th>
                <th width="15%">기관코드</th>
                <th width="15%">기관 가입자</th>
                <th width="20%">등록일</th>
                <th width="20%">등록상태</th>
              </tr>
            </thead>
            <tbody>
              {instList &&
                instList.map((e, i) => {
                  return (
                    <tr key={i} onClick={() => handleRowClick(i)}>
                      <td>
                        <input
                          type="checkbox"
                          checked={isItemSelected(e.inst_id)}
                          onChange={() => handleItemSelect(e.inst_id)}
                          disabled={e.disabled}
                        />
                      </td>
                      <td>{i + 1}</td>
                      <td className={e.actvtn_cd != 1 ? "disabled" : ""}>
                        {e.inst_nm || ""}{" "}
                      </td>
                      <td>{e.inst_cd || ""}</td>
                      <td>{e.user_count || ""}명</td>
                      <td>{e.reg_ymd || ""}</td>
                      <td>
                        <Dropdown
                          inline
                          options={statusOptions}
                          value={e.aprv_stts_cd || ""}
                          onChange={(event, data) => {
                            handleChange(event, data, e.inst_cd);
                          }}
                          disabled={
                            e.aprv_stts_cd === 2 || e.aprv_stts_cd === 3
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="contents-row btn-group">
            <button
              className="gc-btn btn new"
              onClick={() => {
                setShowInstPopup(true);
              }}
            >
              신규 기관 등록
            </button>
            <button className="gc-btn btn deactivation" onClick={disabledClick}>
              기관 비활성화
            </button>
          </div>
        </div>

        <div className="Pagination">
          <div className="contents-row pagination">
            <Pagination
              className="pagination"
              activePage={currentPage}
              totalPages={totalPages}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              onPageChange={handlePageChange}
            ></Pagination>
          </div>
        </div>
      </div>

      <InstAdd
        showInstPopup={showInstPopup}
        setShowInstPopup={setShowInstPopup}
      ></InstAdd>

      <Popup
        show={showPopup}
        onCancel={() => setShowPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowPopup(false);
            }}
          />
        }
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </div>
  );
}

function GetList(props) {
  axios({
    method: "get",
    url: `/api/inst/list?instNm&instCd&sttsWaiting=true&sttsAprved&sttsDenied&strtYmd=2023-03-01&endYmd&page&perPage=0`,
    headers: {
      Authorization: `Bearer {${props.APIToken}}`,
    },
  })
    .then((result) => {
      props.setInstList(result.data.data);
      props.setTotalList(result.data.total);
    })
    .catch((error) => {
      console.log(error);
    });
}
export default Adminmode;

import './ZoneInfo.css';

import React, { useState, useEffect, useRef, useCallback } from 'react';
import Modal from '../../../shared/components/uiElements/Modal'
import Popup from '../../../shared/components/uiElements/Popup'
import { Button, Checkbox, Icon, Table, Dropdown, Input, Pagination, Select } from 'semantic-ui-react';

// React-Map-GL
import Map, { Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import ReactDragListView from 'react-drag-listview';

// AXIOS
import axios from "axios";

// TURF
import turf from "turf"

// config
import config from '../../../config/config';

const ZoneInfo = (props) => {

    const minDate = new Date().getFullYear() - 15;
    const maxDate = new Date().getFullYear();

    // Mapbox TOKEN
    const MAP_TOKEN = 'pk.eyJ1Ijoic2RqMzIzIiwiYSI6ImNsZGIxazJ5cDBvaXkzb25uM2NhZ2xvc3EifQ.wMTHtB5DTOMkIn-RI-Xs-w';
    const APIToken = localStorage.getItem('login-token');

    const mapRef = React.useRef();

    // Raster Layer (V-World MAP)
    const MAP_LAYER_ID = "vworld_map";
    const MAP_SRC = {
        type: "raster",
        tiles: [config.vworldSat]
    };
    const MAP_LAYER_STYLE = {
        id: MAP_LAYER_ID,
        type: 'raster',
        paint: {
            'raster-opacity': 1.0
        },
        minzoom: 6,
        maxzoom: 19
    };

    let ITRST_LAYER_ID = "selected_ItrstZone";

    // Popup
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState(false);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [popupAddMsg, setPopupAddMsg] = useState(false);
    const [showDelPopup, setShowDelPopup] = useState(false);
    const [showCanPopup, setShowCanPopup] = useState(false);
    const [showModPopup, setShowModPopup] = useState(false);

    // Dropdown 옵션
    const options = [
        { key: 1, text: 'ID', value: 'user_id' },
        { key: 2, text: '이름', value: 'user_nm' },
        { key: 3, text: '소속', value: 'inst_nm' },
        { key: 4, text: '신청일', value: 'aply_ymd' },
        { key: 5, text: '승인여부', value: 'aprv_stts_nm' },
    ]

    // 관심구역 권한 신청내역 조회
    const [application, setApplication] = useState('');

    // 조회신청자 배열 길이
    let appliLength = null;

    if (application !== null) {
        appliLength = application.length;
    }

    // Required
    let oldtagList = props.tag_list;
    const tagListOldState = oldtagList.filter(item => item !== "");

    const [zoneidstate, setZoneID] = useState(props.itrst_zone_id);
    const [namestate, setName] = useState(props.itrst_zone_nm);
    const [yearstate, setYear] = useState(props.yr_info);
    const [cropstate, setCrop] = useState(props.crop_ty_cd);
    const [etcCnstate, setEtcCn] = useState(props.etc_cn);
    const [geoJsonstate, setGeoJson] = useState(props.geoJson);
    const [tagListstate, setTagList] = useState(tagListOldState);
    const [dummyArray, setDummyArray] = useState();

    // Optional
    const [shpPath, setShpPath] = useState('');
    const [orgFileNm, setOrgFileNm] = useState('');

    const handleName = (event) => {
        event.preventDefault();
        setName(event.target.value);
    }
    const handleYear = (event) => {
        event.preventDefault();
        setYear(event.target.value);
    }
    const handleEtcCn = (event) => {
        event.preventDefault();
        setEtcCn(event.target.value);
    }
    const goZoneList = () => {
        props.setShowInfo(false);
        props.searchZoneList();
    };


    // useEffect(() => {
    //    console.log("tagListstate"+JSON.stringify());
    // }, [])


    // 승인여부
    const [selectedValue, setSelectedValue] = useState();
    // const handleChange = (e , data) => {
    //     e.preventDefault();
    //     setSelectedValue(data.value);
    // };
    // const handleChange = (e , data) => {
    //     e.preventDefault();
    //     setSelectedValue(data.value);
    // };
    const [selectedValues, setSelectedValues] = useState(new Array(application.length).fill(null));
    const handleChange = (index, value) => {
        setSelectedValues(prevState => {
            const newSelectedValues = [...prevState];
            newSelectedValues[index] = value;
            return newSelectedValues;
        });
    };
    const getDropdownValue = (no) => {
        return selectedValues[no] || '승인 대기';
    };
    // useEffect(()=>{
    //     if(selectedValue == 1){

    //     }
    //     else if(selectedValue == 2){
    //         onOkHandler(
    //             `${zoneidstate}`, 
    //             `${application[0].user_id}`, 
    //             'true'
    //         );
    //     }
    //     else if(selectedValue == 3){
    //         onOkHandler(zoneidstate,  application[0].user_id, 'false');
    //     }
    // },[selectedValue])

    useEffect(() => {
        if (selectedValues && application && zoneidstate) {
            application.forEach((appItem, index) => {
                const selectedValue = selectedValues[index];

                if (selectedValue === 1) {
                    // Logic for selectedValue === 1
                    // ...
                } else if (selectedValue === 2) {
                    onOkHandler(zoneidstate, appItem.user_id, 'true');
                } else if (selectedValue === 3) {
                    onOkHandler(zoneidstate, appItem.user_id, 'false');
                }
            });
        }
    }, [selectedValues, application, zoneidstate]);

    // 관심구역 권한 승인
    const onOkHandler = (itrstZoneId, userId, aprv) => {
        axios({
            method: 'post',
            url: `/api/zone/auth/aprv`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: { itrstZoneId: itrstZoneId, userId: userId, aprv: aprv },
        })
            .then((result) => {
                setPopupMsg(result.data.msg);
                setShowPopup(true);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const [modified, setModified] = useState(false);

    const statusOptions = [
        // {key: 1, text: '승인 대기', value: 1},
        { key: 2, text: '승인 완료', value: 2 },
        { key: 3, text: '승인 거절', value: 3 }
    ]



    // 관심구역 삭제
    const onDelHandler = (zoneId) => {
        axios({
            method: 'post',
            url: `/api/zone/delete`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: { zoneId: zoneId },
        })
            .then((result) => {
                if (result.data.success == true) {
                    setShowPopup(true);
                    setPopupMsg('관심구역이 삭제되었습니다.');

                }
                else if (result.data.success == false) {
                    setPopupMsg(result.data.msg);
                    setShowPopup(true);
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const inputRef = useRef([]);

    useEffect(() => {
        if (modified) {
            inputRef.current[0].disabled = false;
            inputRef.current[2].disabled = false;
            // inputRef.current[3].disabled = false;
            inputRef.current[4].disabled = false;
            inputRef.current[5].disabled = false;
        }
        else {
            inputRef.current[0].disabled = true;
            inputRef.current[2].disabled = true;
            // inputRef.current[3].disabled = true;
            inputRef.current[4].disabled = true;
            inputRef.current[5].disabled = true;
        }
    }, [modified])

    // 조회신청자 검색
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const handlePagination = (event, { activePage }) => {
        setCurrentPage(activePage);
    };

    let totalCount = '';
    const pageSize = 2;


    const [authAplySearch_state, setAuthAplySearch] = useState('user_nm');
    const handleSearchOption = (e, { value }) => {
        setAuthAplySearch(value);
    };

    const [searchAuthAplyWord, setSearchAuthAplyWord] = useState('');
    const handleAuthAplyList = (e, data) => {
        const { value } = e.target;
        setSearchAuthAplyWord(value);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchAuthAplyList();
        }
    };

    function searchAuthAplyList() {
        axios({
            method: 'get',
            url: `/api/zone/auth/aply-list?zoneId=${props.itrst_zone_id}&filterType=${authAplySearch_state}&keyword=${searchAuthAplyWord}&page=${currentPage}&perPage=${pageSize}`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
        })
            .then((result) => {
                setApplication(result.data.data);
                totalCount = result.data.total;
                // setTotalPages(Math.ceil( application.length / pageSize));
                setTotalPages(Math.ceil(totalCount / pageSize));

            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        searchAuthAplyList();
    }, [currentPage])

    function displayItrst() {
        if (props.geoJson) {
            const array = [];
            const geojsondata = JSON.parse(props.geoJson);

            const ITRST_SRC_ACTIVE = {
                type: "geojson",
                data: geojsondata
            };

            const ITRST_LAYER_STYLE_ACTIVE = {
                id: ITRST_LAYER_ID,
                type: 'line',
                source: ITRST_SRC_ACTIVE,
                layout: {},
                paint: {
                    'line-color': 'rgba(255, 255, 0, 1.0)',
                    'line-width': 1,
                },
            }

            if (mapRef.current != null) {
                const bbox = turf.bbox(geojsondata);
                const map = mapRef.current.getMap();
                map.fitBounds(bbox, { animate: false });
                // map.scrollZoom.disable();
            }

            array.push(
                <Source key={ITRST_LAYER_ID} {...ITRST_SRC_ACTIVE}>
                    <Layer {...ITRST_LAYER_STYLE_ACTIVE} />
                </Source>)

            return array;
        }
    }

    const tagNameHandler = (e, i) => {
        const dummyArray = [...tagListstate];
        dummyArray[i] = e.target.value;
        setTagList(dummyArray);
    }

    const addTag = () => {
        if (tagListstate == null || tagListstate.length == 0) {
            const dummyArray = [];
            dummyArray.push('');
            setTagList(dummyArray);
        }
        if (tagListstate.length == 0) {
            const dummyArray = [...tagListstate];
            dummyArray.push("");
            setTagList(dummyArray);
          }
        else if (tagListstate.length >= 1 && tagListstate.length < 10) {
            if (tagListstate[tagListstate.length - 1] == '') {
                setPopupAddMsg("기존 태그의 태그명을 입력하세요.");
                setShowAddPopup(true);
            }
            else {
                const dummyArray = [...tagListstate];
                dummyArray.push('');
                setTagList(dummyArray);
            }
        }
        else if (tagListstate.length >= 10) {
            setPopupAddMsg("최대 태그 개수는 10개입니다.");
            setShowAddPopup(true);
        }
    }

    const delTag = (i) => {
        const dummyArray = [...tagListstate];
        dummyArray.splice(i, 1);
        setTagList(dummyArray);
    }

    const validateTag = (e) => {
        if (e.key === 'Enter') {
            const dummyArray = [...tagListstate];
            const result = [...new Set(dummyArray.filter((item, index) => dummyArray.indexOf(item) !== index))];
            if (result.length >= 1) {
                setPopupAddMsg("중복 태그입니다.");
                setShowAddPopup(true);
            }
        }
    }

    const renderTagList = () => {
        if (!modified) {
            if (tagListstate == null || tagListstate[0] === '') {
                return <div>태그 정보가 없습니다.</div>
            }
            return tagListstate && tagListstate.map((tag, i) => {
                return <div className="newTag">
                    <div
                        className="tagName"
                        style={{
                            width: `${(tagListstate[i].length * 8) + 40}px`,
                        }}
                    >{tagListstate[i]}</div>
                </div>
            })
        }
        else {

            return (
                tagListstate &&
                tagListstate.map((tag, i) => {
                    return (
                        <div className="newTag">
                            <input
                                className="tagName"
                                value={tagListstate[i]}
                                onKeyDown={validateTag}
                                onChange={(e) => {
                                    tagNameHandler(e, i);
                                }}
                                //onBlur={validateTag}
                                maxLength="15"
                                style={{
                                    width: `${tagListstate[i].length * 8 + 40}px`, // 글자 수에 따라 동적으로 너비 조정
                                }}
                            ></input>
                            <i
                                className="fa-regular fa-circle-minus tagDelete"
                                onClick={() => {
                                    delTag(i);
                                }}
                            ></i>
                        </div>
                    );
                })
            );
        }
    }

    return (
        <React.Fragment>
            <Modal
                show={props.showInfo}
                onCancel={() => props.setShowInfo(false)}
                header="관심구역 관리"
                icon={<i className="code x icon" onClick={() => props.setShowInfo(false)} />}
                isMobile={props.isMobile}
                className={`zoneInfo`}
            >
                <div className="zoneinfo-container">
                    <div className="info">
                        <div className="title">관심구역 정보</div>
                        <div className="img_table">
                            <Map
                                className="Mapbox-Map"
                                mapboxAccessToken={MAP_TOKEN}
                                ref={mapRef}
                                initialViewState={{
                                    latitude: props.geometry[1],
                                    longitude: props.geometry[0],
                                    zoom: 13,
                                    minZoom: 6,
                                    maxZoom: 18,
                                    maxBounds: [
                                        [122.0, 33.0],
                                        [132.0, 40.0]
                                    ],
                                }}
                            >
                                <Source {...MAP_SRC}>
                                    <Layer {...MAP_LAYER_STYLE} />
                                </Source>

                                {displayItrst()}

                            </Map>
                            {/* <div className='divideArea_zoneInfo'></div> */}
                            <div className='itrst_zone'>
                                <div className='table_info'>
                                    <div className="field">
                                        <label className="text">관심구역명</label>
                                        <input className="input"
                                            value={namestate}
                                            ref={el => inputRef.current[0] = el}
                                            type="text"
                                            id="name"
                                            name="name"
                                            disabled
                                            onChange={handleName}
                                        />
                                    </div>
                                    <div className="field">
                                        <label className="text">최초등록일</label>
                                        <input className="input"
                                            value={props.reg_ymd.substring('', 10)}
                                            ref={el => inputRef.current[1] = el}
                                            type="text"
                                            id="date"
                                            name="date"
                                            disabled />
                                    </div>
                                    <div className="field">
                                        <label className="text">연도정보</label>
                                        <select
                                            type="input"
                                            className="input"
                                            disabled
                                            // size={10}
                                            value={yearstate}
                                            ref={el => inputRef.current[2] = el}
                                            onChange={handleYear}
                                        >
                                            {Array.from({ length: maxDate - minDate + 1 }, (_, i) => maxDate - i).map(year => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                        {/* <input className="input"
                                        value={yearstate}
                                        ref={el => inputRef.current[2] = el}
                                        type="text"
                                        id="year"
                                        name="year"
                                        disabled
                                        onChange={handleYear}
                                    /> */}
                                    </div>
                                    <div className="field">
                                        <label className="text">이모작 여부</label>
                                        <input className="input"
                                            value={cropstate == 1 ? '단작' : cropstate == 2 ? '하작' : cropstate == 3 ? '동작' : '정보없음'}
                                            ref={el => inputRef.current[3] = el}
                                            type="text"
                                            id="status"
                                            name="status"
                                            disabled
                                        />
                                    </div>
                                    <div className="field">
                                        <label className="text">기타 내용</label>
                                        <input className="input long"
                                            value={etcCnstate || ''}
                                            ref={el => inputRef.current[4] = el}
                                            type="text"
                                            id="etcCn"
                                            name="etcCn"
                                            disabled
                                            onChange={handleEtcCn}
                                        />
                                    </div>
                                    <div className="field">
                                        <label className="text">태그</label>
                                        <div className='tag-List' ref={el => inputRef.current[5] = el}>
                                            {renderTagList()}
                                            {modified ?
                                                <button className="addTagButton" onClick={addTag}>+ 태그 추가</button>
                                                : null}
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>
                    </div>
                    <div className="divideArea_zoneInfo"></div>
                    {props.authCheck == 1 || localStorage.getItem('user-level') >= 10 ?
                        <div className="applicant">
                            <div className="main">
                                <div className="title">조회 신청자</div>
                                <div className="search">
                                    <Select
                                        className="select"
                                        placeholder="이름"
                                        options={options}
                                        value={authAplySearch_state}
                                        onChange={handleSearchOption}
                                    />
                                    <div className="position-btn">
                                        <input
                                            className="input"
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder='검색어를 입력하세요.'
                                            value={searchAuthAplyWord}
                                            onChange={handleAuthAplyList}
                                            onKeyDown={handleKeyPress}
                                            autoComplete="off"
                                        />
                                        <div className="searchIcon" onClick={searchAuthAplyList}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="contents">
                                <table className="zone_list">
                                    <thead>
                                        <tr>
                                            <th width="5%">No.</th>
                                            <th width="15%">ID</th>
                                            <th width="15%">이름</th>
                                            <th width="25%">소속</th>
                                            <th width="20%">신청일</th>
                                            <th width="20%">승인여부</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {application.length === 0 ? (
                                            <tr>
                                                <td colSpan="6">데이터가 없습니다.</td>
                                            </tr>
                                        ) : (
                                            application.map((app, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{app.user_id}</td>
                                                        <td>{app.user_nm}</td>
                                                        <td>{app.inst_nm}</td>
                                                        <td>{app.aply_ymd.substring(0, 10)}</td>
                                                        <td>
                                                            <Dropdown
                                                                inline
                                                                text={app.aprv_stts_cd === 1 ? '승인 대기' : undefined}
                                                                options={statusOptions}
                                                                defaultValue={app.aprv_stts_cd}
                                                                value={selectedValues[index]}
                                                                onChange={(e, data) => handleChange(index, data.value)}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        : null}

                    <div className="group">
                        {props.authCheck == 1 || localStorage.getItem('user-level') >= 10 ?
                            <div className="pagination">
                                <Pagination
                                    className="pagination"
                                    activePage={currentPage}
                                    totalPages={totalPages}
                                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                    onPageChange={handlePagination}
                                >
                                </Pagination>
                            </div>
                            : null}

                        {props.authCheck == 1 || localStorage.getItem('user-level') >= 10 ?
                            <div className="btn-group">
                                {
                                    modified ?
                                        <div>
                                            <button onClick={() => { setShowCanPopup(true); }} className="button modify">취소</button>
                                            <button onClick={() => { setShowModPopup(true); }} className="button ok">저장</button>
                                        </div>
                                        :
                                        <div>
                                            <button onClick={() => { setShowDelPopup(true); }} className="button remove">삭제</button>
                                            <button onClick={() => {
                                                setModified(true);
                                                setDummyArray({
                                                    'zoneId': zoneidstate,
                                                    'name': namestate,
                                                    'year': yearstate,
                                                    'crop': cropstate,
                                                    'etcCn': etcCnstate,
                                                    'geoJson': geoJsonstate,
                                                    'tagList': tagListstate,
                                                })
                                            }} className="button modify">수정</button>
                                            <button onClick={() => { props.setShowInfo(false); }} className="button ok">뒤로가기</button>
                                        </div>
                                }
                            </div>
                            :
                            props.authCheck == 2 ?
                                <div className="btn-group">
                                    <button onClick={goZoneList} className="button ok">뒤로가기</button>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </Modal>

            <Popup
                show={showPopup}
                onCancel={() => { setShowPopup(false); goZoneList(); }}
                header="알림"
                icon={<i className="code x icon" onClick={() => { setShowPopup(false); goZoneList(); }} />}
            >
                <div className='modal-info'>
                    <p>{popupMsg}</p>
                    <button
                        className="close_btn"
                        onClick={() => {
                            setShowPopup(false);
                            setModified(false);

                            goZoneList();
                            window.location.reload();
                        }}>
                        닫기</button>
                </div>
            </Popup>

            <Popup
                show={showAddPopup}
                onCancel={() => { setShowAddPopup(false) }}
                header="알림"
                icon={<i className="code x icon" onClick={() => { setShowAddPopup(false) }} />}
            >
                <div className='modal-info'>
                    <p>{popupAddMsg}</p>
                    <button
                        className="close_btn"
                        onClick={() => {
                            setShowAddPopup(false);
                        }}>
                        닫기</button>
                </div>
            </Popup>

            <Popup
                show={showDelPopup}
                onCancel={() => setShowDelPopup(false)}
                header="알림"
                icon={<i className="code x icon" onClick={() => setShowDelPopup(false)} />}
            >
                <div className='modal-info'>
                    <p>관심구역을 삭제하시겠습니까?</p>
                    <button
                        className="zoneinfo_close_btn"
                        onClick={() => {
                            setShowDelPopup(false);
                        }}>
                        아니오</button>
                    <button
                        className="zoneinfo_ok_btn"
                        onClick={() => {
                            onDelHandler(`${props.itrst_zone_id}`);
                        }}>
                        예</button>
                </div>
            </Popup>

            <Popup
                show={showCanPopup}
                onCancel={() => setShowCanPopup(false)}
                header="알림"
                icon={<i className="code x icon" onClick={() => setShowCanPopup(false)} />}
            >
                <div className='modal-info'>
                    <p>수정을 취소하시겠습니까?</p>
                    <button
                        className="zoneinfo_close_btn"
                        onClick={() => {
                            setShowCanPopup(false);
                        }}>
                        아니오</button>
                    <button
                        className="zoneinfo_ok_btn"
                        onClick={() => {
                            setZoneID((dummyArray.zoneId));
                            setName(dummyArray.name);
                            setYear(dummyArray.year);
                            setCrop(dummyArray.crop);
                            setEtcCn(dummyArray.etcCn);
                            setGeoJson(dummyArray.geoJson);
                            setTagList(dummyArray.tagList);
                            setModified(false);
                            setShowCanPopup(false);
                        }}>
                        예</button>
                </div>
            </Popup>

            <Popup
                show={showModPopup}
                onCancel={() => setShowModPopup(false)}
                header="알림"
                icon={<i className="code x icon" onClick={() => setShowModPopup(false)} />}
            >
                <div className='modal-info'>
                    <p>수정사항을 저장하시겠습니까?</p>
                    <button
                        className="zoneinfo_close_btn"
                        onClick={() => {
                            setShowModPopup(false);
                        }}>
                        아니오</button>
                    <button
                        className="zoneinfo_ok_btn"
                        onClick={() => {

                            let dataform;

                            if (tagListstate[tagListstate.length - 1] == '') {
                                setPopupAddMsg("태그명을 입력하세요.")
                                setShowAddPopup(true);
                                setShowModPopup(false);
                                return false;
                            } else {
                                dataform = {
                                    zoneId: zoneidstate,
                                    name: namestate,
                                    year: yearstate,
                                    crop: cropstate,
                                    etcCn: etcCnstate,
                                    geoJson: geoJsonstate,
                                    tagList: JSON.stringify(tagListstate),
                                    oldtagList: JSON.stringify(oldtagList),
                                }

                                axios({
                                    method: 'post',
                                    url: `/api/zone/update`,
                                    headers: {
                                        'Authorization': `Bearer {${APIToken}}`
                                    },
                                    data: dataform,
                                })
                                    .then((result) => {
                                        setPopupMsg("관심구역 수정이 완료되었습니다.");
                                        setShowPopup(true);
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            }
                            setShowModPopup(false);
                        }}>
                        예</button>
                </div>
            </Popup>

        </React.Fragment>
    );
}

export default ZoneInfo;
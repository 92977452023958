import "./profile.css";

import React, { useEffect, useState, useRef, forwardRef } from "react";
import { Checkbox, Select } from "semantic-ui-react";
import Popup from "../../../shared/components/uiElements/Popup";

// AXIOS
import axios from "axios";

import { useNavigate } from "react-router-dom";

import ChangePwModal from "../components/changePwModal";
import TermsOfService from "../../../routes/user/pages/TermsOfService";

function Profile(props) {
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");
  const [showDelPopup, setShowDelPopup] = useState(false);
  const [showDelPopupPw, setShowDelPopupPw] = useState(false);
  const [showCanPopup, setShowCanPopup] = useState(false);
  const [showModPopup, setShowModPopup] = useState(false);

  const APIToken = localStorage.getItem("login-token");
  const [userProfile, setUserProfile] = useState();
  const [userInfoOk, setUserInfoOk] = useState(false);

  useEffect(() => {
    axios({
      method: "get",
      url: `/api/account/profile`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        setUserProfile(result.data.data);
        setUserInfoOk(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userInfoOk]);

  // 수정 useState
  const [telNo_state, setTellNo] = useState();
  const [email_state, setEmail] = useState("");
  const [hintCd_state, setHintCd] = useState("");
  const [hintAns_state, setHintAns] = useState("");
  const [userTy_state, setUserTy] = useState("");
  const [userNm_state, setUserNm] = useState("");
  const [instId_state, setInstId] = useState("");
  const [instNm_state, setInstNm] = useState("");
  const [deptNm_state, setDeptNm] = useState("");

  // 수정 handle
  const handleHintCd = (event, data) => {
    event.preventDefault();
    setHintCd(data.value);
    setModified(true);
  };
  const handleHintAns = (event) => {
    event.preventDefault();
    setModified(true);
    setHintAns(event.target.value);
  };
  const handleInstId = (event, data) => {
    event.preventDefault();
    setInstId(data.value);
    setModified(true);
    setInstNm(instNmSelect);
  };
  const handleUserNm = (event) => {
    event.preventDefault();
    setModified(true);
    setUserNm(event.target.value);
  };
  const handleTelNo = (event) => {
    event.preventDefault();
    setModified(true);
    setTellNo(event.target.value);
  };
  const handleDeptNm = (event) => {
    event.preventDefault();
    setModified(true);
    setDeptNm(event.target.value);
  };
  const handleEmail = (event) => {
    event.preventDefault();
    setModified(true);
    setEmail(event.target.value);
  };

  // 비밀번호 힌트
  const options = [
    { key: "1", text: "내가 가장 좋아하는 장소는?", value: "1" },
    { key: "2", text: "내가 가장 좋아하는 음식은?", value: "2" },
    { key: "3", text: "인생의 좌우명은?", value: "3" },
    { key: "4", text: "가장 감명 깊게 본 영화는?", value: "4" },
  ];

  const getSelectedText = (selectedValue) => {
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );
    return selectedOption ? selectedOption.text : "";
  };

  useEffect(() => {
    if (userProfile) {
      setTellNo(userProfile.telno_cn || "");
      setEmail(userProfile.eml_cn || "");
      setHintCd(userProfile.hint_cd || "");
      setHintAns(userProfile.hint_ans || "");
      setUserTy(userProfile.user_ty_nm || "");
      setUserNm(userProfile.user_nm || "");
      setInstId(userProfile.inst_id || "");
      setDeptNm(userProfile.dept_nm || "");
    }
  }, [userProfile]);

  // 수정
  const [modified, setModified] = useState(false);

  const inputRef = useRef([null, null, null]);
  useEffect(() => {
    if (modified) {
      if (inputRef.current[0]) inputRef.current[0].disabled = false;
      if (inputRef.current[1]) inputRef.current[1].disabled = false;
      if (inputRef.current[2]) inputRef.current[2].disabled = false;
    } else {
      if (inputRef.current[0]) inputRef.current[0].disabled = true;
      if (inputRef.current[1]) inputRef.current[1].disabled = true;
      if (inputRef.current[2]) inputRef.current[2].disabled = true;
    }
  }, [modified]);

  function onModHandler() {
    if (modified) {
      setShowCanPopup(true);
    } else {
      setModified(true);
      setDummyArray({
        telNo: telNo_state,
        email: email_state,
        hintCd: hintCd_state,
        hintAns: hintAns_state,
        userTy: userTy_state,
        userNm: userNm_state,
        instId: instId_state,
        deptNm: deptNm_state,
      });
    }
  }

  function onSaveHandler() {
    if (modified) {
      setShowModPopup(true);
    } else {
      navigate("/home");
    }
  }

  // 기관명
  const [instList, setInstList] = useState(null);
  let inst_Arr = [];
  const [inst_ArrList, setInst_ArrList] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url: `/api/inst/list`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        setInstList(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let instNmSelect;

  useEffect(() => {
    if (instList !== null) {
      for (let i = 0; i < instList.length; i++) {
        inst_Arr.push({
          key: i,
          text: instList[i].inst_nm,
          value: instList[i].inst_id,
          instnm: instList[i].inst_nm,
        });
      }
    }
    setInst_ArrList(inst_Arr);

    for (let i = 0; i < inst_Arr.length; i++) {
      if (inst_Arr[i].value === instId_state) {
        instNmSelect = inst_Arr[i].instnm;
        break;
      }
    }
    setInstNm(instNmSelect);
  }, [instList, instNm_state, instId_state]);

  function getInstList() {
    if (instList !== null) {
      for (let i = 0; i < instList.length; i++) {
        inst_Arr.push({
          key: i,
          text: instList[i].inst_nm,
          value: instList[i].inst_id,
          instnm: instList[i].inst_nm,
        });
      }
    }
    setInst_ArrList(inst_Arr);
  }

  // 수정사항을 저장하시겠습니까?
  function profileModYes() {
    axios({
      method: "post",
      url: `/api/account/update`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      data: {
        telNo: telNo_state,
        email: email_state,
        hintCd: hintCd_state,
        hintAns: hintAns_state,
        userTy: userTy_state,
        userNm: userNm_state,
        instId: instId_state,
        deptNm: deptNm_state,
      },
    })
      .then((result) => {
        console.log("회원정보 수정 결과 : ", result);
        if (result.data.success) {
          setPopupMsg("회원정보 수정이 완료되었습니다.");
          setShowPopup(true);
          setShowModPopup(false);
          setModified(false);
          setUserInfoOk(false);
        } else {
          setPopupMsg("회원정보 수정을 실패하였습니다.");
          setShowPopup(true);
          setShowModPopup(false);
          setModified(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [dummyArray, setDummyArray] = useState();

  // 수정을 취소하시겠습니까?
  function profileModNo() {
    setTellNo(dummyArray.telNo);
    setEmail(dummyArray.email);
    setHintCd(dummyArray.hintCd);
    setHintAns(dummyArray.hintAns);
    setUserTy(dummyArray.userTy);
    setUserNm(dummyArray.userNm);
    setInstId(dummyArray.instId);
    setModified(false);
    setShowCanPopup(false);
  }

  // 회원탈퇴
  const onDelHandler = (pswd_cn) => {
    axios({
      method: "post",
      url: `/api/account/delete`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      data: { pw: pswd_cn },
    })
      .then((result) => {
        if (result.data.success == true) {
          setPopupMsg("탈퇴하셨습니다.");
          setShowPopup(true);
          setShowModPopup(false);
          setShowDelPopupPw(false);
          setShowDelPopup(false);
          navigate("/");
          localStorage.removeItem("login-token");
          localStorage.removeItem("user-name");
          localStorage.removeItem("user-level");
          localStorage.removeItem("user-id");
          window.location.reload();
        } else if (result.data.success == false) {
          setPopupMsg(result.data.msg);
          setShowPopup(true);
          setShowModPopup(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 회원탈퇴를 진행하기 위해 비밀번호를 입력해주세요
  const [userInputPw, setUserInputPw] = useState("");
  const handleUserInputPw = (event) => {
    event.preventDefault();
    setUserInputPw(event.target.value);
  };

  // 비밀번호 변경
  const [showChangePwPopup, setShowChagePwPopup] = useState(false);

  // 기관코드
  const [foundInstCd, setFoundInstCd] = useState("");
  useEffect(() => {
    if (userProfile && instList) {
      const foundInstIdInfo = instList.find(
        (item) => item.inst_id === userProfile.inst_id
      );
      if (foundInstIdInfo) {
        setFoundInstCd(foundInstIdInfo.inst_cd);
      }
    }
  }, [userProfile, instList]);

  // 이용약관
  const [showTermsOfServicePopup, setShowTermsOfServicePopup] = useState(false);
  const handleLinkClick = (linkNm) => {
    // setShowTermsOfServicePopup(!showTermsOfServicePopup);
    setShowTermsOfServicePopup(true);
    props.handleIsTerms(linkNm);
  };
  return (
    <React.Fragment>
      <div
        className="contents-col profile-container"
        onClick={(e) => {
          if (props.showSubmenu) {
            props.handleShowSubmenu(false);
          }
        }}
      >
        <div className="profile-contents">
          <div className="contents-col main-label">
            {!props.isMobile ? (
              <div className="main-title">플랫폼 회원 정보</div>
            ) : null}
            <div className="main-text">가입구분</div>
          </div>

          <div className="contents-col signup-type">
            <div className="division-A"></div>
            <div className="type-group">
              <div className="contents-row type">
                <Checkbox
                  name="checkboxRadioGroup"
                  type="radio"
                  className="check"
                  value="1"
                  checked={userProfile && userProfile.user_ty_cd === "1"}
                />
                <span className="text">개인(영농조합)</span>
              </div>
              <div className="contents-row type">
                <Checkbox
                  name="checkboxRadioGroup"
                  type="radio"
                  className="check"
                  value="2"
                  checked={userProfile && userProfile.user_ty_cd === "2"}
                />
                <p className="text">영농조합 관리자</p>
              </div>
              <div className="contents-row type">
                <Checkbox
                  name="checkboxRadioGroup"
                  type="radio"
                  className="check"
                  value="3"
                  checked={userProfile && userProfile.user_ty_cd === "3"}
                />
                <p className="text">기관</p>
              </div>
              <div className="contents-row type">
                <Checkbox
                  name="checkboxRadioGroup"
                  type="radio"
                  className="check"
                  value="4"
                  checked={userProfile && userProfile.user_ty_cd === "4"}
                />
                <p className="text">농작업 수행업체</p>
              </div>
            </div>
            <div className="division-A"></div>
          </div>
          <div className="account-info">
            <div className="main-text">계정 정보</div>
            <table className="content">
              <thead>
                <tr>
                  <td>사용자 ID</td>
                  {userProfile ? <th>{userProfile.user_id}</th> : null}
                </tr>
                <tr>
                  <td>비밀번호</td>
                  {userProfile ? (
                    <th>
                      <div className="contents-row pw-change">
                        *********
                        <div
                          className="gc-btn button"
                          onClick={() => setShowChagePwPopup(true)}
                        >
                          비밀번호변경
                        </div>
                      </div>
                    </th>
                  ) : null}
                  {/* <th><div className="gc-btn button" onClick={() => setShowChagePwPopup(true)}>비밀번호변경</div></th> */}
                </tr>
                <tr>
                  <td>비밀번호 힌트</td>
                  <th>
                    {modified ? (
                      <Select
                        className="input-mod"
                        options={options}
                        value={hintCd_state}
                        onChange={handleHintCd}
                        ref={(el) => (inputRef.current[0] = el)}
                        disabled={!modified}
                      />
                    ) : (
                      <div>
                        {userProfile && hintCd_state ? (
                          <div>{getSelectedText(hintCd_state)}</div>
                        ) : null}
                      </div>
                    )}
                  </th>
                  {/* <th></th> */}
                </tr>
                <tr>
                  <td>비밀번호 힌트 답변</td>
                  <th>
                    {modified ? (
                      <input
                        className="input-mod"
                        value={hintAns_state}
                        onChange={handleHintAns}
                        ref={inputRef.current[1]}
                        type="text"
                        id="date"
                        name="date"
                        // style={{ width: "100%" }}
                      />
                    ) : (
                      <div
                        className="input-mod"
                        value={hintAns_state}
                        onChange={handleHintAns}
                      >
                        {hintAns_state ? hintAns_state : null}
                      </div>
                    )}
                  </th>
                  {/* <th></th> */}
                </tr>
                <tr>
                  <td>가입일</td>
                  {userProfile ? (
                    <th>
                      {new Date(userProfile.join_dt).getFullYear() +
                        `-` +
                        String(
                          new Date(userProfile.join_dt).getMonth() + 1
                        ).padStart(2, "0") +
                        `-` +
                        String(
                          new Date(userProfile.join_dt).getDate()
                        ).padStart(2, "0")}
                    </th>
                  ) : null}
                </tr>
              </thead>
              {/* <tbody></tbody> */}
            </table>
          </div>

          <div className="user-info">
            <div className="main-text">사용자 정보</div>
            <table className="content">
              <thead>
                <tr>
                  <td>이름</td>
                  <th>
                    {modified ? (
                      <input
                        className="input-mod"
                        value={userNm_state}
                        onChange={handleUserNm}
                        type="text"
                      />
                    ) : (
                      <>{userProfile ? <>{userProfile.user_nm}</> : null}</>
                    )}
                  </th>
                </tr>
                <tr>
                  <td>전화번호</td>
                  <th>
                    {modified ? (
                      <input
                        className="input-mod"
                        value={telNo_state}
                        onChange={handleTelNo}
                        type="text"
                      />
                    ) : (
                      <>{userProfile ? <>{userProfile.telno_cn}</> : null}</>
                    )}
                  </th>
                </tr>
                <tr>
                  <td>기관명</td>
                  {userProfile ? <th>{userProfile.inst_nm}</th> : null}
                  {/* <th>
                    {modified ? (
                      <Select
                        onClick={getInstList}
                        className="input-mod"
                        options={inst_ArrList}
                        value={instId_state}
                        onChange={handleInstId}
                        ref={inputRef.current[2]}
                        disabled={!modified}
                      />
                    ) : (
                      <div
                        className="input-mod"
                        value={instId_state}
                        onChange={handleInstId}
                      >
                        {instNm_state ? instNm_state : null}
                      </div>
                    )}
                  </th> */}
                </tr>
                <tr>
                  <td>부서명</td>
                  {/* {userProfile ? <th>{userProfile.dept_nm}</th> : null} */}
                  <th>
                    {modified ? (
                      <input
                        className="input-mod"
                        value={deptNm_state}
                        onChange={handleDeptNm}
                        // ref={inputRef.current[3]}
                        type="text"
                      />
                    ) : (
                      <>{userProfile ? <>{userProfile.dept_nm}</> : null}</>
                    )}
                  </th>
                </tr>
                <tr>
                  <td>이메일</td>
                  <th>
                    {modified ? (
                      <input
                        className="input-mod"
                        value={email_state}
                        onChange={handleEmail}
                        type="text"
                      />
                    ) : (
                      <>{userProfile ? <>{userProfile.eml_cn}</> : null}</>
                    )}
                  </th>
                </tr>
                {/* <tr>
                  <td>기관코드</td>
                  {foundInstCd ? <th>{foundInstCd}</th> : null} */}
                {/* {userProfile?<th width="60%">{userProfile.inst_cd}</th>:null} */}
                {/* </tr> */}
              </thead>
              {/* <tbody></tbody> */}
            </table>
          </div>

          <div className="announce">
            <div className="main-text">
              이용약관 및 개인정보 수집 및 이용 동의 정보
            </div>

            <div className="division-A bottom"></div>

            <div className="platform">
              <div className="main-text bottom">플랫폼 이용약관</div>
              <div
                className="gc-btn button typeC"
                onClick={() => handleLinkClick("terms-of-service")}
              >
                전체보기
              </div>
            </div>

            <div className="division-A bottom"></div>

            <div className="individual">
              <div className="main-text bottom">개인정보 수집 및 처리 방침</div>
              <div
                className="gc-btn button typeC"
                onClick={() => handleLinkClick("privacy-prolicy")}
              >
                전체보기
              </div>
            </div>

            <div className="division-A bottom"></div>
          </div>

          <div className="contents-row btn-group">
            {modified ? null : (
              <button
                onClick={() => setShowDelPopup(true)}
                className="gc-btn btn-event cancel"
              >
                회원탈퇴
              </button>
            )}
            {modified ? (
              <button onClick={onModHandler} className="gc-btn btn-event mod">
                취소
              </button>
            ) : (
              <button onClick={onModHandler} className="gc-btn btn-event mod">
                수정
              </button>
            )}
            {modified ? (
              <button onClick={onSaveHandler} className="gc-btn btn-event back">
                저장
              </button>
            ) : (
              <button onClick={onSaveHandler} className="gc-btn btn-event back">
                뒤로가기
              </button>
            )}
          </div>
        </div>
      </div>
      <ChangePwModal
        showChangePwPopup={showChangePwPopup}
        setShowChagePwPopup={setShowChagePwPopup}
        isMobile={props.isMobile}
      ></ChangePwModal>
      {/* <TermsOfService
            showTermsOfServicePopup={showTermsOfServicePopup} 
            setShowTermsOfServicePopup={setShowTermsOfServicePopup}
        ></TermsOfService> */}
      {showTermsOfServicePopup && (
        <TermsOfService
          showTermsOfServicePopup={showTermsOfServicePopup}
          setShowTermsOfServicePopup={setShowTermsOfServicePopup}
          handleIsTerms={props.handleIsTerms}
          isTerms_state={props.isTerms_state}
          isMobile={props.isMobile}
        />
      )}
      <Popup
        show={showPopup}
        onCancel={() => {
          setShowPopup(false);
        }}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowPopup(false);
            }}
          />
        }
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          <button className="close_btn" onClick={() => setShowPopup(false)}>
            닫기
          </button>
        </div>
      </Popup>

      <Popup
        show={showCanPopup}
        onCancel={() => setShowCanPopup(false)}
        header="알림"
        icon={
          <i className="code x icon" onClick={() => setShowCanPopup(false)} />
        }
      >
        <div className="modal-info">
          <p>수정을 취소하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowCanPopup(false);
            }}
          >
            아니오
          </button>
          <button className="zoneinfo_ok_btn" onClick={profileModNo}>
            예
          </button>
        </div>
      </Popup>

      <Popup
        show={showModPopup}
        onCancel={() => setShowModPopup(false)}
        header="알림"
        icon={
          <i className="code x icon" onClick={() => setShowModPopup(false)} />
        }
      >
        <div className="modal-info">
          <p>수정사항을 저장하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowModPopup(false);
            }}
          >
            아니오
          </button>
          <button className="zoneinfo_ok_btn" onClick={profileModYes}>
            예
          </button>
        </div>
      </Popup>

      <Popup
        show={showDelPopup}
        onCancel={() => setShowDelPopup(false)}
        header="알림"
        icon={
          <i className="code x icon" onClick={() => setShowDelPopup(false)} />
        }
      >
        <div className="modal-info">
          <p>회원을 탈퇴하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowDelPopup(false);
            }}
          >
            아니오
          </button>
          <button
            className="zoneinfo_ok_btn"
            onClick={() => {
              setShowDelPopupPw(true);
            }}
          >
            예
          </button>
        </div>
      </Popup>

      <Popup
        // style={{ width: "360px", height: "200px" }}
        show={showDelPopupPw}
        onCancel={() => setShowDelPopupPw(false)}
        header="알림"
        icon={
          <i className="code x icon" onClick={() => setShowDelPopupPw(false)} />
        }
      >
        <div className="modal-info">
          <p style={{ fontSize: "17px", padding: "10px" }}>
            회원탈퇴를 진행하기 위해
            <br />
            비밀번호를 입력해주세요
          </p>

          <input
            className="input-mod"
            value={userInputPw}
            onChange={handleUserInputPw}
            type="password"
            style={{ width: "80%" }}
          />
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowDelPopupPw(false);
              // localStorage.removeItem("login-token");
              // localStorage.removeItem("user-name");
              // localStorage.removeItem("user-level");
              // localStorage.removeItem("user-id");
            }}
            style={{ marginBottom: "10px" }}
          >
            아니오
          </button>
          <button
            className="zoneinfo_ok_btn"
            onClick={() => {
              onDelHandler(`${userInputPw}`);
            }}
          >
            예
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}

export default Profile;

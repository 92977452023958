import "./bulletinBoard.css";
import Popup from "../../../shared/components/uiElements/Popup";

import React, { useEffect, useState, useRef } from "react";
import { Checkbox, Dropdown, Select } from "semantic-ui-react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import moment from "moment";

// AXIOS
import axios from "axios";

function BulletinBoard(props) {
  // loading animation
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  // let props.selectBbs
  // if (props.activeBbs === 'bbs-list' || props.activeBbs === 'bbs-mod') {
  //     props.selectBbs = location.state.props.selectBbs;
  // }

  const navigate = useNavigate();

  const APIToken = localStorage.getItem("login-token");
  const userId = localStorage.getItem("user-id");

  // Popup
  const [popupMsg, setPopupMsg] = useState("필수 정보를 입력하세요.");
  const [showPopup, setShowPopup] = useState(false);
  const [showBbsCanPopup, setShowBbsCanPopup] = useState(false);
  const [showBbsModPopup, setShowBbsModPopup] = useState(false);
  const [showBbsDelPopup, setShowBbsDelPopup] = useState(false);
  const [showFileDelPopup, setShowFileDelPopup] = useState(false);

  // 제목
  const [bbsNm, setBbsNm] = useState("");
  const handleBbsNm = (e) => {
    e.preventDefault();
    setBbsNm(e.target.value);
  };

  // 내용
  const [bbsContent, setBbsContent] = useState("");
  const handleBbsContent = (e) => {
    e.preventDefault();
    setBbsContent(e.target.value);
  };

  const divisionOptions = [
    // { key: 0, text: '전체', value: 0 },
    { key: 1, text: "관심구역 공지", value: 1 },
    { key: 2, text: "보고서", value: 2 },
    { key: 3, text: "기타", value: 3 },
  ];

  // const [divisionOptions_state, setDivisionOptions_state] = useState(divisionOptions[0].value);
  const [divisionOptions_state, setDivisionOptions_state] = useState(
    props.isBbs_state === "notice" ? props.postTy : divisionOptions[0].value
  );
  const handleDivisionOptions = (e, { value }) => {
    setDivisionOptions_state(value);
  };
  // 조회수
  const [bbsInfo, setBbsInfo] = useState("");
  const [bbsViews, setBbsViews] = useState(0);
  useEffect(() => {
    if (props.activeBbs !== "bbs-add") {
      axios({
        method: "get",
        url: `/api/bbs/inquiry?postId=${props.selectBbs.pst_id}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          // console.log(result.data)
          if (result.data.success) {
            setBbsInfo(result.data.data);
            setBbsViews(result.data.data.inq_cnt);
          } else {
            setPopupMsg("조회수 갱신에 실패하였습니다.");
            setShowPopup(true);
          }
        })
        .catch((error) => {
          // props.setLoading(false);
          console.log(error);
        });
    }
  }, []);

  // 작성일
  const [bbsAddYmd, setBbsAddYmd] = useState(moment().format("YYYY-MM-DD"));

  // 파일 업로드
  // const [bbsFileNm, setBbsFileNm] = useState('');
  // const [bbsFile, setBbsFile] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const [deletedFileNames, setDeletedFileNames] = useState([]);

  const bbsFileRef = useRef();

  // const handleFileUpload = (e) => {
  //     const files = Array.from(e.target.files);
  //     setSelectedFiles(files);
  //     console.log(selectedFiles)
  // };
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    const fileNames = files.map((file) => file.name);

    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    setSelectedFileNames((prevFileNames) => [...prevFileNames, ...fileNames]);
    e.target.value = "";
  };

  const handleFileClick = () => {
    bbsFileRef.current.click();
    // bbsFileRef.current.click();
  };

  // 파일 삭제
  // const handleDeleteFile = (index) => {
  //     const updatedFiles = [...selectedFiles];
  //     updatedFiles.splice(index, 1);
  //     setSelectedFiles(updatedFiles);
  // };
  const [fileToDeleteIndex, setFileToDeleteIndex] = useState(null);
  const handleDeleteFileOk = (index) => {
    if (props.activeBbs === "bbs-mod") {
      setPopupMsg("업로드한 해당 파일을 삭제 하시겠습니까?");
      setShowFileDelPopup(true);
      setFileToDeleteIndex(index);
    } else {
      setFileToDeleteIndex(index);
      handleDeleteFile();
    }
  };

  const handleDeleteFile = (index) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });

    setSelectedFileNames((prevFileNames) => {
      // const updatedFileNames = [...prevFileNames];
      // updatedFileNames.splice(index, 1);
      // return updatedFileNames;
      const updatedFileNames = [...prevFileNames];
      const deletedFileName = updatedFileNames.splice(index, 1)[0];
      setDeletedFileNames((prevDeletedFileNames) => [
        ...prevDeletedFileNames,
        deletedFileName,
      ]);
      return updatedFileNames;
    });
  };

  const handleDeleteAllFilesOk = () => {
    if (props.activeBbs === "bbs-mod") {
      setPopupMsg("업로드한 파일 전체를 삭제 하시겠습니까?");
      setShowFileDelPopup(true);
    } else {
      // setShowFileDelPopup(true);
      handleDeleteAllFiles();
    }
  };

  const handleDeleteAllFiles = () => {
    setSelectedFiles([]);
    setSelectedFileNames([]);
  };

  // 업로드 파일 데이터 저장
  // const [bbsFileNm, setBbsFileNm] = useState('');
  // useEffect(() => {
  //     // if (selectedFiles.length > 0) {
  //         selectedFiles && selectedFiles.map((file, i) => {
  //             setBbsFileNm(file.name)
  //             // return (

  //             // )
  //         })
  //     // }
  // }, [selectedFiles])

  function validation() {
    if (bbsNm.length == 0) {
      setPopupMsg("제목을 입력하세요.");
      setShowPopup(true);
      setShowBbsModPopup(false);
      return false;
    }
    if (bbsContent.length == 0) {
      setPopupMsg("내용을 입력하세요.");
      setShowPopup(true);
      setShowBbsModPopup(false);
      return false;
    }

    return true;
  }

  // 게시글 생성
  const bulletinBoardAdd = () => {
    let isOK = validation();

    if (isOK) {
      const formData = new FormData();

      formData.append("postTy", divisionOptions_state);
      formData.append("zoneId", props.selectItrst.itrst_zone_id);
      formData.append("title", bbsNm);
      formData.append("content", bbsContent);
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("attached", selectedFiles[i]);
      }

      setLoading(true);

      axios({
        method: "post",
        url: `/api/bbs/add`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: formData,
      })
        .then((result) => {
          setLoading(false);
          if (result.data.success) {
            // setShowPopup(true);
            if (props.postTy === 0) {
              setPopupMsg("공지사항이 등록되었습니다.");
              setShowPopup(true);
            } else {
              setPopupMsg("게시물이 등록되었습니다.");
              setShowPopup(true);
            }
            // setPopupMsg('등록이 완료되었습니다.');
            // setShowPopup(true);
          } else {
            setPopupMsg("등록에 실패하였습니다.");
            setShowPopup(true);
            // if(result.data.msg){
            //     const falseMsg = result.data.msg.split(":");
            //     setPopupMsg(falseMsg[1] && falseMsg[1].trim());
            // }
            // else{
            //     setPopupMsg('관측영상 등록 실패');
            // }
            // setShowPopup(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  // 조회, 수정 저장된 데이터
  useEffect(() => {
    if (props.activeBbs === "bbs-list") {
      setBbsNm(props.selectBbs.pst_ttl);
      setBbsContent(props.selectBbs.pst_cn);
      setDivisionOptions_state(props.selectBbs.pst_ty);
      if (props.selectBbs.atch_file_path === null) {
        setSelectedFiles([]);
        setSelectedFileNames([]);
      } else {
        setSelectedFiles(props.selectBbs.atch_file_path);
        setSelectedFileNames(props.selectBbs.orgnl_file_nm);
      }
      // setSelectedFiles(props.selectBbs.atch_file_path);
      // setSelectedFileNames(props.selectBbs.orgnl_file_nm);
      setBbsAddYmd(props.selectBbs.pstg_dt);
      setBbsViews(props.selectBbs.inq_cnt);
    }
  }, [props.activeBbs]);

  // 수정
  const [bbsModified, setBbsModified] = useState(false);

  function onModBbsHandler() {
    props.setActiveBbs("bbs-mod");
    if (bbsModified) {
      setShowBbsCanPopup(true);
    } else {
      setBbsModified(true);
    }
  }

  function onSaveBbsHandler() {
    if (bbsModified) {
      setShowBbsModPopup(true);
    } else {
      navigate("/bbsList");
      // props.setShowImageReg(false);
    }
  }

  // 수정을 취소하시겠습니까?
  function bbsModNo() {
    setBbsModified(false);
    setShowBbsCanPopup(false);
    props.setActiveBbs("bbs-list");
  }

  // 수정사항을 저장하시겠습니까?
  const bbsModYes = () => {
    let isOK = validation();

    if (isOK) {
      const formData = new FormData();

      formData.append("postId", props.selectBbs.pst_id);
      formData.append("postTy", divisionOptions_state);
      formData.append("title", bbsNm);
      formData.append("content", bbsContent);
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("attached", selectedFiles[i]);
      }
      // for(let i = 0; i < deletedFileNames.length; i++) {
      // formData.append("detached", deletedFileNames[i]);
      // }
      formData.append("detached", JSON.stringify(deletedFileNames));
      setLoading(true);
      axios({
        method: "post",
        url: `/api/bbs/update`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: formData,
      })
        .then((result) => {
          setLoading(false);
          if (result.data.success) {
            // setShowPopup(true);
            if (props.postTy === 0) {
              setPopupMsg("공지사항이 수정되었습니다.");
              setShowPopup(true);
            } else {
              setPopupMsg("게시물이 수정되었습니다.");
              setShowPopup(true);
            }
            // setPopupMsg('등록이 완료되었습니다.');
            // setShowPopup(true);
          } else {
            setPopupMsg("수정에 실패하였습니다.");
            setShowPopup(true);
            // if(result.data.msg){
            //     const falseMsg = result.data.msg.split(":");
            //     setPopupMsg(falseMsg[1] && falseMsg[1].trim());
            // }
            // else{
            //     setPopupMsg('관측영상 등록 실패');
            // }
            // setShowPopup(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  // 삭제
  const onBbsDelete = () => {
    axios({
      method: "post",
      url: `/api/bbs/delete`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
      data: { postId: props.selectBbs.pst_id },
    })
      .then((result) => {
        if (result.data.success) {
          setShowPopup(true);
          if (props.selectBbs.pst_ty === 0) {
            setPopupMsg("해당 공지사항이 삭제되었습니다.");
          } else {
            setPopupMsg("해당 게시물이 삭제되었습니다.");
          }
          // setShowAddPopup(true);
        } else {
          setPopupMsg("삭제에 실패하였습니다.");
          setShowPopup(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // main-title
  const title =
    props.isBbs_state === "notice"
      ? props.activeBbs === "bbs-add"
        ? "공지사항 등록"
        : props.activeBbs === "bbs-list"
        ? "공지사항 상세 조회"
        : props.activeBbs === "bbs-mod"
        ? "공지사항 수정"
        : ""
      : props.activeBbs === "bbs-add"
      ? "게시물 등록"
      : props.activeBbs === "bbs-list"
      ? "게시물 상세 조회"
      : props.activeBbs === "bbs-mod"
      ? "게시물 수정"
      : "";

  // 다운로드
  const handleDownloadFile = (index) => {
    window.location.href = bbsInfo.atch_url[index];
  };

  // 전체 다운로드
  const DownloadFileAll = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `/api/bbs/download/${props.selectBbs.pst_id}`,
      headers: {
        Authorization: `Bearer {${APIToken}}`,
      },
    })
      .then((result) => {
        setLoading(false);
        if (result.data.success) {
          window.location.href = result.data.data.url;
        } else {
          setPopupMsg(result.data.msg);
          setShowPopup(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // useEffect(() => {
  //     const handleKeyPress = (e) => {
  //       if (e.key === 'F5') {
  //         navigate(-1);
  //       }
  //     };

  //     window.addEventListener('keydown', handleKeyPress);

  //     return () => {
  //       window.removeEventListener('keydown', handleKeyPress);
  //     };
  // }, []);
  useEffect(() => {
    // console.log(props.selectBbs.isBbs_state)
    const handleKeyPress = (e) => {
      if (e.key === "F5") {
        // F5 키를 누르면 세션 스토리지에 저장된 페이지로 이동
        // const storedPage = sessionStorage.getItem('lastVisitedPage');
        // navigate(storedPage || window.history.go(-1));
        // console.log(bbsInfo.pst_ty)
        // if (bbsInfo.pst_ty === 0) {
        // props.handleIsBbs_state('notice');
        navigate(-1);
        // } else {
        // props.handleIsBbs_state('bulletinBoard');
        // window.history.go(-1)
        // }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [navigate]);

  // useEffect(() => {
  //     if (props.isBbs_state === 'notice' || props.isBbs_state === 'bulletinBoard') {
  //         window.history.go(-1);
  //     }
  // }, [navigate])

  // useEffect(() => {
  //     if (props.isBbs_state === 'notice' || props.isBbs_state === 'bulletinBoard') {
  //         window.history.go(-1)
  //     }
  // }, [])

  // console.log(props.showBulletinBoard);
  return (
    // <div className={`contents-col bulletinBoard-container ${!props.showBulletinBoard ? 'hidden' : ''}`}>
    <div className="contents-col bulletinBoard-container">
      {/* <div className='bulletinBoard-contents'> */}
      <label className="main-title">
        {/* {props.isBbs_state === 'notice' ? '공지사항 등록' : props.activeBbs === 'bbs-add' ? '게시글 등록' : props.activeBbs === 'bbs-list' ? '게시글 상세 조회' : props.activeBbs === 'bbs-mod' ? '게시글 수정' : null} */}
        {title}
      </label>
      {/* <div className='contents-col'> */}
      <div
        className={`contents-row field ${
          props.isBbs_state === "notice" ? "notice" : ""
        }`}
      >
        <div
          className={`contents-row division ${
            props.isBbs_state === "notice" ? "hidden" : ""
          } ${props.activeBbs === "bbs-list" ? "readOnly" : ""} `}
        >
          <label className="label">구분</label>
          <Select
            options={divisionOptions}
            className="bulletinBoard-division"
            id="division"
            name="division"
            value={divisionOptions_state}
            onChange={handleDivisionOptions}
          />
        </div>
        <div className="contents-row">
          <label className="label info">조회수</label>
          <div className="text">{bbsViews}</div>
          <label className="label info">작성일</label>
          <div className="text">{bbsAddYmd.slice(0, 10)}</div>
          {/* <div className='text'>{props.selectBbs.pstg_dt}</div> */}
        </div>
      </div>
      <div className="contents-row field">
        <label className="label">제목</label>
        <input
          className="bulletinBoard-input"
          type="text"
          id="title"
          name="title"
          value={bbsNm || ""}
          onChange={handleBbsNm}
          readOnly={props.activeBbs === "bbs-list"}
          spellCheck="false"
        />
      </div>
      <div className="contents-row field">
        <label className="label">내용</label>
        <textarea
          className="bulletinBoard-input content"
          type="textarea"
          value={bbsContent || ""}
          onChange={handleBbsContent}
          readOnly={props.activeBbs === "bbs-list"}
          spellCheck="false"
        ></textarea>
      </div>
      <div className="contents-row field">
        <label className="label">첨부파일</label>
        <div className="bulletinBoard-input file">
          <div className="contents-col uplfile-group">
            {selectedFileNames &&
              selectedFileNames.map((fileName, index) => (
                <div className="contents-row uplfile" key={index}>
                  <span>{fileName}</span>
                  {props.activeBbs === "bbs-list" ? (
                    <i
                      className={`fa-solid fa-download`}
                      onClick={() => {
                        handleDownloadFile(index);
                      }}
                    ></i>
                  ) : props.activeBbs === "bbs-add" ||
                    props.activeBbs === "bbs-mod" ? (
                    <i
                      className={`fa-solid fa-trash-can`}
                      onClick={() => {
                        handleDeleteFileOk(index);
                      }}
                    ></i>
                  ) : null}
                  {/* <i className={`fa-solid fa-download ${!props.activeBbs === 'bbs-list' ? 'hidden' : ''}`} onClick={() => {handleDownloadFile(index);}}></i>
                                            <i className={`fa-solid fa-trash-can ${props.activeBbs === 'bbs-list' ? 'hidden' : ''}`} onClick={() => {handleDeleteFileOk(index);}}></i> */}
                </div>
              ))}
          </div>
          <input
            id="bbsFileUpload"
            type="file"
            multiple
            // accept=".tif"
            ref={bbsFileRef}
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <div className="contents-row bulletinBoard-btn-group">
            {props.activeBbs !== "bbs-list" &&
              selectedFiles &&
              selectedFiles.length > 0 && (
                <div
                  className="gc-btn bulletinBoard-btn all-del"
                  onClick={handleDeleteAllFilesOk}
                >
                  전체 삭제
                </div>
              )}
            {props.activeBbs !== "bbs-list" ? (
              <div
                className="gc-btn bulletinBoard-btn file-check"
                onClick={handleFileClick}
              >
                파일 선택
              </div>
            ) : null}
            {props.activeBbs === "bbs-list" &&
              selectedFiles &&
              selectedFiles.length > 0 && (
                <div
                  className="gc-btn bulletinBoard-btn file-check"
                  onClick={DownloadFileAll}
                >
                  전체 다운로드
                </div>
              )}
          </div>
        </div>
      </div>
      {/* </div> */}

      {props.activeBbs === "bbs-add" ? (
        <div className="contents-row bulletinBoard-btn-group add">
          <div
            className="gc-btn bulletinBoard-btn cancel"
            onClick={() => {
              props.setShowBulletinBoard(false);
            }}
          >
            취소
          </div>
          <div
            className="gc-btn bulletinBoard-btn add"
            onClick={bulletinBoardAdd}
          >
            등록
          </div>
        </div>
      ) : props.activeBbs === "bbs-list" || props.activeBbs === "bbs-mod" ? (
        // <div className='contents-row bulletinBoard-btn-group add'>
        //     <div className='gc-btn bulletinBoard-btn cancel'>삭제</div>
        //     <div className='gc-btn bulletinBoard-btn add'>수정</div>
        //     <div className='gc-btn bulletinBoard-btn add'>뒤로가기</div>
        // </div>
        <div className="contents-row bulletinBoard-btn-group add">
          {bbsModified ? null : userId === props.selectBbs.user_id ||
            localStorage.getItem("user-level") >= 10 ? (
            <div
              className={"gc-btn bulletinBoard-btn red"}
              onClick={() => setShowBbsDelPopup(true)}
            >
              삭제
            </div>
          ) : null}
          {bbsModified ? (
            <div
              onClick={onModBbsHandler}
              className="gc-btn bulletinBoard-btn text-color"
            >
              취소
            </div>
          ) : userId === props.selectBbs.user_id ||
            localStorage.getItem("user-level") >= 10 ? (
            <div
              onClick={onModBbsHandler}
              className="gc-btn bulletinBoard-btn text-color"
            >
              수정
            </div>
          ) : null}
          {bbsModified ? (
            <div
              onClick={() => {
                onSaveBbsHandler();
              }}
              className="gc-btn bulletinBoard-btn green"
            >
              저장
            </div>
          ) : (
            <div
              onClick={() => {
                onSaveBbsHandler();
                props.setShowBulletinBoard(false);
              }}
              className="gc-btn bulletinBoard-btn green"
            >
              뒤로가기
            </div>
          )}
        </div>
      ) : null}

      {/* <div className='contents-row bulletinBoard-btn-group add'>
                    <Link to={'/bbsList'}><div className='gc-btn bulletinBoard-btn cancel'>취소</div></Link>
                    <div className='gc-btn bulletinBoard-btn add' onClick={bulletinBoardAdd}>등록</div>
                </div> */}
      {/* </div> */}

      <Popup
        show={showBbsDelPopup}
        onCancel={() => setShowBbsDelPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => setShowBbsDelPopup(false)}
          />
        }
      >
        <div className="modal-info">
          <p>관측영상을 삭제하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowBbsDelPopup(false);
            }}
          >
            아니오
          </button>
          <button className="zoneinfo_ok_btn" onClick={onBbsDelete}>
            예
          </button>
        </div>
      </Popup>

      <Popup
        show={showBbsCanPopup}
        // onCancel={() => setShowCanPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => setShowBbsCanPopup(false)}
          />
        }
      >
        <div className="modal-info">
          <p>수정을 취소하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowBbsCanPopup(false);
            }}
          >
            아니오
          </button>
          <button className="zoneinfo_ok_btn" onClick={bbsModNo}>
            예
          </button>
        </div>
      </Popup>

      <Popup
        show={showBbsModPopup}
        onCancel={() => setShowBbsModPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => setShowBbsModPopup(false)}
          />
        }
      >
        <div className="modal-info">
          <p>수정사항을 저장하시겠습니까?</p>
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setShowBbsModPopup(false);
            }}
          >
            아니오
          </button>
          <button className="zoneinfo_ok_btn" onClick={bbsModYes}>
            예
          </button>
        </div>
      </Popup>

      <Popup
        show={showPopup}
        // onCancel={() => setShowAddPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowPopup(false);
              if (
                popupMsg.includes("등록") ||
                popupMsg.includes("삭제") ||
                popupMsg.includes("수정")
              ) {
                // navigate("/bbsList");
                props.setShowBulletinBoard(false);
              }
            }}
          />
        }
      >
        <div className="modal-info">
          {/* <p
                        style={{
                            fontSize: fontSizeValue,
                            padding: paddingValue,
                        }}
                        dangerouslySetInnerHTML={{ __html: popupMsg }}
                    ></p> */}
          <p>{popupMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);
              if (
                popupMsg.includes("등록") ||
                popupMsg.includes("삭제") ||
                popupMsg.includes("수정")
              ) {
                // navigate("/bbsList");
                props.setShowBulletinBoard(false);
              }
            }}
          >
            닫기
          </button>
        </div>
      </Popup>

      <Popup
        show={showFileDelPopup}
        onCancel={() => setShowFileDelPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => setShowFileDelPopup(false)}
          />
        }
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          {/* <p>업로드한 파일을 삭제하시겠습니까?</p> */}
          <button
            className="zoneinfo_close_btn"
            onClick={() => {
              setFileToDeleteIndex(null);
              setShowFileDelPopup(false);
            }}
          >
            아니오
          </button>
          <button
            className="zoneinfo_ok_btn"
            onClick={() => {
              setShowFileDelPopup(false);
              if (popupMsg.includes("전체")) {
                handleDeleteAllFiles();
              } else {
                handleDeleteFile(fileToDeleteIndex);
              }
            }}
          >
            예
          </button>
        </div>
      </Popup>
      {loading == true ? (
        <div className="loading-container">
          <div id="loading"></div>
        </div>
      ) : null}
    </div>
  );
}

export default BulletinBoard;

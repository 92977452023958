import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const globalStyle = createGlobalStyle`
    ${reset};

    :root {
      --theme-color: #063761;
      --default-text-color: #404143;
      --default-text-color-hover: #777777;
      --default-infotext-color: #AFAFAF;
      
      --color-primary: #2987cd;
      --color-red: #CC2029;
      --color-green: #2DB84B;
      
      --color-primary-hover: #216aa1;
      --color-red-hover: #99171e;
      --color-green-hover: #2d8740;
    
     --font-family: 'Noto Sans KR', sans-serif;

      --header-height: 50px;
      --footer-height: 50px;
    }

    body{
      position: fixed;  // 모바일 브라우저에서 주소창으로 인한 높이 변화 방지
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    #root{
      height : 100%;
      width : 100%;
      display: flex;
      flex-direction: column;
    }

    .app-container.header-container{
      width : 100vw;
      height : 50px;
      background-color: var(--theme-color);
    }

    main{
      flex: 1;
      width : 100vw;
      height: calc(100% - var(--header-height) - var(--footer-height));
    }

    footer{
      width : 100vw;
      height : 50px;
    }
    
    .contents-row {
      display: flex;
      flex-direction: row;
    }
    
    .contents-col {
      display: flex;
      flex-direction: column;
    }
    
    .gc-btn {
      color: white;
      background-color: var(--color-primary);
      padding : 4px 14px;
      display: inline-block;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
    }
    
    .gc-btn:hover {
      cursor: pointer;
      background-color: var(--color-primary-hover);
      color: white;
    }
    
    .gc-btn-wide {
      padding: 4px 20px;
    }
    
    .gc-btn-big {
      font-size: 18px;
      padding: 12px 26px
    }
    
    .gc-btn-red {
      background-color: var(--color-red);
    }
    
    .gc-btn-red:hover {
      background-color: var(--color-red-hover);
    }

    .spacer {
      width: 100%;
      height: 100%;
      flex-grow : 1;
    }
 
    .code.x.icon {
      position: absolute;
      right: 0;
      cursor: pointer;
    }

    .code.x.icon:hover {
      background-color: white;
      color: black;
    }

    .full-width {
      width: 100%;
    }

    .half-width {
      width: 50%;
    }

    .flex-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
`;

export default globalStyle;

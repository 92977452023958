import './legend.css';

import React, { useEffect, useState } from 'react';

import NewModifyLegend from './NewModifyLegend';
import colormap from 'colormap';

function Legend(props) {
  // 최대 / 최소 입력값
  const [inputMinMax, setInputMinMax] = useState([]);
  // 최대 / 최소 기본값
  const [defaultMinMax, setDefaultMinMax] = useState([]);
  // 분류방식
  const [classifyType, setClassifyType] = useState();
  // 컬러맵
  const [colormapType, setColormapType] = useState();
  // 라벨
  const [legendLabel, setLegendLabel] = useState([]);
  // 면적
  const [legendArea, setLegendArea] = useState([]);
  // 값
  const [legendValue, setLegendValue] = useState([]);
  // 단계
  const [legendStep, setLegendStep] = useState();
  // NoData
  const [nodata, setNodata] = useState();
  // 속성
  const [attribute, setAttribute] = useState();
  // 속성리스트
  const [attrOption, setAttrOption] = useState([]);
  // 범례 타이틀
  const [legendTitle, setLegendTitle] = useState('');

  // 육안판독 전용 범례
  const [visdetecLegend, setVisdetecLegend] = useState();

  const [flag, setFlag] = useState(false);

  const colormaps = [
    'RdYlGn',
    'RdYlGn',
    'RdBu',
    'RdBu',
    'jet',
    'jet',
    'hot',
    'hot',
  ];

  const customColormaps = {
    RdYlGn: ['#d7191c', '#fdae61', '#ffffc0', '#a6d96a', '#1a9641'],
  };

  function initOptions() {
    setInputMinMax([]);
    setDefaultMinMax([]);
    setClassifyType(1);
    setColormapType(1);
    setLegendLabel([]);
    setLegendArea([]);
    setLegendValue([]);
    setNodata();
    setAttribute(null);
    setAttrOption([]);
    setLegendTitle('');
  }

  useEffect(() => {
    if (props.curActiveLayer) {
      // console.log('props.curActiveLayer', props.curActiveLayer);
      // 육안판독
      if (props.curActiveLayer.crt_ty_cd == 4) {
        let dummyArr = [];
        if (props.curActiveLayer.geoJson) {
          for (
            let i = 0;
            i < props.curActiveLayer.geoJson.features.length;
            i++
          ) {
            let name = props.curActiveLayer.geoJson.features[i].properties.name;
            let color =
              props.curActiveLayer.geoJson.features[i].properties.color;
            let area = props.curActiveLayer.geoJson.features[i].properties.AREA;

            dummyArr.push({ name: name, color: color, area: area });
          }
        }

        const groupedData = dummyArr.reduce((result, current) => {
          const key = current.name + current.color;
          if (!result[key]) {
            result[key] = { ...current, count: 1 };
          } else {
            result[key].area += current.area;
            result[key].count += 1;
          }
          return result;
        }, {});

        const transformedArray = Object.values(groupedData);
        setVisdetecLegend(transformedArray);
      } else {
        if (
          props.curActiveLayer.hasOwnProperty('legend_info') &&
          props.curActiveLayer.legend_info != null
        ) {
          let legendInfo;

          if (typeof props.curActiveLayer.legend_info == 'string') {
            legendInfo = JSON.parse(props.curActiveLayer.legend_info);
          } else {
            legendInfo = props.curActiveLayer.legend_info;
          }

          const arrayLabel = [];
          const arrayValue = [];
          const arrayArea = [];

          // 래스터
          if (props.curActiveLayer.expt_ty === 'tif') {
            setDefaultMinMax([
              props.curActiveLayer.raster_stat.min,
              props.curActiveLayer.raster_stat.max,
            ]);
          } else {
            // 벡터
            let calMinMax = calGeoJson();
            setDefaultMinMax(calMinMax);

            // 속성
            if (
              props.curActiveLayer.hasOwnProperty('attr_info') &&
              props.curActiveLayer.attr_info != null
            ) {
              setAttribute(props.curActiveLayer.attr_info);
            }
          }

          // 범위 및 라벨 설정
          legendInfo.legend &&
            legendInfo.legend.map((e, i) => {
              // 라벨
              if (e.hasOwnProperty('label') && e.label != null) {
                arrayLabel.push(e.label);
              }

              // 범위
              if (e.hasOwnProperty('value') && e.value != null) {
                if (typeof e.value !== 'string') {
                  arrayValue.push(Number(e.value).toFixed(3));
                } else {
                  arrayValue.push(e.value);
                }
              } else if (e.value == null) {
                if (i === legendInfo.legend.length - 1) {
                  if (
                    props.curActiveLayer.hasOwnProperty('raster_stat') &&
                    props.curActiveLayer.raster_stat != null
                  ) {
                    if (typeof props.curActiveLayer.raster_stat == 'string') {
                      arrayValue.push(
                        JSON.parse(
                          props.curActiveLayer.raster_stat
                        ).max.toFixed(3)
                      );
                    } else {
                      arrayValue.push(
                        props.curActiveLayer.raster_stat.max.toFixed(3)
                      );
                    }
                  } else {
                    var calMinMax = calGeoJson();
                    if (calMinMax != undefined) {
                      arrayValue.push(calMinMax[1].toFixed(3));
                    }
                  }
                }
              }

              // 넓이
              if (e.hasOwnProperty('m2Area') && e.m2Area != null) {
                arrayArea.push(e.m2Area.toFixed(0));
              }
            });

          // 공통
          setInputMinMax([legendInfo.min, legendInfo.max]);
          setClassifyType(legendInfo.legendTy);
          setColormapType(legendInfo.colorTy);
          setLegendStep(legendInfo.legend.length);
          setLegendLabel(arrayLabel);
          setLegendValue(arrayValue);
          setLegendArea(arrayArea);
          setNodata(legendInfo.noData);
          setLegendTitle(legendInfo.legendTitle);

          if (
            props.curActiveLayer.hasOwnProperty('geoJson') &&
            props.curActiveLayer.geoJson !== null
          ) {
            let dummyOptions = [];
            let dummyArray = Object.entries(
              props.curActiveLayer.geoJson.features[0].properties
            );
            dummyArray = dummyArray.filter((item) => item[0] !== 'color');
            dummyArray &&
              dummyArray.map((e, i) => {
                let isnan = typeof e[1];
                if (isnan == 'number') {
                  dummyOptions.push({
                    key: i,
                    value: e[0],
                    text: e[0],
                    type: 'number',
                  });
                } else if (isnan == 'string') {
                  dummyOptions.push({
                    key: i,
                    value: e[0],
                    text: e[0],
                    type: 'string',
                  });
                }
              });
            setAttrOption(dummyOptions);
          } else {
            //   setAttribute();
            setAttrOption();
          }
        } else {
          initOptions();
          if (
            props.curActiveLayer.hasOwnProperty('geoJson') &&
            props.curActiveLayer.geoJson !== null
          ) {
            let dummyOptions = [];
            let dummyArray = Object.entries(
              props.curActiveLayer.geoJson.features[0].properties
            );
            dummyArray &&
              dummyArray.map((e, i) => {
                let isnan = typeof e[1];
                if (isnan == 'number') {
                  dummyOptions.push({
                    key: i,
                    value: e[0],
                    text: e[0],
                    type: 'number',
                  });
                } else if (isnan == 'string') {
                  dummyOptions.push({
                    key: i,
                    value: e[0],
                    text: e[0],
                    type: 'string',
                  });
                }
              });
            setAttrOption(dummyOptions);
          } else {
            //   setAttribute();
            setAttrOption();
          }
        }
        // 범례 변경 시 속성 지정
      }
    } else {
      initOptions();
    }
  }, [props.curActiveLayer]);

  // 육안판독 범례
  const legendRenderingVD = () => {
    const legendArray = [];
    visdetecLegend &&
      visdetecLegend.map((e) => {
        legendArray.push(
          <div className='contents-row' key={e.name}>
            <div
              className='color label-1'
              style={{ backgroundColor: e.color }}
            ></div>
            <div className='text flex-center'>
              {e.name} ( {e.count} 필지 / {(Number(e.area) / 10000).toFixed(2)}{' '}
              ㏊)
              {/* ㎡ */}
            </div>
          </div>
        );
      });
    return legendArray;
  };

  // 래스터 범례
  const renderingRaster = () => {
    const legendArray = [];

    if (props.curActiveLayer) {
      if (props.curActiveLayer.layerType === 2) {
        if (props.curActiveLayer.legend_info) {
          if (props.curActiveLayer.expt_ty === 'tif') {
            let colors;
            let gradient;
            let direction;

            // colors 배열을 CSS 그라데이션 형식으로 변환
            if (props.curActiveLayer.legend_info.colorTy % 2 === 0) {
              direction = 'to top';
            } else {
              direction = 'to bottom';
            }

            if (props.curActiveLayer.legend_info.colorTy < 3) {
              gradient = `linear-gradient(${direction}, ${customColormaps[
                'RdYlGn'
              ].join(', ')})`;
            } else {
              colors = colormap({
                colormap:
                  colormaps[props.curActiveLayer.legend_info.colorTy - 1], // 사용할 색상 맵 이름
                nshades: 10, // 생성할 색상의 개수
                format: 'hex', // 색상의 형식 ('hex', 'rgb', 'rgba')
                alpha: 1, // 투명도 (0에서 1 사이)
              });
              gradient = `linear-gradient(${direction}, ${colors.join(', ')})`;
            }

            if (props.curActiveLayer.legend_info.legendTy === 1) {
              let result = renderingLinear(
                props.curActiveLayer.legend_info,
                gradient
              );
              legendArray.push(result[0]);
            } else {
              legendArray.push(
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '5px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'flex-start',
                    }}
                  >
                    {props.curActiveLayer.legend_info.legend.map(
                      (legendInfo, index, array) => {
                        const reverseIndex = array.length - 1 - index;
                        const reversedItem = array[reverseIndex];
                        return (
                          <div className='contents-row' key={index}>
                            <div
                              className='color label-1'
                              style={{
                                backgroundColor: rgbToHex(reversedItem.color),
                              }}
                            ></div>
                            <div
                              className='text flex-center'
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div style={{ minWidth: '45px' }}>
                                {reversedItem.label}
                              </div>
                              <div style={{ minWidth: '10px' }}>: </div>
                              <div style={{ whiteSpace: 'pre' }}>
                                {reverseIndex === 0
                                  ? '<  ' +
                                    Number(reversedItem.value).toFixed(3)
                                  : reversedItem.value !== null
                                  ? Number(
                                      array[reverseIndex - 1].value
                                    ).toFixed(3) +
                                    '  -  ' +
                                    Number(reversedItem.value).toFixed(3)
                                  : '>=  ' +
                                    Number(
                                      array[reverseIndex - 1].value
                                    ).toFixed(3)}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            }

            return legendArray;
          }
        } else {
          return (
            <div className='legend-guideline'>
              범례 정보가 존재하지 않습니다.<br></br>
              <br></br>아래 '범례 변경'을 선택하여 <br></br> 범례 정보를
              업데이트해주세요.
            </div>
          );
        }
      }
    }
  };

  // 벡터 범례
  const renderingVector = () => {
    const legendArray = [];

    if (props.curActiveLayer) {
      if (props.curActiveLayer.layerType === 2) {
        if (props.curActiveLayer.legend_info) {
          if (props.curActiveLayer.expt_ty === 'shp') {
            let gradient;

            if (props.curActiveLayer.legend_info.legendTy === 1) {
              let result = renderingLinear(
                props.curActiveLayer.legend_info,
                gradient
              );
              legendArray.push(result[0]);
            } else {
              legendArray.push(
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '5px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'flex-start',
                    }}
                  >
                    {props.curActiveLayer.legend_info.legend.length > 1
                      ? props.curActiveLayer.legend_info.legend.map(
                          (legendInfo, index, array) => {
                            const reverseIndex = array.length - 1 - index;
                            const reversedItem = array[reverseIndex];
                            return (
                              <div className='contents-row' key={index}>
                                <div
                                  className='color label-1'
                                  style={{
                                    backgroundColor: rgbToHex(
                                      reversedItem.color
                                    ),
                                  }}
                                ></div>
                                <div
                                  className='text flex-center'
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  {props.curActiveLayer.legend_info.legendTy !==
                                  6 ? (
                                    <>
                                      <div style={{ minWidth: '60px' }}>
                                        {reversedItem.label}
                                      </div>
                                      <div
                                        style={{
                                          minWidth: '10px',
                                          textAlign: 'center',
                                          whiteSpace: 'pre',
                                        }}
                                      >
                                        {'  :  '}
                                      </div>
                                    </>
                                  ) : null}
                                  {props.curActiveLayer.legend_info.legendTy !==
                                  6 ? (
                                    <div style={{ whiteSpace: 'pre' }}>
                                      {reverseIndex === 0
                                        ? '<  ' +
                                          Number(reversedItem.value).toFixed(3)
                                        : reverseIndex === array.length - 1
                                        ? '>=  ' +
                                          Number(
                                            array[reverseIndex - 1].value
                                          ).toFixed(3)
                                        : Number(
                                            array[reverseIndex - 1].value
                                          ).toFixed(3) +
                                          '  -  ' +
                                          Number(reversedItem.value).toFixed(3)}
                                    </div>
                                  ) : (
                                    reversedItem.value
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )
                      : props.curActiveLayer.legend_info.legend.map(
                          (legendInfo, index, array) => {
                            const reverseIndex = array.length - 1 - index;
                            const reversedItem = array[reverseIndex];
                            return (
                              <div className='contents-row' key={index}>
                                <div
                                  className='color label-1'
                                  style={{
                                    backgroundColor: rgbToHex(legendInfo.color),
                                  }}
                                ></div>
                                <div
                                  className='text flex-center'
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div style={{ minWidth: '60px' }}>
                                    {legendInfo.label}
                                  </div>
                                  <div
                                    style={{
                                      minWidth: '10px',
                                      textAlign: 'center',
                                      whiteSpace: 'pre',
                                    }}
                                  >
                                    {'  :  '}
                                  </div>
                                  {props.curActiveLayer.legend_info.legendTy !==
                                  6 ? (
                                    <div style={{ whiteSpace: 'pre' }}>
                                      {Number(legendInfo.value).toFixed(3) +
                                        '  -  ' +
                                        Number(reversedItem.value).toFixed(3)}
                                    </div>
                                  ) : (
                                    reversedItem.value
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                  </div>
                </div>
              );
            }

            return legendArray;
          }
        } else {
          return (
            <div className='legend-guideline'>
              범례 정보가 존재하지 않습니다.<br></br>
              <br></br>아래 '범례 변경'을 선택하여 <br></br> 범례 정보를
              업데이트해주세요.
            </div>
          );
        }
      }
    }
  };

  function calGeoJson() {
    let maxValue;
    let minValue;
    if (
      props.curActiveLayer.hasOwnProperty('geoJson') &&
      props.curActiveLayer.geoJson != null
    ) {
      let valueArray = [];
      for (let i = 0; i < props.curActiveLayer.geoJson.features.length; i++) {
        let val =
          props.curActiveLayer.geoJson.features[i].properties[
            props.curActiveLayer.attr_info
          ];
        valueArray.push(val);
      }

      if (typeof valueArray[0] !== 'string') {
        maxValue = Math.max(...valueArray);
        minValue = Math.min(...valueArray);
      } else {
        // 빈도 계산을 위한 객체 생성
        let frequency = {};

        valueArray.forEach((value) => {
          if (frequency[value]) {
            frequency[value]++;
          } else {
            frequency[value] = 1;
          }
        });

        // 최대값과 최소값 초기화
        let maxV = null;
        let minV = null;
        let maxC = 0;
        let minC = Infinity;

        // 빈도 객체를 순회하면서 최대값과 최소값 찾기
        for (let key in frequency) {
          if (frequency[key] > maxC) {
            maxC = frequency[key];
            maxV = key;
          }
          if (frequency[key] < minC) {
            minC = frequency[key];
            minV = key;
          }
        }

        maxValue = maxC;
        minValue = minC;

        // console.log("frequency", frequency);
      }
      return [minValue, maxValue];
    }
  }

  function rgbToHex(arr) {
    if (arr) {
      return (
        '#' +
        ((1 << 24) | (arr[0] << 16) | (arr[1] << 8) | arr[2])
          .toString(16)
          .slice(1)
      );
    }
  }

  const renderingLinear = (legend, gradient) => {
    const linearLegend = [];
    linearLegend.push(
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '5px',
        }}
      >
        <div
          className='colorMap'
          style={{
            background: gradient,
            width: '40px',
            height: 'auto',
            minHeight: '140px',
            marginRight: '5px',
          }}
        ></div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div className='contents-row'>
            <div className='text flex-center'>
              High : {legend.max.toFixed(3)}
            </div>
          </div>
          <div className='contents-row'>
            <div className='text flex-center'>
              Low : {legend.min.toFixed(3)}
            </div>
          </div>
        </div>
      </div>
    );

    return linearLegend;
  };

  return (
    <>
      {props.curActiveLayer &&
      props.curActiveLayer.hasOwnProperty('them_map_id') ? (
        <div
          className='legend-contatiner contents-col'
          style={
            props.curActiveLayer.legend_info
              ? props.curActiveLayer.legend_info.legendTy !== 6
                ? {
                    right: '50px',
                    fontFamily: 'Noto Sans KR',
                    minWidth: '250px',
                  }
                : {
                    right: '50px',
                    fontFamily: 'Noto Sans KR',
                    minWidth: '75px',
                  }
              : {
                  right: '50px',
                  fontFamily: 'Noto Sans KR',
                  minWidth: '250px',
                }
          }
        >
          {props.curActiveLayer && props.curActiveLayer.layerType === 2 ? (
            props.curActiveLayer.legend_info ? (
              props.curActiveLayer.legend_info.legendTitle ? (
                <div
                  className='legend contents-row'
                  style={{
                    justifyContent: 'center',
                    fontWeight: '600',
                    fontSize: '18px',
                    margin: '8px',
                    wordBreak: 'break-all',
                    wordWrap: 'break-word',
                  }}
                >
                  {props.curActiveLayer.legend_info.legendTitle}
                </div>
              ) : (
                <div
                  className='legend contents-row'
                  style={{
                    justifyContent: 'center',
                    fontWeight: '600',
                    fontSize: '18px',
                    margin: '8px',
                  }}
                >
                  제목없음
                </div>
              )
            ) : null
          ) : null}

          {props.curActiveLayer.crt_ty_cd == 4
            ? legendRenderingVD()
            : props.curActiveLayer.expt_ty === 'tif'
            ? renderingRaster()
            : renderingVector()}

          {props.curActiveLayer.crt_ty_cd == 4 ? null : (
            <div className='legend-btn-group flex-center'>
              <button
                className='gc-btn Modify-legend'
                onClick={() => {
                  props.setShowModifyLegend(true);
                  setFlag(true);
                }}
              >
                범례 변경
              </button>
            </div>
          )}
        </div>
      ) : null}

      {flag ? (
        <NewModifyLegend
          showModifyLegend={props.showModifyLegend}
          setShowModifyLegend={props.setShowModifyLegend}
          selectItrst={props.selectItrst}
          activeLayerList={props.activeLayerList}
          curActiveLayer={props.curActiveLayer}
          setLoading={props.setLoading}
          inputMinMax={inputMinMax}
          defaultMinMax={defaultMinMax}
          classifyType={classifyType}
          colormapType={colormapType}
          legendLabel={legendLabel}
          legendArea={legendArea}
          legendValue={legendValue}
          legendStep={legendStep}
          legendTitle={legendTitle}
          nodata={nodata}
          attribute={attribute}
          attrOption={attrOption}
          visdetecLegend={visdetecLegend}
          setInputMinMax={setInputMinMax}
          setDefaultMinMax={setDefaultMinMax}
          setClassifyType={setClassifyType}
          setColormapType={setColormapType}
          setLegendLabel={setLegendLabel}
          setLegendArea={setLegendArea}
          setLegendValue={setLegendValue}
          setLegendStep={setLegendStep}
          setLegendTitle={setLegendTitle}
          setNodata={setNodata}
          setAttribute={setAttribute}
          setAttrOption={setAttrOption}
          setVisdetecLegend={setVisdetecLegend}
          activeLayerListHandler={props.activeLayerListHandler}
          delModeHandler={props.delModeHandler}
          setRefreshFlag={props.setRefreshFlag}
          setDummybbox={props.setDummybbox}
          setClickValue={props.setClickValue}
          isMobile={props.isMobile}
        ></NewModifyLegend>
      ) : null}
    </>
  );
}

export default Legend;

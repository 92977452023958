import "./instFarmwork.css";
import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  Select,
  Checkbox,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from "semantic-ui-react";

import Modal from "../../shared/components/uiElements/Modal";
import Popup from "../../shared/components/uiElements/Popup";

import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: ${rotate} 1s infinite linear;
`;

function InstFarmwork(props) {
  const APIToken = localStorage.getItem("login-token");
  const userId = localStorage.getItem("user-id");

  // 재배관리맵
  const [farmmngMaplist, setFarmmngMaplist] = useState([]);
  const [themeMapIndex, setThemeMapIndex] = useState();
  // 드론 기제선택
  const [dronelist, setDronelist] = useState([]);
  const [droneIndex, setDroneIndex] = useState();
  // 드론회전거리
  const [drnrtDstnc, setDrnrtDstnc] = useState(null);
  // 비행고도
  const [drnFlgAlt, setDrnFlgAlt] = useState(null);
  // PWM 하
  const [drnPwmL, setDrnPwmL] = useState(null);
  // PWM 중
  const [drnPwmM, setDrnPwmM] = useState(null);
  // PWM 상
  const [drnPwmH, setDrnPwmH] = useState(null);
  // 약제살포단위
  const [chemApplication, setChemApplication] = useState(null);
  // 측간거리
  const [interDst, setInterDst] = useState(null);
  // 기타 의견
  const [opinion, setOpinion] = useState();

  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState();
  // 지원 실패 Popup
  const [failShowPopup, setFailShowPopup] = useState(false);
  const [failPopupMsg, setFailPopupMsg] = useState();

  const handleThemeMapIndex = (e, data) => {
    setThemeMapIndex(data.value);
  };
  const handleDroneIndex = (e, data) => {
    setDroneIndex(data.value);
  };
  // 드론회전거리
  const handleDrnrtDstnc = (e) => {
    setDrnrtDstnc(e.target.value);
  };
  // 비행고도
  const handleDrnFlgAl = (e) => {
    setDrnFlgAlt(e.target.value);
  };
  // PWM 하
  const handleDrnPwmL = (e) => {
    setDrnPwmL(e.target.value);
  };
  // PWM 중
  const handleDrnPwmM = (e) => {
    setDrnPwmM(e.target.value);
  };
  // PWM 상
  const handleDrnPwmH = (e) => {
    setDrnPwmH(e.target.value);
  };
  // 약제살포단위
  const handleChemApplication = (e) => {
    setChemApplication(e.target.value);
  };
  // 측간거리
  const handleInterDst = (e) => {
    setInterDst(e.target.value);
  };
  // 기타의견
  const handleOpinion = (e) => {
    setOpinion(e.target.value);
  };

  // useEffect(() => {
  //   console.log(props);
  // }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
    }
  };

  // 재배관리맵(주제도) 불러오기
  useEffect(() => {
    if (props.showRegistInstModal) {
      axios({
        method: "get",
        url:
          `/api/theme-map/list?` + `&zoneId=${props.selectItrst.itrst_zone_id}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.success) {
            const resultList = result.data.data;
            const dummyOption = [];
            resultList &&
              resultList.map((e, i) => {
                if (e.layer_nm) {
                  // 래스터 주제도만 해당
                  dummyOption.push({
                    key: i,
                    value: e.them_map_id,
                    text: e.theme_map_ttl,
                  });
                }
              });
            setFarmmngMaplist(dummyOption);
          } else {
            setFarmmngMaplist();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.showRegistInstModal]);

  //드론 기제선택
  useEffect(() => {
    if (props.showRegistInstModal) {
      axios({
        method: "get",
        url: `/api/drone/type/list?` + `&userId=${userId}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.success) {
            const resultList = result.data.data;

            const dummyOption2 = [];

            resultList &&
              resultList.map((item, index) => {
                dummyOption2.push({
                  key: index,
                  value: item.drone_id,
                  text: `${item.drone_model_nm ? item.drone_model_nm : ""} / ${
                    item.drone_mkr_nm ? item.drone_mkr_nm : ""
                  } / ${item.drone_type ? item.drone_type : ""}`, //${item.drone_model_nm} ${item.drone_mkr_nm} ${item.drone_type}
                });
              });
            setDronelist(dummyOption2);
          } else {
            setDronelist();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.showRegistInstModal]);

  function validation() {
    if (
      themeMapIndex === null ||
      droneIndex === null ||
      drnrtDstnc === null ||
      drnFlgAlt === null ||
      drnPwmL === null ||
      drnPwmM === null ||
      drnPwmH === null ||
      chemApplication === null ||
      interDst === null
    ) {
      setFailPopupMsg("필수 정보를 입력해주세요.");
      setFailShowPopup(true);
      return false;
    }
    return true;
  }

  function regFarmWork() {
    let isOK = validation();
    if (isOK) {
      axios({
        method: "post",
        url: `/api/instFarmwork/req`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
        data: {
          themeMapId: themeMapIndex, //재배관리맵
          droneId: droneIndex, //드론기제선택
          rotDist: drnrtDstnc, //드론회전거리
          flgtAlt: drnFlgAlt, //비행 고도
          pwmLow: drnPwmL, // PWM 하
          pwmMid: drnPwmM, // PWM 중
          pwmHigh: drnPwmH, // PWM 상
          unit: chemApplication, //약제살포단위
          size: interDst, //측간거리
          opinion: opinion, // 기타 의견
          frmwkt_req_id: props.curActiveLayer.frmwkt_req_id, // 농작업 요청 아이디
        },
      })
        .then((result) => {
          if (result.data.success) {
            props.setLoading(false);
            setPopupMsg("농작업 지원이 완료되었습니다.");
            setShowPopup(true);
          } else {
            props.setLoading(false);
            setFailPopupMsg("농작업 지원을 실패하였습니다.");
            setFailShowPopup(true);
          }
        })
        .catch((error) => {
          props.setLoading(false);
          console.log(error);
        });
    }
  }

  return (
    <React.Fragment>
      <Modal
        show={props.showRegistInstModal}
        header="농작업 지원"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              props.setShowRegistInstModal(false);
              props.setSelectedFarm();
            }}
          />
        }
        // className={`themeMap ${props.isMobile ? "mobile" : ""}`}
        className={`instFarmwork`}
      >
        <div className="farmworkSelect-modal-container">
          {props.curActiveLayer !== undefined ? (
            <div
              style={{
                width: "100%",
                height: "fit-content",
                // padding: "5px",
                // backgroundColor: "#d8d8d8",
                // borderRadius: "5px",
                fontFamily: "Noto Sans KR",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  fontFamily: "Noto Sans KR",
                  fontWeight: "800",
                  fontSize: "15px",
                  color: "var(--color-primary)",
                }}
              >
                경작자 요청 사항
              </div>
              <Table
                color={"blue"}
                key={"blue"}
                textAlign={"center"}
                style={{ marginTop: "10px" }}
              >
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.1)",
                        // borderBottom: "1px solid rgba(34,36,38,.5)",
                      }}
                    >
                      작업 요청자
                    </TableHeaderCell>
                    <TableCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.1)",
                        // borderBottom: "1px solid rgba(34,36,38,.1)",
                      }}
                      colSpan={3}
                    >
                      {props.curActiveLayer.user_nm} /{" "}
                      {props.curActiveLayer.inst_nm}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeaderCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.1)",
                        // borderBottom: "1px solid rgba(34,36,38,.5)",
                      }}
                    >
                      필지
                    </TableHeaderCell>
                    <TableCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.1)",
                        // borderBottom: "1px solid rgba(34,36,38,.1)",
                      }}
                      colSpan={3}
                    >
                      {props.curActiveLayer.fmap_innb}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeaderCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.1)",
                        // borderBottom: "1px solid rgba(34,36,38,.5)",
                      }}
                    >
                      작업 요청일
                    </TableHeaderCell>
                    <TableCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.1)",
                        // borderBottom: "1px solid rgba(34,36,38,.1)",
                      }}
                      colSpan={3}
                    >
                      {props.curActiveLayer.frmwkt_ymd}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeaderCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.1)",
                        // borderBottom: "1px solid rgba(34,36,38,.5)",
                      }}
                    >
                      농작업 종류
                    </TableHeaderCell>
                    <TableCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.1)",
                        // borderBottom: "1px solid rgba(34,36,38,.1)",
                      }}
                    >
                      {props.curActiveLayer.frmwkt_ty_cd === "lp"
                        ? "액체 방제"
                        : props.curActiveLayer.frmwkt_ty_cd === "sp"
                        ? "고체 방제"
                        : props.curActiveLayer.frmwkt_ty_cd === "lf"
                        ? "액체 비료"
                        : props.curActiveLayer.frmwkt_ty_cd === "sf"
                        ? "고체 비료"
                        : props.curActiveLayer.frmwkt_ty_cd === "so"
                        ? "파종"
                        : null}
                    </TableCell>
                    <TableHeaderCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.5)",
                        // borderBottom: "1px solid rgba(34,36,38,.5)",
                      }}
                    >
                      대상 작물
                    </TableHeaderCell>
                    <TableCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.1)",
                        // borderBottom: "1px solid rgba(34,36,38,.1)",
                      }}
                    >
                      {props.curActiveLayer.trgt_crps}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeaderCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.1)",
                        // borderBottom: "1px solid rgba(34,36,38,.5)",
                      }}
                    >
                      기타 의견
                    </TableHeaderCell>
                    <TableCell
                      style={{
                        paddingTop: "9px",
                        paddingBottom: "9px",
                        // borderTop: "1px solid rgba(34,36,38,.1)",
                        // borderBottom: "1px solid rgba(34,36,38,.5)",
                      }}
                      colSpan={3}
                    >
                      {props.curActiveLayer.frmwkt_req_cn}
                    </TableCell>
                  </TableRow>
                </TableHeader>
              </Table>
            </div>
          ) : null}
          <div className="input-group contents-col">
            <div className="title-text">
              <span className="required-marker">*</span>재배관리맵 선택
            </div>
            <Select
              className="select-map farmwork"
              options={farmmngMaplist}
              placeholder="재배관리맵을 선택하세요."
              onChange={handleThemeMapIndex}
            ></Select>
          </div>

          <div className="input-group contents-col">
            <div className="title-text">
              <span className="required-marker">*</span>드론 기제선택
            </div>
            <Select
              className="select-map farmwork"
              options={dronelist}
              placeholder="드론을 선택하세요."
              onChange={handleDroneIndex}
            ></Select>
          </div>

          <div className="contents-row">
            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>드론 회전 거리(m)
              </div>
              <div className="contents-row">
                <input
                  className="crop farmwork full-width"
                  value={drnrtDstnc}
                  onChange={handleDrnrtDstnc}
                ></input>
              </div>
            </div>
            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>비행 고도(m)
              </div>
              <div className="contents-row">
                <input
                  className="crop farmwork full-width"
                  value={drnFlgAlt}
                  onChange={handleDrnFlgAl}
                  placeholder="소수점 한 자리까지 입력 가능합니다."
                ></input>
              </div>
            </div>
          </div>

          <div className="contents-row">
            <div className="input-group contents-col th-width">
              <div className="title-text">
                <span className="required-marker">*</span>PWM (하)
              </div>
              <div className="contents-row">
                <input
                  className="crop farmwork full-width"
                  value={drnPwmL}
                  onChange={handleDrnPwmL}
                ></input>
              </div>
            </div>
            <div className="input-group contents-col th-width">
              <div className="title-text">
                <span className="required-marker">*</span>PWM (중)
              </div>
              <div className="contents-row">
                <input
                  className="crop farmwork full-width"
                  value={drnPwmM}
                  onChange={handleDrnPwmM}
                ></input>
              </div>
            </div>
            <div className="input-group contents-col th-width">
              <div className="title-text">
                <span className="required-marker">*</span>PWM (상)
              </div>
              <div className="contents-row">
                <input
                  className="crop farmwork full-width"
                  value={drnPwmH}
                  onChange={handleDrnPwmH}
                ></input>
              </div>
            </div>
          </div>

          <div className="contents-row">
            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>약제살포단위
              </div>
              <div className="contents-row">
                <input
                  className="crop farmwork full-width"
                  value={chemApplication}
                  onChange={handleChemApplication}
                ></input>
              </div>
            </div>

            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>측간거리(m)
              </div>
              <input
                className="crop farmwork"
                value={interDst}
                onChange={handleInterDst}
              ></input>
            </div>
          </div>

          {/* Enter가 안됨 */}
          <div className="contents-row">
            <div className="input-group contents-col full-width">
              <div className="title-text">기타 의견</div>
              <div className="contents-row">
                <textarea
                  style={{
                    fontFamily: "Noto Sans KR",
                    fontWeight: 500,
                    fontSize: "15px",
                    textAlign: "left",
                    padding: "8px",
                    color: "var(--default-text-color)",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    borderRadius: "5px",
                    height: "35px",
                    margin: "0 15px",
                    minHeight: "100px",
                    lineHeight: "140%",
                    resize: "none",
                  }}
                  className="full-width"
                  value={opinion}
                  onChange={handleOpinion}
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="button-group flex-center">
          <button
            className="gc-btn farmworkSelect-cancel"
            onClick={() => {
              props.setShowRegistInstModal(false);
            }}
          >
            취소
          </button>
          <button
            className="gc-btn farmworkSelect-register"
            onClick={() => {
              regFarmWork();
            }}
          >
            등록
          </button>
        </div>
      </Modal>

      <Popup
        show={showPopup}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowPopup(false);
            }}
          />
        }
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);
              props.setShowRegistInstModal(false);
              setThemeMapIndex(null);
              setDroneIndex(null);
              setDrnrtDstnc(null);
              setDrnFlgAlt(null);
              setDrnPwmL(null);
              setDrnPwmM(null);
              setDrnPwmH(null);
              setChemApplication(null);
              setInterDst(null);
              setOpinion(null);
              props.searchFarmworkListforInst();
            }}
          >
            닫기
          </button>
        </div>
      </Popup>

      <Popup
        show={failShowPopup}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setFailShowPopup(false);
            }}
          />
        }
      >
        <div className="modal-info">
          <p>{failPopupMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setFailShowPopup(false);
              props.setShowRegistInstModal(true);
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}
export default InstFarmwork;

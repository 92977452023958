import './findid.css';
import React, { useState } from 'react';

import { Checkbox, Dropdown, Select } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Popup from '../../../shared/components/uiElements/Popup';
import { useNavigate } from 'react-router-dom'

import ChangePw from './changePw'

// AXIOS
import axios from "axios";

function FindPw(props) {

    const navigate = useNavigate();
    // const navigate = (to, options) => {
    //     // 경로 변경 및 페이지 이동 로직 구현
    //     console.log('Navigating to', to);
    //     console.log('Options:', options);
    //     // ...
    // };    
    
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState('');

    const options = [
        { key: 1, text: '내가 가장 좋아하는 장소는?', value: 1 },
        { key: 2, text: '내가 가장 좋아하는 음식은?', value: 2 },
        { key: 3, text: '인생의 좌우명은?', value: 3 },
        { key: 4, text: '가장 감명 깊게 본 영화는?', value: 4 },
    ]

    // 비밀번호 찾기 토큰
    // const [pwToken, setPwToken] = useState('');
    const [pwToken, setPwToken] = useState(null);
    const findPwClick = () => {
        let isAddOK = validation();
        if (isAddOK) {
            axios({
                method: 'post',
                url: `/api/account/find/pw`,
                data: {
                    userId: userId,
                    hincCd: pwHintList,
                    hintAns: pwHint,
                },
            })
            .then((result) => {

                if (result.data.success) {
                    setPopupMsg('비밀번호를 재설정하세요.');
                    setPwToken(result.data.data);
                    setShowAddPopup(true);
                } else {
                    setPopupMsg('유효하지 않은 답변입니다.<br>확인 후 다시 시도해주세요.');
                    setShowAddPopup(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    };

    function validation () {
        if (userId === '') {
            setPopupMsg("아이디를 입력하세요.");
            setShowAddPopup(true);
            return false;
        }
        if (pwHintList === '') {
            setPopupMsg("비밀번호 힌트를 선택하세요.");
            setShowAddPopup(true);
            return false;
        }
        if (pwHint === '') {
            setPopupMsg("힌트답변을 입력하세요.");
            setShowAddPopup(true);
            return false;
        }
        return true;
    }

    const [userId, setUserId] = useState('');
    const [pwHintList, setPwHintList] = useState('');
    const [pwHint, setPwHint] = useState('');

    const handleUserId = (e) => {
        e.preventDefault();
        setUserId(e.target.value);
    }
    const handlePwHintList = (e, data) => {
        e.preventDefault();
        setPwHintList(data.value);
    }
    const handlePwHint = (e) => {
        e.preventDefault();
        setPwHint(e.target.value);
    }


    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            findPwClick()
        }
    };
    
    const brCount = (popupMsg.match(/<br>/g) || []).length;
    const paddingValue = brCount === 0 ? '35px 0' : brCount === 1 ? '25px 0' : '15px 0';
    const fontSizeValue = brCount === 0 ? '18px' : '16px';

    return (
        <div className="contents-col findid-container">
            <div className="signup-guide">
                <div className="title">비밀번호 찾기</div>
                <div className="content">비밀번호를 찾고자 하는 아이디를 입력해주세요</div>
            </div>

            <div className="division-A"></div>

            <div className="contents-row findId-inputuser">
                <div className="mainpart">
                
                    <div className="field group subpart">
                        <div className="label">아이디</div>
                        <input 
                        type="text"
                        className="input input-img" 
                        onChange={handleUserId}
                        onKeyDown={handleKeyPress}
                        />
                    </div>
                    <div className="field group subpart">
                        <div className="label">비밀번호 힌트</div>
                        <Select                               
                            className="input input-img hint"
                            selection
                            options={options}
                            onChange={handlePwHintList}
                            onKeyDown={handleKeyPress}
                        >
                        </Select> 
                        </div>
                    <div className="field group subpart">
                        <div className="label">힌트답변</div>
                        <input 
                        type="text"
                        className="input input-img" 
                        onChange={handlePwHint}
                        onKeyDown={handleKeyPress}
                        />
                    </div>
                
                </div>
            </div>

            <button className="gc-btn findId-btn" onClick={findPwClick}>검색</button>
            
            {/* {pwToken && <ChangePw pwToken = {pwToken} style={{ display: 'none' }}/>} */}
            {/* {
                pwToken && Object.keys(pwToken).length > 0 && <ChangePw pwToken = {pwToken}/>
            } */}

            <div className="findId-back"><Link to="/home"> 이전으로</Link></div>
            <Popup
                show={showAddPopup}
                onCancel={() => setShowAddPopup(false)}
                header="알림"
                icon={<i className="code x icon"  
                        onClick={() => {
                            setShowAddPopup(false);
                        }
                    } 
                /> }
            >
                <div className='modal-info'>
                    <p
                        style={{
                            fontSize: fontSizeValue,
                            padding: paddingValue,
                        }}
                        dangerouslySetInnerHTML={{ __html: popupMsg }}
                    ></p>
                    {/* <p>{popupMsg}</p> */}
                    <button 
                        className="close_btn" 
                        onClick={() => {
                            setShowAddPopup(false);
                            if (popupMsg.includes('재설정')) {
                              navigate("/changepw", { state: { pwToken } });
                            }
                          }} 
                    >{typeof popupMsg === 'string' && popupMsg.includes('재설정') ? '확인' : '닫기'}</button>

                    
                </div>
            </Popup>
        </div>
    );
}

export default FindPw;
import './farmworkRequest.css';

import React, { useEffect, useState } from 'react';

import Modal from '../../../shared/components/uiElements/Modal';
import Popup from '../../../shared/components/uiElements/Popup';

import { Select } from 'semantic-ui-react';

import moment from 'moment';

// DatePicker
import DatePicker from 'react-datepicker';

// AXIOS
import axios from 'axios';

// TURF
import turf from 'turf';

// Proj4
import proj4 from 'proj4';

import config from '../../../config/config';

proj4.defs(
  'EPSG:5179',
  '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);
proj4.defs(
  'EPSG:5181',
  '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'
);
proj4.defs(
  'EPSG:5186',
  '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs'
);
proj4.defs('EPSG:4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs');

function FarmworkReqeust(props) {
  const APIToken = localStorage.getItem('login-token');
  const userId = localStorage.getItem('user-id');

  // 일자
  const [startDate, setStartDate] = useState(null);
  // 재배관리맵
  const [farmmngMaplist, setFarmmngMaplist] = useState([]);
  const [themeMapIndex, setThemeMapIndex] = useState();

  // 드론 기제선택
  const [dronelist, setDronelist] = useState([]);
  const [droneIndex, setDroneIndex] = useState();

  // 필지정보
  const [farmReqID, setFarmReqID] = useState();
  // 농작업 선택
  const [farmReqType, setFarmReqType] = useState();
  // 대상 작물
  const [farmReqCrop, setFarmReqCrop] = useState('');
  // 측간거리
  const [interDst, setInterDst] = useState('');
  // 드론 프레임타입
  // const [drnFrameType, setDrnFrameType] = useState("");
  // 약제살포단위
  const [chemApplication, setChemApplication] = useState('');
  // 드론회전거리
  const [drnrtDstnc, setDrnrtDstnc] = useState('');
  // 비행고도
  const [drnFlgAlt, setDrnFlgAlt] = useState('');
  // PWM 상
  const [drnPwmH, setDrnPwmH] = useState('');
  // PWM 중
  const [drnPwmM, setDrnPwmM] = useState('');
  // PWM 하
  const [drnPwmL, setDrnPwmL] = useState('');

  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState();

  // Popup - validation
  const [validationPopup, setValidationPopup] = useState(false);
  const [validationMsg, setValidationMsg] = useState();

  const handleDroneIndex = (e, data) => {
    setDroneIndex(data.value);
  };

  const handleThemeMapIndex = (e, data) => {
    setThemeMapIndex(data.value);
  };

  const handleFarmReqType = (e, data) => {
    if (typeof data === 'object') {
      setFarmReqType(data.value);
    } else {
      setFarmReqType(data);
    }
  };

  const handleFarmReqCrop = (e) => {
    setFarmReqCrop(e.target.value);
  };

  const handleInterDst = (e) => {
    setInterDst(e.target.value);
  };

  // const handleDrnFrameType = (e, data) => {
  //     setDrnFrameType(data.value);
  // }

  const handleChemApplication = (e) => {
    setChemApplication(e.target.value);
  };

  const handleDrnrtDstnc = (e) => {
    setDrnrtDstnc(e.target.value);
  };

  const handleDrnFlgAl = (e) => {
    setDrnFlgAlt(e.target.value);
  };

  const handleDrnPwmH = (e) => {
    setDrnPwmH(e.target.value);
  };

  const handleDrnPwmM = (e) => {
    setDrnPwmM(e.target.value);
  };
  const handleDrnPwmL = (e) => {
    // setDrnPwmL(Number(e.target.value));
    setDrnPwmL(e.target.value);
  };

  useEffect(() => {
    if (props.selectedFarm) {
      setFarmReqID(props.selectedFarm.FMAP_INNB);
    }
  }, [props.selectedFarm]);

  // useEffect(() => { //선택된 option값 확인

  //         console.log(droneIndex);
  //         console.log(themeMapIndex);

  // }, [droneIndex,themeMapIndex])

  useEffect(() => {
    if (props.requestFarmwork) {
      axios({
        method: 'get',
        url:
          `/api/theme-map/list?` + `&zoneId=${props.selectItrst.itrst_zone_id}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.success) {
            const resultList = result.data.data;
            const dummyOption = [];
            resultList &&
              resultList.map((e, i) => {
                if (e.layer_nm) {
                  // 래스터 주제도만 해당
                  dummyOption.push({
                    key: i,
                    value: e.them_map_id,
                    text: e.theme_map_ttl,
                  });
                }
              });
            setFarmmngMaplist(dummyOption);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.requestFarmwork]);

  //드론 기제선택
  useEffect(() => {
    if (props.requestFarmwork) {
      axios({
        method: 'get',
        url: `/api/drone/type/list?` + `&userId=${userId}`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((result) => {
          if (result.data.success) {
            const resultList = result.data.data;

            const dummyOption2 = [];

            resultList &&
              resultList.map((item, index) => {
                dummyOption2.push({
                  key: index,
                  value: item.drone_id,
                  text: `${item.drone_model_nm ? item.drone_model_nm : ''} / ${
                    item.drone_mkr_nm ? item.drone_mkr_nm : ''
                  } / ${item.drone_type ? item.drone_type : ''}`, //${item.drone_model_nm} ${item.drone_mkr_nm} ${item.drone_type}
                });
              });
            setDronelist(dummyOption2);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.requestFarmwork]);

  const farmwork_option = [
    { key: 'lp', value: 'lp', text: '액체 방제' },
    { key: 'sp', value: 'sp', text: '고체 방제' },
    { key: 'lf', value: 'lf', text: '액체 비료' },
    { key: 'sf', value: 'sf', text: '고체 비료' },
    { key: 'so', value: 'so', text: '파종' },
  ];

  // const drnFrame_option = [
  //     { key: 4, value: 4, text: '쿼드콥터' },
  //     { key: 6, value: 6, text: '헥사콥터' },
  //     { key: 8, value: 8, text: '옥토콥터' },
  // ]

  function validation() {
    if (
      themeMapIndex == 0 ||
      farmReqID == 0 ||
      farmReqCrop == 0 ||
      startDate == 0 ||
      farmReqType == 0 ||
      interDst == 0 ||
      chemApplication == 0 ||
      droneIndex == 0 ||
      // drnrtDstnc == 0 ||
      drnFlgAlt == 0 ||
      drnPwmH == 0 ||
      drnPwmM == 0 ||
      drnPwmL == 0
    ) {
      setValidationMsg('모든 정보를 입력하세요.');
      setValidationPopup(true);
      return false;
    }

    const interDstValue = parseFloat(interDst);
    if (!isNaN(interDstValue)) {
      if (!Number.isInteger(interDstValue)) {
        setValidationMsg(
          '측간거리 값이 소수점 숫자입니다. \n다시 입력해주세요.'
        );
        setValidationPopup(true);
        return false;
      }
    } else {
      setValidationMsg('측간거리의 값으로 문자가 아닌 숫자를 입력해주세요.');
      setValidationPopup(true);
      return false;
    }

    const drnrtDstncValue = parseFloat(drnrtDstnc);
    if (!isNaN(drnrtDstncValue)) {
      if (!Number.isInteger(drnrtDstncValue)) {
        setValidationMsg(
          '드론 회전 거리 값이 소수점 숫자입니다. \n다시 입력해주세요.'
        );
        setValidationPopup(true);
        return false;
      }
    } else {
      setValidationMsg(
        '드론 회전 거리의 값으로 문자가 아닌 숫자를 입력해주세요.'
      );
      setValidationPopup(true);
      return false;
    }

    if (props.selectedFarm === undefined || props.selectedFarm === null) {
      setValidationMsg('필지를 선택해주세요.');
      setValidationPopup(true);
      return false;
    }

    return true;
  }

  function regFarmWork() {
    let isOK = validation();
    if (isOK) {
      props.setLoading(true);
      // if (interDst.length == 0) {
      //     setPopupMsg("관측영상 제목을 입력하세요.");
      //     return false
      // }
      const Coord =
        `${props.farmworkCoord[0]}` + `,` + `${props.farmworkCoord[1]}`;

      axios({
        method: 'get',
        // url:`http://api.vworld.kr/req/address?service=address&request=getAddress`
        url:
          `ex-api/api.vworld.kr/req/address?service=address&request=getAddress` +
          `&key=${config.vworldKey}` +
          `&point=${Coord}&type=parcel`,
        headers: {
          Authorization: `Bearer {${APIToken}}`,
        },
      })
        .then((res) => {
          if (isOK) {
            axios({
              method: 'post',
              url: `/api/farmwork/req`,
              headers: {
                Authorization: `Bearer {${APIToken}}`,
              },
              data: {
                themeMapId: themeMapIndex, //재배관리맵
                fmapId: farmReqID, //필지정보
                trgtCrps: farmReqCrop, //대상작물
                fworkYmd: moment(startDate).format('YYYY-MM-DD'), // 날짜선택
                fworkTyCd: farmReqType, //농작업선택
                // interDst: interDst,
                size: interDst, //측간거리
                // drnFrameType: drnFrameType,
                //frameTy: drnFrameType,
                // chemApplication: ('kg/' + chemApplication),
                unit: 'kg/' + chemApplication, //약제살포단위
                addr: res.data.data.response.result[0].text,
                bjdCd: res.data.data.response.result[0].structure.level4LC,
                droneId: droneIndex, //드론기제선택
                rotDist: drnrtDstnc, //드론회전거리
                flgtAlt: drnFlgAlt, //비행 고도
                pwmHigh: drnPwmH,
                pwmMid: drnPwmM,
                pwmLow: drnPwmL,
              },
            }) //
              .then((result) => {
                if (result.data.success) {
                  props.setLoading(false);
                  setPopupMsg('농작업 요청이 완료되었습니다.');
                  setShowPopup(true);
                  props.setSelectedFarm(null);
                  props.setFarmworkDummyArr([]);
                } else {
                  props.setLoading(false);
                  setPopupMsg('농작업 요청 실패');
                  setShowPopup(true);
                  props.setSelectedFarm(null);
                  props.setFarmworkDummyArr([]);
                }
              })
              .catch((error) => {
                props.setLoading(false);
                console.log(error);
                props.setFarmworkDummyArr([]);
              });
          }
        }) //
        .catch((error) => {
          props.setLoading(false);
          console.log(error);
          props.setFarmworkDummyArr([]);
        });
    }
  }

  useEffect(() => {
    if (props.farmworkDummyArr.length !== 0) {
      setThemeMapIndex(props.farmworkDummyArr[0]);
      setStartDate(props.farmworkDummyArr[1]);
      setInterDst(props.farmworkDummyArr[2]);
      setFarmReqType(props.farmworkDummyArr[3]);
      setFarmReqCrop(props.farmworkDummyArr[4]);
      setChemApplication(props.farmworkDummyArr[5]);
      setDroneIndex(props.farmworkDummyArr[6]);
      setDrnrtDstnc(props.farmworkDummyArr[7]);
      setDrnFlgAlt(props.farmworkDummyArr[8]);
      setDrnPwmL(props.farmworkDummyArr[9]);
      setDrnPwmM(props.farmworkDummyArr[10]);
      setDrnPwmH(props.farmworkDummyArr[11]);
    }
  }, [props.requestFarmwork]);

  return (
    <React.Fragment>
      <Modal
        show={props.requestFarmwork}
        // onCancel={() => {
        //   props.setRequestFarmwork(false);
        // }}
        header="농작업 요청"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              props.setRequestFarmwork(false);
              props.setSelectedFarm();
              props.onFarmmapHandler(false);
              props.farmworkSelectHandler(false);
              props.setShowTheme(true);
              props.searchFarmworkList();
              if (props.mapRef.current) {
                const map = props.mapRef.current.getMap();
                const mapLayers = map.getStyle().layers;
                mapLayers.forEach((layer) => {
                  if (layer.id.startsWith('tn_fmap_info')) {
                    map.setPaintProperty(
                      'tn_fmap_info',
                      'fill-color',
                      'rgba(255, 255, 255, 0.0)'
                    );
                  }
                  if (layer.id.startsWith(props.selectItrst.itrst_zone_nm)) {
                    map.setPaintProperty(
                      props.selectItrst.itrst_zone_nm,
                      'fill-color',
                      'rgba(255, 255, 255, 0.0)'
                    );
                  }
                });
              }
              props.setSelectedFarm(null);
              props.setFarmworkDummyArr([]);
            }}
          />
        }
        className={'farmwork'}
        isMobile={props.isMobile}
      >
        <div className="farmwork-modal-container">
          <div className="input-group contents-col full-width">
            <div className="title-text">
              <span className="required-marker">*</span>필지 선택
            </div>
            <div
              className="select-zone farmwork flex-center"
              onClick={() => {
                let arr = [
                  themeMapIndex,
                  startDate,
                  interDst,
                  farmReqType,
                  farmReqCrop,
                  chemApplication,
                  droneIndex,
                  drnrtDstnc,
                  drnFlgAlt,
                  drnPwmL,
                  drnPwmM,
                  drnPwmH,
                ];
                props.setFarmworkDummyArr(arr);
                const geojsondata = JSON.parse(props.selectItrst.geojson);
                const center = turf.center(geojsondata);
                const bbox = turf.bbox(geojsondata);
                const centerCoord5179 = center.geometry.coordinates;
                const centerCoord4326 = proj4(
                  'EPSG:5179',
                  'EPSG:4326',
                  centerCoord5179
                );
                // if (
                //   centerCoord5179[0] > 124.0 &&
                //   centerCoord5179[0] < 132.0 &&
                //   centerCoord5179[1] > 32.0 &&
                //   centerCoord5179[1] < 39.0
                // ) {
                //   props.handleCoord({
                //     centerX: centerCoord5179[0],
                //     centerY: centerCoord5179[1],
                //     bbox: bbox,
                //   });
                // }

                // if (
                //   centerCoord4326[0] > 124.0 &&
                //   centerCoord4326[0] < 132.0 &&
                //   centerCoord4326[1] > 32.0 &&
                //   centerCoord4326[1] < 39.0
                // ) {
                //   props.handleCoord({
                //     centerX: centerCoord4326[0],
                //     centerY: centerCoord4326[1],
                //     bbox: bbox,
                //   });
                // }
                const map = props.mapRef.current.getMap();
                // map.setLayoutProperty("tn_fmap_info", "visibility", "none");

                props.setRequestFarmwork(false);
                props.setShowTheme(false);
                props.onFarmmapHandler(true);
                props.farmworkSelectHandler(true);
                props.setClickFarmRequst(true);
              }}
            >
              {props.selectedFarm ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                  }}
                >
                  {props.selectedFarm.address ? (
                    <>
                      <div>{props.selectedFarm.address}</div>
                      <div>({props.selectedFarm.FMAP_INNB})</div>
                    </>
                  ) : (
                    <>
                      {props.selectedFarm.FMAP_INNB ? (
                        <>
                          <div>
                            {props.selectedFarm.LGL_EMD_NM}{' '}
                            {props.selectedFarm.LNM}
                          </div>
                          <div>({props.selectedFarm.FMAP_INNB})</div>
                        </>
                      ) : (
                        <div>선택됨</div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                // '익산시 황등면 황등리 1528'
                '※ 클릭시 필지 선택 화면으로 이동합니다.'
              )}
            </div>
          </div>

          <div className="input-group contents-col">
            <div className="title-text">
              <span className="required-marker">*</span>재배관리맵 선택
            </div>
            <Select
              className="select-map farmwork"
              options={farmmngMaplist}
              placeholder="재배관리맵을 선택하세요."
              onChange={handleThemeMapIndex}
              value={themeMapIndex}
            ></Select>
          </div>

          <div className="contents-row">
            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>날짜 선택
              </div>
              <div className="select-date farmwork contents-row">
                <DatePicker
                  className="datepicker"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy-MM-dd"
                  minDate={new Date()}
                ></DatePicker>
                <i className="calendar alternate outline icon" />
              </div>
            </div>
            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>측간거리(m)
              </div>
              <input
                className="crop farmwork"
                value={interDst}
                onChange={handleInterDst}
              ></input>
            </div>
          </div>

          <div className="contents-row">
            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>농작업 선택
              </div>
              <Select
                className="select-farmwork farmwork"
                options={farmwork_option}
                onChange={handleFarmReqType}
                value={farmReqType}
              ></Select>
            </div>
            <div className="input-group contents-col half-width">
              {/* <div className="title-text">드론 프레임타입</div>
                        <Select className="select-farmwork farmwork"
                            options={drnFrame_option}
                            onChange={handleDrnFrameType}
                        ></Select> */}
              <div className="title-text">
                <span className="required-marker">*</span>대상 작물
              </div>
              <input
                className="crop farmwork"
                value={farmReqCrop}
                onChange={handleFarmReqCrop}
              ></input>
            </div>
          </div>

          <div className="contents-row">
            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>약제살포단위
              </div>
              <div className="contents-row">
                {/* <input 
                                        className="crop farmwork half-width" 
                                        value='kg'
                                        disabled
                                    ></input> */}
                {/* <div className="flex-center">/</div> */}
                <input
                  className="crop farmwork full-width"
                  value={chemApplication}
                  onChange={handleChemApplication}
                ></input>
              </div>
            </div>
          </div>

          <div className="input-group contents-col">
            <div className="title-text">
              <span className="required-marker">*</span>드론 기제선택
            </div>
            <Select
              className="select-map farmwork"
              options={dronelist}
              placeholder="드론을 선택하세요."
              onChange={handleDroneIndex}
              value={droneIndex}
            ></Select>
          </div>

          <div className="contents-row">
            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>드론 회전 거리(m)
              </div>
              <div className="contents-row">
                <input //input창 다 수정
                  className="crop farmwork full-width"
                  value={drnrtDstnc} //수정
                  onChange={handleDrnrtDstnc}
                ></input>
              </div>
            </div>
            <div className="input-group contents-col half-width">
              <div className="title-text">
                <span className="required-marker">*</span>비행 고도(m)
              </div>
              <div className="contents-row">
                <input
                  className="crop farmwork full-width"
                  value={drnFlgAlt}
                  onChange={handleDrnFlgAl}
                  placeholder="소수점 한 자리까지 입력 가능합니다."
                ></input>
              </div>
            </div>
          </div>

          <div className="contents-row">
            <div className="input-group contents-col th-width">
              <div className="title-text">
                <span className="required-marker">*</span>PWM (하)
              </div>
              <div className="contents-row">
                <input //input창 다 수정
                  className="crop farmwork full-width"
                  value={drnPwmL}
                  onChange={handleDrnPwmL}
                ></input>
              </div>
            </div>
            <div className="input-group contents-col th-width">
              <div className="title-text">
                <span className="required-marker">*</span>PWM (중)
              </div>
              <div className="contents-row">
                <input
                  className="crop farmwork full-width"
                  value={drnPwmM}
                  onChange={handleDrnPwmM}
                ></input>
              </div>
            </div>
            <div className="input-group contents-col th-width">
              <div className="title-text">
                <span className="required-marker">*</span>PWM (상)
              </div>
              <div className="contents-row">
                <input
                  className="crop farmwork full-width"
                  value={drnPwmH}
                  onChange={handleDrnPwmH}
                ></input>
              </div>
            </div>
          </div>

          <div className="button-group flex-center">
            <button
              className="gc-btn cancel"
              onClick={() => {
                props.setRequestFarmwork(false);
                props.setSelectedFarm();
                props.onFarmmapHandler(false);
                props.farmworkSelectHandler(false);
                props.setShowTheme(true);
                props.searchFarmworkList();
                if (props.mapRef.current) {
                  const map = props.mapRef.current.getMap();
                  const mapLayers = map.getStyle().layers;
                  mapLayers.forEach((layer) => {
                    if (layer.id.startsWith('tn_fmap_info')) {
                      map.setPaintProperty(
                        'tn_fmap_info',
                        'fill-color',
                        'rgba(255, 255, 255, 0.0)'
                      );
                    }
                    if (layer.id.startsWith(props.selectItrst.itrst_zone_nm)) {
                      map.setPaintProperty(
                        props.selectItrst.itrst_zone_nm,
                        'fill-color',
                        'rgba(255, 255, 255, 0.0)'
                      );
                    }
                  });
                }
                props.setSelectedFarm(null);
                props.setFarmworkDummyArr([]);
              }}
            >
              취소
            </button>
            <button
              className="gc-btn register"
              onClick={() => {
                regFarmWork();
                props.farmworkSelectHandler(false);
              }}
            >
              등록
            </button>
          </div>
        </div>
      </Modal>

      <Popup
        show={showPopup}
        // onCancel={() => {
        //     setShowPopup(false);
        //     props.setSelectedFarm();
        //     props.setRequestFarmwork(false);
        //     props.farmworkSelectHandler(false);
        //     props.setShowTheme(true);
        // }}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowPopup(false);
              props.setSelectedFarm();
              props.onFarmmapHandler(false);
              props.setRequestFarmwork(false);
              props.farmworkSelectHandler(false);
              props.setShowTheme(true);
              props.searchFarmworkList();
            }}
          />
        }
      >
        <div className="modal-info">
          <p>{popupMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setShowPopup(false);
              props.setSelectedFarm();
              props.onFarmmapHandler(false);
              // props.setRequestFarmwork(true);
              props.farmworkSelectHandler(false);
              props.setShowTheme(true);
              props.searchFarmworkList();
              props.setRequestFarmwork(false);

              if (props.mapRef.current) {
                const map = props.mapRef.current.getMap();
                const mapLayers = map.getStyle().layers;
                mapLayers.forEach((layer) => {
                  if (layer.id.startsWith('tn_fmap_info')) {
                    map.setPaintProperty(
                      'tn_fmap_info',
                      'fill-color',
                      'rgba(255, 255, 255, 0.0)'
                    );
                  }
                  if (layer.id.startsWith(props.selectItrst.itrst_zone_nm)) {
                    map.setPaintProperty(
                      props.selectItrst.itrst_zone_nm,
                      'fill-color',
                      'rgba(255, 255, 255, 0.0)'
                    );
                  }
                });
              }
              props.setFarmworkDummyArr([]);
            }}
          >
            닫기
          </button>
        </div>
      </Popup>

      <Popup
        show={validationPopup}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setValidationPopup(false);
            }}
          />
        }
      >
        <div className="modal-info">
          <p>{validationMsg}</p>
          <button
            className="close_btn"
            onClick={() => {
              setValidationPopup(false);
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </React.Fragment>
  );
}

export default FarmworkReqeust;

import './themeMap.css';

import Modal from '../../shared/components/uiElements/Modal'
import Popup from '../../shared/components/uiElements/Popup'
import questionMarkGuide from '../../config/questionMarkGuide'

import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, Select } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

// AXIOS
import axios from "axios";

function ThemeMap(props) {

    const APIToken = localStorage.getItem('login-token');
    const userId = localStorage.getItem('user-id');
    
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');
    const [showThemeCanPopup, setShowThemeCanPopup] = useState(false);
    const [showThemeDelPopup, setShowThemeDelPopup] = useState(false);
    const [showThemeModPopup, setShowThemeModPopup] = useState(false);

    // 관심구역 id
    const [zoneId_state, setZoneId] = useState(props.selectItrst.itrst_zone_id);
    // 관심구역 nm
    const [zoneNm_state, setZoneNm] = useState(props.selectItrst.itrst_zone_nm);
    // 주제도 제목
    const [themeNm_state, setThemeNm] = useState('');
    // 기준일자
    const today = new Date();
    const [startDate, setStartDate] = useState(today);
    const [startDateTwo, setStartDateTwo] = useState(moment().format('YYYY-MM-DD'));
    const handleDateChangeTwo = (e, date) => {
        e.preventDefault();
        setStartDateTwo(e.target.value);
    };
    // 생성방식
    const [selectedButton, setSelectedButton] = useState('');
    // const [selectedButton, setSelectedButton] = useState(1);
    
    // 회귀식 - 선형회귀식(체크박스)
    const [exprsTy_state, setExprsTy] = useState(true);
    const [linearTy_state, setLinearTy] = useState(1);
    
    // 출력타입
    const [exptTy_state, setExptTy] = useState('');
    // 파일 정보
    const [filePath_state, setFilePath] = useState('');
    // 파일 이름
    const [orgFileNm_state, setOrgFileNm] = useState('');
    
    const [delFlag, setDelFlag] = useState(false);

    const handleButtonClick = (buttonId) => {
        if (buttonId !== '') {
            setSelectedButton(buttonId);
            setOrgFileNm('');
            setfileCsvAttr('');
            setSelectDroneId('');
        }
        
    }
    
    // Handler
    const handleZoneId = (e, data) => {
        e.preventDefault();
        setZoneId(data.value);
    }
    const handleThemeNm = (e) => {
        e.preventDefault();
        setNameCheckTheme(false);
        setThemeNm(e.target.value);
        props.themeNameHandler(e.target.value);
    }
    // const handleExprsTy = (e, data) => {
    //     e.preventDefault();
    //     setExprsTy(exprsTy_state);
    // }
    const handleLinearTy = (e, data) => {
        e.preventDefault();
        setLinearTy(data.value);

        setValA([]);
        setValAOne();
        setValATwo();
        setValAThree();
        setValB('');
        setValResult('')

    }
    const handleExptTy = (e, {value}) => {
        e.preventDefault();
        if(value === 1)
            setExptTy('tif');
        else if (value === 2)
            setExptTy('shp');
    }

    // let [clickedBandInfo_state, setClickedBandInfo] = useState('');
    const [selectedBandInfo, setSelectedBandInfo] = useState(null);
    const handleBandInfo = (index) => {
        if (index !== '') {
            setSelectedBandInfo(index);
            // setClickedBandInfo(selDrnNdvi[index]);
        }
    }

    useEffect(() => {
        axios({
            method:'get',
            url:`/api/zone/list?perPage=0`,
            headers: {
              'Authorization': `Bearer {${APIToken}}`
            },
        })
        .then((result)=>{ 
            setTodoList(result.data.data);
        })
        .catch((error)=>{
            console.log(error);
        })
    }, []);

    const TemplateDownload = () => {
        if (selectedButton === 1 || selectedButton === 2) {
            axios({
                method:'get',
                url:`/api/theme-map/template/down?zoneId=${zoneId_state}`,
                headers: {
                'Authorization': `Bearer {${APIToken}}`
                },
            })
            .then((result)=>{
                if (result.data.success) {
                    const templateDL = result.data.data;
                    window.location.href = templateDL;
                } else {
                    setPopupMsg(result.data.msg);
                    setShowPopup(true);
                }
            })
            .catch((error)=>{
            })
        }
    }

    
    // 주제도(속성)파일 업로드
    // 기준속성명
    const [fileCsvAttr_state, setfileCsvAttr] = useState();
    const [attrFile_state, setAttrFile] = useState('');

    const onThemeFile = (e, buttonId) => {
        const formData = new FormData();
        formData.append("attr", e.target.files[0]);
        if (selectedButton === 5) {
            formData.append("fileTy", 'tif');
        } else {
            formData.append("fileTy", 'csv');
        }
        axios({
            method:'post',
            url:`/api/theme-map/attr/upload`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: formData,
        })
        .then((result)=>{
            // setAttrThemeChange(true);
            if (viewDetailThemeMap.attr_file_path !== result.data.data.filePath) {
                setClicked('');
                // setPreOut(false);
                if (selectedButton === 2) {
                    setValA('');
                    setValB('');
                    setValResult('');
                }
            }
            setfileCsvAttr(result.data.data.csvAttr);
            setFilePath(result.data.data.filePath);
            setOrgFileNm(result.data.data.orgFileNm);
        })
        .catch((error)=>{
        })
    }
    let [clicked_state, setClicked] = useState('');
    const handleProperpertyClick = (str) => {
        if (str !== '') {
            if (clicked_state == str) {
                setClicked('')
            } else {
                setClicked(str);
            }
        }
    }
    
    let crtrYmdValue; // 기준일자
    /// 주제도 제목 중복확인
    const [nameMatching, setNameMatching] = useState(false);
    const [nameCheckTheme, setNameCheckTheme] = useState(false);


    // 직접입력 파일 업로드
    const [tifMap, setTifMap] = useState('');
    const [tifNm, setTifNm] = useState('');
    let tifFiledataNm = '';
    const onSingleBandTifHandler = (e) => {
        tifFiledataNm = e.target.files[0].name;
        setTifNm(tifFiledataNm) 
        setTifMap(e.target.files[0]);
    }
    
    const [selectDroneId_state, setSelectDroneId] = useState('');
    const handleDrone = (e, {value}) => {
        e.preventDefault();
        setSelectDroneId(value);
    }
    
    // 관심구역 선택
    const [todoList, setTodoList] = useState(null);
    const [itrst_ArrList, setItrst_ArrList] = useState([])

    function getItrstList() {

        let itrst_Arr = [];
        let zoneId = null;

        if (todoList != [] && todoList) {
            for (let i=0; i<todoList.length; i++) {
                // zoneId
                if (todoList[i].itrst_zone_id === null) {
                    zoneId = '';
                } else if (todoList[i].itrst_zone_id.length !== 0) {
                    zoneId =  todoList[i].itrst_zone_id
                }
                itrst_Arr.push({key: i, text: todoList[i].itrst_zone_nm, value : zoneId})
            }
        }
        setItrst_ArrList(itrst_Arr);
    };
    
    // 드론 영상 선택
    const [droneList, setDroneList] = useState(null);
    let drone_Arr = [];
    const [drone_ArrList, setDrone_ArrList] = useState([]);
    let ndvi = null;
    
    useEffect(() => {
        if (droneList !== null && droneList) {
            for (let i=0; i<droneList.length; i++) {
                if (props.selectItrst.itrst_zone_id == droneList[i].itrst_zone_id)
                drone_Arr.push({key: i, text: droneList[i].drn_img_ttl, value: droneList[i].drn_img_id, ndvity: droneList[i].ndvi_gen, sensinfo: droneList[i].sens_band_info, metadatacn: JSON.parse(droneList[i].meta_data_cn), regymd: droneList[i].reg_ymd});
            }
        }
        setDrone_ArrList(drone_Arr)
    }, [droneList])

    // 래스터 계산기
    const [inputData, setInputData] = useState('');
    let [isValid, setIsValid] = useState(false);
    let newInputData = '';
    let updatedInputData = '';
    // Band
    function handleTypeButtonClick(event) {
        const buttonData = '"' + event.target.innerText + '"';
        updatedInputData = inputData + buttonData;
        setInputData(updatedInputData);
        setIsValid(false);
    }
    // 연산자
    function handleOpButtonClick (event) {
        let buttonData = event.target.innerText;
        if (buttonData === 'x') {
            buttonData = '*';
        }
        updatedInputData = inputData + buttonData;
        setInputData(updatedInputData);
        setIsValid(false);
    }
    // 표현식 유효성검사
    useEffect(() => {
        try {
            eval(inputData);
            if(inputData.includes('+-') || inputData.includes('-+')) throw('+-/-+ err');
            setIsValid(false);
        } catch(ex) {
            setIsValid(true);
        }
    }, [isValid, inputData]);

    function handleTypeChange(event) {
        newInputData = event.target.value;
        setInputData(newInputData);
    }

    const renderButtons = () => {
        const selectedDroneIndex = drone_ArrList.findIndex(drone => drone.value === selectDroneId_state);
    
        if (selectedDroneIndex !== -1) {
            const selDrnNdvi = drone_ArrList[selectedDroneIndex].ndvity;
            const selDrnSensInfo = drone_ArrList[selectedDroneIndex].sensinfo;
            const selDrnSensInfoLength = JSON.parse(selDrnSensInfo).length
            if (selDrnNdvi !== null) {
                return selDrnNdvi.map((data, index) => {
                    return (
                        !(selDrnSensInfoLength === 1 && selDrnNdvi.length === 1) &&
                        <div
                            onClick={props.activeTheme !== 2 ? () => handleDroneNdvi(data) : handleDroneNdvi('')}
                            className={`gc-btn btn-theme drone ${(clickedDroneNdvi_state === data) ? 'active' : props.isMobile ? 'mobile-style' : ''} ${props.activeTheme === 2 && (clickedDroneNdvi_state !== data) ? 'no-hover-style' : ''}`}
                            key={index}
                        >
                            {data}
                        </div>
                    );
                });
            }
        }
    
        return null; 
    } 

    let referDate='';
    // 기준일자
    const renderDate = () => {
            let selectedDrone = drone_ArrList.find(drone => drone.value === selectDroneId_state);
            if (selectedDrone) {
                const metaDataCn = selectedDrone.metadatacn;
                if (metaDataCn.shoot_start_dt) {
                    referDate = metaDataCn.shoot_start_dt && metaDataCn.shoot_start_dt.slice(0, 10);
                    // setStartDateTwo(metaDataCn.shoot_start_dt && metaDataCn.shoot_start_dt.slice(0, 10));
                    return <React.Fragment><label className="label">기준일자</label> <input className='input-theme' type="text" readOnly="disabled" onChange={handleDateChangeTwo} value={referDate}/></React.Fragment>;
                    // return <React.Fragment><label className="label">기준일자</label> <input className='input-theme' type="text" disabled="disabled" onChange={handleDateChangeTwo} value={startDateTwo}/></React.Fragment>;
                } else {
                    referDate = selectedDrone.regymd;
                    // referDate = '';
                    return <React.Fragment><label className="label">기준일자</label> <input className='input-theme' type="text" readOnly="disabled" onChange={handleDateChangeTwo} value={referDate}/></React.Fragment>;
                }
            }
        return null;
    } 
    

    const renderBands = () => {

        let sensBandInfo;
        let sensBandInfoArr; 

        if(clickedDroneNdvi_state) {
            return <div onClick={handleTypeButtonClick} className='gc-btn band' >Band{0 + 1}</div>;
            // return <div onClick={handleTypeButtonClick} className={selectedBandInfo === 0 ? 'gc-btn band active' : 'gc-btn band'} >Band{0 + 1}</div>;
        }
        else {
            for (let i=0; i<drone_ArrList.length; i++) {
                if (drone_ArrList[i].value === selectDroneId_state) {
                    sensBandInfo = drone_ArrList[i].sensinfo;
                    break;
                }
            }
            if(sensBandInfo && sensBandInfo.length>0) {
                sensBandInfoArr = sensBandInfo.split(",")
                return sensBandInfoArr && sensBandInfoArr.map((data, index)=>{
                    return <div onClick={handleTypeButtonClick} className={selectedBandInfo === index ? 'gc-btn band active' : 'gc-btn band'} key={index}>Band{index + 1}</div>;
                    // return <div onClick={handleTypeButtonClick} className={selectedBandInfo === index ? 'gc-btn band active' : 'gc-btn band'} key={index}>Band{index + 1}</div>;
                });
            }
        }
    }
    
    let [clickedDroneNdvi_state, setClickedDroneNdvi] = useState('');
    const handleDroneNdvi = (data) => {
        if (data !== '') {
            setInputData('');
            if (clickedDroneNdvi_state === data) {
                setClickedDroneNdvi('');
            } else {
                setClickedDroneNdvi(data);
            }
        }
    }

    // 회귀식
    const [preOut_state, setPreOut] = useState(false);
    // 2번 회귀식 - 생성방식 
    const [selectedOption, setSelectedOption] = useState(1);

    const handleOptionChange = (event, data) => {
        event.preventDefault();
        setSelectedOption(data.value);
        setValA('');
        setValB('');
        setValResult('');
    };

    useEffect(() => {
        if (selectedOption === 2) {
            setPreOut(false);
        }
    }, [selectedOption])

    

    // const [valA_state, setValA] = useState('');
    const [valA_state, setValA] = useState([]);
    const [valAOne_state, setValAOne] = useState();
    const [valATwo_state, setValATwo] = useState();
    const [valAThree_state, setValAThree] = useState();
    const [valB_state, setValB] = useState('');
    const [valResult_state, setValResult] = useState('')
    
    // const [valAArray, setValAArray] = useState([]);

    useEffect(() => {
        // setValA([valAOne_state, valATwo_state, valAThree_state]);
        const valA = [];

        if (valAOne_state) {
            valA.push(valAOne_state);
        }
    
        if (valATwo_state) {
            valA.push(valATwo_state);
        }
    
        if (valAThree_state) {
            valA.push(valAThree_state);
        }
        setValA(valA);
    }, [valAOne_state, valATwo_state, valAThree_state]);
    
    // 2번 직접
    const handleValA = (e) => {
        e.preventDefault();
        setValA(e.target.value)
    }
    const handleValAOne = (e) => {
        e.preventDefault();
        setValAOne(e.target.value);
        // const value = parseFloat(e.target.value);
        // setValAOne(value);
    }
    const handleValATwo = (e) => {
        e.preventDefault();
        setValATwo(e.target.value);
        // const value = parseFloat(e.target.value);
        // setValATwo(value);
    }
    const handleValAThree = (e) => {
        e.preventDefault();
        setValAThree(e.target.value);
        // const value = parseFloat(e.target.value);
        // setValAThree(value);
    }
    const handleValB = (e) => {
        e.preventDefault();
        setValB(e.target.value);
        // const value = parseFloat(e.target.value);
        // setValB(value);
    }
    const handleResult = (e) => {
        e.preventDefault();
        setValResult(e.target.value);
    }

    const fileInputRef = useRef(null);
    const handleFileClick = () => {
        fileInputRef.current.click();
    };

    // 계산 & 등록 Handle
    const handleCalculation = () => {
        setPreOut(true);
        // if (preOut_state) { // 없으면 수정이안되고 add
        //     onThemeAdd();
        // }
        
    }

    const [endCalCul, setEndCalCul] = useState(false);
    useEffect(() => {
        if (preOut_state) {
            onThemeAdd();
        }
        if (nameMatching) {
            // onThemeAdd();
            if (props.activeTheme === 3 && !preOut_state) {
                themeModYes();
            } 
            // else {
            //     onThemeAdd();
            // }
        }
    }, [preOut_state, props.activeTheme, nameMatching])
    
    // 유효성검사
    let calCul = false;
    async function validationTheme () {
        if (themeNm_state === '') {
            setPopupMsg('주제도 제목을 입력하세요.');
            return false;
        } 
        crtrYmdValue = selectedButton === 2 || selectedButton === 3 ? referDate : moment(startDate).format('YYYY-MM-DD');
        if (props.activeTheme === 1 && !preOut_state) {
            try {
                const response = await axios({
                    method: 'get',
                    url: `/api/theme-map/check/name?value=${themeNm_state}&date=${crtrYmdValue}`,
                    headers: {
                        'Authorization': `Bearer ${APIToken}`
                    },
                });
                if (response.data.success) {
                    setShowThemeModPopup(false);
                    setNameMatching(true);
                } else {
                    setShowThemeModPopup(false);
                    setPopupMsg('이미 존재하는 제목입니다');
                    setShowPopup(true);
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        } else if (props.activeTheme === 3 && !preOut_state) {
            if(props.curActiveLayer.theme_map_ttl !== themeNm_state){
                try {
                    const response = await axios({
                        method: 'get',
                        url: `/api/theme-map/check/name?value=${themeNm_state}&date=${crtrYmdValue}`,
                        headers: {
                            'Authorization': `Bearer ${APIToken}` // Remove the extra curly braces
                        },
                    });
                    if (response.data.success) {
                        setShowThemeModPopup(false);
                        setNameMatching(true);
                    } else {
                        setShowThemeModPopup(false);
                        setPopupMsg('이미 존재하는 제목입니다');
                        setShowPopup(true);
                        return false;
                    }
                } catch (error) {
                    console.log(error);
                    return false;
                }
            }
        }
        
        if (zoneId_state === '') {
            setPopupMsg('관심구역을 선택하세요.');
            return false;
        }

        if (selectedButton === 2 || selectedButton === 3) {
            if (referDate === '') {
                setPopupMsg('기준일자를 확인하세요.');
                return false;
            }
        } else {
            if (startDate === null) {
                setPopupMsg('기준일자를 선택하세요.');
                return false;
            }
        }

        if (selectedButton ==='') {
            setPopupMsg('생성방식을 선택하세요.');
            return false;
        }

        switch(selectedButton) {
            case 1:
                if (clicked_state === '' || filePath_state === '') {
                    setPopupMsg('파일이나 속성정보를 선택하세요.');
                    return false;
                }
                return true;
            case 2:
                if (selectDroneId_state === '') {
                    setPopupMsg('드론영상을 선택하세요.');
                    return false;
                }
                if (clickedDroneNdvi_state === '') {
                    setPopupMsg('식생지수를 선택하세요.');
                    return false;
                }
                switch (selectedOption) {
                    case 1:
                        if (clicked_state === '' || filePath_state === '') {
                            setPopupMsg('파일이나 속성정보를 선택하세요.');
                            return false;
                        }
                        if (calCul === true && preOut_state === false && endCalCul === false) {
                            setPopupMsg('계산버튼 클릭하세요.');
                            return false;
                        }
                        return true;
                    case 2: 
                        if(valA_state.legnth < 1) {
                            setPopupMsg('기울기 값이 입력되지 않았습니다.');
                            return false;
                        }

                        for (let i=0; i<linearTy_state; i++) {
                            if (valA_state[i] === undefined || valA_state[i] == '') {
                                setPopupMsg('기울기 값이 입력되지 않았습니다.');
                                return false;
                            }

                            if (isNaN(parseFloat(valA_state[i]))) {
                                setPopupMsg('올바르지 않은 입력값 입니다.');
                                return false;
                            }
                        }

                        if (isNaN(parseFloat(valB_state))) {
                            setPopupMsg('올바르지 않은 입력값 입니다.');
                            return false;
                        }
                        return true;

                        // if (valA_state === '' || valB_state === '') {
                            
                        // } 
                        // return true;
                }

            case 3:
                if (selectDroneId_state === '') {
                    setPopupMsg('드론영상을 선택하세요.');
                    return false;
                }  
                if (exptTy_state === '') {
                    setPopupMsg('출력타입을 선택하세요.');
                    return false;
                }
                if (inputData === '') {
                    setPopupMsg('표현식을 입력하세요.');
                    return false;
                }
                if (isValid || inputData.length === 0) {
                    setPopupMsg('유효하지 않은 표현식입니다.');
                    return false;
                }
                return true;
            case 5:
                if (tifMap === '') {
                    setPopupMsg('파일을 선택하세요.');
                    return false;
                }
                return true;

        }
    }

    // 그래프
    const [grphUrl, setGrphUrl] = useState('');
    let preOutData;
    // 주제도 생성 (속성정보)
    const onThemeAdd = async () => {
        let isAddOK = await validationTheme();
        // let isAddOK = props.activeTheme === 3 ? validationThemeMod() : validationTheme();
        if (isAddOK) {
            props.setLoading(true);
            crtrYmdValue = selectedButton === 2 || selectedButton === 3 ? referDate : moment(startDate).format('YYYY-MM-DD');
            let themeData = {
                zoneId: zoneId_state,
                themeNm: themeNm_state,
                crtrYmd: crtrYmdValue,
                crtTy: selectedButton,
            }
            switch(selectedButton) {
                case 1:
                    themeData.attrCol = clicked_state
                    themeData.attrFilePath = filePath_state
                    themeData.orgFileNm = orgFileNm_state
                    break;
                case 2:
                    themeData.drnId = selectDroneId_state
                    themeData.ndviTy = clickedDroneNdvi_state
                    themeData.regTy = selectedOption
                    switch (selectedOption) {
                        case 1:
                            themeData.attrFilePath = filePath_state
                            themeData.orgFileNm = orgFileNm_state
                            themeData.attrCol = clicked_state
                            // themeData.linear = exprsTy_state.toString();
                            themeData.linearTy = linearTy_state;
                            themeData.preOut = preOut_state.toString();
                            if (!preOut_state) {
                                themeData.valA = valA_state
                                themeData.valB = valB_state
                                themeData.valR2 = valResult_state
                            }
                            break;
                        case 2:
                            themeData.linearTy = linearTy_state;
                            themeData.slope  = JSON.stringify(valA_state.map(Number).slice(0, linearTy_state));
                            // themeData.slope  = valA_state;
                            themeData.interY  = valB_state;
                            break;
                    }
                    break;
                case 3:
                    themeData.drnId = selectDroneId_state
                    themeData.exptTy = exptTy_state
                    themeData.formula = inputData
                    themeData.ndviTy = clickedDroneNdvi_state
                    break;
                case 4:
                    break;
                case 5: 
                    themeData = new FormData();
                    themeData.append('zoneId', zoneId_state);
                    themeData.append('themeNm', themeNm_state);
                    themeData.append('crtrYmd', moment(startDate).format('YYYY-MM-DD'));
                    themeData.append('crtTy', selectedButton);
                    themeData.append("map", tifMap);
                    break;
            }
            axios({
                method:'post',
                url:`/api/theme-map/add`,
                headers: {
                    'Authorization': `Bearer {${APIToken}}`
                },
                data: themeData, 
            })
            .then((result)=>{
                props.setLoading(false);

                if (result.data.success) {
                    if (preOut_state) {
                        setPopupMsg('계산되었습니다.');
                        preOutData = result.data.data.value;
                        setGrphUrl(result.data.data.grphUrl);
                        setValA(preOutData.slope);
                        setValB(preOutData.interY);
                        setValResult(preOutData.r2);
                        setPreOut(false);
                        setEndCalCul(true);
                    } else {
                        setPopupMsg('주제도 등록이 완료되었습니다.');
                    }
                    setShowPopup(true);
                } else {
                    if (preOut_state) {
                        setPopupMsg('계산에 실패하였습니다.');
                        setPreOut(false);
                    } else {
                        setPopupMsg(result.data.msg);
                        // setPopupMsg('주제도 등록이 실패하였습니다.');
                    }
                    setShowPopup(true);

                }
            })
            .catch((error)=>{
                props.setLoading(false);
                console.log(error);
            })
        } else {
            setShowPopup(true);
            setPreOut(false);
        }
    };
    
    useEffect((zoneId) => {
        axios({
            method:'get',
            url:`/api/drone/img/info?itrstZoneId=${zoneId}`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
        })
        .then((result)=>{ 
            setDroneList(result.data.data)
        })
        .catch((error)=>{
        })
    }, [zoneId_state]);

    // 수정
    const [themeModified, setThemeModified] = useState(false);

    function onModThemeHandler() {
        props.handleActiveTheme(3);
        if(themeModified){
            setShowThemeCanPopup(true);
        }
        else{
            setThemeModified(true);
        }
    }

    function onSaveThemeHandler() {
        if(themeModified){
            setShowThemeModPopup(true);
        }
        else{
            props.setShowThemeReg(false);
        }
    }
    
    // 주제도 상세보기
    const [viewDetailThemeMap, setViewDetailThemeMap] = useState({});
    useEffect(() => {
        if (props.activeTheme === 2 || props.activeTheme === 3) {
            axios({
                method:'get',
                url:`/api/theme-map/${props.curActiveLayer.them_map_id}`,
                headers: {
                    'Authorization': `Bearer {${APIToken}}`
                },
            })
            .then((result)=>{ 
                setViewDetailThemeMap(result.data.data);
            })
            .catch((error)=>{
            })
        }
    }, [props.activeTheme])
    
    // 기본 SET
    // old
    const [oldLinearTy_state, setOldLinearTy] = useState();

    useEffect (() => {
        if (props.activeTheme === 1) {
            setSelectedButton(1);
        }
        if (props.activeTheme === 2 || props.activeTheme === 3) {
            // 주제도 제목
            setThemeNm(viewDetailThemeMap && viewDetailThemeMap.theme_map_ttl);
            // 생성방식
            // setSelectedButton(viewDetailThemeMap.crt_ty_cd);
            if (viewDetailThemeMap && viewDetailThemeMap.crt_ty_cd === 1) {
                setSelectedButton(1);
                // 속성정보
                setOrgFileNm(viewDetailThemeMap && viewDetailThemeMap.orgnl_file_nm);
                // 기준 속성
                const uplAttrFile = viewDetailThemeMap && viewDetailThemeMap.attr_info
                setfileCsvAttr(uplAttrFile.split(',')); // 배열
                setClicked(uplAttrFile);
                // attr 파일경로
                setFilePath(viewDetailThemeMap.attr_file_path);
            } else if (viewDetailThemeMap && viewDetailThemeMap.crt_ty_cd === 2) {  // 회귀식
                setSelectedButton(2);
                // 드론영상
                setSelectDroneId(viewDetailThemeMap && viewDetailThemeMap.drn_id);
                // 생성방식
                setSelectedOption(viewDetailThemeMap.reg_ty);
                // 식생지수 선택
                setClickedDroneNdvi(viewDetailThemeMap && viewDetailThemeMap.ndvi_ty);
                // 기준일자
                // referDate = viewDetailThemeMap.crtr_ymd && viewDetailThemeMap.crtr_ymd.slice(0, 10);
                if (viewDetailThemeMap.reg_ty === 1) {
                    // 속성정보
                    setOrgFileNm(viewDetailThemeMap && viewDetailThemeMap.orgnl_file_nm);
                    // 기준 속성
                    const uplAttrFile = viewDetailThemeMap && viewDetailThemeMap.attr_info
                    setfileCsvAttr(uplAttrFile.split(',')); // 배열
                    setClicked(uplAttrFile);
                    // attr 파일경로
                    setFilePath(viewDetailThemeMap.attr_file_path);
                } else {
                    
                }
                // 회귀식 (선형회귀식 / 비선형회귀식(2차) / 비선형회귀식(3차)
                setLinearTy(viewDetailThemeMap && viewDetailThemeMap.reg_linear_ty)
                let regVal = viewDetailThemeMap && viewDetailThemeMap.reg_val;
                if (viewDetailThemeMap && viewDetailThemeMap.reg_linear_ty === null) {
                    // OLD
                    setOldLinearTy(viewDetailThemeMap && viewDetailThemeMap.reg_val_a);
                    // setValA(viewDetailThemeMap && viewDetailThemeMap.reg_val_a);
                    setValB(viewDetailThemeMap && viewDetailThemeMap.reg_val_b);
                    setValResult(viewDetailThemeMap && viewDetailThemeMap.reg_val_r2);
                } else {
                    // NEW
                    if (selectedOption === 1) {
                        setValA(regVal.slope);
                        setValB(regVal.interY);
                        setValResult(regVal.r2);
                        setGrphUrl(viewDetailThemeMap && viewDetailThemeMap.grphUrl);
                    } else if (selectedOption === 2) {
                        setValA(regVal.slope);
                        // setValA(JSON.stringify(regVal.slope));
                        setValB(regVal.interY);
                        setValResult(regVal.r2);
                        setGrphUrl(viewDetailThemeMap && viewDetailThemeMap.grphUrl);
                    }
                    
                }
            } else if (viewDetailThemeMap && viewDetailThemeMap.crt_ty_cd === 3) {
                setSelectedButton(3);
                // 드론영상
                setSelectDroneId(viewDetailThemeMap && viewDetailThemeMap.drn_id);
                // 출력타입
                setExptTy(viewDetailThemeMap && viewDetailThemeMap.expt_ty);
                // 밴드 (표현식)
                setInputData(viewDetailThemeMap && viewDetailThemeMap.calc_exprs);
                // 식생지수 선택
                setClickedDroneNdvi(viewDetailThemeMap && viewDetailThemeMap.ndvi_ty);
                // 기준일자
                // setStartDateTwo(viewDetailThemeMap && viewDetailThemeMap.crtr_ymd.slice(0, 10));

            } else if (viewDetailThemeMap && viewDetailThemeMap.crt_ty_cd === 4) {
                setSelectedButton(4);
            } else if (viewDetailThemeMap && viewDetailThemeMap.crt_ty_cd === 5) {
                setSelectedButton(5);
                setTifMap(viewDetailThemeMap && viewDetailThemeMap.file_path);
                setTifNm(viewDetailThemeMap.orgnl_file_nm);
            }
        }
        if (props.activeTheme === 3) {
            setStartDate(new Date(viewDetailThemeMap.crtr_ymd && viewDetailThemeMap.crtr_ymd.substr(0, 10)));
            // setPreOut(false);
            // setEndCalCul(false);
            // attrThemeChange
            if (viewDetailThemeMap && viewDetailThemeMap.reg_val) {
                setPreOut(false);
            }
        }
    }, [props.activeTheme, viewDetailThemeMap])
    

    // 수정을 취소하시겠습니까?
    function themeMapModNo() {
        setThemeModified(false);
        setShowThemeCanPopup(false);
        props.handleActiveTheme(2);
    }

    // 수정유효성검사
    // let calCul = false;
    function validationThemeMod () {
        if (themeNm_state === '') {
            setPopupMsg('주제도 제목을 입력하세요.');
            return false;
        }

        if (zoneId_state === '') {
            setShowPopup(true); setPopupMsg('관심구역을 선택하세요.');
            return false;
        }

        if (selectedButton ==='') {
            setShowPopup(true); setPopupMsg('생성방식을 선택하세요.');
            return false;
        }

        switch(selectedButton) {
            case 1:
                if (clicked_state === '' || filePath_state === '') {
                    setShowPopup(true); setPopupMsg('파일이나 속성정보를 선택하세요.');
                    return false;
                }
                return true;
            case 2:
                if (selectDroneId_state === '') {
                    setShowPopup(true); setPopupMsg('드론영상을 선택하세요.');
                    return false;
                }
                if (clickedDroneNdvi_state === '') {
                    setShowPopup(true); setPopupMsg('식생지수를 선택하세요.');
                    return false;
                }
                switch (selectedOption) {
                    case 1:
                        if (clicked_state === '' || filePath_state === '') {
                            setShowPopup(true); setPopupMsg('파일이나 속성정보를 선택하세요.');
                            return false;
                        }
                        if (valResult_state ==='' && calCul === true && preOut_state === false && endCalCul === false) {
                            setShowPopup(true); setPopupMsg('계산버튼 클릭하세요. !!');
                            return false;
                        }
                        return true;
                    case 2: 
                        if (valA_state === '' || valB_state === '') {
                            setShowPopup(true); setPopupMsg('a값 혹은 b값 넣으세요.');
                            return false;
                        } 
                        return true;
                }
            case 3:
                if (selectDroneId_state === '') {
                    setShowPopup(true); setPopupMsg('드론영상을 선택하세요.');
                    return false;
                }  
                if (exptTy_state === '') {
                    setShowPopup(true); setPopupMsg('출력타입을 선택하세요.');
                    return false;
                }
                if (inputData === '') {
                    setShowPopup(true); setPopupMsg('표현식을 입력하세요.');
                    return false;
                }
                return true;
            case 5:
                if (!tifMap) {
                    setShowPopup(true); setPopupMsg('파일을 선택하세요.');
                    return false;
                }
                return true;
        }
    }

    // 수정사항을 저장하시겠습니까?
    const themeModYes = async () => {
        let inModOK = await validationTheme();
        // let inModOK = validationThemeMod();
        if (inModOK) {
            props.setLoading(true);
            let crtrYmdValue = selectedButton === 2 || selectedButton === 3 ? referDate : moment(startDate).format('YYYY-MM-DD');
            let themeData = {
                themeId: viewDetailThemeMap.them_map_id,
                zoneId: zoneId_state,
                themeNm: themeNm_state,
                crtrYmd: crtrYmdValue,
                crtTy: selectedButton,
            }
            switch(selectedButton) {
                case 1:
                    themeData.attrCol = clicked_state
                    themeData.attrFilePath = filePath_state
                    themeData.orgFileNm = orgFileNm_state
                    break;
                case 2:
                    themeData.drnId = selectDroneId_state
                    themeData.ndviTy = clickedDroneNdvi_state
                    themeData.regTy = selectedOption
                    switch (selectedOption) {
                        case 1:
                            themeData.attrFilePath = filePath_state
                            themeData.orgFileNm = orgFileNm_state
                            themeData.attrCol = clicked_state
                            // themeData.linear = exprsTy_state.toString();
                            themeData.linearTy = linearTy_state;
                            themeData.preOut = preOut_state.toString();
                            if (!preOut_state) {
                                themeData.valA = valA_state
                                themeData.valB = valB_state
                                themeData.valR2 = valResult_state
                            }
                            break;
                        case 2:
                            themeData.linearTy = linearTy_state;
                            themeData.slope  = JSON.stringify(valA_state.map(Number).slice(0, linearTy_state));
                            // themeData.slope  = valA_state;
                            themeData.interY  = valB_state;
                            break;
                    }
                    break;
                case 3:
                    themeData.drnId = selectDroneId_state
                    themeData.exptTy = exptTy_state
                    themeData.formula = inputData
                    themeData.ndviTy = clickedDroneNdvi_state
                    break;
                case 4:
                    break;
                case 5: 
                    themeData = new FormData();
                    themeData.append('themeId', viewDetailThemeMap.them_map_id);
                    themeData.append('zoneId', zoneId_state);
                    themeData.append('themeNm', themeNm_state);
                    themeData.append('crtrYmd', moment(startDate).format('YYYY-MM-DD'));
                    themeData.append('crtTy', selectedButton);
                    themeData.append("map", tifMap);
                    break;
            }
            axios({
                method:'post',
                url:`/api/theme-map/update`,
                headers: {
                'Authorization': `Bearer {${APIToken}}`
                },
                data: themeData, 
            })
            .then((result)=>{
                props.setLoading(false);
    
                if (result.data.success) {
                    if (preOut_state) {
                        setPopupMsg('계산되었습니다.');
                        preOutData = result.data.data;
                        setValA(preOutData.valA);
                        setValB(preOutData.valB);
                        setValResult(preOutData.r2);
                        setPreOut(false);
                        setEndCalCul(true);
                    } else {
                        setPopupMsg('주제도 수정이 완료되었습니다.');
                        result.data.data.layerType = 2;
                        result.data.data.legend_info = JSON.parse(result.data.data.legend_info);
                        result.data.data.raster_stat = JSON.parse(result.data.data.raster_stat);
                        props.curActiveLayerHandler(result.data.data);
                    }
                    setShowPopup(true);
                }
                else {
                    if (preOut_state) {
                        setPopupMsg('계산 실패하였습니다.');
                        setShowPopup(true);
                    } else {
                        setPopupMsg('주제도 수정 실패하였습니다.');
                    }
                }
            })
            .catch((error)=>{
                props.setLoading(false);
                console.log(error);
            })
    
        }

    }

    // 주제도 삭제
    const onThemeDelete = () => {
        axios({
            method:'post',
            url:`/api/theme-map/delete`,
            headers: {
              'Authorization': `Bearer {${APIToken}}`
            },
            // props.curActiveLayer.them_map_id
            data: {themeId : viewDetailThemeMap.them_map_id}, 
        })
        .then((result)=>{ 
            if(result.data.success){
                if(props.activeLayerList.length != 0){
                    for (let i=0; i<props.activeLayerList.length; i++){
                        if(props.activeLayerList[i].layerType == 2){
                            if(props.activeLayerList[i].them_map_id == result.data.data.them_map_id){
                                props.layerDel(i);
                                props.delModeHandler(i);
                                setDelFlag(true);
                                props.setClickValue('');
                            }
                        }
                    }
                }

                setPopupMsg('주제도가 삭제되었습니다.');
                setShowPopup(true);
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    
    // 물음표 가이드
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
        <React.Fragment>
            <Modal
                show={props.showThemeReg}
                // onCancel={()=>{props.setShowThemeReg(false)}}
                header={props.activeTheme === 2 ? "주제도 조회" : props.activeTheme === 3 ? "주제도 수정" : "주제도 등록"}
                icon={<i className="code x icon"  onClick={()=>{props.setShowThemeReg(false)}}/> }
                className={`themeMap ${props.isMobile ? 'mobile' : ''}`}
                // className={'themeMap'}
                isMobile={props.isMobile}
            >
                <div className=' contents-col theme-main-container'>
                    <div className='contents-col theme-reg-form input-form'>
                        <div className="contents-row field group position-btn">
                            <label className="label"><span className="required-marker">*</span>주제도 제목</label>
                            <input 
                                className="input-theme" 
                                type="text" 
                                id="name" 
                                name="name"
                                onChange={handleThemeNm} 
                                autoComplete="off"
                                value={themeNm_state || ''}
                                readOnly={props.activeTheme === 2}
                                // disabled={props.activeTheme === 2}
                                spellCheck="false"
                            />
                        </div>
                        <div className='contents-row inside'>
                            <div className="contents-row field group">
                                <label className="label"><span className="required-marker">*</span>관심구역</label>
                                <Select
                                    // onClick={getItrstList}
                                    className='input-theme select'
                                    options={itrst_ArrList}
                                    id="zoneId"
                                    name="zoneId"
                                    value={zoneId_state}
                                    text={zoneNm_state}
                                    onChange={handleZoneId}
                                    readOnly
                                />
                            </div>
                                {(selectedButton === 2 || selectedButton === 3) ? 
                                // {props.activeTheme === 1 && (selectedButton === 2 || selectedButton === 3) ? 
                                    <div className="contents-row field group position-btn">
                                        {renderDate()}
                                    </div>
                                    : 
                                    
                                    <div className="contents-row field group position-btn">
                                        <label className="label"><span className="required-marker">*</span>기준일자</label>
                                        <DatePicker
                                            className='input-theme'
                                            selected={props.activeTheme === 2? new Date(props.curActiveLayer.crtr_ymd) : startDate}
                                            // selected={props.activeTheme === 2 && props.curActiveLayer.crt_ty_cd === selectedButton ? new Date(props.curActiveLayer.crtr_ymd) : startDate}
                                            onChange={(date) => setStartDate(date)}
                                            // onChange={handleDateChange}
                                            locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                            // isClearable
                                            readOnly={props.activeTheme === 2}
                                        />
                                        <i className="calendar alternate outline icon" onClick={() => {}} />
                                    </div>
                                }
                        </div>
                        <div className="contents-row field group">
                            <label className="label"><span className="required-marker">*</span>생성방식</label>
                            <i
                                className={`fa-solid fa-circle-question ${isHovered ? 'hover' : ''}`}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <span className={`tooltip ${isHovered ? 'hover' : ''}`} dangerouslySetInnerHTML={{ __html: questionMarkGuide.crttycd }} />
                            </i>
                            <div className="contents-row input-theme-gray input-theme last">
                                <button onClick={props.activeTheme === 1 ? () => handleButtonClick(1) : handleButtonClick('')} className={`btn ${selectedButton === 1 ? 'active' : props.isMobile ? 'mobile-style' : 'no-hover-style'}`} >속성정보</button>
                                <button onClick={props.activeTheme === 1 ? () => handleButtonClick(2) : handleButtonClick('')} className={`btn ${selectedButton === 2 ? 'active' : props.isMobile ? 'mobile-style' : 'no-hover-style'}`}>회귀식</button>
                                <button onClick={props.activeTheme === 1 ? () => handleButtonClick(3) : handleButtonClick('')} className={`btn ${selectedButton === 3 ? 'active' : props.isMobile ? 'mobile-style' : 'no-hover-style'}`}>래스터 계산기</button>
                                <button onClick={props.activeTheme === 1 ? () => handleButtonClick(4) : handleButtonClick('')} className={`btn ${selectedButton === 4 ? 'active' : props.isMobile ? 'mobile-style' : 'no-hover-style'}`}>육안판독</button>
                                <button onClick={props.activeTheme === 1 ? () => handleButtonClick(5) : handleButtonClick('')} className={`btn ${selectedButton === 5 ? 'active' : props.isMobile ? 'mobile-style' : 'no-hover-style'}`}>직접 입력</button>
                            </div>
                        </div>
                    </div>
                    <div className='contents-col theme-reg-form scroll-container'>
                        {/* <Segment  className='scroll_box' style={{ height: '300px', overflowY: 'auto', overflowX: 'hidden' }}> */}
                        <div className='scroll_box'>
                            {selectedButton === 1 &&
                                <React.Fragment>
                                    <div className="contents-row field group position-btn zero">
                                        <label className="label"><span className="required-marker">*</span>속성정보</label>
                                        <div className='contents-row input-theme input-theme-gray property'>
                                            <div className='input-theme file file-click' onClick={handleFileClick}>
                                                {orgFileNm_state}
                                                <input 
                                                    id="property-file" 
                                                    className="input-theme file" 
                                                    type="file" 
                                                    accept=".csv"
                                                    style={{ visibility:"hidden" }}
                                                    onChange={onThemeFile}
                                                    ref={fileInputRef}
                                                />
                                            </div>
                                            <div className='btn-theme-group'>
                                                <a title="파일업로드"><label className={`gc-btn btn-theme file ${props.activeTheme === 2 ? 'hidden' : ''}`} onClick={handleFileClick}><i className="plus icon"/></label></a>
                                                <div className={`gc-btn btn-theme orange ${props.activeTheme === 2 ? 'hidden' : ''}`} onClick={TemplateDownload}>탬플릿 다운로드</div>
                                                {/* <a title="템플릿다운로드"><div className='gc-btn btn-theme file download' onClick={TemplateDownload}><i className="arrow down icon"></i></div></a> */}
                                            </div>
                                        </div>  
                                    </div> 
                                    
                                    <div className='contents-row field group'>
                                        <label className="label">기준속성</label>  
                                        <div className="contents-row input-theme-gray input-theme h-70 info">
                                            {fileCsvAttr_state && fileCsvAttr_state.map((str, index) => (
                                                <div 
                                                    onClick={props.activeTheme !== 2 ? () => handleProperpertyClick(str) : handleProperpertyClick('')}
                                                    // onClick={() => handleProperpertyClick(index)}
                                                    className={`gc-btn band property ${(clicked_state === str) ? 'active' : ''}`} 
                                                    // className={`gc-btn band property ${(selectedProperperty === index) || (props.activeTheme === 2 || props.activeTheme === 3 && viewDetailThemeMap.attr_info === str) ? 'active' : ''}`} 
                                                    key={index}
                                                >
                                                    {str}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {selectedButton === 2 &&
                                <React.Fragment>
                                    <div className='contents-row field group'>
                                        <label className="label"><span className="required-marker">*</span>드론영상</label>
                                        <div className='contents-row input-theme'>
                                            <Select
                                                className={`input-theme drone ${props.activeTheme === 2 ? ' readOnly' : ''}`}
                                                options={drone_ArrList}
                                                placeholder='드론영상 선택'
                                                value={selectDroneId_state}
                                                onChange={(e, { value }) => {
                                                    handleDrone(e, { value });
                                                    setClickedDroneNdvi('');
                                                    // setSelectedDroneNdvi(null);
                                                }}
                                                readOnly={props.activeTheme === 2}
                                            />
                                            <div className='contents-row drone-choose'>
                                                <div className='contents-row'>{renderButtons()}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='contents-row field group'>
                                        <label className="label"><span className="required-marker">*</span>생성방식</label>
                                        <div className="contents-row input-theme-gray input-theme">
                                            <div className="radioflex">
                                                {props.isMobile && selectedOption === 1 ? (
                                                    <span>(추정)현장조사 자료 선택</span>
                                                ) : (
                                                    <Checkbox
                                                        label="(추정)현장조사 자료 선택"
                                                        value={1}
                                                        checked={selectedOption === 1}
                                                        onChange={handleOptionChange}
                                                        className={`checkBox ${props.isMobile ? 'hidden' : ''}`}
                                                        readOnly={props.activeTheme === 2}
                                                    />
                                                )}
                                            </div>
                                            <div className="radioflex">
                                                {props.isMobile && selectedOption === 2 ? (
                                                    <span>직접 입력</span>
                                                ) : (
                                                    <Checkbox
                                                        label="직접 입력"
                                                        value={2}
                                                        checked={selectedOption === 2}
                                                        onChange={handleOptionChange}
                                                        className={`checkBox ${props.isMobile ? 'hidden' : ''}`}
                                                        readOnly={props.activeTheme === 2}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div> 
                                    <div className={`contents-row field group position-btn zero ${selectedOption === 2 ? 'hide' : ''}`}>
                                        <label className="label"><span className="required-marker">*</span>현장조사</label>
                                        <div className='input-theme input-theme-gray property'>
                                            <div className='input-theme file file-click' onClick={handleFileClick}>
                                                {orgFileNm_state}
                                                <input 
                                                    id="spot-investigation" 
                                                    className="input-theme file" 
                                                    type="file" 
                                                    style={{ visibility:"hidden" }}
                                                    accept='.csv'
                                                    onChange={onThemeFile}
                                                    ref={fileInputRef}
                                                />
                                            </div>
                                            <div className='btn-theme-group'>
                                                <a title="파일업로드"><label className={`gc-btn btn-theme file ${props.activeTheme === 2 ? 'hidden' : ''}`} onClick={handleFileClick}><i className="plus icon"/></label></a>
                                                <div className={`gc-btn btn-theme orange ${props.activeTheme === 2 ? 'hidden' : ''}`} onClick={TemplateDownload}>탬플릿 다운로드</div>
                                            </div>
                                        </div>  
                                    </div>
                                    
                                    <div className={`contents-row field group ${selectedOption === 2 ? 'hide' : ''}` }>
                                        <label className="label">기준속성</label>  
                                        <div className="contents-row input-theme-gray input-theme h-70 info">
                                                {fileCsvAttr_state && fileCsvAttr_state.map((str, index) => (
                                                <div 
                                                    onClick={props.activeTheme !== 2 ? () => handleProperpertyClick(str) : handleProperpertyClick('')}
                                                    className={`gc-btn band property ${(clicked_state === str) ? 'active' : ''}`} 
                                                    key={index}
                                                >
                                                    {str}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='contents-row field group'>
                                        <label className="label"><span className="required-marker">*</span>회귀식</label>
                                        <div className="contents-row input-theme-gray input-theme">
                                        <div className="radioflex" >
                                                {props.isMobile && linearTy_state === 1 ? (
                                                    <span>선형회귀식</span>
                                                ) : (
                                                    <Checkbox 
                                                        label='선형회귀식'
                                                        value={1}
                                                        checked={linearTy_state === 1}
                                                        onChange={handleLinearTy}
                                                        className={`checkBox ${props.isMobile ? 'hidden' : ''}`}
                                                        readOnly={props.activeTheme === 2}
                                                    />
                                                )}
                                            </div>
                                            <div className="radioflex" >
                                                {props.isMobile && linearTy_state === 2 ? (
                                                    <span>비선형회귀식 (2차)</span>
                                                ) : (
                                                    <Checkbox 
                                                        label='비선형회귀식 (2차)'
                                                        value={2}
                                                        checked={linearTy_state === 2}
                                                        onChange={handleLinearTy}
                                                        className={`checkBox ${props.isMobile ? 'hidden' : ''}`}
                                                        readOnly={props.activeTheme === 2}
                                                    />
                                                )}
                                            </div>
                                            <div className="radioflex" >
                                                {props.isMobile && linearTy_state === 3 ? (
                                                    <span>비선형회귀식 (3차)</span>
                                                ) : (
                                                    <Checkbox 
                                                        label='비선형회귀식 (3차)'
                                                        value={3}
                                                        checked={linearTy_state === 3}
                                                        onChange={handleLinearTy}
                                                        className={`checkBox ${props.isMobile ? 'hidden' : ''}`}
                                                        readOnly={props.activeTheme === 2}
                                                    />
                                                )}
                                                
                                            </div>
                                        </div>
                                    </div> 
                                    <div className='contents-row field group'>
                                        <label className="label">미리보기</label>
                                        <div className='contents-col preview-box'>
                                            <div className='input-theme input-theme-gray preview'>
                                                <div className='contents-col regression'>
                                                    <div className={`regression-equ ${(selectedOption === 2) ? 'hidden' : ''}`} >
                                                        <div className='graph-label'><span className="required-marker">*</span>선형 회귀분석</div>
                                                        <div className={`gc-btn btn-theme orange recal ${selectedOption === 2 ? 'hide' : ''} ${props.activeTheme === 2 ? 'hidden' : ''}`} onClick={handleCalculation}><i className="redo alternate icon"/>계산</div>
                                                        <div className='contents-row regression'>
                                                            <div className='label-blue'>y = </div>
                                                            <input 
                                                                className="label-blue input-theme equ" 
                                                                type="text" 
                                                                id="equ-a" 
                                                                name="equ-a" 
                                                                readOnly={props.activeTheme === 2 || selectedOption === 1}
                                                                onChange={handleValAOne}
                                                                value={linearTy_state===null ? oldLinearTy_state : valA_state.length > 0 ? valA_state[0].toString() || '': ''}
                                                                autoComplete="off"
                                                            />
                                                            <div className='label-blue'>
                                                                {linearTy_state === 1 ? 'x +' : linearTy_state === 2 ? 'x² +' : linearTy_state === 3 ? 'x³ +' : ''}
                                                            </div>
                                                            { linearTy_state === 2 || linearTy_state === 3 ? (
                                                            <input 
                                                                className="label-blue input-theme equ" 
                                                                type="text" 
                                                                id="equ-a" 
                                                                name="equ-a" 
                                                                readOnly={props.activeTheme === 2 || selectedOption === 1}
                                                                onChange={handleValATwo}
                                                                value={valA_state.length > 1 ? valA_state[1] || '' : ''}
                                                                autoComplete="off"
                                                            />
                                                            ) : null}
                                                            <div className='label-blue'>
                                                                {linearTy_state === 2 ? 'x +' : linearTy_state === 3 ? 'x² +' : ''}
                                                            </div>
                                                            { linearTy_state === 3 ? (
                                                            <input 
                                                                className="label-blue input-theme equ" 
                                                                type="text" 
                                                                id="equ-a" 
                                                                name="equ-a" 
                                                                readOnly={props.activeTheme === 2 || selectedOption === 1}
                                                                onChange={handleValAThree}
                                                                value={valA_state.length > 1 ? valA_state[2] || '' : ''}
                                                                autoComplete="off"
                                                            />
                                                            ) : null}
                                                            <div className='label-blue'>
                                                                {linearTy_state === 3 ? 'x +' : ''}
                                                            </div>
                                                            {/* <div className='label-blue'>x + </div> */}
                                                            <input 
                                                                className="label-blue input-theme equ" 
                                                                type="text" 
                                                                id="equ-b" 
                                                                name="equ-b" 
                                                                readOnly={props.activeTheme === 2 || selectedOption === 1}
                                                                onChange={handleValB}
                                                                value={valB_state||''}
                                                                autoComplete="off"
                                                            />
                                                            
                                                        </div>
                                                    </div>
                                                    {/* <div className='regression-equ'>
                                                        <div className='graph-label'>선형 회귀분석</div>
                                                        <div className='contents-row regression'>
                                                            <div className='label-blue'>y = </div>
                                                            <input 
                                                                className="label-blue input-theme equ" 
                                                                type="text" 
                                                                id="equ-a" 
                                                                name="equ-a" 
                                                                disabled={props.activeTheme === 2 || selectedOption === 1}
                                                                onChange={handleValA}
                                                                value={valA_state||''}
                                                            />
                                                            <div className='label-blue'>x + </div>
                                                            <input 
                                                                className="label-blue input-theme equ" 
                                                                type="text" 
                                                                id="equ-b" 
                                                                name="equ-b" 
                                                                disabled={props.activeTheme === 2 || selectedOption === 1}
                                                                onChange={handleValB}
                                                                value={valB_state||''}
                                                            />
                                                            <div className={`gc-btn btn-theme orange recal ${selectedOption === 2 ? 'hide' : ''} ${props.activeTheme === 2 ? 'hidden' : ''}`} onClick={handleCalculation}><i className="redo alternate icon"/>계산</div>
                                                        </div>
                                                    </div> */}
                                                    {selectedOption === 1 ? 
                                                        <div className='regression-equ'>
                                                            <div className='graph-label'><span className="required-marker">*</span>결정계수(R²)</div>
                                                            <div className='contents-row regression'>
                                                                <div className='label-blue'>R² =</div>
                                                                <input 
                                                                    className="label-blue input-theme equ result" 
                                                                    type="text" 
                                                                    id="determination" 
                                                                    name="determination"
                                                                    readOnly={props.activeTheme === 2 || selectedOption === 1 || selectedOption === 2}
                                                                    value={valResult_state || ''}
                                                                    onChange={handleResult}
                                                                />
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                                {selectedOption === 1 ? 
                                                    <img className='input-graph' src={grphUrl} alt=""/>
                                                : null}
                                            </div>
                                            {
                                                // props.isMobile ? 
                                                // <div className='input-theme input-theme-gray mobile-graph'>
                                                //     <img className='input-graph' src={grphUrl} alt=""/>
                                                // </div>
                                                // : null
                                            }
                                        </div>
                                    </div>
                                    {props.isMobile && selectedOption === 1 ? 
                                    <div className={`contents-row field group` }>
                                        <label className="label"></label>  
                                        <div className='input-theme input-theme-gray mobile-graph'>
                                            <img className='input-graph' src={grphUrl} alt=""/>
                                        </div>
                                        {
                                            // props.isMobile ? 
                                            // <div className='input-theme input-theme-gray mobile-graph'>
                                            //     <img className='input-graph' src={grphUrl} alt=""/>
                                            // </div>
                                            // : null
                                        }
                                    </div>
                                    : null}  
                                </React.Fragment>
                            }
                            {selectedButton === 3 &&
                                <React.Fragment>
                                   <div className='contents-row field group'>
                                        <label className="label"><span className="required-marker">*</span>드론영상</label>
                                        <div className='contents-row input-theme'>
                                        <Select
                                            className={`input-theme drone ${props.activeTheme === 2 ? ' readOnly' : ''}`}
                                            options={drone_ArrList}
                                            placeholder='드론영상 선택'
                                            value={selectDroneId_state}
                                            onChange={(e, { value }) => {
                                                handleDrone(e, { value });
                                                setClickedDroneNdvi('');
                                                // setSelectedDroneNdvi(null);
                                            }}
                                            readOnly={props.activeTheme === 2}
                                        />
                                            <div className='contents-row drone-choose'>
                                                <div className='contents-row '>{renderButtons()}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='contents-row field group'>
                                        <label className="label"><span className="required-marker">*</span>출력타입</label>
                                        <div className='contents-row input-theme '>
                                            <Select
                                                className={`input-theme drone ${props.activeTheme === 2 ? ' readOnly' : ''}`}
                                                options={[
                                                    {key: 1, text: '래스터', value: 1},
                                                    {key: 2, text: '벡터', value: 2},
                                                ]}
                                                placeholder='출력타입 선택'
                                                // defaultValue={exptTy_state}
                                                // value={exptTy_state}
                                                value={exptTy_state === 'tif' ? 1 : exptTy_state === 'shp' ? 2 : null}
                                                onChange={handleExptTy}
                                                readOnly={props.activeTheme === 2}
                                            />
                                            <div className='contents-row drone-choose'></div>
                                        </div>
                                    </div>
                                    <div className='contents-row field group'>
                                        <label className="label">밴드</label>
                                        <div className="flex-center input-theme-gray input-theme h-70 ">
                                            {renderBands()}
                                        </div>
                                    </div>
                                    <div className='contents-row field group'>
                                        <label className="label"><span className="required-marker">*</span>표현식</label>
                                        <input 
                                            className="contents-col input-theme-gray input-theme h-120"
                                            type="text" 
                                            value={inputData}
                                            onChange={handleTypeChange}
                                            readOnly={props.activeTheme === 2}
                                        />
                                    </div>
                                    <div className='contents-row field exp-result'>
                                        <label className="label display-none"></label> 
                                        <div className='input-theme input-property exp-result'>
                                            {!isValid && inputData.length > 0 ? <p>유효한 표현식입니다.</p> : <p>유효하지 않은 표현식입니다.</p>}
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {selectedButton === 4 &&
                                <React.Fragment>
                                    <div className='contents-row field group'>
                                        <div to={"/thememap"} className="contents-col input-theme-gray input-theme visual line-none" 
                                            onClick={()=>{
                                                props.setShowThemeMapAdd(true);
                                                props.setShowThemeReg(false);
                                                props.setShowImage(false);
                                                props.setShowTheme(false);
                                                props.setSideBar(false);
                                                props.onFarmmapHandler(true);
                                            }}
                                        >   
                                            ※ 클릭시 육안판독 주제도 생성 화면으로 이동합니다.
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {selectedButton === 5 &&
                                <React.Fragment>
                                    <div className="contents-row field group position-btn zero">
                                        <label className="label"><span className="required-marker">*</span>첨부파일</label>
                                        <div className='input-theme input-theme-gray property'>
                                            <div className='input-theme file file-click' onClick={handleFileClick}>
                                                {tifNm && tifNm !== null ? <span className='filename'>{tifNm}</span> : <span className='filename'>※ 직접 입력하실 파일을 업로드하세요. </span>}
                                                <input 
                                                    id="single-band"
                                                    type="file" 
                                                    accept=".tif, .zip, .ZIP"
                                                    style={{ visibility:"hidden" }}
                                                    onChange={onSingleBandTifHandler}
                                                    ref={fileInputRef}
                                                    readOnly={props.activeTheme === 2}
                                                />
                                            </div>
                                            <div className='btn-theme-group first'>
                                                <a title="파일업로드"><label className={`gc-btn btn-theme file ${props.activeTheme === 2 ? 'hidden' : ''}`} onClick={handleFileClick}><i className="plus icon"/></label></a>
                                            </div>
                                        </div>  
                                    
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        {/* </Segment> */}
                        {
                        props.activeTheme == 1?
                        <div className='btn_group three'>
                            <button className="gc-btn btn-theme-footer text-color" onClick={()=>{props.setShowThemeReg(false)}}>취소</button>
                            <button className="gc-btn btn-theme-footer primary" onClick={onThemeAdd}>등록</button>
                            {/* <button className="gc-btn btn-theme-footer primary" onClick={handleThemeAdd}>등록</button> */}
                            {/* <button className={`gc-btn btn-theme btn-theme-footer save`} onClick={onThemeAdd}>등록</button> */}
                        </div>

                        :

                        props.activeTheme == 2 || props.activeTheme == 3?
                        <div className='btn_group three'>
                            {!props.isMobile ? (
                            <>
                            {
                                themeModified?
                                null:
                                userId === props.curActiveLayer.user_id || localStorage.getItem('user-level') >= 10 ? <button className="gc-btn btn-theme-footer red" onClick={() => setShowThemeDelPopup(true)}>삭제</button> : null
                                }
                                {
                                themeModified?
                                <button onClick={onModThemeHandler} className="gc-btn btn-theme-footer text-color">취소</button>
                                :
                                userId === props.curActiveLayer.user_id || localStorage.getItem('user-level') >= 10? <button onClick={onModThemeHandler} className="gc-btn btn-theme-footer text-color">수정</button> : null
                                }
                                {
                                themeModified?
                                <button onClick={onSaveThemeHandler} className="gc-btn btn-theme-footer green">저장</button>
                                // <button onClick={handleThemeMod} className="gc-btn btn-theme-footer green">저장</button>
                                // <button onClick={themeModYes} className="gc-btn btn-theme-footer green">저장</button>
                                :
                                <button onClick={onSaveThemeHandler} className="gc-btn btn-theme-footer green">뒤로가기</button>
                            }
                            </>
                        ) : (
                            null
                            // <div className='gc-btn mobile-ok' onClick={()=>{props.setShowImageReg(false)}} >확인</div>
                        )}
                        </div>
                        :
                        null
                        }
                    </div>  
                    <div className='contents-row mobile-btn'>
                        {props.isMobile ? <div className='gc-btn mobile-ok' onClick={()=>{props.setShowThemeReg(false)}}>확인</div> : null}
                    </div>      
                </div>       
            </Modal>
            
            <Popup
                show={showThemeDelPopup}
                onCancel={() => setShowThemeDelPopup(false)}
                header="알림"
                icon={<i className="code x icon"  onClick={() => setShowThemeDelPopup(false)} /> }
            >
                <div className='modal-info'>
                    <p style={{ padding: '20px 0 15px 0' }}>주제도를 삭제하시겠습니까?<br /><p>※주제도와 관련된 농작업도 삭제됩니다.</p></p>
                    <button 
                        className="zoneinfo_close_btn" 
                        onClick={() => {
                            setShowThemeDelPopup(false);
                        }}>
                    아니오</button>
                    <button 
                        className="zoneinfo_ok_btn" 
                        onClick={onThemeDelete}
                    >
                    예</button>
                </div>
            </Popup>

            <Popup
                show={showThemeCanPopup}
                // onCancel={() => setShowCanPopup(false)}
                header="알림"
                icon={<i className="code x icon"  onClick={() => setShowThemeCanPopup(false)} /> }
            >
                <div className='modal-info'>
                    <p>수정을 취소하시겠습니까?</p>
                    <button 
                        className="zoneinfo_close_btn" 
                        onClick={() => {
                            setShowThemeCanPopup(false);
                        }}>
                    아니오</button>
                    <button 
                        className="zoneinfo_ok_btn" 
                        onClick={themeMapModNo}>
                    예</button>
                </div>
            </Popup>

            <Popup
                show={showThemeModPopup}
                onCancel={() => setShowThemeModPopup(false)}
                header="알림"
                icon={<i className="code x icon"  onClick={() => setShowThemeModPopup(false)} /> }
            >
                <div className='modal-info'>
                    <p>수정사항을 저장하시겠습니까?</p>
                    <button 
                        className="zoneinfo_close_btn" 
                        onClick={() => {
                            setShowThemeModPopup(false);
                        }}>
                    아니오</button>
                    <button 
                        className="zoneinfo_ok_btn" 
                        onClick={themeModYes}
                        // onClick={handleThemeMod}
                        // onClick={() => {
                        //     setShowDrnModPopup(false);
                        //     drnImgModYes(); 
                        // }} 
                    >예</button>
                </div>
            </Popup>

            <Popup
                show={showPopup}
                // onCancel={() => setShowPopup(false)}
                header="알림"
                icon={<i className="code x icon"  
                    onClick={() => {
                        setShowPopup(false);
                        if (popupMsg.includes('완료')) {
                            props.setShowThemeReg(false);
                            props.handleShowImage(true);
                            // props.activeFsMenuHandler(1);
                            props.setAddCheckTheme(true);
                        }
                    }} 
                /> }
            >
                <div className='modal-info'>
                    <p>{popupMsg}</p>
                    <button className="close_btn"
                        onClick={() => {
                            setShowPopup(false);
                            if (popupMsg.includes('완료') || popupMsg.includes('삭제')) {
                                props.setShowThemeReg(false);
                                props.handleShowImage(true);
                                // props.activeFsMenuHandler(1);
                                props.setAddCheckTheme(true);
                                if(delFlag){
                                    props.curActiveLayerHandler();
                                    setDelFlag(false);
                                }
                            }
                        }}
                    >닫기</button>
                </div>
            </Popup>
        </React.Fragment>
    )
}
export default ThemeMap;
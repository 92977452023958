import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import "./subMenu.style.css";

const SubMenu = ({
  userName,
  handleLogout,
  handleShowImage,
  handleShowTheme,
  handleShowFarmwork,
  handleShowDrnLog,
  handleShowFarmworkSelect,
  handleShowInstFarmwork,
  handleSideBar,
  handleShowThemeMapAdd,
  handleGuideLine,
  setActiveHeaderMenu,
  handleHeader_state,
  handleMouseLeave,
  handleIsBbs_state,
  handlePostTy,
  setShowBulletinBoard,
  isMine,
  showSubmenu,
}) => {
  return (
    <div className={`submenu-container ${showSubmenu ? "active" : ""}`}>
      <Link to={"/profile"}>
        <div className="submenu-item header">
          <Icon name="user" />
          <span className="userNm">{userName} 님</span>
          <Icon name="chevron right" />
        </div>
      </Link>
      <div className="submenu-item main">
        <ul>
          <Link
            to={"/home"}
            onClick={() => {
              setActiveHeaderMenu("/image");
              handleHeader_state(0);
              handleMouseLeave();
            }}
          >
            <li
              onClick={() => {
                handleShowImage(true);
                handleShowTheme(false);
                handleShowFarmwork(false);
                handleShowDrnLog(false);
                handleShowFarmworkSelect(false);
                handleShowInstFarmwork(false);

                handleSideBar(true);
                handleShowThemeMapAdd(false);
                handleGuideLine(false);
              }}
            >
              <Icon name="images" /> 관측 영상 등록
            </li>
          </Link>
          <Link
            to={"/home"}
            onClick={() => {
              setActiveHeaderMenu("/theme");
              handleMouseLeave();
            }}
          >
            <li
              onClick={() => {
                handleShowImage(false);
                handleShowTheme(true);
                handleShowFarmwork(false);
                handleShowDrnLog(false);
                handleShowFarmworkSelect(false);
                handleShowInstFarmwork(false);

                handleSideBar(true);
                handleShowThemeMapAdd(false);
                handleGuideLine(false);
              }}
            >
              <Icon name="chart area" />
              주제도 등록
            </li>
          </Link>
          <Link
            to={"/home"}
            onClick={() => {
              setActiveHeaderMenu("/farmwork");
            }}
          >
            <li
              onClick={() => {
                handleShowImage(false);
                handleShowTheme(false);
                handleShowFarmwork(true);
                handleShowDrnLog(false);
                handleShowFarmworkSelect(false);
                handleShowInstFarmwork(false);

                handleSideBar(true);
                handleShowThemeMapAdd(false);
                handleGuideLine(false);

                handleHeader_state(0);
              }}
            >
              <Icon name="paper plane" /> 농작업 요청
            </li>
          </Link>
          <Link
            to={"/home"}
            onClick={() => {
              setActiveHeaderMenu("/drnLog");
            }}
          >
            <li
              onClick={() => {
                handleShowImage(false);
                handleShowTheme(false);
                handleShowFarmwork(false);
                // handleShowDrnLog(true);
                handleShowFarmworkSelect(false);
                handleShowInstFarmwork(false);

                handleSideBar(true);
                handleShowThemeMapAdd(false);
                handleGuideLine(false);

                handleHeader_state(0);
              }}
            >
              <Icon name="file alternate" /> 드론 로그 업로드
            </li>
          </Link>
          <Link
            to={"/bbslist"}
            className={`bbs ${isMine ? null : "hidden"}`}
            onClick={() => {
              handleIsBbs_state("bulletinBoard");
              handlePostTy(4);
              setShowBulletinBoard(false);

              handleHeader_state(1);
            }}
          >
            <li>
              <Icon name="edit" /> 게시판
            </li>
          </Link>
          <Link
            to={"/bbslist"}
            className={`bbs ${isMine ? null : "hidden"}`}
            onClick={() => {
              handleIsBbs_state("notice");
              handlePostTy(0);
              setShowBulletinBoard(false);

              handleHeader_state(1);
            }}
          >
            <li>
              <Icon name="bell" /> 공지사항
            </li>
          </Link>
        </ul>
      </div>
      <div className="submenu-item footer" onClick={handleLogout}>
        <Icon name="power off" /> 로그아웃
      </div>
    </div>
  );
};

export default SubMenu;

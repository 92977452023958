import "./findid.css";
import React, { useState, useEffect } from "react";

import { Checkbox, Dropdown } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../../../shared/components/uiElements/Popup";
import { useLocation } from "react-router";

// AXIOS
import axios from "axios";

function ChangePw(props) {
  const navigate = useNavigate();

  const { state } = useLocation();
  // console.log(state);
  // console.log(state.pwToken)

  // console.log(props);
  // const { pwToken } = props;
  // console.log(pwToken)

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");

  // const APIToken = state.pwToken;
  const findPwClick = () => {
    if (pwMatching) {
      let isOK = validation();

      if (isOK) {
        axios({
          method: "post",
          url: `/api/account/update/pw`,
          headers: {
            Authorization: `Bearer {${state ? state.pwToken : null}}`,
            // 'Authorization': `Bearer {${APIToken}}`
          },
          data: {
            pw: newPw,
          },
        })
          .then((result) => {
            if (result.data.success) {
              setPopupMsg("비밀번호가 변경되었습니다.");
              setShowAddPopup(true);
            } else {
              setPopupMsg(
                "유효하지 않은 답변입니다.<br>확인 후 다시 시도해주세요."
              );
              setShowAddPopup(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setShowAddPopup(true);
      }
    }
  };

  const [newPw, setNewPw] = useState("");
  const [newPwCheck, setNewPwCheck] = useState("");
  const [pwMatching, setPWMatcing] = useState(false);
  const [pwMsg, setPWMsg] = useState(
    "비밀번호는 8자 이상 문자/숫자/특수문자 조합으로 설정하세요."
  );

  const handleNewPw = (e) => {
    e.preventDefault();
    setNewPw(e.target.value);
  };
  const handleNewPwChenck = (e) => {
    e.preventDefault();
    setNewPwCheck(e.target.value);
  };

  useEffect(() => {
    if (newPw === "") {
      setPWMsg("비밀번호는 8자 이상 문자/숫자/특수문자 조합으로 설정하세요.");
    }
    if (newPw) {
      if (newPw === newPwCheck) {
        setPWMsg("비밀번호가 일치합니다.");
        setPWMatcing(true);
      } else {
        setPWMsg("비밀번호가 일치하지 않습니다.");
        setPWMatcing(false);
      }
    }
  }, [newPw, newPwCheck]);

  useEffect(() => {
    setNewPw("");
    setNewPwCheck("");
    setPWMatcing(false);
    setPWMsg("비밀번호는 8자 이상 문자/숫자/특수문자 조합으로 설정하세요.");
  }, [props.showChangePwPopup]);

  var regexPw = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@$%^&+]).*$/;
  function validation() {
    if (!regexPw.test(newPw)) {
      setPopupMsg(
        "비밀번호는 문자/숫자/특수문자를<br>모두 포함하여야 합니다.<br>※사용 가능 특수문자 : ! @ $ % ^ & +"
      );
      return false;
    }
    return true;
  }

  const brCount = (popupMsg.match(/<br>/g) || []).length;
  const paddingValue =
    brCount === 0 ? "35px 0" : brCount === 1 ? "25px 0" : "15px 0";
  const fontSizeValue = brCount === 0 ? "18px" : "16px";

  return (
    <div className="contents-col findid-container">
      <div className="signup-guide">
        <div className="title">비밀번호 재설정</div>
        <div className="content">변경할 비밀번호를 입력해주세요</div>
      </div>

      <div className="division-A"></div>

      <div className="contents-row findId-inputuser">
        <div className="mainpart">
          <div className="field group subpart">
            <div className="label">새 비밀번호</div>
            <input
              type="password"
              className="input input-img"
              onChange={handleNewPw}
            />
          </div>
          <div className="field group subpart">
            <div className="label">비밀번호 확인</div>
            <input
              type="password"
              className="input input-img"
              onChange={handleNewPwChenck}
            />
          </div>
          <p className={`password ${pwMatching ? "matching" : "unmatching"}`}>
            {pwMsg}
          </p>
        </div>
      </div>

      <button className="gc-btn findId-btn" onClick={findPwClick}>
        변경
      </button>

      <div className="findId-back">
        <Link to="/"> 이전</Link>
      </div>

      <Popup
        show={showAddPopup}
        onCancel={() => setShowAddPopup(false)}
        header="알림"
        icon={
          <i
            className="code x icon"
            onClick={() => {
              setShowAddPopup(false);
            }}
          />
        }
      >
        <div className="modal-info">
          {/* <p
                        style={{
                            fontSize: popupMsg.includes('특수문자 : ! @ $ % ^ & +') ? '16px' : '18px',
                            paddingTop: popupMsg.includes('특수문자 : ! @ $ % ^ & +') ? '15px' : '', 
                        }}
                        dangerouslySetInnerHTML={{ __html: popupMsg }}
                    ></p> */}
          <p
            style={{
              fontSize: fontSizeValue,
              padding: paddingValue,
            }}
            dangerouslySetInnerHTML={{ __html: popupMsg }}
          ></p>
          <button
            className="close_btn"
            onClick={() => {
              setShowAddPopup(false);
              if (popupMsg.includes("비밀번호가 변경되었습니다.")) {
                // navigate("/");
                navigate("/home");
              }
            }}
          >
            닫기
          </button>
        </div>
      </Popup>
    </div>
  );
}

export default ChangePw;

import './findid.css';
import React, { useState, useEffect } from 'react';

import { Input, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Popup from '../../../shared/components/uiElements/Popup';

// AXIOS
import axios from "axios";

function FindId(props) {

    const [showAddPopup, setShowAddPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState('');


    const [showType, setShowType] = useState(1);
    const [searchResult, setSearchResult] = useState("");
    const [searchResultId, setSearchResultId] = useState("");
    const [searchResultDt, setSearchResultDt] = useState("");

    const handleShowType = (e, data) => {
        setShowType(data.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            findIdClick()
        }
    };

    const findIdClick = () => {
        let isAddOK = validation();
        if (isAddOK) {
            axios({
                method: 'post',
                url: `/api/account/find/id`,
                data: {
                    userNm: userNm,
                    tellNo: telNo,
                    email: userEmail,
                },
            })
            .then((result) => {
                if (result.data.success) {
                    // const findedId = result.data.data[0].user_id;
                    // const joinDt = result.data.data[0].join_dt;
                    setSearchResult(result.data.data)
                    // setSearchResultId(findedId);
                    // setSearchResultDt(joinDt);
                } else {
                    setShowAddPopup(true);
                    setPopupMsg(result.data.msg);
                }
            })
            .catch((error) => {
                // console.log(error);
            });
        }
    };

    const [userNm, setUserNm] = useState('');
    const [telNo, setTelNo] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const handleUserNm = (e) => {
        e.preventDefault();
        setUserNm(e.target.value);
    }
    const handleTelNo = (e) => {
        e.preventDefault();
        setTelNo(e.target.value);
    }
    const handleUserEmail = (e) => {
        e.preventDefault();
        setUserEmail(e.target.value);
    }
    
    var expEmail = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
    // function validation () {
    //     if (!userEmail.length > 0 && expEmail.test(email))
    //     {setPopupMsg("이메일 형식을 확인하세요."); return false}

    //     return true;
    // }


    function validation () {
        if (userNm === '') {
            setPopupMsg("이름을 입력하세요.");
            setShowAddPopup(true);
            return false;
        }
        if (showType === 1 && !telNo) {
            setPopupMsg("전화번호를 입력하세요.");
            setShowAddPopup(true);
            return false;
        }
        if (showType === 2 && !userEmail) {
            setPopupMsg("이메일을 입력하세요.");
            setShowAddPopup(true);
            return false;
        }
        // console.log(expEmail.test(userEmail))
        if (showType === 2 && userEmail.length > 0 && expEmail.test(userEmail) === false) {
            // console.log(expEmail.test(userEmail));
            setPopupMsg("이메일 형식을 확인하세요."); 
            setShowAddPopup(true);
            return false
        }
        return true;
    }

    return (
        <div className="contents-col findid-container">
            <div className="signup-guide">
                <div className="title">아이디 찾기</div>
                {searchResult ? (
                    <div className="content">입력하신 정보와 일치하는 아이디 목록입니다.</div>
                    ) : (
                    <div className="content">본인 확인 방법을 선택해 주세요</div>
                )}
            </div>  

            <div className="division-A"></div>

            {searchResult && (
                <div className="contents-row findId-inputuser">
                    <div className="mainpart">
                        {searchResult.map(item => (
                            item.actvtn_cd === 1 && (
                            <div className="field group subpart contents-row" key={item.user_id}>
                                <div className="content">{item.user_id}</div>
                                <div className="content" >{item.join_dt.slice(0,10)}</div>
                            </div>
                            )
                        ))}
                        {/* <div className="field group subpart">
                            <div className="content findId">{searchResultId}</div>
                            <div className="content ">{searchResultDt.slice(0,10)}</div>
                        </div> */}
                    </div>
                </div>
            )}
     
            {!searchResult && (
                <div className="contents-row findId-inputuser">
                    <div className="mainpart">
                    <div className="field group subpart">
                        <div className="contents-row input-img-gray input-img choose">
                        <div className="contents-row">
                            <Checkbox
                            name="selType"
                            label="전화번호"
                            type="radio"
                            value={1}
                            checked={showType == 1}
                            onChange={handleShowType}
                            />
                        </div>
                        <div className="contents-row">
                            <Checkbox
                            name="selType"
                            label="이메일"
                            type="radio"
                            value={2}
                            checked={showType == 2}
                            onChange={handleShowType}
                            />
                        </div>
                        </div>
                    </div>
                    <div className="field group subpart">
                        <div className="label find-id">이름</div>
                        <input 
                        type="text"
                        className="input input-img" 
                        onChange={handleUserNm}
                        onKeyDown={handleKeyPress}
                        />
                    </div>
                    {showType === 1 ? (
                        <div className="field group subpart">
                            <div className="label find-id">전화번호</div>
                            <input
                                type="text"
                                className="input input-img"
                                value={telNo}
                                onChange={handleTelNo}
                                onKeyDown={handleKeyPress}
                            />
                        </div>
                    ) : (
                        <div className="field group subpart">
                            <div className="label find-id">이메일</div>
                            <input
                                type="text"
                                className="input input-img"
                                value={userEmail}
                                onChange={handleUserEmail}
                                onKeyDown={handleKeyPress}
                            />
                        </div>
                    )}
                    {/* <div className="findId-info">
                        <span>*회원 등록 시 입력하신 이메일 주소를 입력하세요.</span>
                    </div> */}
                    {showType === 1 ? (
                        <div className="findId-info">
                            <span>*회원 등록 시 입력하신 이름과 전화번호를 입력하세요.</span>
                        </div>
                    ) : (
                        <div className="findId-info">
                            <span>*회원 등록 시 입력하신 이름과 이메일 주소를 입력하세요.</span>
                        </div>
                    )}
                    </div>
                </div>
            )}
  
            {searchResult ? (
                <Link to="/"><button className="gc-btn findId-btn">로그인하기</button></Link>
                ) : (
                <button className="gc-btn findId-btn" onClick={findIdClick}>검색</button>
            )}
            <div className="findId-back">
                {searchResult ? (
                    <Link to="/findpw">비밀번호 찾기</Link>
                    ) : (
                        <Link to="/home"> 이전으로</Link>
                )}
            </div>
        
            <Popup
                show={showAddPopup}
                onCancel={() => setShowAddPopup(false)}
                header="알림"
                icon={<i className="code x icon" onClick={() => setShowAddPopup(false)} />}
            >
                <div className='modal-info'>
                    <p>{popupMsg}</p>
                    <button className="close_btn" onClick={() => setShowAddPopup(false)}>닫기</button>
                </div>
            </Popup>
        </div>
    );
}

export default FindId;
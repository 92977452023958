import './TermsOfService.css';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Modal from '../../../shared/components/uiElements/Modal'
import terms from '../../../config/terms';

function TermsOfService(props) {
    return (
        <Modal
            className="termsofservice"
            show={props.showTermsOfServicePopup}
            onCancel={() => props.setShowTermsOfServicePopup(false)}
            header='비밀번호 변경'
            icon={<i className="code x icon"  onClick={() => props.setShowTermsOfServicePopup(false)} /> }
            isMobile={props.isMobile}
        >
        <div className="contents-col terms-container">
            <div className="terms-guide">
                <div className="title">{props.isTerms_state === 'terms-of-service' ? '플랫폼 이용 약관' : ' 개인정보 수집 및 이용 동의'}</div>
                {/* <div className="content">회원가입 진행을 위해 플랫폼 이용 약관, 개인정보 수집 및 이용 동의가 필요합니다.</div> */}
            </div>
            <div className="contents-col terms-inputuser">
                <div className="contents-col terms-part">
                <div className='terms-text'>
                        {props.isTerms_state === 'terms-of-service' ? terms.tos : terms.pp}
                    </div>
                </div>
            </div>
            <div className="terms-back" onClick={() => props.setShowTermsOfServicePopup(false)}>이전으로</div>
        </div>
        </Modal>
    )
}

export default TermsOfService;
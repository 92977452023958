import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'semantic-ui-css/semantic.min.css';
import HttpsRedirect from 'react-https-redirect';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.REACT_APP_HTTPS == "true") {
  root.render(
    // <React.StrictMode>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
    // </React.StrictMode>
  );
}else {
  root.render(
    // <React.StrictMode>
      <App />
    // </React.StrictMode>
  );
}

// root.render(
//   // <React.StrictMode>
//   //   <HttpsRedirect>
//       <App />
//   //   </HttpsRedirect>
//   // </React.StrictMode>
// );



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
